import { createAsyncAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import { HotelsAPI } from 'src/modules/api';
import { mapHotelsListParams } from 'src/modules/apiMappers';

const fetchHotelsActions = createAsyncAction(
  'FETCH_HOTELS_LIST_REQUEST',
  'FETCH_HOTELS_LIST_SUCCESS',
  'FETCH_HOTELS_LIST_FAILURE'
)<undefined, any, any>();

export interface HotelsState {
  fetching?: boolean;
  fetched?: boolean;
  items: [] | null;
  totalCount?: number;
  searchParams: {
    [propName: string]: any;
  };
}

export const fetchHotels = (params: object) => (dispatch: any) => {
  dispatch(fetchHotelsActions.request());
  return HotelsAPI.get('/hotels', {
    params: mapHotelsListParams(params),
  }).then(
    (response: AxiosResponse<any>) =>
      dispatch(
        fetchHotelsActions.success({
          data: response.data,
          params,
        })
      ),
    (error: AxiosError) => dispatch(fetchHotelsActions.failure(error))
  );
};

export const batchUpdateHotels =
  ({ searchParams, ids, ...rest }: any) =>
  (dispatch: any, getState: any) => {
    dispatch(fetchHotelsActions.request());
    const mappedSearchParams =
      searchParams && mapHotelsListParams(searchParams);
    return HotelsAPI.post('/hotels/batch', {
      ...mappedSearchParams,
      ids: !_isEmpty(ids) ? ids : mappedSearchParams.ids,
      ...rest,
    }).then(
      () => dispatch(fetchHotels(getState().hotels.searchParams)),
      (error: AxiosError) => dispatch(fetchHotelsActions.failure(error))
    );
  };

const hotelsDefaultState: HotelsState = {
  fetching: false,
  fetched: false,
  items: [],
  searchParams: {
    page: 1,
    pageSize: 10,
    tradingState: '',
    visibility: '',
  },
};

export const hotelsReducer: any = handleActions(
  {
    [fetchHotelsActions.request.toString()]: (state) => ({
      ...state,
      fetching: true,
      fetched: false,
    }),
    [fetchHotelsActions.success.toString()]: (state, { payload }) => ({
      ...state,
      fetching: false,
      fetched: true,
      // @ts-expect-error
      items: payload.data.items,
      // @ts-expect-error
      searchParams: payload.params,
      // @ts-expect-error
      totalCount: payload.data.totalCount,
    }),
    [fetchHotelsActions.failure.toString()]: (state) => ({
      ...state,
      fetching: false,
      fetched: false,
      items: null,
    }),
  },
  hotelsDefaultState
);
