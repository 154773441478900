import { CheckoutFields } from 'src/containers/BookingEngine/Search/components/Rooms/common/Checkout';
import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';
import { apiCall } from 'src/modules/api';
import useSWRImmutable from 'swr/immutable';
import { useSearchId } from './useSearchId';
import _isEmpty from 'lodash/isEmpty';
import { useQueryParams } from 'use-query-params';
import { URL_QUERY_PARAMS } from 'src/containers/BookingEngine/Main/SearchPanel/SearchForm';
import { useConfig } from './useConfig';

const formatValues = ({
  email,
  roomSummaries,
  language,
  phone: {
    fullNumber,
    countryData: { dialCode: phoneCountryCode },
  },
}: CheckoutFields) => ({
  bookingOwner: {
    email,
    language,
    phoneCountryCode,
    phoneNumber: fullNumber
      .replace(`+${phoneCountryCode}`, '')
      .replace(/[^+\d]/g, ''),
  },
  roomSummaries: roomSummaries.map(({ name, ...rest }) => rest),
});

export enum FailReason {
  Unknown = 0,
  Rejected = 1,
  Delayed = 2,
}

export type BookError = { code: string; message: string };

type BookResponse = {
  isSuccess: boolean;
  bookingId: string;
  bookingNumber: number;
  provider: string;
  supplier: string;
  supplierBookingId?: string;
  supplierBookingReference?: string;
  searchId?: string;
  errorMessage?: string;
  failReason?: FailReason;
  bookErrors?: BookError[];
};

type BookProps = { roomRateId?: string; checkoutFields?: CheckoutFields };

export const useBook = ({ roomRateId, checkoutFields }: BookProps) => {
  const {
    searchValue: { hotelId },
  } = useSearchContext();
  const { data } = useSearchId();
  const { data: config } = useConfig();

  const [searchParams] = useQueryParams(URL_QUERY_PARAMS);
  const currency = searchParams?.currency;
  const exchangeRate = config?.currencies[currency]!;

  return useSWRImmutable<BookResponse>(
    !_isEmpty(checkoutFields) && roomRateId && data?.searchId && hotelId
      ? [
          `api/booking-engine/search/${data?.searchId}/hotels/${hotelId}/room-rates/${roomRateId}/book`,
          checkoutFields,
        ]
      : null,
    (url) =>
      apiCall(url, {
        method: 'POST',
        data: {
          ...formatValues(checkoutFields!),
          bookingOwner: {
            ...formatValues(checkoutFields!).bookingOwner,
            currency,
          },
          exchangeRate,
        },
      })
  );
};
