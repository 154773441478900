import { FC, PropsWithChildren, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { TypographyVariant } from 'src/configs/muiTheme';

type Props = { label: ReactNode; width: string };

const labelVariant: TypographyVariant = 'label1';

export const RoomChooserFormItem: FC<PropsWithChildren<Props>> = ({
  children,
  label,
  width = 'unset',
}) => (
  <Stack
    direction="row"
    spacing={5}
    alignItems="center"
    justifyContent="space-between"
  >
    <Typography
      variant={labelVariant}
      component="label"
      display="block"
      width={width}
    >
      {label}
    </Typography>
    <Box flexGrow={1} position="relative">
      {children}
    </Box>
  </Stack>
);
