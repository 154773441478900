import { stringifyUrl } from 'query-string';
import _isEmpty from 'lodash/isEmpty';
import {
  BooleanParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useSearchId } from './useSearchId';
import useSWRInfinite from 'swr/infinite';
import { SortingType } from 'src/containers/BookingEngine/Search/components/Header/Sorting/SortringSection';
import { apiCall } from 'src/modules/api';
import { Address } from './useHotelContent';

export type Hotel = {
  address: Address;
  name: string;
  stars: number;
  distanceToPoint: number;
  id: number;
  images: string[];
};

export type Price = {
  perNight: number;
  total: number;
} | null;

export type Rates = {
  hotel: Hotel;
  sourcePrice: Price;
};

type RatesResponse = {
  rates: Rates[];
  ratesCount: number;
};

interface RatesParams {
  HotelName?: string;
  ShowSoldOut?: boolean;
  sorting?: string;
}

type Sorting = {
  SortingField?: string;
  SortingDirection?: string;
};

export enum RatesFilters {
  SHOW_SOLD_OUT = 'ShowSoldOut',
  HOTEL_NAME = 'HotelName',
  SORTING = 'sorting',
}
interface SortingParams extends RatesParams, Sorting {
  url: string;
  PageIndex: number;
  PageSize: number;
}

export const RATES_QUERY_PARAMS = {
  [RatesFilters.SHOW_SOLD_OUT]: withDefault(BooleanParam, false),
  [RatesFilters.HOTEL_NAME]: withDefault(StringParam, undefined),
  [RatesFilters.SORTING]: withDefault(StringParam, SortingType.DISTANCE_UP),
};

const withRatesParams = ({
  url,
  PageIndex,
  PageSize,
  HotelName,
  ShowSoldOut,
  SortingField,
  SortingDirection,
}: SortingParams) =>
  stringifyUrl({
    url,
    query: {
      PageIndex,
      PageSize,
      ...(HotelName ? { HotelName } : {}),
      ...(ShowSoldOut ? { ShowSoldOut } : {}),
      ...(SortingField && SortingDirection
        ? { SortingField, SortingDirection }
        : {}),
    },
  });

export const RATES_PAGE_SIZE = 50;

export const useHotels = () => {
  const { data } = useSearchId();
  const [queryParams] = useQueryParams(RATES_QUERY_PARAMS);

  const SortingField = queryParams.sorting?.split('-').shift();
  const SortingDirection = queryParams.sorting?.split('-').pop();

  return useSWRInfinite(
    (page) =>
      !_isEmpty(queryParams) && data?.searchId
        ? withRatesParams({
            url: `api/booking-engine/search/${data.searchId}/rates`,
            HotelName: queryParams.HotelName ?? undefined,
            ShowSoldOut: queryParams.ShowSoldOut ?? undefined,
            SortingField,
            SortingDirection,
            PageIndex: page,
            PageSize: RATES_PAGE_SIZE,
          })
        : null,
    async (url) => {
      const res = await apiCall<RatesResponse>(url);

      if (!Object.keys(res).length) {
        throw new Error();
      }

      return { rates: res?.rates, ratesCount: res?.ratesCount };
    },
    {
      revalidateAll: false,
      revalidateFirstPage: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );
};
