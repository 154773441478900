import { Spin as AntSpin } from 'antd';
import { SpinProps } from 'antd/es/spin';

export const Spin = (props: SpinProps) => (
  <AntSpin
    delay={100}
    {...props}
    style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...props.style,
    }}
  />
);
