import { DatePicker, Input, Select, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filtersMap } from 'src/modules/filterMap';
import { currencyFormatter } from 'src/modules/helpers';
import {
  bookingSuppliersSelector,
  currenciesSelector,
  offerStatusesSelector,
  paymentMethodsSelector,
  paymentProcessorsSelector,
  paymentStatusesSelector,
  paymentTypesSelector,
  refundReasonsSelector,
  transactionStatusesSelector,
  blockchainsSelector,
} from 'src/store/config/selectors';
import { PaginationConfig } from 'antd/lib/pagination';
import { statusToStatusColor } from 'src/store/payments/selectors';

export const defaultPaginationConfig: PaginationConfig = {
  showSizeChanger: true,
  pageSizeOptions: ['10', '25', '50'],
};

export const columns: ColumnProps<any>[] = [
  {
    title: 'Payment Date',
    dataIndex: 'createdDateTime',
    key: 'creationDate',
    sorter: true,
    width: 200,
    render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'Payment ID',
    dataIndex: 'paymentId',
    key: 'paymentId',
  },
  {
    title: 'Payment Type',
    dataIndex: 'type',
    key: 'paymentType',
    sorter: true,
    width: 120,
  },
  {
    title: 'Payment Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '10%',
    render: currencyFormatter,
  },
  {
    title: 'Payment Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: '10%',
  },
  {
    title: 'Payment Status',
    dataIndex: 'status',
    key: 'paymentStatus',
    sorter: true,
    width: 120,
    align: 'center',
    render: (value, item) => (
      <Tag style={{ margin: 0 }} color={statusToStatusColor(value)}>
        {value}
      </Tag>
    ),
  },
];

export const useFilters = () => {
  const paymentStatuses = useSelector(paymentStatusesSelector);
  const transactionStatuses = useSelector(transactionStatusesSelector);
  const paymentProcessors = useSelector(paymentProcessorsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const bookingStatuses = useSelector(offerStatusesSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const currencies = useSelector(currenciesSelector);
  const bookingSuppliers = useSelector(bookingSuppliersSelector);
  const refundReasons = useSelector(refundReasonsSelector);
  const blockchains = useSelector(blockchainsSelector);

  return useMemo(
    () => ({
      initialValues: {
        page: 1,
        pageSize: 10,
        paymentDateRange: [moment().subtract(7, 'day'), moment()],
        paymentType: '',
      },
      fields: [
        {
          FormComponent: DatePicker.RangePicker,
          compProps: {
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, 'day'),
                moment().subtract(1, 'day'),
              ],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              'Last 7 days': [moment().subtract(7, 'day'), moment()],
              'Last 30 days': [moment().subtract(30, 'day'), moment()],
            },
            format: 'YYYY/MM/DD',
          },
          ...filtersMap.paymentDateRange,
        },
        {
          FormComponent: Input,
          ...filtersMap.bookingId,
        },
        {
          FormComponent: Input,
          ...filtersMap.extTransactionId,
        },
        {
          FormComponent: Input,
          ...filtersMap.paymentId,
        },
        {
          FormComponent: Select,
          compProps: {
            allowClear: true,
            options: paymentTypes,
          },
          ...filtersMap.paymentType,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: paymentMethods,
          },
          ...filtersMap.paymentMethod,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: currencies,
          },
          ...filtersMap.paymentCurrency,
        },
        {
          FormComponent: DatePicker.RangePicker,
          compProps: {
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, 'day'),
                moment().subtract(1, 'day'),
              ],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              'Last 7 days': [moment().subtract(7, 'day'), moment()],
              'Last 30 days': [moment().subtract(30, 'day'), moment()],
            },
            format: 'YYYY/MM/DD',
          },
          ...filtersMap.lastModifiedDateRange,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: transactionStatuses,
          },
          ...filtersMap.transactionStatus,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: paymentProcessors,
          },
          ...filtersMap.paymentProcessor,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: bookingStatuses,
          },
          ...filtersMap.bookingStatus,
        },
        {
          FormComponent: Input,
          id: 'cardLastFour',
          label: 'Card Number (last 4 digits)',
          hideErrorMessage: true,
          validate: (value: any) =>
            value && value.length !== 4 ? 'Should be exactly 4 digits' : '',
        },
        {
          FormComponent: Input,
          ...filtersMap.email,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: bookingSuppliers,
          },
          ...filtersMap.supplier,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: paymentStatuses,
          },
          ...filtersMap.paymentStatus,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            options: refundReasons,
          },
          ...filtersMap.refundReason,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            mode: 'multiple',
            optionFilterProp: 'children',
            maxTagCount: 1,
            options: blockchains,
          },
          ...filtersMap.blockchain,
        },
      ],
    }),
    [
      paymentStatuses,
      transactionStatuses,
      paymentProcessors,
      paymentTypes,
      bookingStatuses,
      paymentMethods,
      currencies,
      bookingSuppliers,
      refundReasons,
      blockchains,
    ]
  );
};
