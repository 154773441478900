import { Button, Layout } from 'antd';
import { MainNavMenu } from 'src/components/MainNavMenu';
import styled from 'styled-components';
import { FC, useContext, useState } from 'react';
import { LogoutContext } from 'src/providers/AuthProvider';
import { useSelector } from 'react-redux';
import { userEmailSelector } from '../../store/user/selectors';
import { apiCall } from 'src/modules/api';
import { Alert, Portal, Snackbar } from '@mui/material';
import { TWO_FA_ENABLED_NAME } from 'src/constants';

const StyledHeader = styled(Layout.Header)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eff1fb;

  img {
    max-width: 150px;
  }
`;

export const Header: FC = () => {
  const [isError, setIsError] = useState(false);
  const is2FAEnabled = localStorage.getItem(TWO_FA_ENABLED_NAME) === 'true';

  const handleRemoveTwoFa = async () => {
    try {
      const result = await apiCall(
        'api/auth/disable-2fa',
        {
          method: 'POST',
        }
      );

      if (result) {
        localStorage.removeItem('is2FaFlowFinished');
        localStorage.removeItem(TWO_FA_ENABLED_NAME)
        window.location.reload();
      }
    } catch (e) {
      setIsError(true);
    }
   
  }
  return (
    <StyledHeader>
      <div
        style={{
          marginLeft: '5px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img alt="Logo" src="/assets/logo.png" style={{ height: '30px' }} />
        <MainNavMenu />
      </div>
     
      <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
        {is2FAEnabled ? (
          <div style={{ marginRight: 16 }}>
            <Button
              type="link"
              onClick={handleRemoveTwoFa}
              style={{ paddingRight: 0 }}
            >
              Disable 2FA
            </Button>
          </div>
        ) : null} 
        {useSelector(userEmailSelector)}
        <Button
          type="link"
          onClick={useContext(LogoutContext)}
          style={{ paddingRight: 0 }}
        >
          Logout
        </Button>
      </div>

      <Portal>
        <Snackbar
          open={isError}
          autoHideDuration={6000}
          onClose={() => setIsError(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setIsError(false)} severity="error" variant="filled">
            Something going wrong
          </Alert>
        </Snackbar>
      </Portal>
    </StyledHeader>
  )
};
