import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import styled from 'styled-components';

const getItem = (
  label: string | null,
  key?: any,
  children?: ItemType[] | null,
  type?: any
) => ({
  label,
  key,
  children,
  type,
});

const StyledMenu = styled(Menu)`
  margin-right: auto;
  font-weight: 500;
  width: 800px;
`;

const getLink = (key: string, label: string) => ({
  key,
  label: <NavLink to={key} children={label} />,
});

export const MainNavMenu: FC = () => {
  const location = useLocation();

  const items = [] as ItemType[];

  items.push(
    getItem('Bookings', 'Bookings', [
      getItem(
        'Bookings',
        null,
        [
          getLink('/bookings', 'Live Bookings'),
          getLink('/manual-bookings', 'Manual Bookings'),
        ],
        'group'
      ),
    ])
  );

  items.push(getLink('/payments', 'Payments'));

  items.push(getLink('/export', 'Exports'));

  items.push(getLink('/temporary', 'Temporary'));

  items.push(getLink('/booking-engine', 'Booking Engine'));

  items.push(getLink('/manual-transfers', 'Manual Transfers'));

  return (
    <StyledMenu
      mode="horizontal"
      selectedKeys={location.pathname.split('/').map((i) => `/${i}`)}
      items={items}
    />
  );
};
