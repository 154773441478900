import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid, { GridProps } from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ComponentType, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrencySelector } from 'src/containers/BookingEngine/Main/SearchPanel/choosers/CurrencyChooser';
import { useHotels } from 'src/hooks/swr/useHotels';
import { useSearchId } from 'src/hooks/swr/useSearchId';
import SortingSection from './Sorting/SortringSection';
import { Timer } from './Timer';

const GoBack: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { cancelFn, isValidating } = useSearchId();

  const onClick = () => {
    if (isValidating) {
      cancelFn.cancel();
    }

    navigate({
      pathname: '/booking-engine',
      search,
    });
  };

  return (
    <Stack spacing={1} direction="row" padding={0}>
      <Button
        onClick={onClick}
        startIcon={<ArrowBackIcon style={{ color: 'black' }} />}
        style={{ width: 'auto' }}
      >
        <Typography variant="body2" textTransform="capitalize" color="black">
          Go Back Search
        </Typography>
      </Button>
    </Stack>
  );
};

const TotalHotelsCount = () => {
  const { data: ratesData } = useHotels();
  const total = ratesData && ratesData[0]?.ratesCount;

  return (
    <Typography variant="body2">
      Total Hotels Count: {total ?? <CircularProgress size="1em" />}
    </Typography>
  );
};

const Sorting: FC = () => (
  <Stack
    spacing={4}
    alignItems="center"
    direction="row"
    justifyContent="flex-end"
  >
    <TotalHotelsCount />
    <CurrencySelector />
    <SortingSection />
  </Stack>
);

const sections: {
  Component: ComponentType;
  gridProps?: GridProps;
}[] = [
  {
    Component: GoBack,
    gridProps: { xs: 2 },
  },
  {
    Component: Timer,
    gridProps: { xs: 4 },
  },
  {
    Component: Sorting,
    gridProps: { xs: 10 },
  },
];

export const Header: FC = () => (
  <Stack position="sticky" top="0">
    <Grid container alignItems="center" padding="2px 20px">
      {sections.map(({ Component, gridProps }, index) => (
        <Grid item {...gridProps} key={index}>
          <Component key={index} />
        </Grid>
      ))}
    </Grid>
    <Divider />
  </Stack>
);
