import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { ArrowIcon } from 'src/components/common/ArrowIcon';

const ShowMoreContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
`;

const ShowMore = styled.div`
  color: ${(p) => p.theme.custom.linkColor};
  align-self: center;
`;

const WrappedArrowIcon = styled(ArrowIcon)`
  line-height: 9px !important;
`;

interface ExpandButtonProps {
  collapsed: boolean;
  className?: string;
  toggle: () => void;
  withArrowIcon?: boolean;
}

const ExpandButton: FC<PropsWithChildren<ExpandButtonProps>> = ({
  collapsed,
  toggle,
  className = '',
  children,
  withArrowIcon,
}) => (
  <ShowMoreContainer
    className={className}
    onClick={toggle}
    data-test-id={'DataElementsId.HOTEL_ROOM_LIST_SHOW_MORE_BUTTON'}
  >
    <ShowMore>
      {children}
      {withArrowIcon && <WrappedArrowIcon rotate={collapsed ? 0 : 180} />}
    </ShowMore>
  </ShowMoreContainer>
);

export { ExpandButton };
