import 'styled-components/macro';
import CloseIcon from '@mui/icons-material/Close';
import { alpha, FormHelperText } from '@mui/material';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { useCombobox } from 'downshift';
import {
  Loader,
  useAutocompleteService,
  useGeocoder,
} from 'google-maps-js-api-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'src/components/common/WrappedButtons';
import {
  Fields,
  FieldsName,
  SearchPanelInputProps,
} from 'src/containers/BookingEngine/Main/SearchPanel/SearchPanelForm';
import { POINTER_FINE } from 'src/configs/muiTheme';
import useDebouncedSWR from 'src/hooks/swr/useDebouncedSWR';
import handleSetValue from 'src/modules/handleSetValue';
import joinBy from 'src/modules/joinBy';
import preventLostFocus from 'src/modules/preventLostFocus';
import styled from 'styled-components';
import { useSWRConfig } from 'swr';
import { ListItem } from '../../ListItem';
import { generateVariants } from './utils';
import { GooglePredictionVariant } from './variants';
import {
  SearchFormControl,
  SearchPanelFilledInput,
} from '../components/FilledInput';
import { SearchPanelPopover } from '../../SearchPanelPopover';

const StyledButton = styled(Button)`
  padding: 4px;
  font-size: 16px;
  background: ${({ theme }) => theme.palette.divider};
  ${POINTER_FINE} {
    &:hover {
      background: ${({ theme }) => alpha(theme.palette.divider, 0.02)};
    }
  }
  @media (hover: none) {
    &:hover {
      background: ${({ theme }) => theme.palette.divider};
    }
  }
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.text.primary};
  > svg {
    font-size: 1em;
  }
`;

export const DestinationChooser: FC<SearchPanelInputProps> = (props) => (
  <SearchFormControl>
    <Controller
      name={FieldsName.DESTINATION}
      render={(renderProps) => <Destination {...props} {...renderProps} />}
    />
  </SearchFormControl>
);

const Destination: FC<SearchPanelInputProps> = ({
  openDefault,
  handleIsOpen,
}) => {
  const methods = useFormContext<Fields>();

  const defaultValue =
    methods.control._defaultValues.destination?.description || '';

  const [inputValue, setInputValue] = useState(defaultValue);

  const { cache } = useSWRConfig();

  const [wasOpened, setWasOpened] = useState(
    () => openDefault || (cache as Map<string, any>).has(`${inputValue}__gs`)
  );

  const { getPlacePredictions } = useAutocompleteService();

  const [isLongLoading, setIsLongLoading] = useState(false);

  const { data } = useDebouncedSWR(
    wasOpened && inputValue ? `${inputValue}__gs` : null,
    () => getPlacePredictions({ input: inputValue }),
    {
      debounce: 500,
      onLoadingSlow: () => {
        setIsLongLoading(true);
      },
      onSuccess: () => {
        setIsLongLoading(false);
      },
      loadingTimeout: 300,
    }
  );

  const { geocode } = useGeocoder();

  const placePredictions = data?.predictions.filter(
    (prediction) => !prediction.types.includes('country')
  );

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    openMenu,
    isOpen,
  } = useCombobox({
    items: placePredictions || [],
    onIsOpenChange:
      handleIsOpen && (({ isOpen }) => handleIsOpen(isOpen ?? false)),
    itemToString: (item) => item?.description || '',
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: false,
            inputValue: state.selectedItem?.description || '',
          };

        default:
          return changes;
      }
    },
    inputValue,
    defaultSelectedItem: {
      description: defaultValue,
    } as google.maps.places.AutocompletePrediction,
    onInputValueChange: (v) => setInputValue(v.inputValue!),
    onSelectedItemChange: async ({ selectedItem }) => {
      const { results } = await geocode(
        selectedItem?.description
          ? { address: selectedItem?.description }
          : { placeId: selectedItem?.place_id }
      );

      if (selectedItem?.place_id && results[0]?.geometry) {
        handleSetValue(
          methods,
          FieldsName.DESTINATION,
          {
            description: selectedItem.description,
            placeId: selectedItem.place_id,
            position: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
          },
          (a, b) => a?.placeId === b?.placeId
        );
        methods.clearErrors(FieldsName.DESTINATION);
      }
    },
  });

  const anchorElRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const inputLabelRef = useRef<HTMLLabelElement>(null);

  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openDefault && !isOpen) {
      void Loader.completion.then(() => {
        inputRef.current?.focus();

        openMenu();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const variants = [];

  if (
    inputValue &&
    (!placePredictions || placePredictions.length || isLongLoading)
  ) {
    variants.push(
      generateVariants(
        'destinations',
        'destinations',
        placePredictions,
        (item, index) => (
          <GooglePredictionVariant
            key={item?.place_id ?? index}
            item={item}
            {...(item ? getItemProps({ item, index }) : {})}
          />
        )
      )
    );
  }

  const cleanButton = (
    <Fade in={isOpen && Boolean(inputValue)}>
      <StyledButton
        variant="contained"
        size="small"
        color="buttonSecondary"
        onClick={() => setInputValue('')}
        tabIndex={-1}
        {...preventLostFocus}
      >
        <CloseIcon />
      </StyledButton>
    </Fade>
  );

  const formSpacing = 6;

  const modalContent = (
    <Stack
      spacing={formSpacing}
      {...getMenuProps({}, { suppressRefError: true })}
    >
      {joinBy(
        variants.map((props) => <ListItem {...props} />),
        <Divider />
      )}
    </Stack>
  );

  const handleOpen = () => {
    if (!isOpen) {
      if (!wasOpened) {
        void Loader.load();

        setWasOpened(true);
      }

      openMenu();
    }
  };

  return (
    <div ref={anchorElRef}>
      <div {...getComboboxProps()}>
        <SearchPanelFilledInput
          name={FieldsName.DESTINATION}
          focused={isOpen}
          label="Destination"
          placeholder="e.x. Copenhagen, Denmark"
          InputLabelProps={{ ref: inputLabelRef }}
          {...{
            ...getInputProps({
              refKey: 'inputRef',
              ref: inputRef,
              onClick: handleOpen,
            }),
            InputProps: {
              endAdornment: cleanButton,
            },
          }}
          fullWidth
          ref={ref}
        />
        <FormHelperText error style={{ position: 'absolute' }}>
          {methods?.formState?.errors[FieldsName.DESTINATION]?.message}
        </FormHelperText>
      </div>

      <SearchPanelPopover
        anchorEl={anchorElRef.current}
        open={isOpen && Boolean(variants.length)}
        css={`
          width: 600px;
        `}
        ref={popoverRef}
        arrowTargetRef={inputLabelRef}
      >
        {modalContent}
      </SearchPanelPopover>
    </div>
  );
};
