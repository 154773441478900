import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { ReduxState } from 'src/store';
import { statusesMapSelector } from 'src/store/config/selectors';

export const hotelSelector = (state: ReduxState) => state.hotel;

export const hotelDataSelector = createSelector(
  hotelSelector,
  statusesMapSelector,
  (hotel, statusesMap) => ({
    ...hotel.hotel,
    id: _get(hotel, 'id', ''),
    statusName: _get(statusesMap, hotel.hotel.status, { name: '' }).name,
  })
);

export const hotelDescriptionsSelector = createSelector(
  hotelSelector,
  (hotel) => hotel.descriptions
);

export const hotelImagesSelector = createSelector(
  hotelSelector,
  (hotel) => hotel.images.items
);

export const hotelRoomsSelector = createSelector(
  hotelSelector,
  (hotel) => hotel.rooms.items
);

export const hotelProvidersSelector = createSelector(
  hotelSelector,
  (hotel) => hotel.providers
);
