import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _isEmpty from 'lodash/isEmpty';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Chooser } from './Components';

const modalSpacing = { xs: 8, md: 6 };

const getRoundValue = (value: number) => Math.round(value * 100) / 100;

const msg =
  'Pay attention that Sale Price should be enetered in the Customer Currency specified above. The amount will be automatically recalculated to EUR as basic currency for saving in our system';

export enum FieldsName {
  PRICE = 'price',
  SALE_PRICE = 'salePrice',
  EXCHANGE_RATE = 'exchangeRate',
  BOOKING_REFERENCE = 'bookingReference',
  PAYMENT_REFERENCE = 'paymentReference',
  CUSTOMER_CURRENCY = 'customerCurrency',
}

export const Labels = {
  [FieldsName.PRICE]: 'Sale Price In Customer Currency:',
  [FieldsName.SALE_PRICE]: 'Sale Price in EUR:',
  [FieldsName.EXCHANGE_RATE]: 'Exchange Rate:',
  [FieldsName.BOOKING_REFERENCE]: 'Booking Reference:',
  [FieldsName.PAYMENT_REFERENCE]: 'Payment Reference:',
  [FieldsName.CUSTOMER_CURRENCY]: 'Customer Currency:',
};

export interface Fields {
  [FieldsName.PRICE]: number | null;
  [FieldsName.SALE_PRICE]: number | null;
  [FieldsName.EXCHANGE_RATE]: number | null;
  [FieldsName.BOOKING_REFERENCE]: string | null;
  [FieldsName.PAYMENT_REFERENCE]: string | null;
  [FieldsName.CUSTOMER_CURRENCY]: string;
}

type FormProps = {
  onCancel: () => void;
  onSubmit: (values: Fields) => void;
};

export const Form: FC<FormProps> = ({ onSubmit, onCancel }) => {
  const methods = useFormContext<Fields>();

  const { handleSubmit, formState, reset, watch, setValue, resetField } =
    methods;

  const price = watch(FieldsName.PRICE);
  const priceEur = watch(FieldsName.SALE_PRICE);
  const eRate = Number(watch(FieldsName.EXCHANGE_RATE));

  useEffect(() => {
    if (price === null) {
      resetField(FieldsName.SALE_PRICE, { defaultValue: null });
    } else {
      setValue(FieldsName.SALE_PRICE, getRoundValue(price / eRate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, eRate, priceEur]);

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent={{ lg: 'space-between' }}
        spacing={modalSpacing}
      >
        <Grid item xs={8}>
          <Chooser name={FieldsName.CUSTOMER_CURRENCY} disabled />
        </Grid>
        <Grid item xs={8}>
          <Chooser name={FieldsName.PRICE} type="number" />
        </Grid>
        <Grid item xs={16}>
          <Typography variant="h4">{msg}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Chooser name={FieldsName.EXCHANGE_RATE} disabled />
        </Grid>
        <Grid item xs={8}>
          <Chooser name={FieldsName.SALE_PRICE} disabled />
        </Grid>
        <Grid item xs={16}>
          <Chooser name={FieldsName.BOOKING_REFERENCE} />
        </Grid>
        <Grid item xs={16}>
          <Chooser name={FieldsName.PAYMENT_REFERENCE} />
        </Grid>

        <Grid item xs={8} />
        <Grid item xs={8}>
          <Grid
            container
            alignItems="center"
            justifyContent={{ lg: 'space-between' }}
            spacing={modalSpacing}
          >
            <Grid item xs={8}>
              <Button
                variant="contained"
                color="inherit"
                size="medium"
                fullWidth
                onClick={onCancel}
                style={{ border: '1px solid #d9d9d9' }}
              >
                Dismiss
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
                onClick={handleSubmit((v) => {
                  reset(v);
                  onSubmit(v);
                })}
                disabled={!_isEmpty(formState.errors)}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
