import Typography, { TypographyProps } from '@mui/material/Typography';
import styled from 'styled-components/macro';

const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const EllipsisTypography = ({
  children,
  ...props
}: Omit<TypographyProps, 'ref'>) => (
  <StyledTypography
    {...props}
    title={typeof children === 'string' ? children : undefined}
  >
    {children}
  </StyledTypography>
);
