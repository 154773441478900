import _findIndex from 'lodash/findIndex';
import _isFunction from 'lodash/isFunction';
import moment, { Moment } from 'moment';

const arrayMoveMutate = (array: any[], from: number, to: number) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array: any[], from: number, to: number) => {
  const newArray = array.slice();
  arrayMoveMutate(newArray, from, to);
  return newArray;
};

const _replaceAt = (array: [], index: number, replacement: any) => {
  const value = _isFunction(replacement)
    ? replacement(array[index])
    : replacement;

  if (index > -1) {
    return value
      ? [...array.slice(0, index), ...[value], ...array.slice(index + 1)]
      : [...array.slice(0, index), ...array.slice(index + 1)];
  }

  return value ? [...array, value] : array;
};

export const _replaceBy = (array: [], predicate: any, replacement: any) =>
  _replaceAt(array, _findIndex(array, predicate), replacement);

export const currencyFormatter = (amount: any) =>
  typeof amount === 'number' ? amount.toFixed(2) : amount;

export const convertDateToIso = (dt: string | Moment, isDate: boolean) => {
  const dateFormat = 'YYYY-MM-DDT00:00:00.000';
  const timeFormat = 'YYYY-MM-DDTHH:mm:00.000';
  return moment(dt).format(isDate ? dateFormat : timeFormat) + 'Z';
};

export const capitalFirstLetter = (
  str: string,
  isCapitalFirstLetter = true
) => {
  return isCapitalFirstLetter
    ? str.substring(0, 1).toUpperCase() + str.substring(1)
    : str.substring(0, 1).toLowerCase() + str.substring(1);
};

export function isDescending(order?: 'descend' | 'ascend') {
  if (order === 'descend') {
    return true;
  }
  if (order === 'ascend') {
    return false;
  }
  return undefined;
}

export enum DATE_FORMATS {
  CHECK_IN = 'DD MMM YYYY',
  CANCELLATION = 'DD MMM YYYY HH:mm',
  TIME = 'DD MMM YYYY HH:mm:ss',
}

export const getDate = (date: Moment | string, format: DATE_FORMATS): string =>
  moment.utc(date).format(format);

export const getComputedCssProperties = (
  el: HTMLElement,
  properties: any[]
) => {
  const computedStyles = window.getComputedStyle(el);

  return properties.map((cssProperty) => {
    const cssPropertyValue = computedStyles[cssProperty];
    const parsedValue = parseFloat(cssPropertyValue);
    return !isNaN(parsedValue) ? parsedValue : cssPropertyValue;
  });
};

export const DEFAULT_ERROR_MSG =
  'Oops! Something’s gone wrong. Please, try again in a few minutes';

export const TECHNICAL_ERROR_MESSAGE =
  'Sorry, a technical error occurred. Please try again later or contact the dev team';
