import {
  Alert,
  Portal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AuthResponse } from '../AuthProvider';
import { apiCall } from 'src/modules/api';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';
import EastIcon from '@mui/icons-material/East';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 140px;
  height: 140px;
  margin: 16px 0;
`;

interface VerifyTwoFaFormProps {
  qrCodeString: string;
  onSuccess: () => void;
}

export const VerifyTwoFaForm = ({
  qrCodeString,
  onSuccess,
}: VerifyTwoFaFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { code: '' },
  });

  const [errorStatus, setErrorStatus] = useState<number>();

  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    setIsError(false);
  };

  return (
    <Stack
      spacing={4}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await apiCall<AuthResponse>('api/auth/verify-2fa', {
            method: 'POST',
            data,
          });

          onSuccess();
        } catch (err) {
          setErrorStatus((err as AxiosError).response?.status);

          setIsError(true);
        }
      })}
    >
      <img alt="Logo" src="/assets/logo.png" style={{ width: '33%' }} />
      <Typography variant="h1">Verify 2FA</Typography>

      <StyledImage src={`data:image/png;base64,${qrCodeString}`} />

      <Controller
        name="code"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            label="2FA code"
            placeholder="6 digit code"
            type="text"
            {...field}
            fullWidth
            inputProps={{ maxLength: 6 }}
            required
          />
        )}
      />
      <LoadingButton
        type="submit"
        variant="contained"
        loading={isSubmitting}
        loadingPosition="end"
        endIcon={<EastIcon />}
        color="primary"
        fullWidth
      >
        Verify
      </LoadingButton>
      <Portal>
        <Snackbar
          open={isError}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity="error" variant="filled">
            Something going wrong
          </Alert>
        </Snackbar>
      </Portal>
    </Stack>
  );
};
