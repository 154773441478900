import Typography from '@mui/material/Typography';
import styled from 'styled-components/macro';

import { MonthProps } from '.';

export const StyledHeader = styled(Typography)`
  display: block;
  height: 60px;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const WEEKDAY_HEIGHT = 32;

export const StyledWeekdaysContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;
  height: ${WEEKDAY_HEIGHT}px;
`;

export const StyledMonthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDaysContainer = styled.div<Pick<MonthProps, 'sameHeight'>>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  ${({ sameHeight }) => sameHeight && 'grid-template-rows: repeat(6, 1fr);'}
`;
