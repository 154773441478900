import { InputAdornment, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import { FieldsName, SearchPanelInputProps } from '../../SearchPanelForm';
import {
  SearchFormControl,
  SearchPanelFilledInput,
} from '../components/FilledInput';

type Props = SearchPanelInputProps;

export const RadiusChooser: FC<PropsWithChildren<Props>> = () => (
  <SearchFormControl label={FieldsName.RADIUS}>
    <Controller
      name={FieldsName.RADIUS}
      render={({ field, fieldState }) => (
        <SearchPanelFilledInput
          {...field}
          focused={false}
          error={Boolean(fieldState.error?.message)}
          helperText={fieldState.error?.message}
          type="number"
          variant="filled"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Typography component="h4" fontSize={16}>
                  km
                </Typography>
              </InputAdornment>
            ),
            inputProps: {
              min: 0.1,
              max: 25,
              step: 0.1,
            },
            disableUnderline: true,
          }}
        />
      )}
    />
  </SearchFormControl>
);
