import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FC, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import { useConfig } from 'src/hooks/swr/useConfig';
import ArrowsUpDown from 'src/icons/ArrowsUpDown';
import { FieldsName, SearchPanelInputProps } from '../../SearchPanelForm';
import { SearchFormControl } from '../components/FilledInput';

type Props = SearchPanelInputProps;

export const FeedTypeChooser: FC<PropsWithChildren<Props>> = () => {
  const { data } = useConfig();

  const feedTypes = data?.feedTypes || [];

  if (feedTypes.length === 0 || !data) return null;

  return (
    <SearchFormControl label={FieldsName.FEEDTYPE}>
      <Controller
        name={FieldsName.FEEDTYPE}
        render={({ field }) => (
          <Select {...field} disableUnderline IconComponent={ArrowsUpDown}>
            {feedTypes.map((t) => (
              <MenuItem value={t.id} key={t.name}>
                {t.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </SearchFormControl>
  );
};
