import startOfDay from 'date-fns/startOfDay';

import { EDayStatus } from './Day';
import addDays from 'date-fns/addDays';

export const handleDayStatus = (
  start: Date | null,
  end: Date | null,
  monthStart: Date,
  monthEnd: Date
): ((v: Date) => EDayStatus) => {
  if (!start || monthEnd < start || (end && monthStart > end))
    return () => EDayStatus.NONE;

  let queueIndex = 0;

  const queue: ((v: Date) => EDayStatus)[] = [];

  if (monthStart <= start) {
    queue.push((v) => {
      if (v < start) return EDayStatus.NONE;

      queueIndex++;

      return EDayStatus.START;
    });
  }

  if (end) {
    queue.push((v) => {
      if (v < end) return EDayStatus.BETWEEN;

      queueIndex++;

      return EDayStatus.END;
    });
  }

  queue.push(() => EDayStatus.NONE);

  return (v) => queue[queueIndex](v);
};

export const handleDayDisabled = (monthStart: Date, monthEnd: Date) => {
  const prevDay = addDays(startOfDay(new Date()), -1);

  if (monthStart > prevDay) return () => false;

  if (monthEnd < prevDay) return () => true;

  return (v: Date) => v < prevDay;
};
