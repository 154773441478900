import { Row } from 'antd';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import {
  useFormFieldsMap,
  StyledCol,
} from 'src/containers/Bookings/BookingsList/table';

export const Form = () => {
  const {
    providerType,
    supplier,
    bookingReference,
    sourcePrice,
    providerSegmentId,
  } = useFormFieldsMap();
  return (
    <StyledForm layout="horizontal">
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...providerType} />
        </StyledCol>
        <StyledCol>
          <FormikField {...supplier} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...bookingReference} />
        </StyledCol>
        <StyledCol>
          <FormikField {...sourcePrice} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...providerSegmentId} />
        </StyledCol>
      </Row>
    </StyledForm>
  );
};
