import addDays from 'date-fns/addDays';
import setDay from 'date-fns/setDay';
import startOfDay from 'date-fns/startOfDay';
import { stringify } from 'query-string';
import { FC } from 'react';
import { useConfig } from 'src/hooks/swr/useConfig';
import { useSearchId } from 'src/hooks/swr/useSearchId';
import { useNavigate } from 'react-router-dom';
import {
  Fields,
  SearchPanelForm,
} from 'src/containers/BookingEngine/Main/SearchPanel/SearchPanelForm';
import {
  ArrayParam,
  encodeQueryParams,
  JsonParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  withDefault,
} from 'use-query-params';

const with0 = (v: number) => (v < 10 ? `0${v}` : String(v));

const dateToStr = (item: Date) =>
  `${item.getFullYear()}${with0(item.getMonth() + 1)}${with0(
    item.getDate()
  )}T120000+0000`;

const getDefaultDates = () => [
  dateToStr(setDay(startOfDay(addDays(new Date(), 7)), 5)),
  dateToStr(setDay(startOfDay(addDays(new Date(), 7)), 6)),
];

const defaultRooms = [
  {
    adults: 2,
    children: [],
  },
];

export const URL_QUERY_PARAMS = {
  rooms: withDefault(JsonParam, defaultRooms),
  dates: withDefault(ArrayParam, getDefaultDates()),
  radius: withDefault(NumberParam, 5.0),
  currency: withDefault(StringParam, 'EUR'),
  feedType: withDefault(NumberParam, 3),
  provider: withDefault(NumericArrayParam, [1, 4, 9, 32]),
  residency: withDefault(StringParam, 'DK'),
  destination: withDefault(JsonParam, undefined),
  propertyTypes: withDefault(NumericArrayParam, [1]),
};

export const SearchForm: FC = () => {
  const navigate = useNavigate();
  const { data: config } = useConfig();
  const { mutate } = useSearchId();

  const onSubmitHandler = async (data: Fields) => {
    await mutate(undefined, false);

    navigate({
      pathname: '/booking-engine/search',
      search: stringify(encodeQueryParams(URL_QUERY_PARAMS, data)),
    });
  };

  if (!config) return <>Loading...</>;

  return <SearchPanelForm onSubmit={onSubmitHandler} />;
};
