import { Spin, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import { memo } from 'react';
import { getCountryLabel } from 'src/containers/BookingEngine/Main/SearchPanel/choosers/ResidencyChooser';
import { CancellationPolicyPopOver } from 'src/containers/BookingEngine/Search/components/Rooms/common/Table/CancellationPolicy/CancellationPolicyPopOver';
import { renderTaxes } from 'src/containers/Bookings/Booking';
import { Rooms } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/Rooms';
import { useConfigMap } from 'src/hooks/swr/useConfig';
import { ManualBooking } from 'src/hooks/swr/useManualBookings';
import {
  CustomerInfo,
  Finance,
  ManualBookingsDetails,
  SupplierInfo,
  TaxesAndFee,
  HotelInfo,
  useManualBookingsDetails,
} from 'src/hooks/swr/useManualBookingsDetails';
import { IRoom } from 'src/containers/Bookings/types';
import { DATE_FORMATS, getDate } from 'src/modules/helpers';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  .ant-table-container,
  .ant-table-content {
    ::before,
    ::after {
      box-shadow: none !important;
    }
  }
  .ant-table {
    margin: 0 !important;
  }

  & .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 3px;
  }
  & .ant-table-title {
    display: flex;
    .ant-btn {
      margin-left: auto;
    }
  }
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;

  ${StyledTable} {
    margin-bottom: 5px;
  }
`;

interface IConfigItem {
  id: string;
  key:
    | keyof ManualBookingsDetails
    | keyof CustomerInfo
    | keyof Finance
    | keyof HotelInfo
    | keyof SupplierInfo;
}

interface IColumn {
  id: string;
  value?: string | number | null | number[] | boolean | TaxesAndFee[];
}

const hotelColumns: ColumnProps<any>[] = [
  {
    title: '\u00A0',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    colSpan: 2,
    width: 40,
  },
  {
    dataIndex: 'value',
    key: 'value',
    colSpan: 0,
    width: 100,
  },
];

const referencesColumns: ColumnProps<any>[] = [
  {
    title: 'References:',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    colSpan: 2,
    width: 100,
  },
  {
    dataIndex: 'value',
    key: 'value',
    colSpan: 0,
    width: 200,
  },
];

const customerColumns: ColumnProps<any>[] = [
  {
    title: 'Customer Info:',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    colSpan: 2,
    width: 27,
  },
  {
    key: 'value',
    dataIndex: 'value',
    colSpan: 0,
    width: 70,
  },
];

const customerConfig = [
  { id: 'Name', key: 'firstName' },
  { id: 'Email', key: 'email' },
  { id: 'Phone', key: 'phone' },
  { id: 'Residency', key: 'residency' },
] as IConfigItem[];

const bookingConfig = [
  { id: 'Check-In Date', key: 'checkinDate' },
  { id: 'Check-Out Date', key: 'checkoutDate' },
  { id: 'Hotel Country', key: 'countryCode' },
  { id: 'Hotel City', key: 'city' },
  { id: 'Refundability', key: 'refundability' },
] as IConfigItem[];

const hotelConfig = [
  { id: 'Hotel Name', key: 'name' },
  { id: 'Hotel Address', key: 'address' },
  { id: 'Hotel Phone', key: 'phone' },
  { id: 'Hotel Email', key: 'email' },
  { id: 'Booked By', key: 'bookedBy' },
] as IConfigItem[];

const supplierConfig = [
  { id: 'Supplier', key: 'name' },
  { id: 'Booking Reference', key: 'bookingReference' },
] as IConfigItem[];

const referencesConfig = [
  { id: 'Payment', key: 'paymentReference' },
  { id: 'Booking', key: 'bookingReference' },
] as IConfigItem[];

const financeConfig = [
  { id: 'Sale Price', key: 'salePrice' },
  { id: 'Source Price', key: 'sourcePrice' },
  { id: 'RSP', key: 'recommendedSellingPrice' },
  { id: 'Commission', key: 'commission' },
  { id: 'Taxes&Fees', key: 'taxesAndFees' },
] as IConfigItem[];

export const withCurrency = (price: number | null, cur: string) =>
  `${price === null ? '' : price} ${price ? cur.toUpperCase() : ''}`;

export const Details = memo(
  ({
    id,
    rawCancellationPolicy,
    cancellationPolicy,
    bookingCurrency,
  }: ManualBooking) => {
    const { supplierMap, countriesMap } = useConfigMap();
    const { data, isValidating } = useManualBookingsDetails(id);

    const getBookingColumns = (): ColumnProps<any>[] => [
      {
        title: 'Summary:',
        align: 'left',
        dataIndex: 'id',
        key: 'id',
        colSpan: 2,
        width: 50,
      },
      {
        key: 'value',
        colSpan: 0,
        width: 60,
        render: ({ id, value }) => {
          if (id === 'Refundability') {
            return (
              <CancellationPolicyPopOver
                cancellationPolicy={cancellationPolicy}
                cancellationPolicyRaw={rawCancellationPolicy as string}
              />
            );
          } else return value;
        },
      },
    ];

    const getFinanceColumns = (): ColumnProps<any>[] => [
      {
        title: 'Finance Info:',
        align: 'left',
        dataIndex: 'id',
        key: 'id',
        colSpan: 2,
        width: 80,
      },
      {
        key: 'value',
        colSpan: 0,
        width: 100,
        render: ({ id, value }) => {
          if (id === 'Taxes&Fees') {
            const groupedTaxes = _groupBy(
              value,
              (item) => item?.isIncludedInPrice
            );

            return (
              <>
                {renderTaxes(groupedTaxes.true, 'Included taxes: ')}
                {renderTaxes(groupedTaxes.false, 'Extra charges: ')}
              </>
            );
          } else return value;
        },
      },
    ];

    const getSupplierColumns = (): ColumnProps<any>[] => [
      {
        title: 'Supplier Info:',
        align: 'left',
        dataIndex: 'id',
        key: 'id',
        colSpan: 2,
        width: 150,
      },
      {
        key: 'value',
        colSpan: 0,
        width: 300,
        render: ({ id, value }) => {
          if (id === 'Supplier' && data?.supplierInfo?.name) {
            let href = supplierMap[data?.supplierInfo?.name]?.backofficeLink;
            if (!href) return value;
            if (!/^https?:\/\//i.test(href)) {
              href = 'http://' + href;
            }
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                children={supplierMap[value]?.name ?? value}
                href={href}
              />
            );
            // } else if (id === 'Hotel Confirmation') {
            //   return (
            //     <TextArea
            //       deleteItemId={newBookingId}
            //       deleteItemText="confirmation"
            //       rows={2}
            //       text={value}
            //       offerId={offerId}
            //       placeholder="Add a Hotel Confirmation…"
            //       handleUpdate={handleUpdateConfirmation}
            //       handleDelete={handleDeleteConfirmation}
            //     />
            //   );
          } else return value;
        },
      },
    ];

    const getTableByConfig = (config: IConfigItem[], info: any): IColumn[] =>
      config.map(({ key, id }) => {
        switch (key) {
          case 'checkinDate':
          case 'checkoutDate':
            return {
              id,
              value: getDate(info[key], DATE_FORMATS.CHECK_IN),
            };

          case 'firstName':
            return {
              id,
              value: `${info?.firstName} ${info?.lastName}`,
            };

          case 'country':
          case 'countryCode':
          case 'residency':
            return {
              id,
              value: getCountryLabel(
                _get(countriesMap[info[key]], 'name', info[key]),
                info[key]
              ),
            };

          case 'salePrice':
          case 'sourcePrice':
          case 'recommendedSellingPrice':
          case 'commission':
            return {
              id,
              value: withCurrency(
                info[key],
                info.bookingCurrency ?? bookingCurrency
              ),
            };

          default:
            return {
              id,
              value: info[key],
            };
        }
      });

    if (isValidating || !data)
      return (
        <Spin size="large" style={{ display: 'block', textAlign: 'center' }} />
      );

    const tables = [
      {
        style: { maxWidth: 350 },
        columns: customerColumns,
        dataSource: getTableByConfig(customerConfig, data.customerInfo),
      },
      {
        style: { maxWidth: 300 },
        columns: getBookingColumns(),
        dataSource: getTableByConfig(bookingConfig, {
          ...data,
          ...data.hotelInfo,
          ...data.supplierInfo,
        }),
      },
      {
        style: { maxWidth: 400 },
        columns: hotelColumns,
        dataSource: getTableByConfig(hotelConfig, {
          ...data,
          ...data.hotelInfo,
        }),
      },
    ];

    const tables2 = [
      {
        style: { maxWidth: 450, marginBottom: 0 },
        columns: getSupplierColumns(),
        dataSource: getTableByConfig(supplierConfig, {
          ...data,
          ...data.supplierInfo,
        }),
      },
      {
        style: { maxWidth: 450 },
        columns: referencesColumns,
        dataSource: getTableByConfig(referencesConfig, {
          ...data.supplierInfo,
          ...data,
        }),
      },
    ];

    const rooms = data.rooms.map(
      ({
        adultsCount: adults,
        bedType: preferredBed,
        chilndrenAges: childrenAges,
        name: roomName,
        ...rest
      }) =>
        ({
          ...rest,
          adults,
          preferredBed,
          childrenAges,
          roomName,
        } as unknown as IRoom)
    );

    return (
      <>
        <TableWrapper>
          {tables.map((t, index) => (
            <StyledTable
              {...t}
              key={index}
              bordered
              rowKey="id"
              loading={false}
              pagination={false}
              tableLayout="fixed"
            />
          ))}
          <div>
            {tables2.map((t, index) => (
              <StyledTable
                {...t}
                key={index}
                bordered
                rowKey="id"
                loading={false}
                pagination={false}
                tableLayout="fixed"
              />
            ))}
          </div>
          <StyledTable
            key="finance"
            bordered
            rowKey="id"
            loading={false}
            pagination={false}
            tableLayout="fixed"
            style={{ maxWidth: 210 }}
            columns={getFinanceColumns()}
            dataSource={getTableByConfig(financeConfig, data.finance)}
          />
        </TableWrapper>

        <Rooms rooms={rooms} />
      </>
    );
  }
);
