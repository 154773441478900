import { manualBookingPath } from 'src/containers/Bookings/api';
import { apiCall } from 'src/modules/api';
import useSWRImmutable from 'swr/immutable';

export interface ManualBookingsDetails {
  customerInfo: CustomerInfo;
  supplierInfo: SupplierInfo;
  hotelInfo: HotelInfo;
  finance: Finance;
  rooms: Room[];
  checkinDate: Date;
  checkoutDate: Date;
  bookingNumber: number;
  refundability: string;
  paymentReference: null | string;
  bookingReference: null | string;
}

export interface CustomerInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  residency: string;
}

export interface Finance {
  salePrice: null | number;
  sourcePrice: null | number;
  recommendedSellingPrice: null | number;
  commission: null | number;
  taxesAndFees: null | TaxesAndFee[];
}

export interface TaxesAndFee {
  id: null | string;
  currency: string;
  feeTitle: string;
  frequencyType: null | string;
  isIncludedInPrice: boolean;
  isMandatory: boolean;
  isValuePercentage: boolean;
  remarks: null | string;
  unitType: null | string;
  value: number;
}

export interface HotelInfo {
  countryCode: string;
  city: string;
  country: string;
  name: string;
  address: string;
  phone: string;
  email: string;
}

interface Room {
  guestFirstName: string;
  guestLastName: string;
  name: string;
  foodType: string;
  adultsCount: number;
  chilndrenAges: number[];
  bedType: string;
  specialRequest: null | string;
}

export interface SupplierInfo {
  name: string;
  bookingId: string;
  bookingReference: string;
}

export const useManualBookingsDetails = (id?: string) =>
  useSWRImmutable<ManualBookingsDetails>(
    id ? `api/backoffice${manualBookingPath}/${id}` : null,
    apiCall
  );
