import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import 'styled-components/macro';

const StyledHotelCardImgContainer = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 12px;
  mask-image: radial-gradient(white, black);
  overflow: hidden;
`;

const StyledHotelCardInfo = styled(Stack)`
  height: 100%;
`;

const StyledSubTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledName = styled(Typography)`
  overflow: hidden;
  display: inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

type HotelCardContainerProps = {
  carousel: ReactNode;
  title: ReactNode;
  subTitle: ReactNode;
  stars?: ReactNode;
  score?: ReactNode;
  rate?: ReactNode;
  likeButton?: ReactNode;
  checkAvailability?: ReactNode;
  selected: Boolean;
};

const activeMixin = css`
  cursor: default;
  border-radius: 14px;
  background: #ecedf3;
`;

const nonActiveMixin = css`
  background: 'unset';
`;

const StyledWrapper = styled.div<{ selected: Boolean }>`
  position: relative;
  padding: ${({ theme }) => theme.spacing(2)};
  ${({ selected }) => (selected ? activeMixin : nonActiveMixin)};
`;

const HotelCardContainer: FC<HotelCardContainerProps> = ({
  carousel,
  title,
  subTitle,
  selected,
  stars,
  score,
  rate,
}) => (
  <StyledWrapper selected={selected}>
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <StyledHotelCardImgContainer>{carousel}</StyledHotelCardImgContainer>
      </Grid>
      <Grid item xs={10}>
        <StyledHotelCardInfo spacing={1} justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between">
            <div>
              <StyledName variant="h5">{title}</StyledName>
              <StyledSubTitle>{subTitle}</StyledSubTitle>
              {(stars || score) && (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  marginBottom={1}
                  marginTop={1}
                >
                  {stars}
                  {score}
                </Stack>
              )}
            </div>
          </Stack>

          {rate}
        </StyledHotelCardInfo>
      </Grid>
    </Grid>
  </StyledWrapper>
);

export default HotelCardContainer;
