import { FC } from 'react';
import { Address } from 'src/hooks/swr/useHotelContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const formatAddress = (address?: Address) =>
  Object.values({
    line1: address?.line1,
    city: address?.city,
    country: address?.country,
    postalCode: address?.postalCode,
  });

const StyledTypography = styled(Typography)`
  display: inline;
`;

const StyledSpan = styled.span`
  cursor: pointer;
  display: inline;
  padding-right: 10px;
`;

type Props = { address?: Address; name?: string };

const AddressLink: FC<Props> = ({ address, name }) => {
  const addressArr = formatAddress(address);

  const openNewTabSearch = () =>
    window.open(
      `https://google.com/search?q=${[name, ...addressArr].join('+')}`,
      '_blank',
      'noopener,noreferrer'
    );

  return (
    <StyledSpan onClick={openNewTabSearch}>
      <StyledTypography variant="h2">{name}</StyledTypography>
      <br />
      <StyledTypography variant="h3" color="gray" display="inline-block">
        {addressArr.join(', ')}
      </StyledTypography>
    </StyledSpan>
  );
};

export default AddressLink;
