import Grid, { GridProps } from '@mui/material/Grid';
import _isEmpty from 'lodash/isEmpty';
import { ComponentType, FC, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { modalSpacing } from 'src/components/common/CModal';
import { Button } from 'src/components/common/WrappedButtons';
import useToggler from 'src/hooks/useToggler';
import styled from 'styled-components/macro';
import { CurencyChooser } from './choosers/CurrencyChooser';
import { DatesChooser } from './choosers/DatesChooser';
import { DestinationChooser } from './choosers/DestinationChooser';
import { FeedTypeChooser } from './choosers/FeedTypeChooser';
import { PropertyTypeChooser } from './choosers/PropertyTypeChooser';
import { ProviderChooser } from './choosers/ProviderChooser';
import { RadiusChooser } from './choosers/RadiusChooser';
import { ResidencyChooser } from './choosers/ResidencyChooser';
import { RoomChooser } from './choosers/RoomChooser';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryParams } from 'use-query-params';
import { URL_QUERY_PARAMS } from './SearchForm';
import * as yup from 'yup';
import { useConfig } from 'src/hooks/swr/useConfig';

export enum FieldsName {
  DESTINATION = 'destination',
  DATES = 'dates',
  ROOMS = 'rooms',
  LOCATION = 'location',
  RADIUS = 'radius',
  PROPERTY_TYPES = 'propertyTypes',
  PROVIDER = 'provider',
  FEEDTYPE = 'feedType',
  CURRENCY = 'currency',
  RESIDENCY = 'residency',
}

interface Destination {
  position: { lat: number; lng: number };
  placeId: string;
  description: string;
}

type Dates = [checkIn: string | null, checkOut: string | null];

export interface Child {
  age: number;
}

export interface Room {
  adults: number;
  children: Child[];
}

export interface Fields {
  [FieldsName.DESTINATION]: Destination;
  [FieldsName.DATES]: Dates;
  [FieldsName.ROOMS]: Room[];
  [FieldsName.RADIUS]: number;
  [FieldsName.RESIDENCY]: string;
  [FieldsName.CURRENCY]: string;
  [FieldsName.FEEDTYPE]: number;
  [FieldsName.PROVIDER]: number[];
  [FieldsName.PROPERTY_TYPES]: number[];
}

const Z_INDEX = 6;

const Container = styled.div`
  position: relative;
  z-index: ${Z_INDEX};
  width: 683px;
  box-shadow: 0px -3px 57px rgba(0, 0, 0, 0.05);
  border-radius: 30px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    &&& {
      margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

export type SearchPanelInputProps = {
  handleIsOpen?: (isOpen: boolean) => void;
  openDefault: boolean;
};

const choosers: {
  Component: ComponentType<SearchPanelInputProps>;
  gridProps?: GridProps;
}[] = [
  {
    Component: DestinationChooser,
    gridProps: { xs: 12 },
  },
  {
    Component: RadiusChooser,
    gridProps: { xs: 4 },
  },
  {
    Component: DatesChooser,
    gridProps: { xs: 16 },
  },
  {
    Component: RoomChooser,
    gridProps: { xs: 16 },
  },
  {
    Component: ResidencyChooser,
    gridProps: { xs: 8 },
  },
  {
    Component: CurencyChooser,
    gridProps: { xs: 8 },
  },
  {
    Component: ProviderChooser,
    gridProps: { xs: 10 },
  },
  {
    Component: FeedTypeChooser,
    gridProps: { xs: 6 },
  },
  {
    Component: PropertyTypeChooser,
    gridProps: { xs: 16 },
  },
];

type Props = {
  onSubmit: (values: Fields, destinationChanged?: boolean) => void;
};

const searchPanelValidationSchema = yup.object().shape({
  destination: yup
    .object()
    .shape({
      placeId: yup.string(),
      description: yup.string(),
      position: yup.object().shape({ lat: yup.number(), lng: yup.number() }),
    })
    .default(undefined)
    .required(),
  rooms: yup.array().required(),
  dates: yup
    .array()
    .of(yup.string().typeError('Date is empty').required())
    .length(2)
    .required(),
  radius: yup
    .number()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .typeError('Invalid Radius')
    .min(0.1, 'Min value is 0.1')
    .max(25, 'Max value is 25')
    .required('Required'),
  residency: yup.string().required(),
  currency: yup.string().required(),
  feedType: yup.string().required(),
  provider: yup.array().of(yup.number()).min(1, 'Not selected').required(),
  propertyTypes: yup.array().of(yup.number()).min(1, 'Not selected').required(),
});

export const SearchPanelForm: FC<Props> = ({ onSubmit }) => {
  const { data: config } = useConfig();

  const [queryParams] = useQueryParams(URL_QUERY_PARAMS);

  const methods = useForm<Fields>({
    defaultValues: {
      ...queryParams,
      provider: config!.providers.map((item) => item.id),
    } as Fields,
    resolver: yupResolver(searchPanelValidationSchema),
  });

  const handleRef = useRef<number | undefined>();
  const { toggler, handleClose, handleOpen } = useToggler();
  /** this method handled case when user close one input by open another */
  const handleIsOpen = (isOpen: boolean) => {
    if (handleRef.current === undefined) {
      handleRef.current = window.setTimeout(() => {
        if (isOpen !== toggler) {
          if (isOpen) {
            handleOpen();
          } else {
            handleClose();
          }
        }

        handleRef.current = undefined;
      }, 0);
    } else {
      window.clearTimeout(handleRef.current);

      handleRef.current = undefined;
    }
  };

  const { handleSubmit, formState, reset } = methods;

  const containerRef = useRef<HTMLDivElement>(null);

  const inputs = choosers.map(({ Component, gridProps }, index) => (
    <Grid item {...gridProps} key={index}>
      <Component openDefault={false} handleIsOpen={handleIsOpen} />
    </Grid>
  ));

  inputs.push(
    <Grid
      item
      sm={16}
      xs={16}
      lg={16}
      container
      justifyContent="center"
      key="submitButton"
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={handleSubmit((v) => {
          reset(v);
          onSubmit(v);
        })}
        disabled={!_isEmpty(formState.errors)} //isDirty is not working https://github.com/react-hook-form/react-hook-form/issues/4740
        sx={{ borderRadius: '16px' }}
      >
        search
      </Button>
    </Grid>
  );

  return (
    <Container ref={containerRef}>
      <FormProvider {...methods}>
        <Grid
          container
          alignItems="center"
          justifyContent={{ lg: 'space-between' }}
          spacing={modalSpacing}
          padding={7}
          columnSpacing={{ sm: 4 }}
        >
          {inputs}
        </Grid>
      </FormProvider>
    </Container>
  );
};
