import { SvgIconProps } from '@mui/material';
import { FC } from 'react';

const SortingIcon: FC<{ className?: string; props?: SvgIconProps }> = ({
  className,
  props,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M2.29289 8.29289C1.90237 8.68342 1.90237 9.31658 2.29289 9.70711C2.68342 10.0976 3.31658 10.0976 3.70711 9.70711L2.29289 8.29289ZM7 5L7.70711 4.29289C7.31658 3.90237 6.68342 3.90237 6.29289 4.29289L7 5ZM10.2929 9.70711C10.6834 10.0976 11.3166 10.0976 11.7071 9.70711C12.0976 9.31658 12.0976 8.68342 11.7071 8.29289L10.2929 9.70711ZM8 5C8 4.44772 7.55228 4 7 4C6.44772 4 6 4.44772 6 5H8ZM6 19C6 19.5523 6.44772 20 7 20C7.55228 20 8 19.5523 8 19H6ZM3.70711 9.70711L7.70711 5.70711L6.29289 4.29289L2.29289 8.29289L3.70711 9.70711ZM6.29289 5.70711L10.2929 9.70711L11.7071 8.29289L7.70711 4.29289L6.29289 5.70711ZM6 5V19H8V5H6Z"
      fill={props?.fill ?? '#24242C'}
    />
    <path
      d="M21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929C21.3166 13.9024 20.6834 13.9024 20.2929 14.2929L21.7071 15.7071ZM17 19L16.2929 19.7071C16.6834 20.0976 17.3166 20.0976 17.7071 19.7071L17 19ZM13.7071 14.2929C13.3166 13.9024 12.6834 13.9024 12.2929 14.2929C11.9024 14.6834 11.9024 15.3166 12.2929 15.7071L13.7071 14.2929ZM16 19C16 19.5523 16.4477 20 17 20C17.5523 20 18 19.5523 18 19H16ZM18 5C18 4.44772 17.5523 4 17 4C16.4477 4 16 4.44772 16 5H18ZM20.2929 14.2929L16.2929 18.2929L17.7071 19.7071L21.7071 15.7071L20.2929 14.2929ZM17.7071 18.2929L13.7071 14.2929L12.2929 15.7071L16.2929 19.7071L17.7071 18.2929ZM18 19V5H16V19H18Z"
      fill={props?.fill ?? '#24242C'}
    />
  </svg>
);

export default SortingIcon;
