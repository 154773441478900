import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tag } from 'antd';
import { FC } from 'react';

export enum Check {
  NOT_CHECKED = 'NotChecked',
  CHECKED = 'Checked',
  IN_PROGRESS = 'InProgress',
  ATTENTION = 'Attention',
  FRAUD = 'Fraud',
}

const CHECK_LABEL = {
  [Check.NOT_CHECKED]: 'Not Checked',
  [Check.CHECKED]: 'Checked',
  [Check.IN_PROGRESS]: 'In Progress',
  [Check.ATTENTION]: 'Attention',
  [Check.FRAUD]: 'Fraud',
};

export const MANUAL_CHECK_LABEL = {
  ...CHECK_LABEL,
  [Check.FRAUD]: 'Fraud/Test',
};

const COLORS_MAP = new Map([
  [Check.NOT_CHECKED, ''],
  [Check.CHECKED, 'green'],
  [Check.IN_PROGRESS, 'blue'],
  [Check.ATTENTION, 'red'],
  [Check.FRAUD, 'orange'],
]);

export interface IOfferChangeStatus {
  checkStatus: Check | string;
  offerId: string;
}

interface ICheckStatus {
  offerId: string;
  isManualBookings?: boolean;
  checkStatus: Check | string;
  changeStatus: ({ offerId, checkStatus }: IOfferChangeStatus) => Promise<any>;
}

export const CheckStatus: FC<ICheckStatus> = ({
  checkStatus,
  offerId,
  changeStatus,
  isManualBookings,
}) => {
  const LABEL = isManualBookings ? MANUAL_CHECK_LABEL : CHECK_LABEL;

  const items = Object.entries(LABEL)
    .map(([id, name]) => ({ id, name }))
    .filter(({ id }) => id !== checkStatus)
    .map(({ id, name }) => ({
      key: id,
      label: (
        <div
          key={id}
          onClick={() => changeStatus({ offerId, checkStatus: id })}
          style={{ cursor: 'pointer' }}
        >
          {name}
        </div>
      ),
    }));

  return (
    <Dropdown trigger={['click']} overlay={<Menu items={items} />}>
      <Tag
        style={{ margin: 0, cursor: 'pointer' }}
        color={COLORS_MAP.get(checkStatus as Check)}
      >
        {LABEL[checkStatus as Check] ?? checkStatus} <DownOutlined />
      </Tag>
    </Dropdown>
  );
};
