import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { AdminAPI } from 'src/modules/api';

export interface TransferFundsRequest {
  amount: number;
  networkId: string;
  currency: string;
  wallet: string;
  sourceWalletId: string;
}

export const transferFunds = (data: TransferFundsRequest) =>
  AdminAPI.post('/transfers', data)
    .then((res: AxiosResponse<any>) => {
      if (res.status === 200) {
        notification.success({
          message: 'Transfer Successful',
          description:
            'Request for funds transferring were created successfully!',
        });
      }
    })
    .catch((e: AxiosError<any>) => {
      notification.error({
        message: 'Transfer Failed',
        description: `Unable to transfer funds: ${e.message}`,
      });
    });
