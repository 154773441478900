import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import useSWRImmutable from 'swr/immutable';
import { apiCall } from 'src/modules/api';

const STATIC_CONFIG = {
  refundability: [
    { id: 0, name: 'NRF' },
    { id: 1, name: 'REF' },
    { id: 2, name: 'NRF' },
  ] as ConfigItem[],
};

export type ConfigItem = { id: number; name: string };

type ConfigResponse = {
  currencies: Record<string, string>;
  propertyTypes: ConfigItem[];
  feedTypes: ConfigItem[];
  providers: ConfigItem[];
  facilityGroups: ConfigItem[];
};

export const useConfig = () => {
  const { data } = useConfiguration();

  return useSWRImmutable(
    _isEmpty(data?.providerType) ? null : 'api/booking-engine/config',
    async (url) => {
      const res = await apiCall<ConfigResponse>(url);

      if (!Object.keys(res).length) {
        throw new Error();
      }

      return { ...STATIC_CONFIG, ...data, ...res };
    }
  );
};

type Supplier = {
  id: string;
  name: string;
  backofficeLink?: string;
};

type ConfigurationResponse = {
  bedType: ConfigItem[];
  foodType: ConfigItem[];
  providerType: ConfigItem[];
  offerStatus: ConfigItem[];
  offerCheckStatus: ConfigItem[];
  countries: { code: string; name: string }[];
  suppliers: Supplier[];
  manualBookMadeBy: string[];
};

export const useConfiguration = () =>
  useSWRImmutable('api/backoffice/Configurations', async (url) => {
    const res = await apiCall<ConfigurationResponse>(url);

    if (!Object.keys(res).length) {
      throw new Error();
    }

    return res;
  });

export const useConfigMap = () => {
  const { data: config } = useConfig();
  const { data: oldConfig } = useConfiguration();

  const offerStatusMap = _keyBy(oldConfig?.offerStatus, 'id');
  const providerTypeMap = _keyBy(oldConfig?.providerType, 'id');
  const supplierMap = _keyBy(oldConfig?.suppliers, 'id');
  const feedTypesMap = _keyBy(config?.feedTypes, 'id');
  const foodTypesMap = _keyBy(config?.foodType, 'id');
  const countriesMap = _keyBy(config?.countries, 'code');

  return {
    supplierMap,
    countriesMap,
    feedTypesMap,
    foodTypesMap,
    offerStatusMap,
    providerTypeMap,
  };
};
