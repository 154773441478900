import { POINTER_FINE } from 'src/configs/muiTheme';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  position: relative;
  padding: 10px 20px;
  border-radius: 6px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  background: #ecedf3;
  color: #24242c;

  ${POINTER_FINE} {
    &:hover {
      background: #ecedf3;
      color: #24242c;
    }
  }
`;
