import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { formatAddress } from 'src/containers/BookingEngine/common/AddressLink';
import { StyledButton } from 'src/containers/BookingEngine/common/Button';
import { Rates } from 'src/hooks/swr/useHotels';
import { useFormatPrice } from 'src/hooks/useFormatCurrency';
import useFormatDistance from 'src/hooks/useFormatDistance';
import styled, { css } from 'styled-components';
import 'styled-components/macro';
import { useSearchContext } from '../../SearchContext';
import Gallery from './Gallery';
import HotelCardContainer from './HotelCardContainer';

const activeMixin = css`
  cursor: default;
  pointer-events: none;
  border-radius: 14px;
  background: #ecedf3;
  color: black;
`;

const nonActiveMixin = css``;

const Button = styled(StyledButton)<{ selected?: Boolean }>`
  position: relative;
  border-radius: 12px;
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  ${({ selected }) => (selected ? activeMixin : nonActiveMixin)};
`;

const StyledSubTitleItem = styled(Typography)`
  overflow: hidden;
  display: inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

const HotelCard: FC<Rates> = ({ hotel, sourcePrice }) => {
  const { address, name, distanceToPoint, id, stars } = hotel;
  const formatDistance = useFormatDistance();

  const formatPrice = useFormatPrice();

  const {
    searchValue: { hotelId },
    setSearchValue,
  } = useSearchContext();

  const onCardClick = () =>
    setSearchValue({ hotelId: id, hotelImg: hotel.images[0] });

  const formattedAddress = formatAddress(address).join(', ');

  const subheaderFields = [
    <StyledSubTitleItem
      variant="body3"
      color="inherit"
      key="address"
      title={formattedAddress}
    >
      {formattedAddress}
    </StyledSubTitleItem>,
  ];

  subheaderFields.push(
    <StyledSubTitleItem
      variant="body3"
      color="inherit"
      key="distance"
      title="dsa"
    >
      {'Distance to point: ' + formatDistance(distanceToPoint)}
    </StyledSubTitleItem>
  );

  //we will add this later
  // const score = Boolean(stars) && (
  //   <Stack spacing={2} direction="row">
  //     <RatingContainer>
  //       <Typography variant="body3">{stars.toFixed(1)}</Typography>
  //     </RatingContainer>
  //     <Typography variant="body2" color="secondary">
  //       {
  //         ['Bad', 'Poor', 'Fair', 'Good', 'Very Good', 'Excellent'][
  //           Number(stars)
  //         ]
  //       }
  //     </Typography>
  //   </Stack>
  // );

  const rate = (
    <Stack direction="row" alignItems="end" justifyContent="space-between">
      <Stack alignItems="flex-start">
        <Typography variant="body4" color="text.secondary">
          Price:
        </Typography>

        <Tooltip
          placement="top"
          title={
            sourcePrice?.perNight
              ? `Price per night ${formatPrice(sourcePrice.perNight)}`
              : 'Sold out'
          }
        >
          <Typography variant="h1" color={sourcePrice ? 'black' : 'red'}>
            {sourcePrice?.total ? formatPrice(sourcePrice.total) : 'Sold Out'}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack>
        {hotelId !== id && (
          <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={onCardClick}
          >
            Select
          </Button>
        )}
      </Stack>
    </Stack>
  );

  const card = (
    <HotelCardContainer
      selected={hotelId === id}
      carousel={<Gallery {...hotel} />}
      title={name}
      rate={rate}
      subTitle={subheaderFields}
      stars={
        Boolean(stars) && (
          <Stack direction="row">
            {Array.from({ length: stars }, (_, index) => (
              <StarRoundedIcon key={index} color="primary" />
            ))}
          </Stack>
        )
      }
    />
  );
  return card;
};

export default HotelCard;
