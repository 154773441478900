import { Alert, Modal, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import styled from 'styled-components';
import { IBooking } from 'src/containers/Bookings/Booking';
import { FC } from 'react';
import { AxiosError } from 'axios';
import { Status } from '../BookingStatus';

export const STATUSES_TO_SEND_VOUCHER = [Status.COMPLETED];

const StyledModal = styled(Modal)`
  p {
    margin: 0px;
  }
  h5 {
    display: block;
    margin-left: 25px;
  }
  h4 {
    margin-top: 10px;
  }
`;

interface ISentVoucherModal {
  visible: boolean;
  initialValues: IBooking;
  onOk: (values: ISentVoucher) => Promise<void | AxiosError<any, any>>;
  onCancel: () => void;
}

export type ISentVoucher = Pick<IBooking, 'newBookingId' | 'offerId'>;

export const SendVoucherModal: FC<ISentVoucherModal> = ({
  visible,
  initialValues,
  onOk,
  onCancel,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (response: any) => {
          setSubmitting(false);
          if (response.errors) {
            setStatus({
              commonApiErrors: response.errors.filter(
                ({ field }: any) => !field
              ),
              fieldApiErrors: _keyBy(
                response.errors.filter(({ field }: any) => field),
                'field'
              ),
            });
          }
        }
      );
    },
  });

  const { status, isSubmitting, handleSubmit } = formik;
  const { newBookingId } = initialValues;

  return (
    <FormikProvider value={formik}>
      <StyledModal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title={`Send voucher (${newBookingId})`}
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Send voucher"
        cancelText="Cancel"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <h4>You're attempting to send a voucher</h4>
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </StyledModal>
    </FormikProvider>
  );
};
