import { FC } from 'react';
import { Facility, useHotelContent } from 'src/hooks/swr/useHotelContent';
import styled from 'styled-components/macro';
import _groupBy from 'lodash/groupBy';
import { Title, Info, InfoBlock } from 'src/components/InfoBlock';
import { getIconByFacilityId } from 'src/modules/facilities';
import { ItemWrapper, Item, Icon } from './common';
import { useConfig } from 'src/hooks/swr/useConfig';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import './styles/index.css';

const Wrapper = styled.ul`
  width: 100%;
`;

const InfoBlockWrapper = styled.li`
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
`;

const StyledInfoBlock = styled(InfoBlock)`
  ${Title} {
    font-size: 16px;
  }

  ${Info} {
    padding-left: 25px;
  }

  ${Icon} {
    padding-top: 10px;
  }
`;

interface FacilitiesItemProps {
  facilities: Facility[];
}

const FacilitiesItem: FC<FacilitiesItemProps> = ({ facilities }) => (
  <>
    {facilities.map((facilitySubItem) => (
      <ItemWrapper key={facilitySubItem.name}>
        <Item>{facilitySubItem.name}</Item>
      </ItemWrapper>
    ))}
  </>
);

export const HotelFacilities: FC = () => {
  const { data: config } = useConfig();
  const { data } = useHotelContent();

  const facilityGroupsMap = new Map(
    config?.facilityGroups.map(({ id, name }) => [id, name])
  );

  const facilities: { [key: number]: Facility[] } = _groupBy(
    data?.hotelContent.facilities,
    (facility) => facility.groupId
  );

  if (!data?.hotelContent.facilities?.length || !config?.facilityGroups) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Typography variant="h3" marginLeft={10}>
        Facilities:
      </Typography>
      <Wrapper>
        {Object.entries(facilities).map(([groupId, facilities]) => (
          <InfoBlockWrapper key={groupId}>
            <StyledInfoBlock
              title={facilityGroupsMap.get(Number(groupId))}
              iconElement={
                <Icon className={getIconByFacilityId(Number(groupId))} />
              }
            >
              <FacilitiesItem facilities={facilities} />
            </StyledInfoBlock>
          </InfoBlockWrapper>
        ))}
      </Wrapper>
    </Stack>
  );
};
