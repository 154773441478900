import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { BackofficeAPI } from 'src/modules/api';
import { TValidationSchema } from './';

export const updateHotelSettings = ({ hotelId, ...rest }: TValidationSchema) =>
  //@ts-expect-error
  BackofficeAPI.patch(`/hotels/${hotelId.trim()}/settings`, rest)
    .then((res: AxiosResponse<any>) => {
      if (res.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Nice Job! Hotel settings updated!',
        });
      }
    })
    .catch((e: AxiosError<any>) => {
      notification.warning({
        message: 'Hotel settings not updated!:(',
        description: e.message,
      });
    });
