import { Checkbox, Col, DatePicker, Input, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSelectOptions, filterOption } from 'src/components/FormikField';
import { filtersMap } from 'src/modules/filterMap';
import { DATE_FORMATS, getDate } from 'src/modules/helpers';
import { defaultSearchParams } from 'src/store/bookings/reducer';
import {
  bedTypesSelector,
  bookingSourcesSelector,
  bookingSuppliersSelector,
  countryCodesSelector,
  foodTypesSelector,
  offerCheckStatusesSelector,
  offerStatusesSelector,
  providerTypesSelector,
} from 'src/store/config/selectors';
import styled from 'styled-components';
import { IBooking } from '../Booking';
import { childAges } from '../helpers';
import { BookingStatus } from './compounds/BookingStatus';

export const StyledCol = styled(Col)`
  flex-basis: 50%;
  width: 50%;
`;

export const columns: ColumnProps<IBooking>[] = [
  {
    title: 'Booking Status',
    dataIndex: 'bookingStatus',
    key: 'bookingStatus',
    width: 90,
    sorter: true,
    render: (bookingStatus) => <BookingStatus bookingStatus={bookingStatus} />,
  },
  {
    title: 'Booking Date',
    dataIndex: 'creationDateTime',
    key: 'creationDate',
    width: 70,
    sorter: true,
    render: (creationDateTime) => getDate(creationDateTime, DATE_FORMATS.TIME),
  },
  {
    title: 'New Booking ID',
    key: 'newBookingId',
    width: 90,
    render: ({ newBookingId, offerId }) => (
      <Link
        to={`/bookings/${offerId}`}
        rel="noopener noreferrer"
        target="_blank"
        children={newBookingId}
      />
    ),
  },
  {
    title: 'Customer Name',
    key: 'Customer Name',
    width: 90,
    render: ({ guestFirstName, guestLastName }) =>
      `${guestFirstName} ${guestLastName}`,
  },
  {
    title: 'Check-In Date',
    dataIndex: 'checkInDate',
    key: 'checkInDate',
    width: 70,
    sorter: true,
    render: (checkInDate) => getDate(checkInDate, DATE_FORMATS.CHECK_IN),
  },
  {
    title: 'Check-Out Date',
    dataIndex: 'checkOutDate',
    key: 'checkOutDate',
    width: 70,
    sorter: true,
    render: (checkOutDate) => getDate(checkOutDate, DATE_FORMATS.CHECK_IN),
  },
  {
    title: 'Provider',
    key: 'providerType',
    width: 90,
    sorter: true,
    render: ({ providerType, providerName }) => providerName || providerType,
  },
  {
    title: 'Supplier',
    key: 'supplier',
    width: 100,
    sorter: true,
    render: ({ supplierInfo, supplier }) => supplierInfo?.name || supplier,
  },
  {
    title: 'Sale Price',
    key: 'salePrice',
    width: 80,
    render: ({ salePrice, transactionFee, bookedCurrency }) =>
      (salePrice + transactionFee).toFixed(2) + ' ' + bookedCurrency,
  },
  {
    title: 'Booking Reference',
    dataIndex: 'bookingReference',
    key: 'bookingReference',
    width: 80,
  },
];

export const useFilters = () => {
  const countries = useSelector(countryCodesSelector);
  const providers = useSelector(providerTypesSelector);
  const suppliers = useSelector(bookingSuppliersSelector);
  const bookingSources = useSelector(bookingSourcesSelector);
  const offerStatuses = useSelector(offerStatusesSelector);
  const offerCheckStatuses = useSelector(offerCheckStatusesSelector);

  return useMemo(
    () => ({
      initialValues: defaultSearchParams,
      fields: [
        {
          FormComponent: DatePicker.RangePicker,
          compProps: {
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, 'day'),
                moment().subtract(1, 'day'),
              ],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              'Last 7 days': [moment().subtract(7, 'day'), moment()],
              'Last 30 days': [moment().subtract(30, 'day'), moment()],
            },
            format: DATE_FORMATS.CHECK_IN,
          },
          ...filtersMap.bookingDateRange,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.NewBookingId,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.SupplierBookingReference,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.customerEmail,
        },
        {
          FormComponent: DatePicker,
          compProps: {
            format: DATE_FORMATS.CHECK_IN,
            placeholder: 'All',
            showToday: false,
          },
          ...filtersMap.checkInDate,
        },
        {
          FormComponent: DatePicker,
          compProps: {
            format: DATE_FORMATS.CHECK_IN,
            placeholder: 'All',
            showToday: false,
          },
          ...filtersMap.checkOutDate,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: providers,
          },
          ...filtersMap.providerType,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: suppliers,
          },
          ...filtersMap.supplierIn,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: countries,
          },
          ...filtersMap.HotelCountryIn,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.HotelCity,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.HotelName,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: offerStatuses,
          },
          ...filtersMap.BookingStatus,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: offerCheckStatuses,
          },
          ...filtersMap.CheckStatus,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.customerName,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.customerId,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.customerPhone,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: bookingSources,
          },
          ...filtersMap.Source,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.ExternalId,
        },
        {
          FormComponent: Checkbox,
          compProps: {
            children: filtersMap.OnlyVipUser.label,
          },
          ...filtersMap.OnlyVipUser,
        },
        {
          FormComponent: Checkbox,
          compProps: {
            children: filtersMap.OnlyWithSpecialRequest.label,
          },
          ...filtersMap.OnlyWithSpecialRequest,
        },
      ],
    }),
    [
      countries,
      providers,
      suppliers,
      bookingSources,
      offerStatuses,
      offerCheckStatuses,
    ]
  );
};

export const useFormFieldsMap = () => {
  const bedTypes = useSelector(bedTypesSelector);
  const foodTypes = useSelector(foodTypesSelector);
  const countries = useSelector(countryCodesSelector);
  const providers = useSelector(providerTypesSelector);
  const suppliers = useSelector(bookingSuppliersSelector);
  return useMemo(
    () => ({
      //Room Form
      roomName: {
        key: 'roomName',
        name: 'roomName',
        label: 'Room Name',
        FormComponent: Input,
      },
      guestFirstName: {
        key: 'guestFirstName',
        name: 'guestFirstName',
        label: 'Guest First Name',
        FormComponent: Input,
      },
      residency: {
        key: 'residency',
        name: 'residency',
        label: 'Residency',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(countries),
      },
      guestLastName: {
        key: 'guestLastName',
        name: 'guestLastName',
        label: 'Guest Last Name',
        FormComponent: Input,
      },
      foodType: {
        key: 'foodType',
        name: 'foodType',
        label: 'Food Type',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(foodTypes),
      },
      preferredBed: {
        key: 'preferredBed',
        name: 'preferredBed',
        label: 'Preferred bed',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(bedTypes),
      },
      adults: {
        key: 'adults',
        name: 'adults',
        label: 'Adults',
        FormComponent: Input,
      },
      childrenAges: {
        key: 'childrenAges',
        name: 'childrenAges',
        label: 'childrenAges',
        FormComponent: Select,
        options: childAges,
      },
      specialRequest: {
        key: 'specialRequest',
        name: 'specialRequest',
        label: 'Special Request',
        FormComponent: Input.TextArea,
      },
      //Summary Form
      hotelName: {
        key: 'hotelName',
        name: 'hotelName',
        label: 'Hotel Name',
        FormComponent: Input,
      },
      hotelCountry: {
        key: 'hotelCountry',
        name: 'hotelCountry',
        label: 'Hotel Country',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(countries),
      },
      hotelCity: {
        key: 'hotelCity',
        name: 'hotelCity',
        label: 'Hotel City',
        FormComponent: Input,
      },
      hotelAddress: {
        key: 'hotelAddress',
        name: 'hotelAddress',
        label: 'Hotel Address',
        FormComponent: Input,
      },
      hotelPhone: {
        key: 'hotelPhone',
        name: 'hotelPhone',
        label: 'Hotel Phone',
        FormComponent: Input,
      },
      hotelEmail: {
        key: 'hotelEmail',
        name: 'hotelEmail',
        label: 'Hotel Email',
        FormComponent: Input,
      },
      price: {
        key: 'price',
        name: 'price',
        label: 'Price',
        FormComponent: Input,
      },
      sellingPrice: {
        key: 'sellingPrice',
        name: 'sellingPrice',
        label: 'Selling Price',
        FormComponent: Input,
      },
      fee: {
        key: 'fee',
        name: 'fee',
        label: 'Fee',
        FormComponent: Input,
      },
      refundability: {
        key: 'refundability',
        name: 'refundability',
        label: 'Refundability',
        FormComponent: Select,
        children: getSelectOptions([
          {
            id: 'Refundable',
            name: 'Refundable',
          },
          {
            id: 'Nonrefundable',
            name: 'Non-refundable',
          },
        ]),
      },
      bookingRemarks: {
        key: 'bookingRemarks',
        name: 'bookingRemarks',
        label: 'Booking Remarks',
        FormComponent: Input.TextArea,
      },
      checkInDate: {
        key: 'checkInDate',
        name: 'checkInDate',
        label: 'Check-In Date',
        FormComponent: DatePicker,
        popupClassName: 'ant-date-time-picker-without-footer',
        format: DATE_FORMATS.CHECK_IN,
        showToday: false,
        showNow: false,
      },
      checkOutDate: {
        key: 'checkOutDate',
        name: 'checkOutDate',
        label: 'Check-Out Date',
        FormComponent: DatePicker,
        popupClassName: 'ant-date-time-picker-without-footer',
        format: DATE_FORMATS.CHECK_IN,
        showToday: false,
        showNow: false,
      },
      dateFrom: {
        key: 'dateFrom',
        name: 'dateFrom',
        label: 'Cancellation Policy',
        FormComponent: DatePicker,
        popupClassName: 'ant-date-time-picker-without-footer',
        showTime: { defaultValue: moment('00:00', 'HH:mm') },
        format: DATE_FORMATS.CANCELLATION,
        showToday: false,
        showNow: false,
      },
      //Customer Form
      customerEmail: {
        key: 'customerEmail',
        name: 'customerEmail',
        label: 'Email',
        FormComponent: Input,
      },
      customerPhone: {
        key: 'customerPhone',
        name: 'customerPhone',
        label: 'Phone',
        FormComponent: Input,
      },
      //SupplierForm
      providerType: {
        key: 'providerType',
        name: 'providerType',
        label: 'Provider',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(providers),
      },
      supplier: {
        key: 'supplier',
        name: 'supplier',
        label: 'Supplier',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(suppliers),
      },
      bookingReference: {
        key: 'bookingReference',
        name: 'bookingReference',
        label: 'Booking Reference',
        FormComponent: Input,
      },
      sourcePrice: {
        key: 'sourcePrice',
        name: 'sourcePrice',
        label: 'Source Price',
        FormComponent: Input,
      },
      providerSegmentId: {
        key: 'providerSegmentId',
        name: 'providerSegmentId',
        label: 'Provider Segment ID',
        FormComponent: Input,
      },
      providerBookingId: {
        key: 'providerBookingId',
        name: 'providerBookingId',
        label: 'Provider Booking ID',
        FormComponent: Input,
      },
    }),
    [bedTypes, foodTypes, countries, providers, suppliers]
  );
};
