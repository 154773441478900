import { Col, DatePicker, Select, Tag, Form, Tooltip } from 'antd';
import { StyledSelect } from 'src/components/StyledSelect';
import _find from 'lodash/find';
import _isObject from 'lodash/isObject';
import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _omit from 'lodash/omit';
import styled from 'styled-components';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';

const isFieldEmpty = (value: any) =>
  value == null || value === '' || (_isArray(value) && _isEmpty(value));

const formatter = (value: any, field: any) => {
  if (field.FormComponent === DatePicker) {
    return value.format('YYYY-MM-DD');
  }
  if (field.FormComponent === DatePicker.RangePicker) {
    return `${value[0].format('YYYY-MM-DD')} - ${value[1].format(
      'YYYY-MM-DD'
    )}`;
  }
  if ([Select, StyledSelect].includes(field.FormComponent)) {
    const valueProp = field.compProps.valueProp || 'id';
    const isCollection = _isObject(field.compProps.options[0]);
    if (field.compProps.mode === 'multiple') {
      return isCollection
        ? value
            .map(
              (item: any) =>
                _find(field.compProps.options, { [valueProp]: item })?.name
            )
            .join(', ')
        : value.join(', ');
    }
    return isCollection
      ? _find(field.compProps.options, { [valueProp]: value }).name
      : value;
  }
  return value;
};

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  border-radius: 50%;
  font-size: 13px;
  opacity: 0.6;
  transition: 0.3s;
  &:hover {
    color: red;
    background-color: white;
    opacity: 1;
  }
`;

const StyledTag = styled(Tag)`
  cursor: default;
  &.ant-tag {
    background: #7b7b9b;
    border-radius: 50px;
    color: #fff;
    vertical-align: middle;
    font-size: 12px;
    padding: 2px 8px;
    margin-bottom: 10px;

    .filter-value {
      font-size: 13px;
      font-weight: 500;
    }
  }
`;

export const FilterTags = ({ fields, values, onChangeValues }: any) => {
  const filterTags = fields
    .filter((field: any) =>
      field.allowEmptyString && values[field.id] === ''
        ? true
        : !isFieldEmpty(values[field.id])
    )
    .map((field: any) => ({
      label:
        field.allowEmptyString &&
        values[field.id] === '' &&
        field.compProps.checkbox.label
          ? field.compProps.checkbox.label
          : field.label,
      id: field.id,
      value: formatter(values[field.id], field),
    }));

  const onClick = (id: string) => {
    if (_isFunction(onChangeValues)) {
      onChangeValues(_omit(values, id));
    }
  };

  return filterTags.length ? (
    <Form.Item>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Col className="ant-form-item-label" span={24}>
          <label style={{ fontWeight: 500, fontSize: 12, marginRight: 20 }}>
            Active filters:
          </label>
          {filterTags.map((tag: any) => (
            <StyledTag key={tag.id}>
              {tag.label}
              {tag.value === true || tag.value === '' ? '' : ': '}
              <span className="filter-value">{tag.value}</span>
              <Tooltip title={`Click to reset "${tag.label}" filters`}>
                <StyledCloseCircleOutlined onClick={() => onClick(tag.id)} />
              </Tooltip>
            </StyledTag>
          ))}
        </Col>
      </div>
    </Form.Item>
  ) : null;
};
