import {
  DownloadOutlined,
  DownOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Layout,
  Menu,
  notification,
  TablePaginationConfig,
} from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import { AxiosError, AxiosResponse } from 'axios';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _forEach from 'lodash/forEach';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersPanel } from 'src/components/FiltersPanel';
import { StyledPageHeader } from 'src/components/StyledPageHeader';
import { Column, StyledTable } from 'src/components/StyledTable';
import {
  CheckStatus,
  IOfferChangeStatus,
} from 'src/containers/Bookings/BookingsList/compounds/CheckStatus';
import { TextArea } from 'src/containers/Bookings/BookingsList/compounds/TextArea';
import {
  columns,
  useFilters,
} from 'src/containers/Bookings/BookingsList/table';
import {
  BModal,
  BModals,
  defaultBModalState,
} from 'src/containers/Bookings/helpers';
import { InfoDescription } from 'src/containers/PaymentsList/index';
import { BackofficeAPI } from 'src/modules/api';
import { mapBookingsListParams } from 'src/modules/apiMappers';
import { capitalFirstLetter, isDescending } from 'src/modules/helpers';
import { fetchBookings, IParams } from 'src/store/bookings/reducer';
import {
  bookingsSelector,
  bookingsTableListSelector,
} from 'src/store/bookings/selectors';
import { IBooking } from '../Booking';
import { ExpandedItem } from './compounds/ExpandedItem';
import { PdfModal } from './compounds/PdfModal';
import {
  STATUSES_TO_CANCEL,
  CancelModal,
  CancelBooking,
} from './compounds/CancelModal';
import {
  STATUSES_TO_REBOOK,
  RebookModal,
  RebookBooking,
} from './compounds/RebookModal';
import {
  handleCreateVoucher,
  sentVoucher,
  cancelBooking,
  rebookBooking,
  updateComment,
  deleteComment,
  changeOfferStatus,
} from '../api';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import {
  ISentVoucher,
  SendVoucherModal,
  STATUSES_TO_SEND_VOUCHER,
} from './compounds/SendVoucherModal';
import { ThunkDispatcher } from 'src/store';
import { Status } from './compounds/BookingStatus';
import { ColumnProps } from 'antd/lib/table';

const defaultPaginationConfig: PaginationConfig = {
  pageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '25', '50'],
};

export const showNotificationError = (error: AxiosError<any>) => {
  const errors = _get(error, 'response.data.errors', null);
  if (errors) {
    _forEach(errors, ({ msgs }: any) => {
      _forEach(msgs, ({ msg }: any) => {
        notification.error({
          message: 'Error',
          description: msg,
        });
      });
    });
  }
  return error;
};

export const BookingsList = () => {
  const filters = useFilters();
  const dispatch = useDispatch<ThunkDispatcher>();
  const bookingsList = useSelector(bookingsTableListSelector);
  const { fetching, totalCount, searchParams } = useSelector(bookingsSelector);

  const pagination = {
    ...defaultPaginationConfig,
    current: searchParams.page,
    pageSize: searchParams.pageSize || defaultPaginationConfig.pageSize,
    total: totalCount,
    showTotal: (total: number) => `Total ${total} items`,
  } as TablePaginationConfig;

  const [exportState, setExportState] = useState({ loading: false });
  const [{ visibility, modalData }, setModalState] =
    useState<BModal>(defaultBModalState);

  const resetModalState = () =>
    setModalState((prevValue) => ({ ...prevValue, visibility: BModals.NONE }));

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: IParams,
    sorter: IParams,
    extra: IParams
  ) =>
    dispatch(
      fetchBookings(
        extra.action === 'sort'
          ? {
              ...searchParams,
              SortField:
                sorter.order &&
                capitalFirstLetter(sorter.columnKey) === 'BookingStatus'
                  ? sorter.order && 'Status'
                  : sorter.order && capitalFirstLetter(sorter.columnKey),
              IsSortingDescending: isDescending(sorter.order),
            }
          : {
              ...searchParams,
              page: pagination.current,
              pageSize: pagination.pageSize,
            }
      )
    );

  const refetchBookings = () =>
    dispatch(fetchBookings(searchParams))
      .then(resetModalState)
      .catch(showNotificationError);

  const handleCancelBooking = (values: CancelBooking) =>
    cancelBooking(values).then(refetchBookings).catch(showNotificationError);

  const handleRebookBooking = (values: RebookBooking) =>
    rebookBooking(values).then(refetchBookings).catch(showNotificationError);

  const handleSentVoucher = (values: ISentVoucher) =>
    sentVoucher(values).then(refetchBookings).catch(showNotificationError);

  const handleExport = () => {
    setExportState({ loading: true });

    const exportParams = mapBookingsListParams(
      _omit(searchParams, ['page', 'pageSize'])
    );
    let shouldOpenInNewTab = true;

    const wait = window.setTimeout(() => {
      notification.info({
        message: 'Info',
        description: InfoDescription,
      });
      shouldOpenInNewTab = false;
    }, 10000);

    BackofficeAPI.get('/Export/bookings', {
      params: exportParams,
    }).then(
      (response: AxiosResponse<any, { exportUrl: string }>) => {
        window.clearTimeout(wait);
        setExportState({ loading: false });

        if (shouldOpenInNewTab) {
          window.open(response.data.exportUrl, '_blank');
        }
      },
      () => {
        window.clearTimeout(wait);
        setExportState({ loading: false });
      }
    );
  };

  const onFiltersChanged = ({
    OnlyVipUser,
    OnlyWithSpecialRequest,
    ...params
  }: IParams) =>
    dispatch(
      fetchBookings({
        ...params,
        ...(OnlyVipUser && { OnlyVipUser }),
        ...(OnlyWithSpecialRequest && { OnlyWithSpecialRequest }),
        pageSize: searchParams.pageSize,
        page: 1,
      })
    );

  const handleUpdateComment = (offerId: string, comment: string | null) =>
    updateComment(offerId, comment)
      .then(refetchBookings)
      .catch(showNotificationError);

  const handleDeleteComment = (id: string) =>
    deleteComment(id).then(refetchBookings).catch(showNotificationError);

  const handleChangeOfferStatus = (values: IOfferChangeStatus) =>
    changeOfferStatus(values)
      .then(refetchBookings)
      .catch(showNotificationError);

  return (
    <Layout.Content>
      <StyledPageHeader
        title="Live Bookings List"
        extra={
          <>
            <Button
              icon={<FilePdfOutlined />}
              size="small"
              onClick={() =>
                setModalState({ modalData, visibility: BModals.PDF })
              }
              loading={false}
            />
            <Button
              icon={<DownloadOutlined />}
              size="small"
              onClick={handleExport}
              loading={exportState.loading}
            />
          </>
        }
      >
        <FiltersPanel
          searchParams={searchParams}
          config={filters}
          onChange={onFiltersChanged}
        />
      </StyledPageHeader>
      <StyledTable
        loading={fetching}
        dataSource={bookingsList}
        bordered={true}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="newBookingId"
        expandedRowRender={(item: any) => <ExpandedItem {...item} />}
        expandable={{ columnWidth: 25 }}
        tableLayout="fixed"
        sortDirections={['ascend', 'descend']}
      >
        {columns?.map((column: ColumnProps<IBooking>) => (
          <Column key={column.key} {...column} />
        ))}
        <>
          <Column
            key="checkStatus"
            title="Check Status"
            width={85}
            sorter={true}
            align="center"
            render={({ offerId, checkStatus }) => (
              <CheckStatus
                offerId={offerId}
                checkStatus={checkStatus}
                changeStatus={handleChangeOfferStatus}
              />
            )}
          />
          <Column
            title="Comment"
            width={280}
            align="center"
            key="comment"
            render={(props) => (
              <TextArea
                deleteItemId={props.newBookingId}
                deleteItemText="comment"
                placeholder="Add a comment..."
                rows={2}
                text={props.comment}
                offerId={props.offerId}
                handleUpdate={handleUpdateComment}
                handleDelete={handleDeleteComment}
              />
            )}
          />
          <Column
            title="Action"
            width={70}
            align="center"
            key="action"
            render={(value, record: IBooking) => {
              const items = [] as ItemType[];

              const addItem = (key: BModals, label: string) =>
                items.push({
                  key,
                  label: (
                    <div
                      onClick={() =>
                        setModalState({
                          visibility: key,
                          modalData: record,
                        })
                      }
                    >
                      {label}
                    </div>
                  ),
                });

              if (STATUSES_TO_CANCEL.includes(record.bookingStatus as Status)) {
                addItem(BModals.CANCEL, 'Cancel');
              }

              if (STATUSES_TO_REBOOK.includes(record.bookingStatus as Status)) {
                addItem(BModals.REBOOK, 'Rebook');
              }

              if (
                STATUSES_TO_SEND_VOUCHER.includes(
                  record.bookingStatus as Status
                )
              ) {
                addItem(BModals.SEND_VOUCHER, 'Send Voucher');
              }

              return items.length === 0 ? null : (
                <Dropdown trigger={['click']} overlay={<Menu items={items} />}>
                  <Button size="small">
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>
              );
            }}
          />
        </>
      </StyledTable>
      <CancelModal
        visible={visibility === BModals.CANCEL}
        onOk={handleCancelBooking}
        initialValues={modalData}
        onCancel={resetModalState}
      />
      <RebookModal
        visible={visibility === BModals.REBOOK}
        onOk={handleRebookBooking}
        initialValues={modalData}
        onCancel={resetModalState}
      />
      <PdfModal
        visible={visibility === BModals.PDF}
        onOk={handleCreateVoucher}
        onCancel={resetModalState}
      />
      <SendVoucherModal
        visible={visibility === BModals.SEND_VOUCHER}
        initialValues={modalData}
        onOk={handleSentVoucher}
        onCancel={resetModalState}
      />
    </Layout.Content>
  );
};
