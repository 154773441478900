import { FC } from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const LoaderWrapper = styled.div`
  margin-top: 30%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;

export const Loader: FC = () => (
  <LoaderWrapper>
    <CircularProgress size="1em" />
  </LoaderWrapper>
);
