import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Tag, Typography } from 'antd';
import { FieldArray, FieldArrayRenderProps, FormikValues } from 'formik';
import _get from 'lodash/get';
import { Moment } from 'moment';
import { useState } from 'react';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import { MAX_CHILDREN, MAX_PEOPLE } from 'src/containers/Bookings/helpers';
import styled from 'styled-components';
import { useFormFieldsMap } from './helpers';
import { roomInitialValue, roomsPackageInitialValue } from './index';
import { HtmlEditor } from './htmlEditor';
import { MAX_ROOMS } from 'src/containers/BookingEngine/Main/SearchPanel/choosers/RoomChooser/RoomChooserForm';
const { Text } = Typography;

const StyledVoucherForm = styled(StyledForm)`
  .ant-form-item {
    margin-bottom: unset;
  }
`;

const StyledLabel = styled.label`
  color: #7b7b9b;
  font-size: 12px;
  font-weight: 500;
`;

const StyledEditorWrapper = styled.div`
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  border: 1px dashed black;
  min-height: 100px;
  cursor: text;

  & .public-DraftStyleDefault-block {
    margin: 5px;
  }

  & .rdw-link-modal {
    height: unset;
  }

  & .rdw-link-modal-target-option {
    display: none;
  }
`;

export interface IForm {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: FormikValues;
}

const separator = {
  borderRadius: 10,
  margin: 5,
  padding: 5,
  width: 'fitContent',
  border: '1px dashed black',
};
const basicCellStyle = { width: 200 };

export const PdfForm = ({ values, setFieldValue }: IForm) => {
  const {
    emailCulture,
    residency,
    name,
    address,
    phone,
    email,
    bookingReference,
    paymentConfirmationId,
    arrivalDate,
    departureDate,
    cancellationPolicy,
    type,
    foodType,
    customValue,
    ageOfChildren,
    mainGuestName,
    bedType,
    specialRequests,
    currency,
    amount,
    title,
    // isIncludedInPrice,
  } = useFormFieldsMap();
  const [open, setOpen] = useState(false);
  return (
    <StyledVoucherForm>
      <Row>
        <Col>
          <Tag>General Info</Tag>
          <Row gutter={24} style={separator}>
            <Col style={basicCellStyle}>
              <FormikField {...emailCulture} />
            </Col>
          </Row>
        </Col>

        <Col>
          <Tag>Hotel information</Tag>
          <Row gutter={24} style={separator}>
            <Col style={basicCellStyle} children={<FormikField {...name} />} />
            <Col
              style={{ width: '250px' }}
              children={<FormikField {...address} />}
            />
            <Col style={basicCellStyle} children={<FormikField {...phone} />} />
            <Col style={basicCellStyle} children={<FormikField {...email} />} />
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Tag>Booking details</Tag>
          <Row gutter={24} style={separator}>
            <Col
              style={basicCellStyle}
              children={<FormikField {...bookingReference} />}
            />
            <Col
              style={basicCellStyle}
              children={<FormikField {...paymentConfirmationId} />}
            />
            <Col
              style={basicCellStyle}
              children={<FormikField {...arrivalDate} />}
            />
            <Col
              style={basicCellStyle}
              children={<FormikField {...departureDate} />}
            />
            <Col
              style={basicCellStyle}
              children={
                <FormikField
                  {...cancellationPolicy}
                  onSelect={(time: Moment) => {
                    setFieldValue(cancellationPolicy.name, time);
                    const diffInMinutes = time.diff(
                      values?.cancellationPolicy,
                      'minutes'
                    );
                    if (Math.abs(diffInMinutes) < 60) {
                      setOpen(false);
                    }
                  }}
                  open={open}
                  onClick={() => setOpen(true)}
                  onBlur={() => setOpen(false)}
                />
              }
            />
          </Row>
        </Col>
      </Row>
      <Row style={{ display: 'flex', width: '100%' }}>
        <Col style={{ width: 'calc(50% - 20px)' }}>
          <Tag>Rooms information</Tag>
          <FieldArray
            name="rooms"
            render={({ form, ...arrayHelpers }: FieldArrayRenderProps) => (
              <Col style={{ display: 'flex', flexWrap: 'wrap', ...separator }}>
                {_get(values.rooms, 'length')! &&
                  values.rooms.map((mapping: any, roomIndex: number) => (
                    <Col key={roomIndex}>
                      <Row>
                        <Col>
                          <Text>Room {roomIndex + 1}</Text>
                        </Col>
                        <Col>
                          {values?.rooms?.length !== 1 && (
                            <Button
                              size="small"
                              icon={<DeleteOutlined />}
                              type="link"
                              onClick={arrayHelpers.handleRemove(roomIndex)}
                            >
                              Remove
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row align="bottom" gutter={24} key={roomIndex}>
                        <Col span={22}>
                          <FormikField
                            {...type}
                            name={`rooms.${roomIndex}.type`}
                          />
                          <FormikField
                            {...foodType}
                            name={`rooms.${roomIndex}.included.foodType`}
                          />
                          <FormikField
                            {...residency}
                            name={`rooms.${roomIndex}.residency`}
                          />
                          {values?.rooms?.[roomIndex]?.included?.foodType ===
                            100 && (
                            <FormikField
                              {...customValue}
                              name={`rooms.${roomIndex}.included.customValue`}
                              key={`rooms.${roomIndex}.included.customValue`}
                              label="Custom included"
                            />
                          )}

                          <Col
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: 0,
                              marginBottom: 15,
                            }}
                          >
                            <Row style={{ alignItems: 'center' }}>
                              <StyledLabel>Adults:</StyledLabel>
                            </Row>
                            <Row style={{ alignItems: 'center' }}>
                              <Button
                                shape="circle"
                                icon={<MinusOutlined />}
                                disabled={
                                  values?.rooms[roomIndex]?.numberOfAdults === 1
                                }
                                onClick={() =>
                                  setFieldValue(
                                    `rooms.${roomIndex}.numberOfAdults`,
                                    values?.rooms[roomIndex]?.numberOfAdults - 1
                                  )
                                }
                              />
                              <Col style={{ width: 30, textAlign: 'center' }}>
                                <h2 style={{ margin: 0 }}>
                                  {values?.rooms[roomIndex]?.numberOfAdults}
                                </h2>
                              </Col>
                              <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                disabled={
                                  MAX_PEOPLE -
                                    values?.rooms[roomIndex]?.numberOfChildren -
                                    values?.rooms[roomIndex]?.numberOfAdults ===
                                  0
                                }
                                onClick={() =>
                                  setFieldValue(
                                    `rooms.${roomIndex}.numberOfAdults`,
                                    values?.rooms[roomIndex]?.numberOfAdults + 1
                                  )
                                }
                              />
                            </Row>
                          </Col>
                          <FieldArray
                            name={`rooms.${roomIndex}.ageOfChildren`}
                            render={({
                              form,
                              ...arrayAgeOfChildren
                            }: FieldArrayRenderProps) => (
                              <>
                                <Col
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: 0,
                                    marginBottom: 15,
                                  }}
                                >
                                  <Row style={{ alignItems: 'center' }}>
                                    <StyledLabel>Children:</StyledLabel>
                                  </Row>
                                  <Row style={{ alignItems: 'center' }}>
                                    <Button
                                      shape="circle"
                                      icon={<MinusOutlined />}
                                      disabled={
                                        values?.rooms[roomIndex]?.ageOfChildren
                                          ?.length === 0
                                      }
                                      onClick={() => {
                                        setFieldValue(
                                          `rooms.${roomIndex}.numberOfChildren`,
                                          values?.rooms[roomIndex]
                                            ?.ageOfChildren?.length - 1
                                        );
                                        arrayAgeOfChildren.remove(
                                          values?.rooms[roomIndex]
                                            ?.ageOfChildren?.length - 1
                                        );
                                      }}
                                    />
                                    <Col
                                      style={{ width: 30, textAlign: 'center' }}
                                    >
                                      <h2 style={{ margin: 0 }}>
                                        {
                                          values?.rooms[roomIndex]
                                            ?.numberOfChildren
                                        }
                                      </h2>
                                    </Col>
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      icon={<PlusOutlined />}
                                      disabled={
                                        MAX_PEOPLE -
                                          values?.rooms[roomIndex]
                                            ?.numberOfAdults -
                                          values?.rooms[roomIndex]
                                            ?.ageOfChildren?.length ===
                                          0 ||
                                        values?.rooms[roomIndex]?.ageOfChildren
                                          ?.length === MAX_CHILDREN
                                      }
                                      onClick={() => {
                                        setFieldValue(
                                          `rooms.${roomIndex}.numberOfChildren`,
                                          values?.rooms[roomIndex]
                                            ?.ageOfChildren?.length + 1
                                        );
                                        arrayAgeOfChildren.push(0);
                                      }}
                                    />
                                  </Row>
                                </Col>
                                {values?.rooms[roomIndex]?.ageOfChildren?.map(
                                  (age: number, childIndex: number) => (
                                    <FormikField
                                      {...ageOfChildren}
                                      key={childIndex}
                                      name={`rooms.${roomIndex}.ageOfChildren.${childIndex}`}
                                      label={`Child Age ${childIndex + 1}`}
                                    />
                                  )
                                )}
                              </>
                            )}
                          />

                          <FormikField
                            {...mainGuestName}
                            key={`rooms.${roomIndex}.mainGuestName`}
                            name={`rooms.${roomIndex}.mainGuestName`}
                          />
                          <FormikField
                            {...bedType}
                            name={`rooms.${roomIndex}.bedType.bedType`}
                          />
                          {values?.rooms?.[roomIndex]?.bedType?.bedType ===
                            100 && (
                            <FormikField
                              {...customValue}
                              name={`rooms.${roomIndex}.bedType.customValue`}
                              key={`rooms.${roomIndex}.bedType.customValue`}
                              label="Custom preferred bed"
                            />
                          )}
                          <FormikField
                            {...specialRequests}
                            name={`rooms.${roomIndex}.specialRequests`}
                            key={`rooms.${roomIndex}.specialRequests`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                {values?.rooms?.length < MAX_ROOMS && (
                  <Form.Item>
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      type="link"
                      onClick={() => arrayHelpers.push(roomInitialValue)}
                      disabled={values?.rooms?.length === MAX_ROOMS}
                    >
                      Add One More Room
                    </Button>
                  </Form.Item>
                )}
              </Col>
            )}
          />
        </Col>
        <Col style={{ width: 'calc(50% - 20px)' }}>
          <Tag>Booking Remarks</Tag>
          <StyledEditorWrapper>
            <HtmlEditor setFieldValue={setFieldValue} values={values} />
          </StyledEditorWrapper>
          <Tag>Extra charges</Tag>
          <Row style={{ ...separator, flexDirection: 'column' }}>
            <Col style={basicCellStyle}>
              <FormikField {...currency} />
            </Col>
            <FieldArray
              name="extraCharges.roomsPackageTaxAndFeeDto"
              render={({ form, ...arrayHelpers }: any) => (
                <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {values?.extraCharges?.roomsPackageTaxAndFeeDto?.map(
                    (mapping: any, index: number) => (
                      <Col key={index}>
                        <Row>
                          <Col>
                            <Text>Extra charge {index + 1}</Text>
                          </Col>
                          <Col>
                            <Button
                              size="small"
                              icon={<DeleteOutlined />}
                              type="link"
                              onClick={arrayHelpers.handleRemove(index)}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                        <Row align="bottom" gutter={24}>
                          <Col span={22}>
                            <FormikField
                              {...title}
                              key={`extraCharges.roomsPackageTaxAndFeeDto.${index}.title`}
                              name={`extraCharges.roomsPackageTaxAndFeeDto.${index}.title`}
                            />
                            <FormikField
                              {...amount}
                              key={`extraCharges.roomsPackageTaxAndFeeDto.${index}.amount`}
                              name={`extraCharges.roomsPackageTaxAndFeeDto.${index}.amount`}
                            />
                            {/* <FormikField
                              {...isIncludedInPrice}
                              key={`extraCharges.roomsPackageTaxAndFeeDto.${index}.isIncludedInPrice`}
                              name={`extraCharges.roomsPackageTaxAndFeeDto.${index}.isIncludedInPrice`}
                              hidden={true}
                            /> */}
                          </Col>
                        </Row>
                      </Col>
                    )
                  )}
                  <Form.Item>
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      type="link"
                      onClick={() =>
                        arrayHelpers.push(roomsPackageInitialValue)
                      }
                    >
                      Add Extra Сharge
                    </Button>
                  </Form.Item>
                </Col>
              )}
            />
          </Row>
        </Col>
      </Row>
    </StyledVoucherForm>
  );
};
