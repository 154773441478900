import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';
import { apiCall } from 'src/modules/api';
import useSWR from 'swr';
import { RoomRate } from './useRooms';
import { useSearchId } from './useSearchId';

type CheckResponse = {
  checkResult: RoomRate;
};

export const useCheckPrice = (roomRateId?: string) => {
  const { data } = useSearchId();

  const {
    searchValue: { hotelId },
  } = useSearchContext();

  return useSWR(
    roomRateId && data?.searchId && hotelId
      ? `api/booking-engine/search/${data?.searchId}/hotels/${hotelId}/room-rates/${roomRateId}/check-price`
      : null,
    async (url: string) => {
      const res = await apiCall<CheckResponse>(url, {
        method: 'POST',
      });

      if (!Object.keys(res).length) {
        throw new Error();
      }

      return res.checkResult;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      errorRetryCount: 0,
    }
  );
};
