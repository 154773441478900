import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, useState } from 'react';
import { handleDownloadManualVoucher } from 'src/containers/Bookings/api';

type Props = {
  bookingId: string;
  fileName: string;
};

export const VoucherButton: FC<Props> = ({ bookingId, fileName }) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadVoucher = async () => {
    setLoading(true);
    await handleDownloadManualVoucher({ id: bookingId, fileName });
    setLoading(false);
  };

  return (
    <Button
      endIcon={loading ? <CircularProgress size="1em" /> : <DownloadIcon />}
      variant="contained"
      color="primary"
      fullWidth
      disabled={loading}
      onClick={handleDownloadVoucher}
    >
      Download Voucher
    </Button>
  );
};
