import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, ReactNode } from 'react';

export const Row: FC<{
  title: string;
  value: ReactNode;
  brakeValue?: boolean;
}> = ({ title, value, brakeValue }) => (
  <Stack
    key={title}
    spacing={2}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
  >
    <Typography variant="body2" component="div">
      {title}:
    </Typography>
    <Typography
      variant="body2"
      color="text.secondary"
      component="div"
      style={
        brakeValue
          ? {
              maxWidth: '60%',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              hyphens: 'auto',
            }
          : {}
      }
    >
      {value}
    </Typography>
  </Stack>
);
