import { createStore, applyMiddleware, compose, AnyAction } from 'redux';
import { rootReducer } from './rootReducer';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { HotelsState } from 'src/store/hotels/reducer';
import { ConfigState } from 'src/store/config/reducer';
import { HotelState } from 'src/store/hotel/reducer';
import { BookingsState } from 'src/store/bookings/reducer';
import { PaymentsState } from 'src/store/payments/reducer';
import { ExportsState } from 'src/store/exports/reducer';
import { ExternalFacilitiesState } from 'src/store/facilities/externalFacilities/reducer';
import { InternalFacilitiesState } from 'src/store/facilities/internalFacilities/reducer';
import { User } from './user/reducer';

declare const window: Window & {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?(a: any): any;
};

export interface ReduxState {
  config: ConfigState;
  hotels: HotelsState;
  hotel: HotelState;
  payments: PaymentsState;
  bookings: BookingsState;
  exports: ExportsState;
  externalFacilities: ExternalFacilitiesState;
  internalFacilities: InternalFacilitiesState;
  user: { currentUser: User };
}

export type ThunkDispatcher = ThunkDispatch<ReduxState, any, AnyAction>;
export type ThunkResult<R = void> = ThunkAction<R, ReduxState, undefined, any>;
export type TDispatch = typeof store.dispatch;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
