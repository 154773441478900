import Stack from '@mui/material/Stack';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import ButtonSelect from './ButtonSelect';
import HeaderButton from './HeaderButton';
import { RatesFilters } from 'src/hooks/swr/useHotels';
import { Typography } from '@mui/material';
import SortingIcon from 'src/icons/SotringIcon';

export type SortingItem<Nested extends boolean = true> = {
  value: Nested extends true ? SortingType | SortingItem<false>[] : SortingType;
  label: string;
  disabled?: boolean;
};

const StyledIcon = styled(SortingIcon)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export enum SortingType {
  PRICE_UP = 'Price-Asc',
  PRICE_DOWN = 'Price-Desc',
  DISTANCE_UP = 'DistanceToPoint-Asc',
  DISTANCE_DOWN = 'DistanceToPoint-Desc',
}

const getDesktopSortingMap = (value: string): SortingItem[] => [
  {
    value: [
      {
        value: SortingType.DISTANCE_UP,
        label: 'Nearest',
      },
      {
        value: SortingType.DISTANCE_DOWN,
        label: 'Farthest',
      },
    ],
    label: 'distance',
  },
  {
    value: [
      {
        value: SortingType.PRICE_UP,
        label: 'Low to High',
      },
      {
        value: SortingType.PRICE_DOWN,
        label: 'High to Low',
      },
    ],
    label: 'price',
  },
];

const SortingSection: FC = () => {
  const [sorting, setSorting] = useQueryParam(
    RatesFilters.SORTING,
    withDefault(StringParam, SortingType.DISTANCE_UP)
  );

  const handleChange = (sorting: SortingType) => setSorting(sorting);

  return (
    <Stack direction="row">
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="body2" whiteSpace="nowrap">
          Sort by:
        </Typography>
      </Stack>
      {getDesktopSortingMap(sorting as SortingType).map(
        ({ value, label, disabled }, index) =>
          Array.isArray(value) ? (
            <ButtonSelect
              value={sorting as SortingType}
              options={value}
              key={index}
              $selected={value.some((item) => item.value === sorting)}
              $toggler
              onChange={handleChange}
            >
              <StyledIcon
                props={{
                  fill: value.some((item) => item.value === sorting)
                    ? '#1948F0'
                    : 'black',
                }}
              />
              <Typography variant="body2">{label}</Typography>
            </ButtonSelect>
          ) : (
            <HeaderButton
              key={index}
              $selected={value === sorting}
              $toggler
              onClick={() => handleChange(value)}
              fullWidth
              disabled={disabled}
            >
              {label}
            </HeaderButton>
          )
      )}
    </Stack>
  );
};

export default SortingSection;
