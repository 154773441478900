import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _sortBy from 'lodash/sortBy';
import { FC, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import { useConfig } from 'src/hooks/swr/useConfig';
import ArrowsUpDown from 'src/icons/ArrowsUpDown';
import { FieldsName, SearchPanelInputProps } from '../../SearchPanelForm';
import { SearchFormControl } from '../components/FilledInput';

const countryToFlag = (isoCode: string) =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;

const isMac = window.navigator.userAgent.indexOf('Mac') !== -1;

export const getCountryLabel = (name: string, code: string) =>
  `${isMac ? countryToFlag(code) : ''} ${name} (${code})`;

type Props = SearchPanelInputProps;

export const ResidencyChooser: FC<PropsWithChildren<Props>> = () => {
  const { data } = useConfig();
  const countries = data?.countries;

  if (!countries) return <></>;

  const mappedCountries = countries?.map(({ code, name }) => ({
    code,
    label: getCountryLabel(name, code),
  }));
  const options = _sortBy(mappedCountries, ['label']);

  if (!options) return <></>;

  return (
    <SearchFormControl label={FieldsName.RESIDENCY}>
      <Controller
        name={FieldsName.RESIDENCY}
        render={({ field }) => (
          <Select {...field} disableUnderline IconComponent={ArrowsUpDown}>
            {mappedCountries.map((t) => (
              <MenuItem value={t.code} key={t.code}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </SearchFormControl>
  );
};
