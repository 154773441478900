import Grid from '@mui/material/Grid';
import HotelCardList from './components/HotelCard/HotelCardsList';
import { Header } from './components/Header';
import { SideBar } from './components/Sidebar';
import { RoomsSection } from './components/Rooms';
import { FC } from 'react';
import { SearchContextProvider } from './SearchContext';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
`;

const StyledContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledScrollableContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const Search: FC = () => (
  <SearchContextProvider>
    <StyledWrapper>
      <StyledContainer>
        <Header />
        <SideBar />
        <StyledWrapper>
          <StyledContainer>
            <Grid container height="100%">
              <Grid item xs={4} height="100%">
                <StyledScrollableContainer>
                  <HotelCardList />
                </StyledScrollableContainer>
              </Grid>
              <Grid item xs height="100%">
                <StyledWrapper>
                  <StyledContainer>
                    <RoomsSection />
                  </StyledContainer>
                </StyledWrapper>
              </Grid>
            </Grid>
          </StyledContainer>
        </StyledWrapper>
      </StyledContainer>
    </StyledWrapper>
  </SearchContextProvider>
);
