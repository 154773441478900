import styled from 'styled-components/macro';
import { Button, ButtonProps } from 'src/components/common/WrappedButtons';

export type HeaderButtonProps = {
  $selected?: boolean;
  $toggler?: boolean;
} & ButtonProps;

const HeaderButton = styled(Button).attrs({
  variant: 'outlined',
})<HeaderButtonProps>`
  text-transform: capitalize;
  color: black;
  &&& {
    ${({ $selected, $toggler, disabled, theme }) => {
      if (disabled) {
        return {
          background: theme.palette.action.disabledBackground,
          color: theme.palette.text.disabled,
        };
      }

      const color = $toggler ? ($selected ? '#1948F0' : 'black') : 'black';

      return {
        color,
        border: 'none',
      };
    }}
  }
  padding: ${({ theme }) => theme.spacing(1)};

  white-space: nowrap;
`;

export default HeaderButton;
