import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, Select, Switch, Tooltip } from 'antd';
import { Field, FieldProps } from 'formik';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import { Fragment, useCallback, useMemo } from 'react';
import { DescriptionEditor } from 'src/components/DescriptionEditor';
import { StyledSelect } from 'src/components/StyledSelect';
import { IOption } from 'src/store/config/reducer';
import styled from 'styled-components';

const customOnChangeComponents = [
  DatePicker,
  DatePicker.RangePicker,
  Select,
  StyledSelect,
  Switch,
  DescriptionEditor,
];

interface SelectOptionType {
  [key: string]: string | number;
}

const selectComponents = [Select, StyledSelect];

const customOnBlurComponents = [Select, StyledSelect];

const StyledFormItem = styled(Form.Item)`
  flex-flow: column wrap !important;

  .ant-form-item-row {
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: left !important;

    label {
      font-size: 12px;
      font-weight: 500;

      .anticon {
        font-size: 12px;
        vertical-align: -2px;
      }
    }
  }

  .ant-picker {
    width: 100%;
  }

  .ant-picker-range {
    width: 100%;

    .ant-picker-input input {
      text-align: center !important;
    }
  }

  .ant-checkbox-wrapper {
    margin: 32px 0px;
    color: #7b7b9b;
    font-size: 12px;
  }
`;

const Errors = ({ errors }: { errors: { msg: string }[] }) => (
  <>
    {errors.map(({ msg }, index: number) => (
      <Fragment key={index}>
        <span>{msg}</span>
        <br />
      </Fragment>
    ))}
  </>
);

const FormikComponent = ({
  field,
  form: { errors, setFieldValue, touched, setFieldTouched, status },
  FormComponent,
  label,
  labelHint,
  name,
  extra,
  hideErrorMessage,
  options,
  valueProp = 'id',
  optionToLabel,
  ...rest
}: FieldProps & any) => {
  const customOnChange = useCallback(
    (value: any) => {
      setFieldValue(field.name, value);
    },
    [setFieldValue, field.name]
  );

  const customOnBlur = useCallback(
    () => setFieldTouched(field.name, true),
    [setFieldTouched, field.name]
  );

  const selectOptions = useMemo(
    () =>
      _isArray(options)
        ? options.map((option: SelectOptionType) => {
            const optionValue = _isObject(option) ? option[valueProp] : option;
            const optionLabel = optionToLabel
              ? optionToLabel(option)
              : _isObject(option)
              ? option.name
              : option;

            return (
              <Select.Option value={optionValue} key={optionValue}>
                {optionLabel}
              </Select.Option>
            );
          })
        : [],
    [options, valueProp, optionToLabel]
  );

  let fieldLabel = useMemo(
    () =>
      labelHint ? (
        <>
          {label}{' '}
          <Tooltip title={labelHint}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ) : (
        label
      ),
    [label, labelHint]
  );

  const compProps = {
    readOnly: rest.disabled,
    autoComplete: 'off',
    ...field,
    ...rest,
  };

  if (selectComponents.includes(FormComponent) && !rest.children) {
    compProps.children = selectOptions;
  }

  if (customOnChangeComponents.includes(FormComponent) && !rest.onChange) {
    compProps.onChange = customOnChange;
  }

  if (customOnBlurComponents.includes(FormComponent) && !rest.onBlur) {
    compProps.onBlur = customOnBlur;
  }

  if (FormComponent === Checkbox) {
    compProps.checked = compProps.value;
    compProps.children = label;
    fieldLabel = '';
  }

  const isTouched = _get(touched, field.name, false);
  const fieldError = _get(errors, field.name, '');
  const apiFieldErrors = status?.fieldApiErrors[field.name]?.msgs ?? [];
  const apiFieldError = apiFieldErrors[0]?.msg ?? '';

  const errorsMsg = apiFieldErrors[0] ? <Errors errors={apiFieldErrors} /> : '';

  return (
    <StyledFormItem
      extra={extra}
      label={fieldLabel}
      help={!hideErrorMessage && isTouched && (errorsMsg || fieldError)}
      validateStatus={
        isTouched && (apiFieldError || fieldError) ? 'error' : 'success'
      }
    >
      <FormComponent {...compProps} />
    </StyledFormItem>
  );
};

export const FormikField = (props: any) => (
  <Field component={FormikComponent} {...props} />
);

export const filterOption = (input: string, option: { children: string }) =>
  option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;

export const getSelectOptions = (options: IOption[]) =>
  options.map((option: IOption) => (
    <Select.Option value={option.id} key={option.id}>
      {option.name}
    </Select.Option>
  ));
