import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';
import { apiCall } from 'src/modules/api';
import useSWR from 'swr';
import { useSearchId } from './useSearchId';

export interface Address {
  city: string;
  country: string;
  countryCode: string;
  line1: string;
  postalCode: string;
}

export interface Facility {
  id: number;
  name: string;
  groupId: number;
}

interface HotelContent {
  address: Address;
  description: string;
  facilities: Facility[];
  name: string;
}

interface HotelContentResponse {
  hotelContent: HotelContent;
}

export const useHotelContent = () => {
  const { data } = useSearchId();

  const {
    searchValue: { hotelId },
  } = useSearchContext();

  return useSWR(
    data?.searchId && hotelId
      ? `api/booking-engine/search/${data.searchId}/hotels/${hotelId}/hotel-content`
      : null,
    async (url) => {
      const res = await apiCall<HotelContentResponse>(url);

      if (!Object.keys(res).length) {
        throw new Error();
      }

      return res;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );
};
