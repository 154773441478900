import _get from 'lodash/get';
import { IParams } from 'src/store/bookings/reducer';

export const mapPaymentListParams = (params: IParams) => {
  const { paymentDateRange, lastModifiedDateRange, ...restParams } = params;
  const paymentDateFrom = _get(paymentDateRange, '0');
  const paymentDateTo = _get(paymentDateRange, '1');
  const lastModifiedDateFrom = _get(lastModifiedDateRange, '0');
  const lastModifiedDateTo = _get(lastModifiedDateRange, '1');
  Object.keys(restParams).forEach(
    (k) =>
      (restParams[k] = restParams[k]?.trim
        ? restParams[k].trim()
        : restParams[k])
  );
  return {
    ...restParams,
    paymentDateFrom: paymentDateFrom && paymentDateFrom.format('YYYY-MM-DD'),
    paymentDateTo: paymentDateTo && paymentDateTo.format('YYYY-MM-DD'),
    lastModifiedDateFrom:
      lastModifiedDateFrom && lastModifiedDateFrom.format('YYYY-MM-DD'),
    lastModifiedDateTo:
      lastModifiedDateTo && lastModifiedDateTo.format('YYYY-MM-DD'),
  };
};

export const mapHotelsListParams = (params: IParams) => {
  const { modificationDateRange, ids, ...restParams } = params;
  const modificationDateFrom = _get(modificationDateRange, '0');
  const modificationDateTo = _get(modificationDateRange, '1');
  Object.keys(restParams).forEach(
    (k) =>
      (restParams[k] = restParams[k]?.trim
        ? restParams[k].trim()
        : restParams[k])
  );
  return {
    ...restParams,
    ids: ids && ids.split(',').map((id: string) => id.trim()),
    modificationDateFrom:
      modificationDateFrom && modificationDateFrom.format('YYYY-MM-DD'),
    modificationDateTo:
      modificationDateTo && modificationDateTo.format('YYYY-MM-DD'),
  };
};

export const mapBookingsListParams = (params: IParams) => {
  const { BookingDate, CheckInDate, CheckOutDate, ids, ...restParams } = params;
  const modificationDateFrom = _get(BookingDate, '0');
  const modificationDateTo = _get(BookingDate, '1');
  Object.keys(restParams).forEach(
    (k) =>
      (restParams[k] = restParams[k]?.trim
        ? restParams[k].trim()
        : restParams[k])
  );
  return {
    ...restParams,
    ids: ids && ids.split(',').map((id: string) => id.trim()),
    BookingStartDate:
      modificationDateFrom && modificationDateFrom.format('YYYY-MM-DD'),
    BookingEndDate:
      modificationDateTo && modificationDateTo.format('YYYY-MM-DD'),
    CheckInDate: CheckInDate && CheckInDate.format('YYYY-MM-DD'),
    CheckOutDate: CheckOutDate && CheckOutDate.format('YYYY-MM-DD'),
  };
};

export const mapManualListParams = (params: IParams) => {
  if (params.BookingNumber) {
    return { BookingNumber: params.BookingNumber };
  }

  const { BookingDate, CheckinDate, CheckoutDate, ids, ...restParams } = params;
  const modificationDateFrom = _get(BookingDate, '0');
  const modificationDateTo = _get(BookingDate, '1');
  Object.keys(restParams).forEach(
    (k) =>
      (restParams[k] = restParams[k]?.trim
        ? restParams[k].trim()
        : restParams[k])
  );
  return {
    ...restParams,
    'CreateDate.From':
      modificationDateFrom && modificationDateFrom.format('YYYY-MM-DD'),
    'CreateDate.To':
      modificationDateTo && modificationDateTo.format('YYYY-MM-DD'),
    CheckinDate: CheckinDate && CheckinDate.format('YYYY-MM-DD'),
    CheckoutDate: CheckoutDate && CheckoutDate.format('YYYY-MM-DD'),
  };
};
