import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import _groupBy from 'lodash/groupBy';
import { FC } from 'react';
import Divider from '@mui/material/Divider';
import { TaxesAndFees } from 'src/hooks/swr/useRooms';
import { useFormatPrice } from 'src/hooks/useFormatCurrency';

import useToggler from 'src/hooks/useToggler';
import joinBy from 'src/modules/joinBy';
import { Label, Value, StyledTooltip, Title, Icon, StyledRow } from '../..';

interface TaxAndFeePopOverProps {
  taxesAndFees: Pick<TaxesAndFees, 'title' | 'value' | 'isIncludedInPrice'>[];
  icon?: JSX.Element;
}

export const getSumTaxes = (taxAndFees: TaxesAndFees[]): number =>
  taxAndFees?.reduce((acc, { value }) => acc + value, 0) || 0;

interface TaxItem {
  name: string;
  taxes: TaxesAndFees[];
}

const TaxesItem = ({ name, taxes }: TaxItem) => {
  const formatPrice = useFormatPrice();
  if (getSumTaxes(taxes) === 0) return null;
  return (
    <Stack spacing={2}>
      <Title>{name}: </Title>
      {taxes?.map((tax) => (
        <StyledRow key={tax.title}>
          <Label>{tax.title}</Label>
          <Value>{formatPrice(tax.value)}</Value>
        </StyledRow>
      ))}
    </Stack>
  );
};

export const TaxesAndFeesPopOver: FC<TaxAndFeePopOverProps> = ({
  taxesAndFees,
}) => {
  const groupedTaxes = _groupBy(taxesAndFees, (item) => item.isIncludedInPrice);

  const { toggler, handleClose, handleOpen } = useToggler();

  const taxesList: TaxItem[] = [];

  if (getSumTaxes(groupedTaxes.true)) {
    taxesList.push({
      name: 'Included in price',
      taxes: groupedTaxes.true,
    });
  }

  if (getSumTaxes(groupedTaxes.false)) {
    taxesList.push({
      name: 'Extra charges',
      taxes: groupedTaxes.false,
    });
  }

  const content = (
    <Stack spacing={2}>
      {joinBy(
        taxesList.map((taxItem, index) => (
          <TaxesItem key={taxItem.name + index} {...taxItem} />
        )),
        <Divider variant="fullWidth" />
      )}
    </Stack>
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <StyledTooltip
          placement="top"
          title={content}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleClose}
          open={toggler}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Icon onClick={toggler ? handleClose : handleOpen} />
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
};
