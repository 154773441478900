import { Tabs } from 'antd';
import { ManualBooking } from 'src/hooks/swr/useManualBookings';
import { Details } from './Details';

enum TABS {
  DETAILS = 'Booking Details',
  TRANSACTIONS = 'Transactions Summary',
}

const tabs = [
  {
    key: TABS.DETAILS,
    label: TABS.DETAILS,
    Component: (props: ManualBooking) => <Details {...props} />,
  },
];

export const ExpandedItem = (props: ManualBooking) => (
  <Tabs
    type="card"
    defaultActiveKey={TABS.DETAILS}
    items={tabs.map((t) => ({
      ...t,
      children: t.Component(props),
    }))}
  />
);
