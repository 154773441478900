import { FC, memo, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { CountryData } from 'react-intl-tel-input';
import { CustomPhoneInput, CustomPhoneInputProps } from '../CustomPhoneInput';
import styled from 'styled-components/macro';
import { ErrorMessage } from '@hookform/error-message';

const MessageGeneral = styled.div`
  font-weight: 500;
  min-width: 150px;
`;

const ErrorValidationMessage = styled(MessageGeneral)`
  margin-left: 14px;
  position: absolute;
  bottom: -15px;
  left: 0;
  color: ${(p) => p.theme.custom.errorMessageColor};
`;

const ClientError: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <ErrorValidationMessage className={className}>
    {children}
  </ErrorValidationMessage>
);

const ClientErrorMessage: FC<
  PropsWithChildren<{ name: string; className?: string }>
> = (props) => <ErrorMessage as={<ClientError />} {...props} />;

export interface IPhone {
  phoneNumber: string;
  fullNumber: string;
  isValid: boolean;
  countryData: CountryData;
}

interface PhoneInputProps extends Omit<CustomPhoneInputProps, 'value'> {
  name: string;
  onChange: (data: IPhone) => void;
  value?: IPhone;
}

export const PhoneInput: FC<PhoneInputProps> = memo(
  ({ className, name, formFieldLabel, onChange, value, ...rest }) => {
    const {
      formState: { errors, isSubmitted },
    } = useFormContext();

    const error = errors?.name;

    const handleChange: PhoneInputProps['onPhoneNumberChange'] = (
      isValid,
      phoneNumber,
      countryData,
      fullNumber
    ) => onChange({ phoneNumber, isValid, fullNumber, countryData });

    return (
      <div className={className}>
        <CustomPhoneInput
          onPhoneNumberChange={handleChange}
          fieldName={name}
          fieldId={formFieldLabel?.id}
          preferredCountries={[]}
          invalid={isSubmitted && Boolean(error)}
          formFieldLabel={formFieldLabel}
          nationalMode={false}
          autoHideDialCode={false}
          formatOnInit
          format
          autoComplete="on"
          value={value?.phoneNumber}
          {...rest}
        />
        {isSubmitted && <ClientErrorMessage name={name} />}
      </div>
    );
  }
);
