import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spin } from 'src/components/Spin';
import { PaymentsList } from 'src/containers/PaymentsList';
import { ThunkDispatcher } from 'src/store';
import { fetchBackofficeConfig } from 'src/store/config/reducer';
import { backofficeConfigFetchedSelector } from 'src/store/config/selectors';

export const BackofficeMain: FC = () => {
  const dispatch = useDispatch<ThunkDispatcher>();
  const isConfigFetched = useSelector(backofficeConfigFetchedSelector);

  useEffect(() => {
    if (!isConfigFetched) dispatch(fetchBackofficeConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isConfigFetched) return <Spin size="large" />;

  return (
    <Routes>
      <Route path="/" element={<PaymentsList />} />
      <Route path="/*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
