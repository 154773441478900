import { AxiosRequestConfig } from 'axios';
import { ManualBooking } from 'src/hooks/swr/useManualBookings';
import { BackofficeAPI } from 'src/modules/api';
import { CancelBooking } from './BookingsList/compounds/CancelModal';
import { IOfferChangeStatus } from './BookingsList/compounds/CheckStatus';
import { TVoucher } from './BookingsList/compounds/PdfModal';
import { RebookBooking } from './BookingsList/compounds/RebookModal';
import { ISentVoucher } from './BookingsList/compounds/SendVoucherModal';

export const manualBookingPath = '/admin/manual-booking';

export const deleteConfirmation = (offerId: string) =>
  BackofficeAPI.delete(`/Booking/${offerId}/hotel-confirmation`);

export const updateConfirmation = (
  offerId: string,
  hotelConfirmation: string | null
) =>
  BackofficeAPI.put(`/Booking/${offerId}/hotel-confirmation`, {
    hotelConfirmation,
  });

export const changeOfferStatus = ({
  offerId,
  checkStatus,
}: IOfferChangeStatus) =>
  BackofficeAPI.put(`/Booking/${offerId}/check-status`, {
    checkStatus,
  });

export const changeManualCheckStatus = ({
  id,
  checkStatus: status,
}: Partial<ManualBooking>) =>
  BackofficeAPI.put(`${manualBookingPath}/${id}/check`, {
    status,
  });

export const deleteComment = (id: string) =>
  BackofficeAPI.delete(`/Booking/${id}/comment`);

export const updateComment = (offerId: string, comment: string | null) =>
  BackofficeAPI.put(`/Booking/${offerId}/comment`, {
    comment,
  });

export const deleteManualComment = ({ id }: Partial<ManualBooking>) =>
  BackofficeAPI.delete(`${manualBookingPath}/${id}/comment`);

export const updateManualComment = ({
  id,
  commentField,
}: Partial<ManualBooking>) =>
  BackofficeAPI.post(`${manualBookingPath}/${id}/comment`, {
    comment: commentField,
  });

export const rebookBooking = ({ comment, offerId }: RebookBooking) =>
  BackofficeAPI.put(`/Booking/${offerId}/rebook`, {
    comment,
  });

export const cancelBooking = ({ offerId, comment }: CancelBooking) =>
  BackofficeAPI.put(`/Booking/${offerId}/cancel`, { comment });

export const cancelManualBooking = ({
  id,
  commentField,
}: Partial<ManualBooking>) =>
  BackofficeAPI.put(`${manualBookingPath}/${id}/cancel`, {
    comment: commentField,
  });

export const sentVoucher = ({ offerId }: ISentVoucher) =>
  BackofficeAPI.post(`/Voucher/${offerId}`);

export const handlePreviewVoucher = async (offerId: string) => {
  const axiosConfig = {
    responseType: 'blob',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/pdf',
    },
  } as AxiosRequestConfig;

  const response = await BackofficeAPI.get<Blob>(
    `/Voucher/${offerId}`,
    axiosConfig
  );
  window.open(URL.createObjectURL(response.data));
};

export const handleCreateVoucher = async (values: TVoucher) => {
  const json = JSON.stringify(values);
  const axiosConfig = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  } as AxiosRequestConfig;

  const response = await BackofficeAPI.post<Blob>(
    `/Voucher`,
    json,
    axiosConfig
  );
  const blob = new Blob([response.data]);
  const fileName = `Booking voucher - ${values.rooms![0].mainGuestName} - ${
    values.hotelDetails.name
  }.pdf`;
  const objectUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = objectUrl;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(objectUrl);
};

export const handleDownloadManualVoucher = async ({
  id,
  fileName,
}: {
  id: string;
  fileName: string;
}) => {
  const response = await BackofficeAPI.get<Blob>(
    `/Voucher/manual-booking/${id}`,
    {
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    }
  );
  const blob = new Blob([response.data]);
  const objectUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = objectUrl;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(objectUrl);
};
