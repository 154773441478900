import {
  Alert,
  Portal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { apiCall } from 'src/modules/api';
import { ThunkDispatcher } from 'src/store';
import { AuthResponse, handleToken } from '../AuthProvider';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';
import EastIcon from '@mui/icons-material/East';

export const AuthForm: FC<{ setAuthorized(value: boolean): void }> = ({
  setAuthorized,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { email: '', password: '', otp: '' },
  });

  const dispatch = useDispatch<ThunkDispatcher>();

  const [errorStatus, setErrorStatus] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    setIsError(false);
  };

  return (
    <Stack
      spacing={4}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(async (data) => {
        try {
          dispatch(
            handleToken(
              await apiCall<AuthResponse>(
                'api/auth/login',
                {
                  method: 'POST',
                  data,
                },
                true
              )
            )
          );

          setAuthorized(true);
          localStorage.setItem('is2FaFlowFinished', 'true');
        } catch (err) {
          console.log(err);
          setErrorStatus((err as AxiosError).response?.status);
          setIsError(true);

          if (typeof (err as AxiosError).response?.data === 'object') {
            setErrorMessage('Something going wrong');

            return;
          }
          
          setErrorMessage((err as AxiosError).response?.data as string);
        }
      })}
    >
      <img alt="Logo" src="/assets/logo.png" style={{ width: '33%' }} />
      <Typography variant="h1">Sign-in</Typography>
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            label="Email"
            placeholder="Email"
            type="email"
            {...field}
            fullWidth
            required
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            label="Password"
            placeholder="Password"
            type="password"
            {...field}
            fullWidth
            required
          />
        )}
      />
      <Controller
        name="otp"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            label="2FA code"
            placeholder="6 digit code"
            type="text"
            {...field}
            fullWidth
            inputProps={{ maxLength: 6 }}
            required
          />
        )}
      />

      <LoadingButton
        type="submit"
        variant="contained"
        loading={isSubmitting}
        loadingPosition="end"
        endIcon={<EastIcon />}
        color="primary"
        fullWidth
      >
        login
      </LoadingButton>
      <Portal>
        <Snackbar
          open={isError}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity="error" variant="filled">
            {errorStatus === 404
              ? 'We cannot find this user account, please check that the e-mail address is correct and try again'
              : errorStatus === 401
              ? errorMessage
              : 'Something going wrong'}
          </Alert>
        </Snackbar>
      </Portal>
    </Stack>
  );
};
