import { VFC } from 'react';

const ArrowDown: VFC<{ className?: string }> = ({ className }) => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.94328 1.27388L9.38804 0.741844C9.35174 0.707064 9.3025 0.6875 9.25117 0.6875C9.2507 0.6875 9.25022 0.6875 9.24985 0.6875C9.19795 0.687862 9.14851 0.70806 9.11241 0.743656L5 4.79657L0.887594 0.743746C0.851485 0.708151 0.802049 0.687953 0.750154 0.687591C0.749776 0.687591 0.749304 0.687591 0.748831 0.687591C0.697504 0.687591 0.648256 0.707155 0.611959 0.741935L0.0567188 1.27397C-0.0182395 1.34579 -0.0189957 1.46191 0.0550174 1.53464L4.86143 6.25653C4.89782 6.2923 4.94782 6.3125 5 6.3125C5.05218 6.3125 5.10218 6.2923 5.13857 6.25653L9.94498 1.53455C10.019 1.46182 10.0182 1.3457 9.94328 1.27388Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDown;
