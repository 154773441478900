const useFormatDistance = () => {
  return (distance: number) => {
    let unit;

    if (distance >= 1000) {
      distance /= 1000;

      unit = 'kilometer';
    } else {
      distance = Math.round(distance / 10) * 10;

      unit = 'meter';
    }

    return new Intl.NumberFormat('en-GB', {
      style: 'unit',
      unit,
      maximumFractionDigits: 2,
    }).format(distance);
  };
};

export default useFormatDistance;
