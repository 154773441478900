import {
  filledInputClasses,
  FormControl,
  formHelperTextClasses,
  formLabelClasses,
} from '@mui/material';
import { inputClasses } from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FC, forwardRef, MouseEventHandler, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

type Props = Omit<TextFieldProps, 'color'> & {
  focused?: boolean;
  alignRight?: boolean;
};

const StyledTextField = styled(TextField)<TextFieldProps>`
  position: relative;
  & .${formLabelClasses.root} {
    padding-left: 12px;
    padding-top: 10px;
  }

  & .${inputClasses.focused} {
    border-radius: 20px;
  }

  & .${inputClasses.root} {
    border-radius: 20px;
  }

  & .${inputClasses.input} {
    border-radius: 20px;
  }

  & .${filledInputClasses.focused} {
    border-radius: 12px;
  }

  & .${filledInputClasses.root} {
    border-radius: 12px;
    background: #f7f8fb;
  }

  & .${formHelperTextClasses.filled}, .${formHelperTextClasses.contained} {
    position: absolute;
    bottom: -18px;
  }
`;

export const SearchPanelFilledInput = forwardRef<HTMLDivElement, Props>(
  ({ focused, alignRight, ...props }, ref) => (
    <StyledTextField
      {...props}
      ref={ref}
      variant="filled"
      InputLabelProps={{
        ...props.InputLabelProps,
        focused,
        shrink: true,
        style: { textTransform: 'capitalize' },
      }}
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
      }}
      sx={
        alignRight
          ? {
              //   [`.${inputLabelClasses.root}`]: { right: 'auto', left: 'auto' },
              [`.${inputClasses.input}`]: { textAlign: 'right' },
            }
          : undefined
      }
    />
  )
);

const StyledFormControl = styled(FormControl)`
  position: 'relative';
  //label
  & .${formLabelClasses.root} {
    padding-left: 12px;
    padding-top: 10px;
  }

  & .${inputClasses.focused} {
    border-radius: 20px;
  }

  & .${inputClasses.root} {
    border-radius: 20px;
  }

  & .${inputClasses.input} {
    border-radius: 20px;
  }

  & .${filledInputClasses.focused} {
    border-radius: 12px;
  }

  & .${filledInputClasses.root} {
    border-radius: 12px;
    background: #f7f8fb;
  }

  & .${formHelperTextClasses.filled}, .${formHelperTextClasses.contained} {
    position: absolute;
    bottom: -18px;
  }
`;

export const SearchFormControl: FC<
  PropsWithChildren<{
    label?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
  }>
> = ({ label, children, onClick }) => (
  <StyledFormControl variant="filled" fullWidth onClick={onClick}>
    {label && (
      <InputLabel style={{ textTransform: 'capitalize' }}>{label}</InputLabel>
    )}
    {children}
  </StyledFormControl>
);
