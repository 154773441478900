import { Alert, Modal } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  currenciesSelector,
  paymentProcessorsSelector,
} from 'src/store/config/selectors';
import { AddPaymentDepositForm } from './AddPaymentDepositForm';
import { AddPaymentInvoiceForm } from './AddPaymentInvoiceForm';
import { AddPaymentInternalCostForm } from './AddPaymentInternalCostForm';
import { AddPaymentPayAtHotelForm } from './AddPaymentPayAtHotelForm';
import { PAYMENT_PROCESSORS } from 'src/constants';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import * as yup from 'yup';
import { FC } from 'react';
import { AxiosError } from 'axios';

const payAtHotelValidationSchema = yup.object().shape({
  currency: yup.string().required('Required'),
  amount: yup
    .number()
    .required('Required')
    .typeError('Should be a number')
    .positive('Should be higher than 0'),
  oldBookingId: yup.string().required('Required'),
});

interface IProcessInvoiceModal {
  visible: boolean;
  initialValues: any;
  onOk: (
    values: yup.InferType<typeof payAtHotelValidationSchema>
  ) => Promise<void | AxiosError<any>>;
  onCancel: () => void;
}

export const AddPaymentModal: FC<IProcessInvoiceModal> = ({
  visible,
  initialValues,
  onCancel,
  onOk,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: (props: any) =>
      props.initialValues.type === 'payAtHotel'
        ? payAtHotelValidationSchema
        : yup.object(),
    initialValues: {
      type: undefined,
      paymentProcessor: undefined,
      currency: undefined,
      externalId: '',
      oldBookingId: '',
      comment: '',
      date: '',
      amount: '',
      ...initialValues,
    },
    onSubmit: (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (response: any) => {
          setSubmitting(false);
          if (response.errors) {
            setStatus({
              commonApiErrors: response.errors.filter(
                ({ field }: any) => !field
              ),
              fieldApiErrors: _keyBy(
                response.errors.filter(({ field }: any) => field),
                'field'
              ),
            });
          }
        }
      );
    },
  });

  const { handleSubmit, status, isSubmitting, values } = formik;

  const currencies = useSelector(currenciesSelector);
  const paymentProcessors = useSelector(paymentProcessorsSelector);
  const depositPaymentProcessors = paymentProcessors.filter(
    (i) =>
      i.name === PAYMENT_PROCESSORS.QUICK_PAY ||
      i.name === PAYMENT_PROCESSORS.STRIPE
  );

  return (
    <FormikProvider value={formik}>
      <Modal
        destroyOnClose={true}
        maskClosable={false}
        title="Add Payment"
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Add"
        cancelText="Cancel"
      >
        {values.type === 'deposit' && (
          <AddPaymentDepositForm
            {...{ depositPaymentProcessors, currencies }}
          />
        )}
        {values.type === 'invoice' && (
          <AddPaymentInvoiceForm {...{ currencies }} />
        )}
        {values.type === 'internalCost' && <AddPaymentInternalCostForm />}
        {values.type === 'payAtHotel' && (
          <AddPaymentPayAtHotelForm {...{ currencies }} />
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </Modal>
    </FormikProvider>
  );
};
