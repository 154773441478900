import { Input, Select } from 'antd';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';

export const AddPaymentDepositForm = ({
  depositPaymentProcessors,
  currencies,
}: any) => (
  <StyledForm layout="horizontal">
    <FormikField
      key="type"
      label="Payment Type"
      name="type"
      FormComponent={Input}
      disabled={true}
    />
    <FormikField
      key="paymentProcessor"
      label="Processor"
      name="paymentProcessor"
      FormComponent={Select}
      placeholder="Select"
      options={depositPaymentProcessors}
    />
    <FormikField
      key="currency"
      label="Payment Currency"
      name="currency"
      FormComponent={Select}
      placeholder="Select"
      options={currencies}
    />
    <FormikField
      key="externalId"
      label="Transaction External ID"
      name="externalId"
      FormComponent={Input}
    />
    <FormikField
      key="oldBookingId"
      label="Old Booking ID"
      name="oldBookingId"
      FormComponent={Input}
    />
    <FormikField
      key="comment"
      label="Comment"
      name="comment"
      FormComponent={Input.TextArea}
    />
  </StyledForm>
);
