import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import { FC, PropsWithChildren, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SearchFormControl } from '../components/FilledInput';
import { useConfig } from 'src/hooks/swr/useConfig';
import ArrowsUpDown from 'src/icons/ArrowsUpDown';
import {
  Fields,
  FieldsName,
  SearchPanelInputProps,
} from '../../SearchPanelForm';

type Props = SearchPanelInputProps;

export const PropertyTypeChooser: FC<PropsWithChildren<Props>> = (props) => {
  const { data } = useConfig();
  const options = data?.propertyTypes || [];

  const methods = useFormContext<Fields>();

  const [selected, setSelected] = useState<number[]>(
    methods.getValues()[FieldsName.PROPERTY_TYPES].length
      ? methods.getValues()[FieldsName.PROPERTY_TYPES]
      : []
  );

  return (
    <SearchFormControl label="Property types" {...props}>
      <Controller
        name={FieldsName.PROPERTY_TYPES}
        render={({ field, fieldState }) => (
          <>
            <Select
              {...field}
              value={selected}
              onChange={(e) => {
                const value = e.target.value;
                if (value[value.length - 1] === 'all') {
                  e.target.value =
                    selected.length === options.length
                      ? []
                      : options.map(({ id }) => id);
                  field.onChange(e);
                  setSelected(e.target.value);
                  return;
                }
                field.onChange(e);
                setSelected(value as number[]);
              }}
              multiple
              fullWidth
              disableUnderline
              IconComponent={ArrowsUpDown}
              error={Boolean(fieldState.error?.message)}
              renderValue={(selected) =>
                options.length > 0 && selected.length === options.length
                  ? 'All'
                  : selected
                      .map((id) => options.find((t) => t.id === id)?.name)
                      .join(', ') || 'Select some options'
              }
            >
              <MenuItem key="all" value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      options.length > 0 && selected.length === options.length
                    }
                    indeterminate={
                      selected.length > 0 && selected.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <ListItemIcon>
                    <Checkbox checked={selected.indexOf(option.id) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>

            {fieldState.error ? (
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            ) : null}
          </>
        )}
      />
    </SearchFormControl>
  );
};
