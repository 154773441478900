import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { alpha, Button, Stack, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import isAfter from 'date-fns/isAfter';
import { FC, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import CModal from 'src/components/common/CModal';
import { useHotels } from 'src/hooks/swr/useHotels';
import { useSearchId } from 'src/hooks/swr/useSearchId';
import useToggler from 'src/hooks/useToggler';
import styled, { keyframes } from 'styled-components';
import { useSearchContext } from '../../../SearchContext';

const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => alpha(theme.palette.secondary.main, 0.1)};
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  animation: ${spin} 2s infinite ease-out;
  fill: red;
`;
const StyledAccessAlarmIcon = styled(AccessAlarmIcon)`
  fill: ${({ theme }) => theme.palette.secondary.main};
`;

const Modal = styled(CModal)`
  width: 100%;
  height: auto;
  max-width: 466px;
`;

const getTwoDigits = (value: number) =>
  value.toString().length < 2 ? `0${value}` : value;

interface TimerProps {
  expiryTimestamp: Date;
  refreshSearch: () => void;
}

const TimerChip: FC<TimerProps> = ({ expiryTimestamp, refreshSearch }) => {
  const { toggler, handleOpen, handleClose } = useToggler();

  const { seconds, minutes, restart, isRunning } = useTimer({
    autoStart: false,
    expiryTimestamp,
    onExpire: handleOpen,
  });

  useEffect(() => {
    if (expiryTimestamp && isAfter(Date.now(), expiryTimestamp)) {
      refreshSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expiryTimestamp) {
      restart(expiryTimestamp, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTimestamp]);

  const label = (
    <Typography variant="body2">
      Results will expire in {getTwoDigits(minutes)}
      {seconds % 2 === 0 ? ' ' : ':'}
      {getTwoDigits(seconds)} minutes
    </Typography>
  );

  return (
    <>
      <StyledChip
        label={isRunning ? label : 'Session is Over'}
        avatar={
          minutes < 1 ? <StyledAccessTimeIcon /> : <StyledAccessAlarmIcon />
        }
      />
      <Modal open={toggler}>
        <Stack spacing={4}>
          <Typography variant="h3" textAlign="center">
            Session timed out
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ textTransform: 'none' }}
            onClick={() => {
              refreshSearch();
              handleClose();
            }}
          >
            Start new search
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export const Timer: FC = () => {
  const { data, mutate, isValidating } = useSearchId();
  const { mutate: mutateHotels } = useHotels();

  const {
    searchValue: { hotelId },
    resetSearchValue,
  } = useSearchContext();

  const refreshSearch = () => {
    if (hotelId) {
      resetSearchValue();
    }
    mutate();
    mutateHotels(undefined, false);
  };

  const time = new Date(data?.expiresAt as Date).getTime() as unknown as Date;

  if (!data?.expiresAt || isValidating) return null;

  return <TimerChip refreshSearch={refreshSearch} expiryTimestamp={time} />;
};
