import { Alert, Modal, notification, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import _get from 'lodash/get';
import * as yup from 'yup';
import { Form } from './Form';
import styled from 'styled-components';
import { IRoom } from 'src/containers/Bookings/types';
import { AxiosError } from 'axios';
import { FC } from 'react';

const StyledModal = styled(Modal)`
  p {
    margin: 0px;
  }
  h5 {
    display: block;
    margin-left: 25px;
  }
  h4 {
    margin-top: 10px;
  }
`;

export interface IRoomModalState {
  visibility: boolean;
  modalData: IRoom;
  roomIndex: number;
}

export const defaultRoomModalState = {
  visibility: false,
  modalData: {},
} as IRoomModalState;

interface IRoomModal {
  visible: boolean;
  initialValues: IRoom;
  roomIndex: number;
  onOk: (values: IRoom) => Promise<void>;
  onCancel: () => void;
}

const validationSchema = yup.object().shape({
  roomName: yup
    .string()
    .nullable()
    .required('Room Name is required field')
    .max(255, 'Room Name must be below 255 symbols'),
  residency: yup
    .string()
    .required('Residency is required field')
    .max(255, 'Residency must be below 255 symbols'),
  guestFirstName: yup
    .string()
    .nullable()
    .required('First Name is required field')
    .max(100, 'First Name must be below 100 symbols'),
  guestLastName: yup
    .string()
    .nullable()
    .required('Last Name is required field')
    .max(100, 'Last Name must be below 100 symbols'),
  foodType: yup
    .string()
    .nullable()
    .required('Food Type is required field')
    .max(255, 'Food Type must be below 255 symbols'),
  preferredBed: yup
    .string()
    .nullable()
    .required('Bed Type is required field')
    .max(255, 'Bed Type must be below 255 symbols'),
  adults: yup
    .number()
    .nullable()
    .required('Adults is required field')
    .max(255, 'First name must be below 255 symbols'),
  childrenAges: yup.array().of(yup.number()),
  specialRequest: yup.string().nullable().notRequired(),
});

export const RoomModal: FC<IRoomModal> = ({
  visible,
  initialValues,
  roomIndex,
  onOk,
  onCancel,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (values, { setSubmitting, setFieldError, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (error: AxiosError<any>) => {
          const { errors } = _get(error, 'response.data');
          if (_get(error, 'response.status') === 400 && errors) {
            for (const key in errors) {
              const msg = errors[key][0];
              if (msg) {
                errors[key].map((message: string) =>
                  notification.error({
                    message: 'Error',
                    description: key + ': ' + message,
                  })
                );
                setFieldError(key, msg);
                setStatus({
                  fieldApiErrors: { error: msg },
                });
              }
            }
          }
          setSubmitting(false);
        }
      );
    },
  });

  const { handleSubmit, isSubmitting, status, ...rest } = formik;

  return (
    <FormikProvider value={formik}>
      <StyledModal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title={`Edit Room №${roomIndex + 1}`}
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Confirm"
        cancelText="Dismiss"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <Form {...rest} />
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </StyledModal>
    </FormikProvider>
  );
};
