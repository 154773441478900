import MuiButton, {
  ButtonProps as MuiButtonProps,
  ButtonTypeMap,
} from '@mui/material/Button';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import MuiIconButton, {
  iconButtonClasses,
  IconButtonProps as MuiIconButtonProps,
  IconButtonTypeMap,
} from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FC, forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

type WrapperProps = {
  tooltip?: string;
  fullWidth?: boolean;
};

const ButtonWrapper = styled.div<Pick<WrapperProps, 'fullWidth'>>`
  ${({ fullWidth }) => fullWidth && { width: '100%' }}
  .${iconButtonClasses.root} {
    height: auto;
    width: auto;
  }
`;

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  children,
  tooltip,
  fullWidth,
}) => {
  const el = <ButtonWrapper fullWidth={fullWidth}>{children}</ButtonWrapper>;

  return tooltip ? <Tooltip title={tooltip}>{el}</Tooltip> : el;
};

export type ButtonProps = MuiButtonProps & WrapperProps;

export const Button = forwardRef(({ tooltip, fullWidth, ...props }, ref) => (
  <Wrapper tooltip={tooltip} fullWidth={fullWidth}>
    <MuiButton fullWidth={fullWidth} {...props} ref={ref} />
  </Wrapper>
)) as ExtendButtonBase<ButtonTypeMap<WrapperProps>>;

type IconButtonProps = MuiIconButtonProps & Omit<WrapperProps, 'fullWidth'>;

export const IconButton = (({ tooltip, ...props }: IconButtonProps) => (
  <Wrapper tooltip={tooltip}>
    <MuiIconButton {...props} />
  </Wrapper>
)) as ExtendButtonBase<IconButtonTypeMap<Omit<WrapperProps, 'fullWidth'>>>;
