import { PAYMENT_STATUSES } from 'src/constants';
import { ReduxState } from 'src/store';

export const paymentsSelector = (state: ReduxState) => state.payments;

const ERROR_STATUSES = [
  PAYMENT_STATUSES.REFUNDED,
  PAYMENT_STATUSES.PARTIALLY_REFUNDED,
  PAYMENT_STATUSES.CHARGEBACK,
];

const WARNING_STATUSES = [PAYMENT_STATUSES.CANCELLED, PAYMENT_STATUSES.FAILED];

export const statusToStatusColor = (status: string) => {
  if (status === PAYMENT_STATUSES.SUCCESSFUL) {
    return 'green';
  }
  if (ERROR_STATUSES.includes(status)) {
    return 'red';
  }
  if (WARNING_STATUSES.includes(status)) {
    return 'orange';
  }
  return '';
};
