import { FC, useEffect } from 'react';
import { UpCircleFilled, DownCircleFilled } from '@ant-design/icons';
import { Button, Col, Row, Form } from 'antd';
import styled from 'styled-components';
import { FormikProvider, useFormik } from 'formik';
import { FormikField } from 'src/components/FormikField';
import _chunk from 'lodash/chunk';
import { FilterTags } from './FilterTags';
import { IParams } from 'src/store/bookings/reducer';
import { useToggle } from 'src/hooks/useToggle';

interface FiltersPanelProps {
  config: any;
  searchParams: IParams;
  onChange: (filters: IParams) => Promise<any>;
}

const StyledFiltersForm = styled(Form)`
  & .ant-select-selection--multiple {
    .ant-select-selection__choice {
      max-width: 40%;
    }
  }
  & .ant-calendar-picker {
    display: block;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-form-item {
    margin-bottom: 10px;
  }
  & .ant-form-item-explain-connected {
    width: 100%;
    position: absolute;
    bottom: -8px;
    left: 0;
  }
`;

export const FiltersPanel: FC<FiltersPanelProps> = ({
  searchParams,
  config,
  onChange,
}) => {
  const [toggledOn, toggle, hide] = useToggle(false);

  const formik = useFormik({
    validationSchema: config?.validationSchema,
    initialValues: config.initialValues,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onChange(values).then(() => {
        setSubmitting(false);
      });
    },
  });

  const { handleSubmit, isSubmitting, values, isValid, resetForm } = formik;

  const resetFields = (values: IParams) => {
    resetForm({ values });
    handleSubmit();
    hide();
  };

  const onFinish = () => {
    handleSubmit(values);
    hide();
  };

  useEffect(
    () => resetFields(values),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <FormikProvider value={formik}>
      <StyledFiltersForm onFinish={onFinish}>
        {_chunk(config.fields, 5).map((fieldsChunk: any, index: number) => (
          <Row
            key={`chunk-${index}`}
            style={{ display: index !== 0 && !toggledOn ? 'none' : 'flex' }}
            gutter={[8, 8]}
          >
            {fieldsChunk.map(({ id, compProps, ...options }: any) => (
              <Col key={id} style={{ flexBasis: '20%', width: '20%' }}>
                <FormikField name={id} {...options} {...compProps} />
              </Col>
            ))}
          </Row>
        ))}
        <Row gutter={8}>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSubmitting || !isValid}
              >
                Filter
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="ghost"
                onClick={() => resetFields({})}
                disabled={isSubmitting}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
          {config.fields.length > 5 ? (
            <Col style={{ marginLeft: 'auto' }}>
              <Form.Item>
                <Button type="link" onClick={toggle}>
                  {toggledOn ? (
                    <>
                      <UpCircleFilled /> Less filters
                    </>
                  ) : (
                    <>
                      <DownCircleFilled /> More filters
                    </>
                  )}
                </Button>
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <FilterTags
          fields={config.fields}
          values={searchParams}
          onChangeValues={resetFields}
        />
      </StyledFiltersForm>
    </FormikProvider>
  );
};
