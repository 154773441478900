import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchPanelFilledInput } from 'src/containers/BookingEngine/Main/SearchPanel/choosers/components/FilledInput';
import {
  Rates,
  RatesFilters,
  RATES_PAGE_SIZE,
  RATES_QUERY_PARAMS,
  useHotels,
} from 'src/hooks/swr/useHotels';
import styled from 'styled-components';
import { useQueryParams } from 'use-query-params';
import HotelCard from '..';
import HotelCardSkeleton from '../HotelCardSkeleton';

const LinkButton = styled(Typography)`
  cursor: pointer;
  display: inline;
  color: blue;
  background: none;

  & :hover {
    opacity: 0.5;
    text-decoration-style: underline;
  }
`;

const GoBackButton: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <LinkButton
      variant="body3"
      onClick={() => {
        navigate({
          pathname: '/booking-engine',
          search,
        });
      }}
    >
      fresh search
    </LinkButton>
  );
};

const HotelCardList: FC = () => {
  const [params, setParams] = useQueryParams(RATES_QUERY_PARAMS);

  const methods = useForm({
    defaultValues: params,
  });
  const { watch, handleSubmit } = methods;

  useEffect(() => {
    const fn = handleSubmit((data) => setParams(data));

    const debouncedFn = debounce(fn, 400);

    const t = watch((_, { name }) => {
      if (name === RatesFilters.HOTEL_NAME) {
        void debouncedFn();
      } else {
        void fn();
      }
    });

    return () => t.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const { data: ratesData, error, size, setSize, isValidating } = useHotels();

  const data = ratesData?.map((i) => i.rates);

  //@ts-expect-error
  const hotels = data ? [].concat(...data) : [];

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < RATES_PAGE_SIZE);
  const isRefreshing = isValidating && data && data.length === size;

  const loadingComp = Array.apply(null, Array(3)).map((_, index) => (
    <HotelCardSkeleton key={index} />
  ));

  const total = ratesData && ratesData[0]?.ratesCount;

  return (
    <div>
      <Stack padding={2}>
        <FormProvider {...methods}>
          <Controller
            name={RatesFilters.HOTEL_NAME}
            render={({ field }) => (
              <SearchPanelFilledInput
                value={field.value}
                onChange={field.onChange}
                focused={false}
                label="Hotel name"
                placeholder="e.x. Irida Birida Hotel Aris"
              />
            )}
          />
        </FormProvider>
      </Stack>
      <Stack>
        {hotels?.map((rate: Rates) => (
          <HotelCard key={rate.hotel.id + rate.hotel.name} {...rate} />
        ))}
      </Stack>
      {(isLoadingMore || isRefreshing) && loadingComp}
      <Stack padding={2} spacing={6}>
        {error ? (
          <p>
            Error occurred, start the <GoBackButton /> again
          </p>
        ) : (
          <>
            {isEmpty && 'No hotels are available with selected search criteria'}
            {!isReachingEnd && !isLoadingMore && (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => setSize(size + 1)}
              >
                Show more
              </Button>
            )}
          </>
        )}
        {hotels.length && total && !isValidating ? (
          <Typography variant="body2">
            Showing {hotels.length} hotels of {total}
          </Typography>
        ) : null}
      </Stack>
    </div>
  );
};

export default HotelCardList;
