import { Checkbox, DatePicker, Input, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { getCountryLabel } from 'src/containers/BookingEngine/Main/SearchPanel/choosers/ResidencyChooser';
import { useConfig, useConfigMap } from 'src/hooks/swr/useConfig';
import { ManualBooking } from 'src/hooks/swr/useManualBookings';
import { DATE_FORMATS, getDate } from 'src/modules/helpers';
import { IParams } from 'src/store/bookings/reducer';
import * as yup from 'yup';
import { BookingStatus } from '../BookingsList/compounds/BookingStatus';
import {
  Check,
  MANUAL_CHECK_LABEL,
} from '../BookingsList/compounds/CheckStatus';
import { withCurrency } from './ExpandedItem/Details';

const statuses = ['Created', 'Failed', 'Completed', 'Cancelled'].map((i) => ({
  id: i,
  name: i,
}));

export const useColumns: () => ColumnProps<ManualBooking>[] = () => {
  const { supplierMap } = useConfigMap();

  return useMemo(
    () => [
      {
        title: 'Booking Status',
        key: 'bookingStatus',
        width: 90,
        render: ({ status }: ManualBooking) => (
          <BookingStatus bookingStatus={status} />
        ),
      },
      {
        title: 'Booking Date',
        key: 'CreationDate',
        width: 70,
        sorter: true,
        render: ({ createDate }: ManualBooking) =>
          getDate(createDate, DATE_FORMATS.TIME),
      },
      {
        title: 'Booking ID',
        key: 'bookingId',
        dataIndex: 'bookingNumber',
        width: 90,
      },
      {
        title: 'Customer Name',
        key: 'Customer Name',
        width: 90,
        render: ({ customerFirstName, customerLastName }: ManualBooking) =>
          `${customerFirstName} ${customerLastName}`,
      },
      {
        title: 'Check-In Date',
        key: 'CheckInDate',
        sorter: true,
        width: 70,
        render: ({ checkinDate }: ManualBooking) =>
          getDate(checkinDate, DATE_FORMATS.CHECK_IN),
      },
      {
        title: 'Check-Out Date',
        key: 'CheckOutDate',
        sorter: true,
        width: 70,
        render: ({ checkoutDate }: ManualBooking) =>
          getDate(checkoutDate, DATE_FORMATS.CHECK_IN),
      },
      {
        title: 'Provider',
        key: 'providerType',
        dataIndex: 'providerType',
        width: 90,
      },
      {
        title: 'Supplier',
        key: 'supplier',
        width: 100,
        render: ({ supplierName }: ManualBooking) =>
          _get(supplierMap[supplierName], 'name', supplierName),
      },
      {
        title: 'Source Price',
        key: 'sourcePrice',
        width: 100,
        render: ({ sourcePrice, bookingCurrency }: ManualBooking) =>
          withCurrency(sourcePrice, bookingCurrency),
      },
      {
        title: 'Sale Price ',
        key: 'salePrice',
        width: 80,
        render: ({ salePrice, bookingCurrency }: ManualBooking) =>
          withCurrency(salePrice, bookingCurrency),
      },
      {
        title: 'Booking Reference',
        key: 'supplierBookingReference',
        dataIndex: 'supplierBookingReference',
        width: 80,
      },
    ],
    [supplierMap]
  );
};

const filtersMap = {
  bookingDate: {
    label: 'Booking Date',
    id: 'BookingDate',
  },
  bookingId: {
    label: 'Booking Id',
    id: 'Id',
  },
  bookingNumber: {
    label: 'Booking Id',
    id: 'BookingNumber',
  },
  SupplierBookingReference: {
    label: 'Supplier Booking Reference',
    id: 'SupplierBookingReference',
  },
  customerEmail: {
    label: 'Customer Email',
    id: 'CustomerEmail',
  },
  checkInDate: {
    label: 'Check-In Date',
    id: 'CheckinDate',
  },
  checkOutDate: {
    label: 'Check-Out Date',
    id: 'CheckoutDate',
  },
  providerType: {
    label: 'Provider',
    id: 'ProvidersTypes',
  },
  supplier: {
    label: 'Supplier',
    id: 'SuppliersNames',
  },
  countries: {
    label: 'Hotel Country',
    id: 'HotelsCountryCodes',
  },
  hotelCity: {
    label: 'Hotel City',
    id: 'HotelCity',
  },
  hotelName: {
    label: 'Hotel Name',
    id: 'HotelName',
  },
  status: {
    label: 'Booking Status',
    id: 'Statuses',
  },
  checkStatus: {
    label: 'Check Status',
    id: 'CheckStatuses',
  },
  customerLastName: {
    label: 'Customer Last Name',
    id: 'CustomerLastName',
  },
  onlyWithSpecialRequest: {
    label: 'Bookings With Special Request',
    id: 'HasSpecialRequest',
  },
  onlyNoSalePrice: {
    label: 'No Sale Price',
    id: 'NoSalePrice',
  },
  paymentReference: {
    label: 'Payment Reference',
    id: 'PaymentReference',
  },
  paymentReferenceCheckbox: {
    label: 'no Payment Reference',
    id: 'PaymentReferenceCheckbox',
  },
  bookingReference: {
    label: 'Booking Reference',
    id: 'BookingReference',
  },
  bookingReferenceCheckbox: {
    label: 'no Booking Reference',
    id: 'BookingReferenceCheckbox',
  },
  bookedBy: {
    label: 'Booked By',
    id: 'BookedBy',
  },
};

export const withFilters = ({
  BookingNumber,
  BookingDate,
  Id,
  CustomerEmail,
  HotelName,
  HotelCity,
  CheckinDate,
  CheckoutDate,
  CheckStatuses,
  ProvidersTypes,
  SuppliersNames,
  HotelsCountryCodes,
  SupplierBookingReference,
  Statuses,
  CustomerLastName,
  HasSpecialRequest,
  NoSalePrice,
  PaymentReference,
  BookingReference,
  BookedBy,
}: IParams) =>
  BookingNumber
    ? {
        'Filter.BookingNumber': BookingNumber,
      }
    : {
        ...(BookingDate?.length > 1 && {
          'Filter.CreateDate.From': BookingDate[0].format('YYYY-MM-DD'),
          'Filter.CreateDate.To': BookingDate[1].format('YYYY-MM-DD'),
        }),
        ...(Id && {
          'Filter.Id': Id,
        }),
        ...(SupplierBookingReference && {
          'Filter.SupplierBookingReference': SupplierBookingReference,
        }),
        ...(CheckinDate && {
          'Filter.CheckinDate': CheckinDate.format('YYYY-MM-DD'),
        }),
        ...(CheckoutDate && {
          'Filter.CheckoutDate': CheckoutDate.format('YYYY-MM-DD'),
        }),
        ...(ProvidersTypes && {
          'Filter.ProvidersTypes': ProvidersTypes,
        }),
        ...(SuppliersNames && {
          'Filter.SuppliersNames': SuppliersNames,
        }),
        ...(HotelsCountryCodes && {
          'Filter.HotelsCountryCodes': HotelsCountryCodes,
        }),
        ...(HotelCity && {
          'Filter.HotelCity': HotelCity,
        }),
        ...(HotelName && {
          'Filter.HotelName': HotelName,
        }),
        ...(Statuses && {
          'Filter.Statuses': Statuses,
        }),
        ...(CheckStatuses && {
          'Filter.CheckStatuses': CheckStatuses,
        }),
        ...(CustomerLastName && {
          'Filter.CustomerLastName': CustomerLastName,
        }),
        ...(HasSpecialRequest && {
          'Filter.HasSpecialRequest': HasSpecialRequest,
        }),
        ...(NoSalePrice && {
          'Filter.NoSalePrice': NoSalePrice,
        }),
        ...(CustomerEmail && {
          'Filter.CustomerEmail': CustomerEmail,
        }),
        ...((PaymentReference || PaymentReference === '') && {
          'Filter.PaymentReference': PaymentReference,
        }),
        ...((BookingReference || BookingReference === '') && {
          'Filter.BookingReference': BookingReference,
        }),
        ...((BookedBy || BookedBy === '') && {
          'Filter.BookedBy': BookedBy,
        }),
      };

const yupNumber = yup
  .number()
  .typeError('Must be a number')
  .min(0, 'Must be positive')
  .notRequired();

const validationSchema = yup.object({
  [filtersMap.bookingNumber.id]: yupNumber,
  [filtersMap.bookingReferenceCheckbox.id]: yup.boolean().notRequired(),
  [filtersMap.bookingReference.id]: yup
    .mixed()
    .when([filtersMap.bookingReferenceCheckbox.id], {
      is: true,
      then: yup.string().notRequired(),
      otherwise: yupNumber,
    }),
});

export const useFilters = () => {
  const { data: config } = useConfig();

  return useMemo(
    () => ({
      validationSchema,
      initialValues: {
        page: 1,
        pageSize: 10,
        BookingDate: [moment().subtract(7, 'day'), moment()],
      },
      fields: [
        {
          FormComponent: DatePicker.RangePicker,
          compProps: {
            format: DATE_FORMATS.CHECK_IN,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [
                moment().subtract(1, 'day'),
                moment().subtract(1, 'day'),
              ],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'This Month': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              'Last 7 days': [moment().subtract(7, 'day'), moment()],
              'Last 30 days': [moment().subtract(30, 'day'), moment()],
            },
          },
          ...filtersMap.bookingDate,
        },
        // {
        //   FormComponent: Input,
        //   compProps: {
        //     placeholder: 'All',
        //   },
        //   ...filtersMap.bookingId,
        // },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.bookingNumber,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.SupplierBookingReference,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.customerEmail,
        },
        {
          FormComponent: DatePicker,
          compProps: {
            format: DATE_FORMATS.CHECK_IN,
            placeholder: 'All',
            showToday: false,
          },
          ...filtersMap.checkInDate,
        },
        {
          FormComponent: DatePicker,
          compProps: {
            format: DATE_FORMATS.CHECK_IN,
            placeholder: 'All',
            showToday: false,
          },
          ...filtersMap.checkOutDate,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: [
              { name: 'HotelBeds', id: 1 },
              { name: 'Expedia', id: 9 },
              { name: 'Travelgate', id: 4 },
              { name: 'Travolutionary', id: 32 },
            ],
          },
          ...filtersMap.providerType,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: config?.suppliers,
          },
          ...filtersMap.supplier,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: config?.countries?.map(({ code, name }) => ({
              id: code,
              name: getCountryLabel(name, code),
            })),
          },
          ...filtersMap.countries,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.hotelCity,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.hotelName,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: statuses,
          },
          ...filtersMap.status,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: config?.offerCheckStatus?.map((s) => ({
              id: s.id,
              name: MANUAL_CHECK_LABEL[s.name as Check] ?? s.name,
            })),
          },
          ...filtersMap.checkStatus,
        },
        {
          FormComponent: Input,
          compProps: {
            placeholder: 'All',
          },
          ...filtersMap.customerLastName,
        },
        {
          FormComponent: Checkbox,
          compProps: {
            children: filtersMap.onlyWithSpecialRequest,
          },
          ...filtersMap.onlyWithSpecialRequest,
        },
        {
          FormComponent: Checkbox,
          compProps: {
            children: filtersMap.onlyNoSalePrice,
          },
          ...filtersMap.onlyNoSalePrice,
        },
        {
          FormComponent: InputCheckBox,
          allowEmptyString: true,
          compProps: {
            input: {
              placeholder: 'All',
              ...filtersMap.paymentReference,
            },
            checkbox: {
              ...filtersMap.paymentReferenceCheckbox,
            },
          },
          ...filtersMap.paymentReference,
        },
        {
          FormComponent: InputCheckBox,
          allowEmptyString: true,
          compProps: {
            input: {
              placeholder: 'All',
              ...filtersMap.bookingReference,
            },
            checkbox: {
              ...filtersMap.bookingReferenceCheckbox,
            },
          },
          ...filtersMap.bookingReference,
        },
        {
          FormComponent: Select,
          compProps: {
            placeholder: 'All',
            optionFilterProp: 'children',
            mode: 'multiple',
            allowClear: true,
            maxTagCount: 1,
            options: config?.manualBookMadeBy?.map((s) => ({
              id: s,
              name: s,
            })),
          },
          ...filtersMap.bookedBy,
        },
      ],
    }),
    [config]
  );
};

const InputCheckBox: FC = ({
  checkbox,
  input,
  allowEmptyString,
  ...compProps
}: any) => {
  const { values, setFieldValue, setFieldError } = useFormikContext<any>();

  const resetInput = () => {
    setFieldValue(input.id, undefined, false);
    setFieldValue(checkbox.id, false, false);
  };

  const onCheckboxChange = () => {
    if (values[checkbox.id]) {
      resetInput();
    } else {
      setFieldError(input.id, undefined);
      setFieldValue(input.id, '', false);
      setFieldValue(checkbox.id, true, false);
    }
  };

  const checked = values[input.id] === '';

  return (
    <>
      <Input
        {...compProps}
        {...input}
        disabled={checked}
        onChange={(e) =>
          e.target.value === '' ? resetInput() : compProps.onChange(e)
        }
      />
      <Checkbox
        {...compProps}
        {...checkbox}
        checked={checked}
        onChange={onCheckboxChange}
        children={checkbox.label}
        style={{
          margin: 0,
          position: 'absolute',
          top: 50,
          left: 0,
        }}
      />
    </>
  );
};
