import { combineReducers } from 'redux';
import { configReducer as config } from './config/reducer';
import { bookingsReducer as bookings } from './bookings/reducer';
import { hotelsReducer as hotels } from './hotels/reducer';
import { hotelReducer as hotel } from './hotel/reducer';
import { paymentsReducer as payments } from './payments/reducer';
import { exportsReducer as exports } from './exports/reducer';
import {
  externalFacilitiesReducer as externalFacilities,
  internalFacilitiesReducer as internalFacilities,
} from 'src/store/facilities';
import { userReducer } from './user/reducer';

export const rootReducer = combineReducers({
  config,
  hotels,
  hotel,
  bookings,
  payments,
  exports,
  externalFacilities,
  internalFacilities,
  user: userReducer,
});
