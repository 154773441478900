import Modal, { ModalProps } from '@mui/material/Modal';
import Fade, { FadeProps } from '@mui/material/Fade';
import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { dividerClasses } from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

import mediaVariables from 'src/modules/mediaVariables';

import { IconButton } from './WrappedButtons';

type CModalProps = Omit<ModalProps, 'ref' | 'onClose'> & {
  FadeProps?: Omit<FadeProps, 'in' | 'ref' | 'children'>;
  withCloseButton?: boolean;
  onClose?: () => void;
};

export const modalSpacing = { xs: 8, md: 6 };

const modalMixin = css(
  mediaVariables(
    {
      xs: { padding: ({ theme }) => theme.spacing(modalSpacing.xs) },
      md: { padding: ({ theme }) => theme.spacing(modalSpacing.md) },
    },
    ({ padding }, { theme }) => ({
      padding,
      background: theme.palette.background.default,
      borderRadius: '24px',
    })
  )
);

const DIVIDER_ENABLE_GUTTER_CLASS_NAME = 'enable-gutter';

/**
 * @required css variable `padding`
 */
export const modalDividerMixin = css`
  ${`.${dividerClasses.root}:not(.${dividerClasses.vertical}):not(.${DIVIDER_ENABLE_GUTTER_CLASS_NAME})`} {
    margin-left: calc(var(--padding) * -1);
    margin-right: calc(var(--padding) * -1);
    width: calc(100% + var(--padding) * 2);
  }
`;

const StyledContainer = styled.div`
  ${modalMixin}

  height: 100vh;
  width: 90vw;
  max-width: 900px;
  max-height: 675px;
  overflow: auto;
  position: relative;

  ${modalDividerMixin}
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  position: sticky;
  right: var(--padding);
  top: 0px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  height: 0px;
`;

const CModal: FC<CModalProps> = ({
  children,
  open,
  className,
  FadeProps,
  withCloseButton,
  onClose,
  ...props
}) => (
  <StyledModal
    open={open}
    {...props}
    onClose={withCloseButton ? () => null : onClose}
  >
    <Fade in={open} {...FadeProps} mountOnEnter unmountOnExit>
      <StyledContainer className={className}>
        {withCloseButton && onClose && (
          <ButtonWrapper>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </ButtonWrapper>
        )}
        {children}
      </StyledContainer>
    </Fade>
  </StyledModal>
);

export default CModal;
