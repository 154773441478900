import unset from 'lodash/unset';
import { get, Path, PathValue, set, UseFormReturn } from 'react-hook-form';

type DirtyFields = { [key: string]: boolean | DirtyFields | DirtyFields[] };

const isFieldDirty = (t: DirtyFields[keyof DirtyFields]) =>
  typeof t === 'boolean' ? t : areFieldsDirty(t);

const areFieldsDirty = (obj: DirtyFields | DirtyFields[]) => {
  const arr = Array.isArray(obj) ? obj : Object.values(obj);

  for (let i = arr.length; i--; ) {
    if (isFieldDirty(arr[i])) return true;
  }

  return false;
};

const handleSetValue = <N extends Path<T>, T extends Record<string, any>>(
  methods: UseFormReturn<T, any>,
  name: N,
  newValue: PathValue<T, N>,
  isEqual: (a: PathValue<T, N>, b: PathValue<T, N>) => boolean
) => {
  const { control, setValue } = methods;

  if (isEqual(get(control._defaultValues, name), newValue)) {
    if (unset(control._formState.dirtyFields, name)) {
      if (!areFieldsDirty(control._formState.dirtyFields as any)) {
        control._formState.isDirty = false;

        control._subjects.state.next({ name });
      }
    }
  } else {
    set(control._formState.dirtyFields, name, true);

    if (!control._formState.isDirty) {
      control._formState.isDirty = true;

      control._subjects.state.next({ name });
    }
  }

  setValue(name, newValue as any);
};

export default handleSetValue;
