import { DatePicker, Input, Select, Switch } from 'antd';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filterOption, getSelectOptions } from 'src/components/FormikField';
import { childAges } from 'src/containers/Bookings/helpers';
import { DATE_FORMATS } from 'src/modules/helpers';
import {
  countryCodesSelector,
  currenciesSelector,
  languagesSelector,
  pdfBedTypesSelector,
  pdfFoodTypesSelector,
} from 'src/store/config/selectors';

export const useFormFieldsMap = () => {
  const languages = useSelector(languagesSelector);
  const countries = useSelector(countryCodesSelector);
  const currencies = useSelector(currenciesSelector);
  const pdfFoodTypes = useSelector(pdfFoodTypesSelector);
  const pdfBedTypes = useSelector(pdfBedTypesSelector);

  return useMemo(
    () => ({
      emailCulture: {
        key: 'emailCulture',
        name: 'emailCulture',
        label: 'E-mail language',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(languages),
      },
      residency: {
        key: 'residency',
        label: 'Country of residence:',
        FormComponent: Select,
        filterOption,
        showSearch: true,
        children: getSelectOptions(countries),
      },
      bookingReference: {
        key: 'bookingReference',
        name: 'bookingReference',
        label: 'Booking Reference',
        FormComponent: Input,
      },
      paymentConfirmationId: {
        key: 'paymentConfirmationId',
        name: 'paymentConfirmationId',
        label: 'Payment Confirmation Id',
        FormComponent: Input,
      },
      arrivalDate: {
        key: 'arrivalDate',
        name: 'arrivalDate',
        label: 'Arrival',
        FormComponent: DatePicker,
        format: DATE_FORMATS.CHECK_IN,
        showToday: false,
      },
      departureDate: {
        key: 'departureDate',
        name: 'departureDate',
        label: 'Departure',
        FormComponent: DatePicker,
        format: DATE_FORMATS.CHECK_IN,
        showToday: false,
      },
      cancellationPolicy: {
        key: 'cancellationPolicy',
        name: 'cancellationPolicy',
        label: 'Сancellation Policy GMT',
        FormComponent: DatePicker,
        popupClassName: 'ant-date-time-picker-without-footer',
        showTime: { defaultValue: moment('00:00', 'HH:mm') },
        format: DATE_FORMATS.CANCELLATION,
        showToday: false,
        showNow: false,
      },
      specialRequests: {
        key: 'specialRequests',
        name: 'specialRequests',
        label: 'Special request',
        FormComponent: Input,
      },
      bookingRemarks: {
        key: 'bookingRemarks',
        name: 'bookingRemarks',
        label: 'Booking Remarks',
        FormComponent: Input,
      },
      // hotel
      name: {
        key: 'hotelDetails.name',
        name: 'hotelDetails.name',
        label: 'Hotel Name',
        FormComponent: Input,
      },
      address: {
        key: 'hotelDetails.address',
        name: 'hotelDetails.address',
        label: 'Hotel Address',
        FormComponent: Input,
      },
      phone: {
        key: 'hotelDetails.phone',
        name: 'hotelDetails.phone',
        label: 'Hotel Phone',
        FormComponent: Input,
      },
      email: {
        key: 'hotelDetails.email',
        name: 'hotelDetails.email',
        label: 'Hotel Email',
        FormComponent: Input,
      },
      // rooms
      type: {
        key: 'type',
        name: 'type',
        label: 'Room type',
        FormComponent: Input,
      },
      foodType: {
        key: 'foodType',
        name: 'foodType',
        label: 'Included',
        FormComponent: Select,
        children: getSelectOptions(pdfFoodTypes),
      },
      bedType: {
        key: 'bedType',
        name: 'bedType',
        label: 'Preferred bed',
        FormComponent: Select,
        allowClear: true,
        children: getSelectOptions(pdfBedTypes),
      },
      customValue: {
        key: 'customValue',
        name: 'customValue',
        label: 'Custom Value',
        FormComponent: Input,
      },
      numberOfAdults: {
        key: 'numberOfAdults',
        name: 'numberOfAdults',
        label: 'Adults',
        FormComponent: Input,
      },
      numberOfChildren: {
        key: 'numberOfChildren',
        name: 'numberOfChildren',
        label: 'Children',
        FormComponent: Input,
      },
      ageOfChildren: {
        key: 'ageOfChildren',
        name: 'ageOfChildren',
        label: 'Age Of Children',
        FormComponent: Select,
        options: childAges,
      },
      mainGuestName: {
        key: 'mainGuestName',
        name: 'mainGuestName',
        label: 'Guest Name',
        FormComponent: Input,
      },
      // extraCharges
      currency: {
        key: 'extraCharges.currency',
        name: 'extraCharges.currency',
        label: 'Currency',
        FormComponent: Select,
        placeholder: 'Select currency',
        allowClear: true,
        children: getSelectOptions(currencies),
      },
      title: {
        key: 'title',
        name: 'title',
        label: 'Title',
        FormComponent: Input,
      },
      amount: {
        key: 'extraCharges.roomsPackageTaxAndFeeDto.amount',
        name: 'extraCharges.roomsPackageTaxAndFeeDto.amount',
        label: 'Amount',
        FormComponent: Input,
      },
      isIncludedInPrice: {
        key: 'extraCharges.roomsPackageTaxAndFeeDto.isIncludedInPrice',
        name: 'extraCharges.roomsPackageTaxAndFeeDto.isIncludedInPrice',
        //label: 'Is Included In Price',
        FormComponent: Switch,
        checked: false,
      },
    }),
    [currencies, languages, pdfBedTypes, pdfFoodTypes, countries]
  );
};
