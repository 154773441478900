import { FC, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useWatch } from 'react-hook-form';
import useToggler from 'src/hooks/useToggler';
import { SearchPanelPopover } from '../../SearchPanelPopover';
import {
  Fields,
  FieldsName,
  Room,
  SearchPanelInputProps,
} from '../../SearchPanelForm';
import { RoomChooserForm } from './RoomChooserForm';
import {
  SearchFormControl,
  SearchPanelFilledInput,
} from '../components/FilledInput';

const Dot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.text.primary};
  margin: 0 5px;
`;

const StyledButton = styled.button`
  text-align: left;
  cursor: pointer;
`;

const getRoomsValues = (rooms: Room[]) =>
  rooms.reduce(
    (res, room) => ({
      ...res,
      adults: res.adults + room.adults,
      children: res.children + room?.children?.length || 0,
    }),
    { rooms: rooms.length, adults: 0, children: 0, dot: () => <Dot /> }
  );

const getlabel = (rooms: Room[]) => {
  const { rooms: roomsLabel, adults, children } = getRoomsValues(rooms);
  return `Rooms: ${roomsLabel} / Adults: ${adults} ${
    children === 0 ? '' : ' / Children: ' + children
  }`;
};

export const RoomChooser: FC<SearchPanelInputProps> = ({
  handleIsOpen,
  openDefault,
}) => {
  const rooms = useWatch<Fields, FieldsName.ROOMS>({
    name: FieldsName.ROOMS,
  });

  const anchorElRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement>(null);

  const inputLabelRef = useRef<HTMLLabelElement>(null);

  const popoverRef = useRef<HTMLDivElement>(null);

  const { toggler, handleOpen, handleClose } = useToggler(
    handleIsOpen && {
      onOpen: () => handleIsOpen(true),
      onClose: () => handleIsOpen(false),
    }
  );

  useEffect(() => {
    if (openDefault && !toggler) handleOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const form = <RoomChooserForm />;

  return (
    <div ref={anchorElRef}>
      <SearchFormControl label="Guests">
        <SearchPanelFilledInput
          fullWidth
          focused={toggler}
          InputLabelProps={{ shrink: true, ref: inputLabelRef }}
          onClick={handleOpen}
          ref={ref}
          value={getRoomsValues(rooms)}
          InputProps={{
            componentsProps: {
              input: {
                children: getlabel(rooms),
              },
            },
            //@ts-expect-error
            inputComponent: StyledButton,
          }}
        />
        <SearchPanelPopover
          open={toggler}
          anchorEl={anchorElRef.current}
          onClickOutside={handleClose}
          ref={popoverRef}
          arrowTargetRef={inputLabelRef}
        >
          {form}
        </SearchPanelPopover>
      </SearchFormControl>
    </div>
  );
};
