import { ColumnProps } from 'antd/lib/table';
import _isFunction from 'lodash/isFunction';
import { Title } from 'src/containers/Bookings/Booking/compounds/Title';
import { StyledTable } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/Details';
import { IRoom } from 'src/containers/Bookings/types';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { countryCodesMapSelector } from 'src/store/config/selectors';

interface IRooms {
  rooms: IRoom[];
  onRoomClick?: (roomIndex: number) => void;
}

export const Rooms = ({ rooms, onRoomClick }: IRooms) => {
  const countryCodesMap = useSelector(countryCodesMapSelector);

  const roomColumns: ColumnProps<any>[] = [
    {
      dataIndex: 'id',
      key: 'id',
      width: _isFunction(onRoomClick) ? 100 : 80,
      render: (value, record, roomIndex) =>
        _isFunction(onRoomClick) ? (
          <Title
            title={`Room №${roomIndex + 1}`}
            onClick={() => onRoomClick(roomIndex)}
          />
        ) : (
          <span>Room №{roomIndex + 1}</span>
        ),
    },
    {
      title: 'Guest Name',
      key: 'guestFirstName',
      width: 250,
      render: ({ guestFirstName, guestLastName }: IRoom) =>
        guestFirstName + ' ' + guestLastName,
    },
    { title: 'Room Name', dataIndex: 'roomName', key: 'roomName' },
    {
      title: 'Food Type',
      key: 'foodType',
      dataIndex: 'foodType',
      width: 150,
    },
    {
      title: 'Guests',
      key: 'adults',
      width: 130,
      render: ({ adults = 0, childrenAges = [] }: IRoom) => {
        let string = '';
        const childrens = childrenAges?.length;
        if (adults > 0) {
          string = adults + ' adult';
          if (adults > 1) {
            string += 's';
          }
        }
        if (childrens > 0) {
          string += ', ' + childrens + ' child';
          if (childrens > 1) {
            string += 'ren';
          }
        }
        return string;
      },
    },
    {
      title: 'Children Ages',
      key: 'childrenAges',
      width: 130,
      render: ({ childrenAges }: IRoom) =>
        childrenAges?.join(', ').replace(/\b0\b/g, 'Less than 1 year') ||
        'No children',
    },
    {
      title: 'Preferred Bed',
      key: 'preferredBed',
      dataIndex: 'preferredBed',
      width: 110,
    },
    {
      title: 'Special Request',
      dataIndex: 'specialRequest',
      key: 'specialRequest',
    },
    {
      title: 'Residency',
      dataIndex: 'residency',
      key: 'residency',
      render(value) {
        return _get(countryCodesMap[value?.toUpperCase()], 'name', '');
      },
    },
  ];

  return (
    <StyledTable
      key="rooms"
      rowKey="roomName"
      tableLayout="fixed"
      columns={roomColumns}
      pagination={false}
      bordered={true}
      loading={false}
      dataSource={rooms}
    />
  );
};
