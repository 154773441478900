import Stack from '@mui/material/Stack';
import { FC, MouseEvent, PropsWithChildren } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import CModal from 'src/components/common/CModal';
import AddressLink from 'src/containers/BookingEngine/common/AddressLink';
import { Hotel } from 'src/hooks/swr/useHotels';
import useToggler from 'src/hooks/useToggler';
import styled from 'styled-components';
import 'styled-components/macro';

const StyledHotelCardImgContainer = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 12px;
  mask-image: radial-gradient(white, black);
  overflow: hidden;
`;

const StyledImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: transform 500ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const StyledModal = styled(CModal)`
  width: 100%;
  height: auto;
  max-width: 60vw;
  max-height: 90vh;
`;

const GalleryWrapper = styled.div`
  height: auto;
  overflow: hidden;

  /* for safari https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
  mask-image: radial-gradient(white, black);

  .image-gallery-slide-wrapper {
    padding-bottom: 55px;
  }

  .image-gallery-slides {
    margin: 0 auto;
    height: 55vh;
    width: calc(100% - 128px);
  }

  .image-gallery-slide {
    height: 100%;
    background: none;
  }

  .image-gallery-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }

  .image-gallery-index {
    border-radius: 12px;
  }
`;

const StyledButton = styled.button`
  position: absolute;
  top: calc(50% - 55px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  font-size: 10px;
  color: ${(p) => p.theme.custom.reverseTextColor};
  background-color: #525252;
  box-shadow: none;
  outline: none;
  transition: opacity 0.35 ${(p) => p.theme.custom.defaultEasing};
  z-index: 1;
  cursor: pointer;

  &:focus,
  &:hover {
    color: ${(p) => p.theme.custom.reverseTextColor};
    background-color: ${(p) => p.theme.custom.blackSecondaryColor};
  }

  &:hover {
    opacity: 0.8;
  }

  .icon-arrow::before {
    position: relative;
    display: inline-block;
  }
`;

const ButtonPrev = styled(StyledButton)`
  left: 0;

  .icon-arrow::before {
    transform: rotate(270deg);
  }
`;

const ButtonNext = styled(StyledButton)`
  right: 0;

  .icon-arrow::before {
    transform: rotate(90deg);
  }
`;

interface NavigationProps {
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

const NavigationPrev: FC<NavigationProps> = ({ onClick }) => (
  <ButtonPrev onClick={onClick}>
    <span className="icon-arrow" />
  </ButtonPrev>
);

const NavigationNext: FC<NavigationProps> = ({ onClick }) => (
  <ButtonNext onClick={onClick}>
    <span className="icon-arrow" />
  </ButtonNext>
);

const renderLeftNav = (onClick: (event: MouseEvent<HTMLElement>) => void) => (
  <NavigationPrev onClick={onClick} />
);

const renderRightNav = (onClick: (event: MouseEvent<HTMLElement>) => void) => (
  <NavigationNext onClick={onClick} />
);

const Gallery: FC<PropsWithChildren<Hotel>> = ({ images, name, address }) => {
  const { handleOpen, handleClose, toggler } = useToggler();

  const items = images.map((original) => ({
    original,
    thumbnail: original,
  })) as ReactImageGalleryItem[];

  if (images?.length) {
    return (
      <>
        <StyledHotelCardImgContainer>
          <StyledImg src={images[0]} alt="" onClick={handleOpen} />
        </StyledHotelCardImgContainer>
        <StyledModal open={toggler} onClose={handleClose} withCloseButton>
          <Stack spacing={1}>
            <AddressLink name={name} address={address} />
            <GalleryWrapper>
              <ImageGallery
                items={items}
                startIndex={0}
                infinite={true}
                lazyLoad={true}
                showIndex={true}
                showBullets={false}
                showThumbnails={true}
                showPlayButton={false}
                showFullscreenButton={false}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
              />
            </GalleryWrapper>
          </Stack>
        </StyledModal>
      </>
    );
  }

  return <img src="/assets/no-image.svg" alt="" />;
};

export default Gallery;
