import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import _isEmpty from 'lodash/isEmpty';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { RoomRate, useRooms } from 'src/hooks/swr/useRooms';
import styled from 'styled-components';
import { RoomRow } from './TableRow';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import {
  ControllerRenderProps,
  FormProvider,
  useController,
  useForm,
  useWatch,
} from 'react-hook-form';
import Select from '@mui/material/Select';
import { ConfigItem, useConfig } from 'src/hooks/swr/useConfig';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Popover from '@mui/material/Popover';
import get from 'lodash/get';
import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

const StyledTable = styled(MuiTable)`
  table-layout: fixed;
  border-collapse: collapse;
  position: relative;
`;

export type Column = {
  title: string;
  key: string;
  width: number;
  renderFilter?(
    props: ControllerRenderProps,
    placeholder: string,
    onClose: () => void,
    values: any
  ): JSX.Element;
  configKey?: keyof NonNullable<ReturnType<typeof useConfig>['data']>;
};

const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: -1px;
  z-index: 1;
  background: #fff;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background: ${({ theme }) => theme.palette.divider};
  }
`;

const StyledContainer = styled.div`
  width: 200px;
`;

const renderSelect = (
  { ref, ...props }: ControllerRenderProps,
  placeholder: string,
  onClose: () => void,
  values: ConfigItem[]
) => {
  return (
    <StyledContainer>
      <Select
        fullWidth
        multiple
        placeholder={placeholder}
        {...props}
        inputRef={ref}
        onClose={onClose}
        defaultOpen
      >
        {values.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </StyledContainer>
  );
};

const columns: Column[] = [
  {
    title: 'Provider',
    key: 'providerType',
    width: 45,
    renderFilter: renderSelect,
    configKey: 'providerType',
  },
  {
    title: 'Supplier',
    key: 'supplier',
    width: 55,
    renderFilter: renderSelect,
    configKey: 'suppliers',
  },
  {
    title: 'Feed Type',
    key: 'feedType',
    width: 40,
    renderFilter: renderSelect,
    configKey: 'feedTypes',
  },
  {
    title: 'Rate Type',
    key: 'isGross',
    width: 40,
    renderFilter: renderSelect,
  },
  {
    title: 'Room Name',
    key: 'rooms',
    width: 65,
    renderFilter: renderSelect,
  },
  {
    title: 'Food Type',
    key: 'foodType',
    width: 50,
    renderFilter: renderSelect,
    configKey: 'foodType',
  },
  {
    title: 'Refundability',
    key: 'cancellationPolicy.refundability',
    width: 45,
    renderFilter: renderSelect,
    configKey: 'refundability',
  },
  {
    title: 'Source Price',
    key: 'sourcePrice',
    width: 45,
  },
  {
    title: 'SRSP Price',
    key: 'srsplPrice',
    width: 45,
  },
  {
    title: 'Tax / Fee',
    key: 'taxesAndFees',
    width: 50,
  },
  {
    title: '',
    key: 'actionButton',
    width: 40,
  },
];

const TableBody: FC<{ rates: RoomRate[] }> = ({ rates }) => {
  const fields = useWatch();

  return (
    <MuiTableBody>
      {rates.reduce((acc, rate) => {
        let isIncludes = fields.rooms.length
          ? rate.rooms.some((item) => fields.rooms.includes(item.name.trim()))
          : true;

        if (isIncludes) {
          for (const key in fields) {
            if (key !== 'rooms') {
              const values = fields[key];
              if (
                values.length &&
                !values.includes(get(rate, key.split('!').join('.')))
              ) {
                isIncludes = false;

                break;
              }
            }
          }
        }

        if (isIncludes) {
          acc.push(<RoomRow key={rate.id} rate={rate} columns={columns} />);
        }

        return acc;
      }, [] as Array<JSX.Element>)}
    </MuiTableBody>
  );
};

const Filter: FC<{
  name: string;
  renderFilter: NonNullable<Column['renderFilter']>;
  values?: any;
  placeholder: string;
}> = ({ name, renderFilter, values, placeholder }) => {
  const [toggler, setToggler] = useState(false);

  const { field } = useController({ name });

  const { value } = field;

  const ref = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setToggler(false);
  };

  return (
    <>
      <Link
        ref={ref}
        component="button"
        fontSize="inherit"
        color={value.length ? 'secondary.main' : 'text.secondary'}
        onClick={() => {
          setToggler(true);
        }}
        display="flex"
      >
        <FilterAltIcon fontSize="inherit" color="inherit" />
      </Link>
      <Popover
        open={toggler}
        anchorEl={() => ref.current!}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {renderFilter(field, placeholder, handleClose, values)}
      </Popover>
    </>
  );
};

const StyledHeaderCellContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Table: FC<{ rates: RoomRate[] }> = ({ rates }) => {
  const { data: config } = useConfig();

  const possibleValues = useMemo(() => {
    const _map = new Map<string, Set<string | number>>();

    const map = new Map<string, ConfigItem[]>();

    map.set('rooms', []);

    _map.set('rooms', new Set());

    map.set('isGross', []);

    _map.set('isGross', new Set());

    const keysObj = {} as Record<keyof RoomRate, Column['configKey']>;

    for (let i = 0; i < columns.length; i++) {
      const item = columns[i];

      if (item.renderFilter) {
        if (item.configKey) {
          _map.set(item.key, new Set());

          map.set(item.key, []);

          keysObj[item.key as keyof RoomRate] = item.configKey!;
        }
      }
    }

    const isGrossSet = _map.get('isGross')!;

    const roomSet = _map.get('rooms')!;

    const roomsArr = map.get('rooms')!;

    for (let i = 0; i < rates.length; i++) {
      const item = rates[i];

      if (!isGrossSet.has(item.isGross as any)) {
        isGrossSet.add(item.isGross as any);

        map.get('isGross')!.push({
          id: item.isGross as any,
          name: item.isGross ? 'Gross' : 'Net',
        });
      }

      item.rooms.forEach(({ name }) => {
        name = name.trim();

        if (!roomSet.has(name)) {
          roomSet.add(name);

          roomsArr.push({ id: name as any, name });
        }
      });

      for (const key in keysObj) {
        const set = _map.get(key)!;

        const field = get(item, key) as string | number;

        if (!set.has(field)) {
          set.add(field);

          const configItem = (
            config![keysObj[key as keyof RoomRate]!] as ConfigItem[]
          ).find((item) => item.id === field);

          if (configItem) {
            map.get(key)!.push(configItem);
          }
        }
      }
    }

    roomsArr.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return map;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates]);

  const methods = useForm({
    defaultValues: columns.reduce(
      (acc, item) =>
        item.renderFilter
          ? { ...acc, [item.key.split('.').join('!')]: [] }
          : acc,
      {}
    ),
  });

  const {
    searchValue: { hotelId },
  } = useSearchContext();

  useEffect(() => {
    methods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId]);

  return (
    <StyledTable size="small">
      <FormProvider {...methods}>
        <StyledTableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell key={item.key} style={{ width: item.width }}>
                <StyledHeaderCellContainer>
                  {item.title && (
                    <Typography
                      variant="body4"
                      fontWeight={600}
                      display="block"
                      alignItems="center"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      component="div"
                      title={item.title}
                    >
                      {item.title}
                    </Typography>
                  )}
                  {item.renderFilter && (
                    <Filter
                      placeholder={item.title}
                      name={item.key.split('.').join('!')}
                      renderFilter={item.renderFilter}
                      values={possibleValues.get(item.key)}
                    />
                  )}
                </StyledHeaderCellContainer>
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody rates={rates} />
      </FormProvider>
    </StyledTable>
  );
};

export const RoomsTable: FC = () => {
  const { data } = useRooms();

  if (!data) {
    return (
      <Stack spacing={10}>
        <Typography
          variant="h4"
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          paddingTop={30}
          paddingBottom={60}
        >
          Packages are loading
          <StyledCircularProgress size="1em" />
        </Typography>
      </Stack>
    );
  }

  const { rates } = data;

  if (_isEmpty(rates) || rates.length === 0) {
    return (
      <Typography
        variant="body2"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        paddingTop={4}
        paddingBottom={4}
      >
        No free rooms available!
      </Typography>
    );
  }

  return <Table rates={rates} />;
};
