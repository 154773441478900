import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import { memo, useEffect, useState } from 'react';
import { IBooking } from 'src/containers/Bookings/Booking';
import {
  ICryptoTransaction,
  ITransaction,
} from 'src/containers/Bookings/types';
import { BackofficeAPI } from 'src/modules/api';
import { DATE_FORMATS, getDate } from 'src/modules/helpers';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  .ant-table {
    margin: 0 !important;
  }

  & .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 3px;
  }
  & .ant-table-title {
    display: flex;

    .ant-btn {
      margin-left: auto;
    }
  }
`;

const transactionsColumns: ColumnProps<any>[] = [
  {
    title: 'Date/Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    render: (createdAt) => getDate(createdAt, DATE_FORMATS.TIME),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 75,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 95,
  },
  {
    title: 'Fees if possible',
    dataIndex: 'fees',
    key: 'fees',
    width: 95,
  },
  {
    title: 'Network used',
    dataIndex: 'blockchain',
    key: 'blockchain',
    width: 120,
  },
  {
    title: 'TxID',
    dataIndex: 'hashTx',
    key: 'hashTx',
    width: 100,
  },
  {
    title: 'Deposit wallet used',
    dataIndex: 'addressTo',
    key: 'addressTo',
    width: 100,
  },
];

const defaultTransactionsState = {
  loading: false,
  dataSource: [] as ICryptoTransaction[],
  error: null,
};

export const CryptoTransactions = memo(({ offerId }: IBooking) => {
  const [itemState, setItemState] = useState(defaultTransactionsState);

  const handleFetchTransactions = () => {
    setItemState({
      ...defaultTransactionsState,
      loading: true,
    });
    return BackofficeAPI.get(`/booking/${offerId}/cryptoPaymentsDetails`).then(
      ({ data }: AxiosResponse<any, ICryptoTransaction[]>) =>
        setItemState({
          ...defaultTransactionsState,
          dataSource: data.length ? data : [],
        }),
      (error) => setItemState({ ...defaultTransactionsState, error })
    );
  };

  useEffect(() => {
    if (offerId) handleFetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  return (
    <StyledTable
      rowKey="id"
      columns={transactionsColumns}
      pagination={false}
      bordered={true}
      {...itemState}
    />
  );
});
