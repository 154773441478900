import { ChangeEvent, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Input, Radio, RadioChangeEvent } from 'antd';
import _isFunction from 'lodash/isFunction';
import { IOption } from 'src/store/config/reducer';

interface IDescriptionEditor {
  descriptionLanguages: IOption[];
  compProps: {
    [propName: string]: any;
  };
  disabled: boolean;
  placeholder: string;
  value: {
    [propName: string]: any;
  };
  onChange: ChangeEvent<HTMLInputElement>;
}

export const DescriptionEditor = ({
  descriptionLanguages,
  compProps,
  disabled,
  placeholder,
  value,
  onChange,
}: IDescriptionEditor) => {
  const [state, setState] = useState({
    currentLanguage: 'en',
    descriptions: value || {},
  });

  const triggerChange = (changedValue: string) => {
    if (_isFunction(onChange)) {
      onChange({
        ...state.descriptions,
        [state.currentLanguage]: changedValue,
      });
    }
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      descriptions: {
        ...prevState.descriptions,
        [prevState.currentLanguage]: value,
      },
    }));
    triggerChange(value);
  };

  const handleLanguageChange = (e: RadioChangeEvent) =>
    setState({ ...state, currentLanguage: e.target.value });

  return (
    <>
      <Radio.Group
        value={state.currentLanguage}
        onChange={handleLanguageChange}
        size="small"
      >
        {descriptionLanguages.map((language: IOption) => (
          <Radio.Button value={language.id} key={language.id}>
            {language.name}{' '}
            {state.descriptions[language.id] && (
              <CheckOutlined style={{ color: '#52c41a' }} />
            )}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Input.TextArea
        {...compProps}
        value={state.descriptions[state.currentLanguage]}
        onChange={handleDescriptionChange as any}
        autoSize={{ minRows: 3 }}
        disabled={disabled}
        placeholder={placeholder}
      />
    </>
  );
};
