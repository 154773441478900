import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Main } from './Main';
import { Search } from './Search';

export const BookingEngine: FC = () => (
  <Routes>
    <Route path="/" element={<Main />} />
    <Route path="/search" element={<Search />} />
  </Routes>
);
