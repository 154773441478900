import { Spin, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteConfirmation,
  updateConfirmation,
} from 'src/containers/Bookings/api';
import { IBooking, VIPLabel } from 'src/containers/Bookings/Booking';
import { Rooms } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/Rooms';
import { TextArea } from 'src/containers/Bookings/BookingsList/compounds/TextArea';
import { IRoom } from 'src/containers/Bookings/types';
import { BackofficeAPI } from 'src/modules/api';
import { DATE_FORMATS, getDate } from 'src/modules/helpers';
import {
  bookingSuppliersMapSelector,
  countryCodesMapSelector,
} from 'src/store/config/selectors';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table-container,
  .ant-table-content {
    ::before,
    ::after {
      box-shadow: none !important;
    }
  }
  .ant-table {
    margin: 0 !important;
  }

  & .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 3px;
  }
  & .ant-table-title {
    display: flex;

    .ant-btn {
      margin-left: auto;
    }
  }
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;

  ${StyledTable} {
    margin-bottom: 5px;
  }
`;

const defaultDetailsState = {
  loading: false,
  dataSource: {} as IBookingDetails,
  error: null,
};

interface ICustomer {
  guestFirstName: string;
  customerEmail: string;
  customerId: number;
  customerPhone: string;
  customerMarkup: null | number;
}

interface IHotelInfo {
  hotelAddress: string;
  hotelCity: string;
  hotelConfirmation: null | string;
  hotelCountry: string;
  hotelEmail: string;
  hotelName: string;
  hotelPhone: string;
}

export type AffiliateSystem =
  | 'None'
  | 'Google'
  | 'Kayak'
  | 'Trivago'
  | 'Bing'
  | 'TripAdvisor'
  | 'Facebook'
  | 'Wego'
  | 'Mock';

interface IBookingDetails extends ICustomer, IHotelInfo {
  newBookingId: string;
  bookingId: string;
  bookingReference: string;
  checkInDate: string;
  checkOutDate: string;
  commission: number;
  refundability: number;
  supplier: string;
  sourcePrice: number;
  rooms: IRoom[];
  affiliateSystem: AffiliateSystem;
}

interface IConfigItem {
  title: string;
  key: keyof IBookingDetails;
}

interface IColumn {
  id: string;
  value: string | number | null | number[] | IRoom[] | boolean;
}

const bookingColumns: ColumnProps<any>[] = [
  {
    title: 'Summary:',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    colSpan: 2,
    width: 50,
  },
  {
    dataIndex: 'value',
    key: 'value',
    colSpan: 0,
    width: 60,
  },
];

const hotelColumns: ColumnProps<any>[] = [
  {
    title: '\u00A0',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    colSpan: 2,
    width: 40,
  },
  {
    dataIndex: 'value',
    key: 'value',
    colSpan: 0,
    width: 100,
  },
];

const financeColumns: ColumnProps<any>[] = [
  {
    title: 'Finance Info:',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    colSpan: 2,
    width: 10,
  },
  {
    dataIndex: 'value',
    key: 'value',
    colSpan: 0,
    width: 10,
  },
];

export const customerConfig = [
  { title: 'Name', key: 'guestFirstName' },
  { title: 'Email', key: 'customerEmail' },
  { title: 'Phone', key: 'customerPhone' },
  { title: 'ID', key: 'customerId' },
  { title: 'VIP status', key: 'customerMarkup' },
] as IConfigItem[];

const bookingConfig = [
  { title: 'Check-In Date', key: 'checkInDate' },
  { title: 'Check-Out Date', key: 'checkOutDate' },
  { title: 'Hotel Country', key: 'hotelCountry' },
  { title: 'Hotel City', key: 'hotelCity' },
  { title: 'Refundability', key: 'refundability' },
] as IConfigItem[];

const hotelConfig = [
  { title: 'Hotel Name', key: 'hotelName' },
  { title: 'Hotel Address', key: 'hotelAddress' },
  { title: 'Hotel Phone', key: 'hotelPhone' },
  { title: 'Hotel Email', key: 'hotelEmail' },
] as IConfigItem[];

const supplierConfig = [
  { title: 'Supplier', key: 'supplier' },
  { title: 'Booking Reference', key: 'bookingReference' },
  { title: 'Provider Segment ID', key: 'providerSegmentId' },
  { title: 'Provider Booking ID', key: 'supplierBookingId' },
  { title: 'Affiliate Source', key: 'affiliateSystem' },
  { title: 'Hotel Confirmation', key: 'hotelConfirmation' },
] as IConfigItem[];

const financeConfig = [
  { title: 'Sale Price', key: 'salePrice' },
  { title: 'Transaction Fee', key: 'transactionFee' },
  { title: 'Source Price', key: 'sourcePrice' },
  { title: 'Commission', key: 'commission' },
] as IConfigItem[];

const getCustomerColumns = (): ColumnProps<any>[] => [
  {
    title: 'Customer Info:',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    colSpan: 2,
    width: 27,
  },
  {
    key: 'value',
    colSpan: 0,
    width: 70,
    render: ({ id, value }) =>
      id === 'VIP status' ? <VIPLabel value={value} /> : value,
  },
];

export const Details = memo(({ offerId }: IBooking) => {
  const [{ dataSource, loading }, setItemState] = useState(defaultDetailsState);

  const countryCodesMap = useSelector(countryCodesMapSelector);
  const bookingSuppliersMap = useSelector(bookingSuppliersMapSelector);

  const handleFetchDetails = () => {
    setItemState({
      ...defaultDetailsState,
      loading: true,
    });
    return BackofficeAPI.get<IBookingDetails>(
      `/Booking/${offerId}/shortDetails`
    ).then(
      ({ data: dataSource }: AxiosResponse<IBookingDetails>) => {
        setItemState({
          ...defaultDetailsState,
          dataSource,
          loading: false,
        });
      },
      (error) => setItemState({ ...defaultDetailsState, error })
    );
  };

  const handleUpdateConfirmation = (
    offerId: string,
    hotelConfirmation: string | null
  ) =>
    updateConfirmation(offerId, hotelConfirmation).then(() =>
      setItemState({
        ...defaultDetailsState,
        dataSource: { ...dataSource, hotelConfirmation },
      })
    );

  const handleDeleteConfirmation = (offerId: string) =>
    deleteConfirmation(offerId).then(() =>
      setItemState({
        ...defaultDetailsState,
        dataSource: { ...dataSource, hotelConfirmation: null },
      })
    );

  const { supplier, newBookingId, rooms, hotelCountry } = dataSource;

  const getSupplierColumns = (): ColumnProps<any>[] => [
    {
      title: 'Supplier Info:',
      align: 'left',
      dataIndex: 'id',
      key: 'id',
      colSpan: 2,
      width: 150,
    },
    {
      key: 'value',
      colSpan: 0,
      width: 300,
      render: ({ id, value }) => {
        if (id === 'Supplier') {
          let href = bookingSuppliersMap[supplier]?.backofficeLink;
          if (!href) return value;
          if (!/^https?:\/\//i.test(href)) {
            href = 'http://' + href;
          }
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              children={value}
              href={href}
            />
          );
        } else if (id === 'Hotel Confirmation') {
          return (
            <TextArea
              deleteItemId={newBookingId}
              deleteItemText="confirmation"
              rows={2}
              text={value}
              offerId={offerId}
              placeholder="Add a Hotel Confirmation…"
              handleUpdate={handleUpdateConfirmation}
              handleDelete={handleDeleteConfirmation}
            />
          );
        } else return value;
      },
    },
  ];

  const getTableByConfig = (config: IConfigItem[]): IColumn[] =>
    config.map(({ key, title: id }) => {
      switch (key) {
        case 'supplier':
          return {
            id,
            value: _get(bookingSuppliersMap[supplier], 'name', ''),
          };

        case 'hotelCountry':
          return {
            id,
            value: _get(countryCodesMap[hotelCountry], 'name', ''),
          };

        case 'checkInDate':
        case 'checkOutDate':
          return {
            id,
            value: getDate(dataSource[key], DATE_FORMATS.CHECK_IN),
          };

        case 'guestFirstName':
          return {
            id,
            value: `${rooms?.[0]?.guestFirstName} ${rooms?.[0]?.guestLastName}`,
          };

        default:
          return {
            id,
            value: dataSource[key],
          };
      }
    });

  useEffect(() => {
    if (offerId) handleFetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  if (loading || _isEmpty(dataSource))
    return (
      <Spin size="large" style={{ display: 'block', textAlign: 'center' }} />
    );

  return (
    <>
      <TableWrapper>
        <StyledTable
          style={{ maxWidth: 350 }}
          key="customer"
          rowKey="id"
          tableLayout="fixed"
          columns={getCustomerColumns()}
          pagination={false}
          bordered={true}
          loading={loading}
          dataSource={getTableByConfig(customerConfig)}
        />

        <StyledTable
          style={{ maxWidth: 300 }}
          key="booking"
          rowKey="id"
          tableLayout="fixed"
          columns={bookingColumns}
          pagination={false}
          bordered={true}
          loading={loading}
          dataSource={getTableByConfig(bookingConfig)}
        />

        <StyledTable
          style={{ maxWidth: 400 }}
          key="hotel"
          rowKey="id"
          tableLayout="fixed"
          columns={hotelColumns}
          pagination={false}
          bordered={true}
          loading={loading}
          dataSource={getTableByConfig(hotelConfig)}
        />

        <StyledTable
          style={{ maxWidth: 450 }}
          key="supplier"
          rowKey="id"
          tableLayout="fixed"
          columns={getSupplierColumns()}
          pagination={false}
          bordered={true}
          loading={loading}
          dataSource={getTableByConfig(supplierConfig)}
        />

        <StyledTable
          style={{ maxWidth: 210 }}
          key="finance"
          rowKey="id"
          tableLayout="fixed"
          columns={financeColumns}
          pagination={false}
          bordered={true}
          loading={loading}
          dataSource={getTableByConfig(financeConfig)}
        />
      </TableWrapper>

      <Rooms rooms={dataSource?.rooms} />
    </>
  );
});
