import {
  filledInputClasses,
  FormControl,
  formHelperTextClasses,
  formLabelClasses,
} from '@mui/material';
import { inputClasses, InputProps } from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import {
  ChangeEvent,
  FC,
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { Controller } from 'react-hook-form';
import CModal from 'src/components/common/CModal';
import styled from 'styled-components';
import { FieldsName, Labels } from './Form';

const StyledTextField = styled(TextField)<TextFieldProps>`
  position: relative;
  & .${formLabelClasses.root} {
    padding-left: 12px;
    padding-top: 10px;
  }

  & .${inputClasses.focused} {
    border-radius: 20px;
  }

  & .${inputClasses.root} {
    border-radius: 20px;
  }

  & .${inputClasses.input} {
    border-radius: 20px;
  }

  & .${filledInputClasses.focused} {
    border-radius: 12px;
  }

  & .${filledInputClasses.root} {
    border-radius: 12px;
    background: #f7f8fb;
  }

  & .${filledInputClasses.disabled} {
    color: unset;
    -webkit-text-fill-color: unset;
  }

  & .${inputClasses.disabled} {
    color: unset;
    -webkit-text-fill-color: unset;
  }

  & .${formHelperTextClasses.filled}, .${formHelperTextClasses.contained} {
    position: absolute;
    bottom: -18px;
  }
`;

const StyledFormControl = styled(FormControl)`
  position: 'relative';
  //label
  & .${formLabelClasses.root} {
    padding-left: 12px;
    padding-top: 10px;
  }

  & .${inputClasses.focused} {
    border-radius: 20px;
  }

  & .${inputClasses.root} {
    border-radius: 20px;
  }

  & .${inputClasses.input} {
    border-radius: 20px;
  }

  & .${filledInputClasses.focused} {
    border-radius: 12px;
  }

  & .${filledInputClasses.root} {
    border-radius: 12px;
    background: #f7f8fb;
  }

  & .${formHelperTextClasses.filled}, .${formHelperTextClasses.contained} {
    position: absolute;
    bottom: -18px;
  }
`;

const FilledInput = forwardRef<HTMLDivElement, any>(({ ...props }, ref) => (
  <StyledTextField
    {...props}
    ref={ref}
    variant="filled"
    InputLabelProps={{
      ...props.InputLabelProps,
      style: { textTransform: 'capitalize' },
    }}
    InputProps={{
      ...props.InputProps,
      disableUnderline: true,
    }}
    inputProps={{ tabIndex: -1, ...props.inputProps }}
  />
));

const EditFormControl: FC<
  PropsWithChildren<{
    label?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
  }>
> = ({ label, children, onClick }) => (
  <StyledFormControl variant="filled" fullWidth onClick={onClick}>
    {label && (
      <InputLabel style={{ textTransform: 'capitalize' }}>{label}</InputLabel>
    )}
    {children}
  </StyledFormControl>
);

type CProps = {
  name: FieldsName;
};

export const Chooser: FC<PropsWithChildren<CProps & InputProps>> = ({
  name,
  ...inputProps
}) => (
  <EditFormControl label={Labels[name]}>
    <Controller
      name={name}
      render={({ field, fieldState }) => (
        <FilledInput
          {...inputProps}
          {...field}
          error={Boolean(fieldState.error?.message)}
          helperText={fieldState.error?.message}
          variant="filled"
          fullWidth
          value={field.value ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            field.onChange(
              e.target.value === ''
                ? { ...e, target: { ...e.target, value: null } }
                : e
            )
          }
        />
      )}
    />
  </EditFormControl>
);

export const Modal = styled(CModal)`
  max-width: 680px;
  max-height: 100vh;
  height: fit-content;
`;
