import { DatePicker, Input, Select } from 'antd';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import moment from 'moment';

const validateInvoiceDate = (value: any) => {
  if (!moment.isMoment(value)) {
    return 'Invalid date';
  }
  if (value.isAfter(moment())) {
    return 'Date can not be in future';
  }
  return '';
};

export const AddPaymentInvoiceForm = ({ currencies }: any) => (
  <StyledForm layout="horizontal">
    <FormikField
      key="type"
      label="Payment Type"
      name="type"
      FormComponent={Input}
      disabled={true}
    />
    <FormikField
      key="date"
      label="Payment Date"
      name="date"
      FormComponent={DatePicker}
      showTime={true}
      placeholder="Select Time"
      validate={validateInvoiceDate}
    />
    <FormikField
      key="currency"
      label="Payment Currency"
      name="currency"
      FormComponent={Select}
      placeholder="Select"
      options={currencies}
    />
    <FormikField
      key="amount"
      label="Payment Amount"
      name="amount"
      FormComponent={Input}
    />
    <FormikField
      key="oldBookingId"
      label="Old Booking ID"
      name="oldBookingId"
      FormComponent={Input}
    />
  </StyledForm>
);
