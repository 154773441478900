import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import format from 'date-fns/format';
import { forwardRef, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { Calendar, DateProps, EDateSelection } from 'src/components/Calendar';
import { useCalendarLocale } from 'src/components/Calendar/CalendarProvider';
import useMergedRef from 'src/hooks/useMergedRef';
import useToggler from 'src/hooks/useToggler';

import {
  Fields,
  FieldsName,
  SearchPanelInputProps,
} from '../../SearchPanelForm';
import { SearchPanelPopover } from '../../SearchPanelPopover';

import { NightsCount } from './NightsCount';
import {
  SearchFormControl,
  SearchPanelFilledInput,
} from '../components/FilledInput';
import ArrowsUpDown from 'src/icons/ArrowsUpDown';
import { FormHelperText, InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';

type Props = { onBlur: () => void; inputFormat: string } & DateProps &
  SearchPanelInputProps;

const StyledPopper = styled(SearchPanelPopover)`
  --padding: ${({ theme }) => theme.spacing(10)};
  padding-bottom: 0;
`;

export const DateRangeInput = forwardRef<HTMLDivElement, Props>(
  (
    { value, onChange, onBlur, inputFormat, handleIsOpen, openDefault },
    outerRef
  ) => {
    const locale = useCalendarLocale();
    const [start, end] = value;

    const methods = useFormContext<Fields>();
    const errors = methods?.formState?.errors?.[FieldsName?.DATES];

    const [dateSelection, setDateSelection] = useState(EDateSelection.START);

    const { toggler, handleClose, handleOpen } = useToggler(
      handleIsOpen && {
        onOpen: () => handleIsOpen(true),
        onClose: () => handleIsOpen(false),
      }
    );

    const anchorRef = useRef<HTMLDivElement>(null);

    const startRef = useRef<HTMLDivElement>(null);

    const endRef = useRef<HTMLDivElement>(null);

    const startInputLabelRef = useRef<HTMLLabelElement>(null);

    const endInputLabelRef = useRef<HTMLLabelElement>(null);

    const popoverRef = useRef<HTMLDivElement>(null);

    const ref = useMergedRef(anchorRef, outerRef);

    const calendar = (
      <Calendar
        value={value}
        onChange={onChange}
        dateSelection={dateSelection}
        setDateSelection={(v) => {
          let input;

          switch (v) {
            case EDateSelection.START:
              input = startRef.current;
              break;
            case EDateSelection.END:
              input = endRef.current;
              break;
          }

          input?.click();
        }}
      />
    );

    return (
      <Stack
        ref={ref}
        onBlur={onBlur}
        direction="row"
        spacing={{ xs: 4, sm: 4, lg: 4 }}
        justifyContent={{ xs: 'space-between' }}
        alignItems="center"
      >
        <SearchFormControl label="Check-in">
          <SearchPanelFilledInput
            inputProps={{
              size: inputFormat.length,
            }}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowsUpDown />
                </InputAdornment>
              ),
            }}
            ref={startRef}
            onClick={() => {
              setDateSelection(EDateSelection.START);

              handleOpen();
            }}
            InputLabelProps={{ ref: startInputLabelRef }}
            value={
              start && locale ? format(start, inputFormat, { locale }) : ''
            }
            placeholder="START DATE"
            focused={toggler && dateSelection === EDateSelection.START}
            label="Check-in"
          />
          {errors?.[0]?.message ? (
            <FormHelperText error>{errors?.[0]?.message}</FormHelperText>
          ) : null}
        </SearchFormControl>
        <SearchFormControl label="Check-out">
          <SearchPanelFilledInput
            alignRight
            inputProps={{
              size: inputFormat.length,
            }}
            ref={endRef}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowsUpDown />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ ref: endInputLabelRef }}
            onClick={() => {
              setDateSelection(EDateSelection.END);

              handleOpen();
            }}
            value={end && locale ? format(end, inputFormat, { locale }) : ''}
            placeholder="END DATE"
            focused={toggler && dateSelection === EDateSelection.END}
            label="Check-out"
          />
          {errors?.[1]?.message ? (
            <FormHelperText error>{errors?.[1]?.message}</FormHelperText>
          ) : null}
        </SearchFormControl>
        <StyledPopper
          open={toggler}
          anchorEl={anchorRef.current}
          arrowTargetRef={
            dateSelection === EDateSelection.START
              ? startInputLabelRef
              : endInputLabelRef
          }
          onClickOutside={(e) => {
            if (
              toggler &&
              ![startRef, endRef].some(({ current }) =>
                current?.contains(e.target as Node)
              )
            ) {
              handleClose();
            }
          }}
          ref={popoverRef}
        >
          <Stack spacing={2} marginBottom={2}>
            {calendar}
            <Divider />
            <NightsCount dates={value} />
          </Stack>
        </StyledPopper>
      </Stack>
    );
  }
);
