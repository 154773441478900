import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import { FC, Fragment } from 'react';
import AddressLink from 'src/containers/BookingEngine/common/AddressLink';
import { getCountryLabel } from 'src/containers/BookingEngine/Main/SearchPanel/choosers/ResidencyChooser';
import { URL_QUERY_PARAMS } from 'src/containers/BookingEngine/Main/SearchPanel/SearchForm';
import { BookingSummaryResponse } from 'src/hooks/swr/useBookingSummary';
import { useConfigMap } from 'src/hooks/swr/useConfig';
import { useHotelContent } from 'src/hooks/swr/useHotelContent';
import { useFormatPrice } from 'src/hooks/useFormatCurrency';
import styled from 'styled-components';
import { useQueryParams } from 'use-query-params';
import { getDate } from '../../../Sidebar';
import HotelImage from '../HotelImage';
import {
  getSumTaxes,
  TaxesAndFeesPopOver,
} from '../Table/TaxAndFee/TaxesAndFeesPopOver';
import { PricePopOver } from '../Table/TotalPrice/PricePopOver';
import { Row } from './Row';
import { Refundability } from './utils';

const StyledPaper = styled(Paper).attrs({ elevation: 0 })`
  padding: 20px;
  background: #f7f8fb;
  border-radius: 12px;
`;

const Sidebar: FC<{ data: BookingSummaryResponse }> = ({ data }) => {
  const formatPrice = useFormatPrice();

  const [queryParams] = useQueryParams(URL_QUERY_PARAMS);

  const currency = queryParams?.currency || 'EUR';

  const { data: hotelData } = useHotelContent();
  const {
    supplierMap,
    providerTypeMap,
    foodTypesMap,
    countriesMap,
    feedTypesMap,
  } = useConfigMap();

  const groupedTaxes = _groupBy(
    data?.taxesAndFees,
    (item) => item.isIncludedInPrice
  );

  const isSingleRoom = data?.rooms?.length === 1;

  return (
    <Stack spacing={6} position="sticky" top="0">
      <StyledPaper>
        <Stack spacing={3}>
          <HotelImage />
          <AddressLink
            address={hotelData?.hotelContent?.address}
            name={hotelData?.hotelContent?.name}
          />
          <Typography variant="h2" color="secondary">
            Booking summary
          </Typography>
          <Row
            title="Dates"
            value={
              <Stack spacing={2} direction="row" alignItems="center">
                <div>{getDate(data.checkIn)}</div>
                <ArrowRightAltIcon color="secondary" />
                <div>{getDate(data.checkOut)}</div>
              </Stack>
            }
          />
          <Row title="Nights" value={`${data.nightsCount} nights`} />
          <Row title="Rooms" value={`${data.rooms?.length} rooms`} />

          {data?.rooms?.map((room, index) => (
            <Fragment key={index}>
              <Row
                title={`Room ${isSingleRoom ? '' : index + 1} Name`}
                value={room.name}
              />
              <Row
                title="Guests"
                value={`${room?.adultsCount} adults${
                  room?.kidsAges?.length
                    ? ` + ${room?.kidsAges.length} children`
                    : ''
                }`}
              />
              {Boolean(room?.kidsAges?.length) && (
                <Row
                  title="Children ages"
                  value={room?.kidsAges
                    ?.map((age) =>
                      age === 0
                        ? 'less than 1 year'
                        : `${age} ${age === 1 ? 'year' : 'years'}`
                    )
                    .join(', ')}
                />
              )}
              {room?.foodType && (
                <Row
                  title="Food Type"
                  value={_get(
                    foodTypesMap[room.foodType],
                    'name',
                    room.foodType
                  )}
                />
              )}
            </Fragment>
          ))}

          <Row
            title="Refundability"
            value={
              data.cancellationPolicy.refundability ===
              Refundability.Refundable ? (
                <Typography variant="inherit" color="secondary">
                  Refundable
                </Typography>
              ) : (
                <Typography variant="inherit" color="error">
                  Nonrefundable
                </Typography>
              )
            }
          />
          {data.residency && (
            <Row
              title="Residency"
              value={getCountryLabel(
                countriesMap[data.residency].name,
                data.residency
              )}
            />
          )}
          <Row
            title="Provider"
            value={_get(
              providerTypeMap[data.providerType],
              'name',
              data?.providerType
            )}
          />
          {data.supplier && (
            <Row
              title="Supplier"
              value={_get(supplierMap[data.supplier], 'name', data.supplier)}
            />
          )}
          <Row title="Rate type" value={data.isGross ? 'Gross' : 'Net'} />
          <Row
            title="Feed type"
            value={_get(feedTypesMap[data.feedType], 'name', data.feedType)}
          />
        </Stack>
      </StyledPaper>

      <StyledPaper>
        <Stack spacing={3}>
          <Typography variant="h2" color="secondary">
            Price summary
          </Typography>

          {data?.sourcePrice && currency !== 'EUR' && (
            <Row
              title="Source Price in selected currency"
              value={
                <Typography variant="h2" color="secondary">
                  {formatPrice(data.sourcePrice.total)}
                  <PricePopOver
                    label="Price per night:"
                    price={formatPrice(data.sourcePrice.perNight)}
                  />
                </Typography>
              }
            />
          )}
          {data?.supplierRecommendedSellingPrice &&
            currency !== 'EUR' &&
            data.isGross && (
              <Row
                title="SRSP in selected currency"
                value={
                  <Typography variant="h2" color="secondary">
                    {formatPrice(data.supplierRecommendedSellingPrice.total)}
                    <PricePopOver
                      label="Price per night:"
                      price={formatPrice(
                        data.supplierRecommendedSellingPrice.perNight
                      )}
                    />
                  </Typography>
                }
              />
            )}
          {getSumTaxes(groupedTaxes.true) > 0 && (
            <Row
              title="Included in price"
              value={
                <Stack direction="row">
                  {formatPrice(getSumTaxes(groupedTaxes.true))}
                  <TaxesAndFeesPopOver taxesAndFees={groupedTaxes.true} />
                </Stack>
              }
            />
          )}
          {getSumTaxes(groupedTaxes.false) > 0 && (
            <Row
              title="Extra charges"
              value={
                <Stack direction="row">
                  {formatPrice(getSumTaxes(groupedTaxes.false))}
                  <TaxesAndFeesPopOver taxesAndFees={groupedTaxes.false} />
                </Stack>
              }
            />
          )}
          {data?.sourcePrice && (
            <Row
              title="Source Price in Euro"
              value={
                <Typography variant="h2" color="secondary">
                  {formatPrice(data.sourcePrice.total, true)}
                  <PricePopOver
                    label="Price per night:"
                    price={formatPrice(data.sourcePrice.perNight, true)}
                  />
                </Typography>
              }
            />
          )}
          {data?.supplierRecommendedSellingPrice && data.isGross && (
            <Row
              title="SRSP in Euro"
              value={
                <Typography variant="h2" color="secondary">
                  {formatPrice(
                    data.supplierRecommendedSellingPrice.total,
                    true
                  )}
                  <PricePopOver
                    label="Price per night:"
                    price={formatPrice(
                      data.supplierRecommendedSellingPrice.perNight,
                      true
                    )}
                  />
                </Typography>
              }
            />
          )}
        </Stack>
      </StyledPaper>
    </Stack>
  );
};

export default Sidebar;
