import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';
import { apiCall } from 'src/modules/api';
import useSWRImmutable from 'swr/immutable';
import { Price } from './useHotels';
import { useSearchId } from './useSearchId';

export interface BookingSummaryResponse {
  checkIn: string;
  checkOut: string;
  nightsCount: number;
  rooms: Room[];
  providerType: number;
  supplier: string;
  feedType: number;
  cancellationPolicy: CancellationPolicy;
  taxesAndFees: null | TaxesAndFees[];
  isGross: boolean;
  sourcePrice: Price;
  supplierRecommendedSellingPrice: Price;
  residency: string;
}
interface CancellationPolicy {
  refundability: number;
  description: string;
  dateFrom: string;
}

interface Room {
  name: string;
  adultsCount: number;
  kidsAges: number[] | null;
  foodType: number;
}

type TaxesAndFees = {
  title: string | null;
  value: number;
  isIncludedInPrice: boolean;
};

export const useBookingSummary = (roomRateId?: string) => {
  const { data } = useSearchId();
  const {
    searchValue: { hotelId },
  } = useSearchContext();

  return useSWRImmutable<BookingSummaryResponse>(
    roomRateId && data?.searchId && hotelId
      ? `api/booking-engine/search/${data?.searchId}/hotels/${hotelId}/room-rates/${roomRateId}/booking-summary`
      : null,
    apiCall
  );
};
