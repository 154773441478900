import { FC, useEffect, useRef, PropsWithChildren } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import Select from '@mui/material/Select';
import styled from 'styled-components/macro';

import { NumberStepper } from 'src/components/common/NumberStepper';

import { RoomChooserFormItem } from './RoomChooserFormItem';

import { FormSectionProps, MAX_CHILDREN, MAX_GUESTS } from '.';
import { Fields, FieldsName } from '../../../SearchPanelForm';

type Props = FormSectionProps & {
  labelId: string;
  ageLabelId: string;
};

const StyledSelectWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
`;

const SelectWrapper: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (el && el.parentElement) {
      el.parentElement.style.height = `${el.clientHeight}px`;
    }
  }, []);

  return <StyledSelectWrapper ref={ref}> {children}</StyledSelectWrapper>;
};

export const Children: FC<Props> = ({
  index,
  width = 'unset',
  labelId,
  ageLabelId,
}) => {
  const { fields, append, remove } = useFieldArray<
    Fields,
    `${FieldsName.ROOMS}.${number}.children`
  >({
    name: `${FieldsName.ROOMS}.${index}.children`,
  });

  const adults = useWatch<Fields, `${FieldsName.ROOMS}.${number}.adults`>({
    name: `${FieldsName.ROOMS}.${index}.adults`,
  });

  const handleChange = (value: number) => {
    const diff = value - fields.length;

    if (diff > 0) {
      append(Array.from({ length: diff }, () => ({ age: 0 })));
    } else if (diff < 0) {
      const lastIndex = fields.length - 1;
      remove(Array.from({ length: -diff }, (_, i) => lastIndex - i));
    }
  };

  return (
    <>
      <RoomChooserFormItem label={labelId} width={width}>
        <NumberStepper
          min={0}
          max={Math.min(MAX_GUESTS - adults, MAX_CHILDREN)}
          value={fields.length}
          onChange={handleChange}
          size={1}
        />
      </RoomChooserFormItem>
      {fields.map((item, j) => (
        <RoomChooserFormItem
          label={ageLabelId + (j + 1)}
          key={item.id}
          width={width}
        >
          <SelectWrapper>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                  fullWidth
                  variant="outlined"
                  label=""
                  native
                >
                  <option value={0}>Less than 1 year</option>
                  {Array.from({ length: 17 }, (_, i) => {
                    const age = i + 1;

                    return (
                      <option key={age} value={age}>
                        {age}
                      </option>
                    );
                  })}
                </Select>
              )}
              name={`${FieldsName.ROOMS}.${index}.children.${j}.age`}
            />
          </SelectWrapper>
        </RoomChooserFormItem>
      ))}
    </>
  );
};
