import { ManualBooking } from 'src/hooks/swr/useManualBookings';
import { IBooking } from './Booking';
import { Status } from './BookingsList/compounds/BookingStatus';

export const MAX_PEOPLE = 6;
export const MAX_CHILDREN = 4;

export const childAges = GetChildAges();
function GetChildAges() {
  const childArr = [{ id: 0, name: 'Less than 1 year' }];
  let age = 0;
  while (age < 17) {
    ++age;
    childArr.push({ id: age, name: age.toString() });
  }
  return childArr;
}

export enum REFUNDABILITY {
  REFUNDABLE = 1,
  NON_REFUNDABLE = 2,
}

export const STATUSES_TO_EDIT = [Status.COMPLETED];
export const STATUSES_TO_SHOW_VOUCHER = [Status.COMPLETED];

export enum BModals {
  NONE,
  CANCEL,
  REBOOK,
  PDF,
  SUMMARY,
  CUSTOMER,
  SUPPLIER,
  SEND_VOUCHER,
}

export interface BModal {
  visibility: BModals;
  modalData: IBooking;
}

export const defaultBModalState = {
  visibility: BModals.NONE,
  modalData: {},
} as BModal;

export enum MModals {
  NONE = 'MB_NONE',
  EDIT = 'MB_EDIT',
}

export interface MModal {
  visible: MModals;
  mData: ManualBooking;
}

export const defaultMModalState = {
  visible: MModals.NONE,
  mData: {},
} as MModal;
