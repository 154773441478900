import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { CalendarProvider } from 'src/components/Calendar/CalendarProvider';
import { DateRange } from 'src/components/Calendar';

import { dateToStr, getInputFormat, strToDate } from './utils';
import { DateRangeInput } from './DateRangeInput';
import { FieldsName } from '../../SearchPanelForm';

export const DatesChooser: FC<any> = (props) => {
  const inputFormat = getInputFormat('en-GB');

  return (
    <Controller<any, FieldsName.DATES>
      render={({ field }) => (
        <CalendarProvider locale={'en-GB'}>
          <DateRangeInput
            {...field}
            value={
              field.value.map(
                (item: any) => item && strToDate(item)
              ) as DateRange
            }
            onChange={(value: any) =>
              field.onChange(value.map((item: any) => item && dateToStr(item)))
            }
            inputFormat={inputFormat}
            {...props}
          />
        </CalendarProvider>
      )}
      name={FieldsName.DATES}
    />
  );
};
