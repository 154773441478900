import colors from 'src/configs/colors';

type Var<T extends string> = `@${T}`;

type Colors = Record<string, string>;

type Theme<V extends Colors> = Record<
  string,
  String | Var<keyof V extends string ? keyof V : never> | number
>;

const createMainTheme = <C extends Colors, T extends Theme<C>>(
  colors: C,
  theme: keyof T extends Exclude<keyof T, keyof C> ? T | Theme<C> : never
) => ({
  ...colors,
  ...Object.keys(theme).reduce<{
    readonly [key in keyof T]: T[key] extends Var<infer K> ? C[K] : T[key];
  }>((acc, key: keyof typeof theme) => {
    const colorName = /^@(?<var>.*)$/.exec(theme[key] as string)?.groups
      ?.var as undefined | keyof C;

    if (colorName) {
      return { ...acc, [key]: colors[colorName] };
    } else {
      return { ...acc, [key]: theme[key] };
    }
  }, {} as any),
});

export const mainTheme = createMainTheme(colors, {
  primaryColor: '@new_orangeMainColor',
  secondaryColor: '@new_orangeSecondaryColor',
  fifthColor: '#ff6567',
  sixthColor: '#f4b572',
  linkColor: '@darkMainColor',
  linkHoverColor: '@new_orangeSecondaryColor',
  linkActiveColor: '@new_orangeSecondaryColor',
  borderColor: '@greyAlternativeColor',
  grayTextColor: '@greyMainColor',
  grayLightBorderColor: '@greyLightColor',
  errorMessageColor: '@new_redMainColor',
  fontFamily: '"Noto Sans Display", sans-serif',
  fontSizeBase: '14px',
  fontSizeSm: '12px',
  fontSizeBaseCheckout: '13px',
  fontSizeSmCheckout: '15px',
  fontSizeSmDescription: '11px',
  layoutHeaderBackground: '@new_whiteMainColor',
  borderedPanelBackground: '@new_whiteMainColor',
  borderRadiusBase: '3px',
  borderRadiusPortal: '10px',
  borderRadiusPortalCheckout: '22px',
  lineHeightBase: '1.15',
  btnPaddingBase: '0 32px',
  btnPaddingSm: '0 32px',
  btnHeightBase: '44px',
  btnHeightSm: '32px',
  inputHeightBase: '44px',
  inputHeightSm: '34px',
  textColor: '@new_blackMainColor',
  headingColor: '@new_blackMainColor',
  reverseTextColor: '@new_whiteMainColor',
  defaultPhonePadding: '10px',
  defaultDesktopPadding: '30px',
  defaultEasing: 'ease-in-out',
  backgroundMain: '#F7F7F8',
  settingsButtonBackgroundColor: '@greySecondaryColor',
  footerBackgroundColor: '@greySecondaryColor',
  faceBookBackgroundButtonColor: '#3251a8',
  linkedInBackgroundButtonColor: '#0073b1',
  googleBackgroundButtonColor: '#316df9',
  noimageBackgroundColor: '@greySecondaryColor',
  modalMaskBgColor: 'rgba(35, 35, 44, 0.95)',
});

export type CustomTheme = typeof mainTheme;
