import { ReactNode } from 'react';

const joinBy = (arr: ReactNode[], separator: JSX.Element) => {
  const l = arr.length - 1;

  if (!l) return arr;

  const _arr: typeof arr = [];

  for (let i = 0; i < l; i++) {
    _arr.push(arr[i], { ...separator, key: '_' + i });
  }

  _arr.push(arr[l]);

  return _arr;
};

export default joinBy;
