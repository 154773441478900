import axios from 'axios';
import { format, parseISO } from 'date-fns';
import _isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import { URL_QUERY_PARAMS } from 'src/containers/BookingEngine/Main/SearchPanel/SearchForm';
import { Fields } from 'src/containers/BookingEngine/Main/SearchPanel/SearchPanelForm';
import { apiCall } from 'src/modules/api';
import useSWR from 'swr';
import { useQueryParams } from 'use-query-params';

type SearchIdResponse = {
  searchId: string;
  expiresAt: Date;
  ratesCount: number;
};

const formatDate = (date?: string | null) =>
  format(parseISO(date || new Date().toDateString()), 'yyyy-MM-dd');

const getSearchIdParams = ({
  propertyTypes,
  destination,
  residency,
  feedType,
  provider,
  radius,
  rooms,
  dates,
}: Fields) => ({
  location: {
    latitude: destination.position?.lat,
    longitude: destination.position?.lng,
    radiusInMeters: radius * 1000,
  },
  checkIn: formatDate(dates[0]),
  checkOut: formatDate(dates[1]),
  language: 'en-US',
  currency: 'EUR',
  propertyTypes,
  residency,
  feedType,
  provider,
  rooms,
});

export const useSearchId = () => {
  const { pathname } = useLocation();
  const [searchParams] = useQueryParams(URL_QUERY_PARAMS);
  const { currency, ...restParams } = searchParams;
  // eslint-disable-next-line import/no-named-as-default-member
  const cancelFn = axios.CancelToken.source();

  return {
    ...useSWR(
      !_isEmpty(restParams) && pathname === '/booking-engine/search'
        ? ['api/booking-engine/search', restParams]
        : null,
      async (url) => {
        const res = await apiCall<SearchIdResponse>(url, {
          method: 'POST',
          data: getSearchIdParams(restParams as Fields),
          cancelToken: cancelFn.token,
        });

        if (!Object.keys(res).length) {
          throw new Error();
        }

        return res;
      },
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
      }
    ),
    cancelFn,
  };
};
