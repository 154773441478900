import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from 'react';
import {
  BEARER_TOKEN_NAME,
  REFRESH_TOKEN_EXPIRATION_NAME,
  REFRESH_TOKEN_NAME,
  TWO_FA_ENABLED_NAME,
} from 'src/constants';
import { BEARER_TOKEN_EXPIRATION_NAME } from '../constants/index';
import { apiCall } from 'src/modules/api';

import jwtDecode from 'jwt-decode';
import { User } from '../store/user/reducer';
import { useDispatch } from 'react-redux';
import { setUser } from 'src/store/user/actions';
import { ThunkDispatcher } from 'src/store';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { AuthForm, EnableTwoFaForm, VerifyTwoFaForm } from './forms';

export const getTokenRef: [(now: Date) => Promise<string>] = [] as any;

export const handleToken = ({
  token,
  expiration,
  refreshToken,
  refreshExpiration,
  isTwoFaEnabled
}: AuthResponse) => {
  localStorage.setItem(BEARER_TOKEN_NAME, token);

  localStorage.setItem(BEARER_TOKEN_EXPIRATION_NAME, expiration);

  localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);

  localStorage.setItem(REFRESH_TOKEN_EXPIRATION_NAME, refreshExpiration);

  localStorage.setItem(TWO_FA_ENABLED_NAME, isTwoFaEnabled.toString())

  return setUser(jwtDecode(token) as User);
};

export type AuthResponse = {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshExpiration: string;
  isTwoFaEnabled: boolean;
};

const StyledPaper = styled(Paper)`
  max-width: 450px;
  padding: ${({ theme }) => theme.spacing(8, 4)};
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: contrast(0.5);
  margin: auto;
  width: 100%;
`;

const StyledContainer = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
    url('assets/background.png');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
`;

const TwoFaLinkContainer = styled.div`
  margin-top: 16px;
  text-align: center;
  span {
    cursor: pointer;
    color: rgb(0, 108, 228);
  }
`;

enum AuthState {
  SIGN_IN = 'SIGN_IN',
  ENABLE_2FA = 'ENABLE_2FA',
  VERIFY_2FA = 'VERIFY_2FA',
}

const isRefreshTokenValid = (now: Date) =>
  new Date(localStorage.getItem(REFRESH_TOKEN_EXPIRATION_NAME)!) > now;

export const LogoutContext = createContext<() => void>(null as any);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch<ThunkDispatcher>();
  const [authState, setAuthState] = useState<AuthState>(AuthState.SIGN_IN);
  const [qrCodeString, setQrCodeString] = useState('');

  const logout = useCallback(() => {
    localStorage.removeItem(BEARER_TOKEN_NAME);

    localStorage.removeItem(BEARER_TOKEN_EXPIRATION_NAME);

    localStorage.removeItem(REFRESH_TOKEN_NAME);

    localStorage.removeItem(REFRESH_TOKEN_EXPIRATION_NAME);

    localStorage.removeItem(TWO_FA_ENABLED_NAME)

    localStorage.removeItem('is2FaFlowFinished');

    setAuthorized(false);
  }, []);

  const [isAuthorized, setAuthorized] = useState(() => {
    document.addEventListener('visibilitychange', () => {
      if (
        !document.hidden &&
        (!localStorage.getItem(BEARER_TOKEN_NAME) ||
          !isRefreshTokenValid(new Date()) ||
          localStorage.getItem('is2FaFlowFinished') !== 'true')
      ) {
        logout();
      }
    });

    let promise: Promise<string> | undefined;

    getTokenRef[0] = (now) => {
      if (promise) {
        return promise;
      }

      if (new Date(localStorage.getItem(BEARER_TOKEN_EXPIRATION_NAME)!) > now) {
        return Promise.resolve(localStorage.getItem(BEARER_TOKEN_NAME)!);
      }

      const cleanup = () => {
        logout();

        return Promise.reject();
      };

      if (isRefreshTokenValid(now)) {
        promise = apiCall<AuthResponse>(
          'api/auth/refresh',
          {
            method: 'POST',
            data: { refreshToken: localStorage.getItem(REFRESH_TOKEN_NAME)! },
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                BEARER_TOKEN_NAME
              )!}`,
            },
          },
          true
        ).then((res) => {
          dispatch(handleToken(res));

          promise = undefined;

          return res.token;
        }, cleanup);

        return promise;
      }

      return cleanup();
    };

    return (
      Boolean(localStorage.getItem(BEARER_TOKEN_NAME)) &&
      isRefreshTokenValid(new Date())
    );
  });

  const handleChangeAuthState = (state: AuthState) => {
    setAuthState(state);
  };

  const renderForm = () => {
    if (authState === AuthState.ENABLE_2FA && !isAuthorized) {
      return (
        <StyledContainer>
          <StyledPaper elevation={3}>
            <EnableTwoFaForm
              onSuccess={(value) => {
                handleChangeAuthState(AuthState.VERIFY_2FA);
                setQrCodeString(value);
                localStorage.setItem('is2FaFlowFinished', 'false');
              }}
            />
          </StyledPaper>
        </StyledContainer>
      );
    }

    if (authState === AuthState.VERIFY_2FA && !isAuthorized) {
      return (
        <StyledContainer>
          <StyledPaper elevation={3}>
            <VerifyTwoFaForm
              qrCodeString={qrCodeString}
              onSuccess={() => {
                logout();
                handleChangeAuthState(AuthState.SIGN_IN);
              }}
            />
          </StyledPaper>
        </StyledContainer>
      );
    }

    return (
      <LogoutContext.Provider value={logout}>
        {isAuthorized ? (
          children
        ) : (
          <StyledContainer>
            <StyledPaper elevation={3}>
              <AuthForm setAuthorized={setAuthorized} />
              <TwoFaLinkContainer>
                If you didn’t connect 2FA app{' '}
                <span
                  onClick={() => handleChangeAuthState(AuthState.ENABLE_2FA)}
                >
                  Click here
                </span>
              </TwoFaLinkContainer>
            </StyledPaper>
          </StyledContainer>
        )}
      </LogoutContext.Provider>
    );
  };

  return renderForm();
};

export default AuthProvider;
