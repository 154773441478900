import { createAsyncAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';
import { HotelsAPI } from 'src/modules/api';

interface ExternalFacilitiesSuccess {
  fetching: boolean;
  fetched: boolean;
  items: [];
  totalCount: number;
  searchParams: {
    [propName: string]: any;
  };
}

export interface ExternalFacilitiesState {
  fetching: boolean;
  fetched: boolean;
  totalCount: number;
  items: [];
  searchParams: {
    [propName: string]: any;
  };
}

const fetchExternalFacilitiesActions = createAsyncAction(
  'FETCH_EXTERNAL_FACILITIES_REQUEST',
  'FETCH_EXTERNAL_FACILITIES_SUCCESS',
  'FETCH_EXTERNAL_FACILITIES_FAILURE'
)<undefined, ExternalFacilitiesSuccess, any>();

const updateExtFacilityActions = createAsyncAction(
  'UPDATE_EXT_FACILITY_REQUEST',
  'UPDATE_EXT_FACILITY_SUCCESS',
  'UPDATE_EXT_FACILITY_FAILURE'
)<undefined, ExternalFacilitiesSuccess, any>();

export const fetchExternalFacilities =
  (searchParams: any = {}) =>
  (dispatch: any) => {
    dispatch(fetchExternalFacilitiesActions.request());
    return HotelsAPI.get('/externalfacilities', {
      params: searchParams,
    }).then(
      (externalFacilities: AxiosResponse<any>) =>
        dispatch(
          fetchExternalFacilitiesActions.success({
            fetching: false,
            fetched: true,
            items: externalFacilities.data.items,
            totalCount: externalFacilities.data.totalCount,
            searchParams,
          })
        ),
      (error: AxiosError) =>
        dispatch(fetchExternalFacilitiesActions.failure(error))
    );
  };

export const updateExternalFacility =
  ({ id, ...values }: any) =>
  (dispatch: any) => {
    dispatch(updateExtFacilityActions.request());

    return HotelsAPI.patch(`/externalfacilities/${id}`, values).then(
      (response: AxiosResponse<any>) =>
        dispatch(updateExtFacilityActions.success(response.data)),
      (error: AxiosError) => dispatch(updateExtFacilityActions.failure(error))
    );
  };

const defaultState: ExternalFacilitiesState = {
  fetching: false,
  fetched: false,
  items: [],
  totalCount: 0,
  searchParams: {
    page: 1,
    pageSize: 10,
  },
};

export const externalFacilitiesReducer: any =
  handleActions<ExternalFacilitiesState>(
    {
      [fetchExternalFacilitiesActions.request.toString()]: (state) => ({
        ...state,
        fetching: true,
        fetched: false,
        items: [],
      }),
      [fetchExternalFacilitiesActions.success.toString()]: (
        state,
        { payload }
      ) => ({
        ...state,
        ...payload,
      }),
      [fetchExternalFacilitiesActions.failure.toString()]: () => ({
        ...defaultState,
      }),
    },
    defaultState
  );
