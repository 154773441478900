import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from '@mui/material/Typography';
import _minBy from 'lodash/minBy';
import { FC, useState } from 'react';
import { IconButton } from 'src/components/common/WrappedButtons';
import AddressLink from 'src/containers/BookingEngine/common/AddressLink';
import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';
import { useHotelContent } from 'src/hooks/swr/useHotelContent';
import { useRooms } from 'src/hooks/swr/useRooms';
import { useFormatPrice } from 'src/hooks/useFormatCurrency';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

const StyledTypography = styled(Typography)`
  display: inline;
`;

const Copier: FC<{ children: number }> = ({ children }) => {
  const [isCopied, setCopied] = useState(false);

  return (
    <Typography variant="body2" component="div" fontWeight={600}>
      Hotel ID: {children}
      <Tooltip
        title={isCopied ? 'Copied' : 'Copy'}
        placement="right"
        arrow
        TransitionProps={{
          onExited: () => {
            setCopied(false);
          },
        }}
      >
        <Link
          onClick={async () => {
            await navigator.clipboard.writeText(String(children));

            setCopied(true);
          }}
          component="button"
          sx={{ marginLeft: 1 }}
          color="secondary.main"
          fontSize="inherit"
        >
          <ContentCopyIcon fontSize="inherit" color="inherit" />
        </Link>
      </Tooltip>
    </Typography>
  );
};

export const HotelLink: FC = () => {
  const formatPrice = useFormatPrice();
  const { data: hotelData } = useHotelContent();
  const { data: rooms } = useRooms();

  const {
    searchValue: { hotelId },
    resetSearchValue,
  } = useSearchContext();

  const closeRoomsTab = () => {
    if (!hotelId) return;
    resetSearchValue();
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack spacing={1}>
        <AddressLink
          address={hotelData?.hotelContent?.address}
          name={hotelData?.hotelContent?.name}
        />
        {hotelId && <Copier>{hotelId}</Copier>}
      </Stack>
      <Stack direction="row" alignItems="center">
        {rooms && (
          <Stack direction="row" alignItems="center" marginRight={4}>
            {rooms?.rates.length === 0 ? (
              <StyledTypography variant="h2" color="primary">
                Sold Out
              </StyledTypography>
            ) : (
              <>
                <StyledTypography variant="body4">From:</StyledTypography>
                <StyledTypography
                  variant="h2"
                  paddingLeft={1}
                  color="secondary"
                >
                  {formatPrice(
                    Number(
                      _minBy(rooms?.rates, 'sourcePrice.total')?.sourcePrice
                        ?.total
                    )
                  )}
                </StyledTypography>
              </>
            )}
          </Stack>
        )}
        <IconButton size="small" onClick={closeRoomsTab}>
          <HighlightOffOutlinedIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
