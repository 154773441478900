export enum Refundability {
  Unknown = 0,
  Refundable = 1,
  Nonrefundable = 2,
}

export const languages = [
  { name: 'English', code: 'en' },
  { name: 'Danish', code: 'da' },
  { name: 'German', code: 'de' },
  { name: 'Spanish', code: 'es' },
  { name: 'Finish', code: 'fi' },
  { name: 'French', code: 'fr' },
  { name: 'Italian', code: 'it' },
  { name: 'Norwegian', code: 'nb' },
  { name: 'Dutch', code: 'nl' },
  { name: 'Swedish', code: 'sv' },
  { name: 'Russian', code: 'ru' },
];
