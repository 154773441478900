import { FC, PropsWithChildren } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { NumberStepper } from 'src/components/common/NumberStepper';

import { RoomChooserFormItem } from './RoomChooserFormItem';

import { FormSectionProps, MAX_GUESTS } from '.';
import { FieldsName } from '../../../SearchPanelForm';

type Props = FormSectionProps & { labelId: string };

export const Adults: FC<PropsWithChildren<Props>> = ({
  index,
  width = 'unset',
  labelId,
}) => {
  const children: number = useWatch({
    name: `${FieldsName.ROOMS}.${index}.children.length`,
  });

  return (
    <RoomChooserFormItem label={labelId} width={width}>
      <Controller
        render={({ field }) => (
          <NumberStepper
            min={1}
            max={MAX_GUESTS - children}
            {...field}
            size={1}
          />
        )}
        name={`${FieldsName.ROOMS}.${index}.adults`}
      />
    </RoomChooserFormItem>
  );
};
