import Button from '@mui/material/Button';
import { FC } from 'react';
import CModal from 'src/components/common/CModal';
import useToggler from 'src/hooks/useToggler';
import styled from 'styled-components';
import Checkout, { CheckoutProps } from '../../Checkout';

const Modal = styled(CModal)`
  max-width: 80vw;
  max-height: 100vh;
  height: auto;
`;

const StyledButton = styled(Button)`
  position: relative;
  padding: 10px 20px;
  border-radius: 6px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
`;

export const BookButton: FC<CheckoutProps> = ({
  roomRateId,
  cancellationPolicyRaw,
}) => {
  const { toggler, handleOpen, handleClose } = useToggler();

  return (
    <>
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        onClick={handleOpen}
      >
        Book
      </StyledButton>
      <Modal open={toggler} onClose={handleClose} withCloseButton>
        <Checkout
          onClose={handleClose}
          roomRateId={roomRateId}
          cancellationPolicyRaw={cancellationPolicyRaw}
        />
      </Modal>
    </>
  );
};
