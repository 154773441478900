import { createSelector } from 'reselect';
import _get from 'lodash/get';
import {
  countriesMapSelector,
  providerTypesMapSelector,
  bookingSuppliersMapSelector,
} from 'src/store/config/selectors';
import { ReduxState } from '..';

export const bookingsSelector = (state: ReduxState) => state.bookings;

export const bookingsTableListSelector = createSelector(
  bookingsSelector,
  countriesMapSelector,
  providerTypesMapSelector,
  bookingSuppliersMapSelector,
  ({ items }, countriesMap, providerTypesMap, suppliersMap) =>
    items?.map((booking) => ({
      ...booking,
      countryName: _get(countriesMap[booking.countryCode], 'name', ''),
      providerName: _get(providerTypesMap[booking.providerType], 'name', ''),
      supplierInfo: suppliersMap[booking.supplier],
    }))
);
