import { FC } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Label, Value, StyledTooltip, Icon, StyledRow } from '../..';
import { Stack } from '@mui/material';
import useToggler from 'src/hooks/useToggler';
import styled from 'styled-components';

interface Props {
  label: string;
  price: string;
}

const StyledContainer = styled.div`
  display: flex;
`;

export const PricePopOver: FC<Props> = ({ price, label }) => {
  const { toggler, handleClose, handleOpen } = useToggler();

  const content = (
    <Stack spacing={2} display="inline-block">
      <StyledRow>
        <Label>{label}</Label>
        <Value>{price}</Value>
      </StyledRow>
    </Stack>
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledContainer>
        <StyledTooltip
          placement="top"
          title={content}
          onClose={handleClose}
          open={toggler}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Icon onClick={toggler ? handleClose : handleOpen} />
        </StyledTooltip>
      </StyledContainer>
    </ClickAwayListener>
  );
};
