import { FC, ReactNode } from 'react';
import { BookError, FailReason as EFailReason } from 'src/hooks/swr/useBook';
import Typography from '@mui/material/Typography';
import { Row } from '../Row';
import Stack from '@mui/material/Stack';

const CheckProvider: FC = () => (
  <b style={{ color: 'red' }}>
    &nbsp;Check at Provider side the offer status.&nbsp;
  </b>
);

const getFailReasonData = ({
  failReason,
  bookErrors,
  errorMessage,
}: Props): { reason: string; message: ReactNode } => {
  switch (failReason) {
    case EFailReason.Unknown:
      return bookErrors && bookErrors.length
        ? {
            reason: 'Booking failed due to supplier error',
            message: (
              <>
                {bookErrors.map(
                  ({ code, message }) =>
                    (code || message) && (
                      <>
                        {code && <p>Supplier error code: {code}</p>}
                        {message && <p>Supplier error message: {message}</p>}
                      </>
                    )
                )}
              </>
            ),
          }
        : {
            reason: 'Failed due to some error',
            message: (
              <>
                Hint:
                <CheckProvider />
                If it's OK - save manual booking info in excel and send voucher.
                If it's failed - proceed with another booking attempt. If you
                need to investigate the issue, provide QA team with BookingId
                and SearchId.
                {errorMessage && (
                  <>
                    <br />
                    Error message: {errorMessage}
                  </>
                )}
              </>
            ),
          };
    case EFailReason.Delayed:
      return {
        reason:
          'Failed due to booking is pending and is waiting for the response from Supplier',
        message: (
          <>
            Hint:
            <CheckProvider />
            If it's OK - save manual booking info in excel and send voucher. If
            it's failed - proceed with another booking attempt.
            {bookErrors?.map(
              ({ code, message }) =>
                (code || message) && (
                  <>
                    {code && <p>Supplier error code: {code}</p>}
                    {message && <p>Supplier error message: {message}</p>}
                  </>
                )
            )}
          </>
        ),
      };
    case EFailReason.Rejected:
      return {
        reason: 'Failed due to booking is rejected by Supplier',
        message: (
          <>
            Hint:
            <CheckProvider />
            If it's OK - save manual booking info in excel and send voucher. If
            it's failed - proceed with another booking attempt.
          </>
        ),
      };
  }
};

type Props = {
  failReason: EFailReason;
  bookErrors?: BookError[];
  errorMessage?: string;
};

export const FailReason: FC<Props> = (props) => {
  const data = getFailReasonData(props);

  return (
    <Stack spacing={2}>
      <Row
        title="Fail Reason"
        value={<Typography variant="body2">{data.reason}</Typography>}
      />
      <Typography variant="body2">{data.message}</Typography>
    </Stack>
  );
};
