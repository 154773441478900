import { Alert, Modal, notification, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import _get from 'lodash/get';
import * as yup from 'yup';
import { Form } from './Form';
import styled from 'styled-components';
import { IBooking } from 'src/containers/Bookings/Booking';
import { FC } from 'react';
import { AxiosError } from 'axios';

export const STATUSES_TO_REBOOK = ['Failed'];

const StyledModal = styled(Modal)`
  h4 {
    margin-top: 10px;
  }
`;

const validationSchema = yup.object().shape({
  comment: yup
    .string()
    .nullable()
    .required('Comment is required field')
    .max(255, 'Comment must be below 255 symbols'),
});

interface IRebookModal {
  visible: boolean;
  initialValues: IBooking;
  onOk: (values: RebookBooking) => Promise<any>;
  onCancel: () => void;
}

export type RebookBooking = Pick<
  IBooking,
  'comment' | 'offerId' | 'newBookingId'
>;

export const RebookModal: FC<IRebookModal> = ({
  visible,
  initialValues: { comment, offerId, newBookingId },
  onOk,
  onCancel,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      comment,
      offerId,
      newBookingId,
    },
    onSubmit: (values, { setSubmitting, setFieldError, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (error: AxiosError<any>) => {
          const { errors } = _get(error, 'response.data');
          if (_get(error, 'response.status') === 400 && errors) {
            for (const key in errors) {
              const msg = errors[key][0];
              if (msg) {
                errors[key].map((message: string) =>
                  notification.error({
                    message: 'Error',
                    description: key + ': ' + message,
                  })
                );
                setFieldError(key, msg);
                setStatus({
                  fieldApiErrors: { error: msg },
                });
              }
            }
          }
          setSubmitting(false);
        }
      );
    },
  });

  const { status, isSubmitting, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <StyledModal
        centered
        title={`Rebook Booking (${newBookingId})`}
        destroyOnClose={true}
        maskClosable={false}
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        cancelText="Dismiss"
        okText="Change status to Rebooked"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <>
            <h4>
              <b>You are attempting to Rebook Booking</b>
              <br />
              Please note that current function changes only booking status in
              our system to Rebooked. Actual rebook should be done manually on
              the supplier’s side.
            </h4>
            <Form />
          </>
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </StyledModal>
    </FormikProvider>
  );
};
