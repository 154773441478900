import {
  Alert,
  Portal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ThunkDispatcher } from 'src/store';
import { AuthResponse, handleToken } from '../AuthProvider';
import { apiCall } from 'src/modules/api';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';
import EastIcon from '@mui/icons-material/East';

interface EnableTwoFaResponse {
  qrCodeImage: string;
}

interface EnableTwoFaFormProps {
  onSuccess: (value: string) => void;
}

export const EnableTwoFaForm = ({ onSuccess }: EnableTwoFaFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { email: '', password: '' },
  });

  const dispatch = useDispatch<ThunkDispatcher>();

  const [errorStatus, setErrorStatus] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    setIsError(false);
  };

  return (
    <Stack
      spacing={4}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(async (data) => {
        try {
          dispatch(
            handleToken(
              await apiCall<AuthResponse>(
                'api/auth/login',
                {
                  method: 'POST',
                  data,
                },
                true
              )
            )
          );

          const result = await apiCall<EnableTwoFaResponse>(
            'api/auth/setup-2fa',
            {
              method: 'POST',
            }
          );

          onSuccess(result.qrCodeImage);
        } catch (err) {
          setErrorStatus((err as AxiosError).response?.status);
          setErrorMessage((err as AxiosError).response?.data as string);

          setIsError(true);
        }
      })}
    >
      <img alt="Logo" src="/assets/logo.png" style={{ width: '33%' }} />
      <Typography variant="h1">Enable 2FA</Typography>
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            label="Email"
            placeholder="Email"
            type="email"
            {...field}
            fullWidth
            required
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            label="Password"
            placeholder="Password"
            type="password"
            {...field}
            fullWidth
            required
          />
        )}
      />

      <LoadingButton
        type="submit"
        variant="contained"
        loading={isSubmitting}
        loadingPosition="end"
        endIcon={<EastIcon />}
        color="primary"
        fullWidth
      >
        Get QR Code
      </LoadingButton>
      <Portal>
        <Snackbar
          open={isError}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity="error" variant="filled">
          {errorStatus === 404
            ? 'We cannot find this user account, please check that the e-mail address is correct and try again'
            : errorStatus === 401 && errorMessage === 'Invalid or missing OTP.'
            ? 'You already have enabled 2FA. Try to sign in or contact customer support.'
            : errorStatus === 401
            ? errorMessage
            : 'Something going wrong'}

          </Alert>
        </Snackbar>
      </Portal>
    </Stack>
  );
};
