import { createSelector } from 'reselect';
import moment from 'moment';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _isUndefined from 'lodash/isUndefined';
import {
  paymentStatusesMapSelector,
  transactionStatusesMapSelector,
  paymentProcessorsMapSelector,
  paymentTypesMapSelector,
  offerStatusesMapSelector,
  paymentMethodsMapSelector,
  refundReasonsMapSelector,
  currenciesMapSelector,
  bookingSuppliersMapSelector,
  countryCodesMapSelector,
  providerTypesMapSelector,
  offerCheckStatusesMapSelector,
  offerManualCheckStatusesMapSelector,
  blockchainsMapSelector,
} from 'src/store/config/selectors';
import { ReduxState } from 'src/store';
import { FiltersMap, filtersMap } from 'src/modules/filterMap';

export const exportsSelector = (state: ReduxState) => state.exports;

const manualBookingStatusMap = _keyBy(
  [
    { id: 0, name: 'Created' },
    { id: 1, name: 'Completed' },
    { id: 2, name: 'Failed' },
    { id: 3, name: 'Cancelled' },
  ],
  'id'
);

const configFiltersMapSelector = createSelector(
  paymentStatusesMapSelector,
  transactionStatusesMapSelector,
  paymentProcessorsMapSelector,
  paymentTypesMapSelector,
  offerStatusesMapSelector,
  paymentMethodsMapSelector,
  blockchainsMapSelector,
  refundReasonsMapSelector,
  currenciesMapSelector,
  bookingSuppliersMapSelector,
  countryCodesMapSelector,
  providerTypesMapSelector,
  offerCheckStatusesMapSelector,
  offerManualCheckStatusesMapSelector,
  (
    paymentStatusesMap,
    transactionStatusesMap,
    paymentProcessorsMap,
    paymentTypesMap,
    offerStatusesMap,
    paymentMethodsMap,
    blockchainMap,
    refundReasonsMap,
    currenciesMap,
    bookingSuppliersMap,
    countryCodesMap,
    providerTypesMap,
    offerCheckStatusesMap,
    offerManualCheckStatusesMap
  ) => ({
    [filtersMap.paymentType.id]: paymentTypesMap,
    [filtersMap.paymentMethod.id]: paymentMethodsMap,
    [filtersMap.blockchain.id]: blockchainMap,
    [filtersMap.paymentCurrency.id]: currenciesMap,
    [filtersMap.paymentStatus.id]: paymentStatusesMap,
    [filtersMap.paymentProcessor.id]: paymentProcessorsMap,
    [filtersMap.transactionStatus.id]: transactionStatusesMap,
    [filtersMap.supplier.id]: bookingSuppliersMap,
    [filtersMap.supplierIn.id]: bookingSuppliersMap,
    [filtersMap.SupplierIn.id]: bookingSuppliersMap,
    [filtersMap.suppliersNames.id]: bookingSuppliersMap,
    [filtersMap.refundReason.id]: refundReasonsMap,
    [filtersMap.countryIn.id]: countryCodesMap,
    [filtersMap.hotelCountryIn.id]: countryCodesMap,
    [filtersMap.HotelCountryIn.id]: countryCodesMap,
    [filtersMap.hotelsCountryCodes.id]: countryCodesMap,
    [filtersMap.providerType.id]: providerTypesMap,
    [filtersMap.providersTypes.id]: providerTypesMap,
    [filtersMap.checkStatus.id]: offerCheckStatusesMap,
    [filtersMap.CheckStatus.id]: offerCheckStatusesMap,
    [filtersMap.checkStatuses.id]: offerManualCheckStatusesMap,
    [filtersMap.bookingStatus.id]: offerStatusesMap,
    [filtersMap.status.id]: offerStatusesMap,
    [filtersMap.statuses.id]: manualBookingStatusMap,
  })
);

export const exportsTableListSelector = createSelector(
  exportsSelector,
  configFiltersMapSelector,
  ({ items }, configFilters) =>
    (items || []).map((item, index) => {
      const format = 'YYYY-MM-DD';
      const filters = item.filters;

      const paymentDateRange: Record<string, string> = {
        paymentDateFrom: '',
        paymentDateTo: '',
      };
      const lastModifiedDateRange: Record<string, string> = {
        lastModifiedDateFrom: '',
        lastModifiedDateTo: '',
      };
      const bookingDateRange: Record<string, string> = {
        bookingStartDate: '',
        bookingEndDate: '',
        createDateFrom: '',
        createDateTo: '',
      };

      const criteriaWithoutDate = Object.keys(filters).reduce<string[]>(
        (acc, filterKey) => {
          const exportFilters = filters[filterKey];
          const configFilterMap = configFilters[filterKey];

          const formFilter =
            filtersMap[
              filterKey as Exclude<
                FiltersMap,
                | 'paymentDateRange'
                | 'lastModifiedDateRange'
                | 'bookingDateRange'
              >
            ];

          if (_isArray(exportFilters)) {
            let filterValues = exportFilters
              ?.map((exportFilter) =>
                _get(configFilterMap?.[exportFilter], 'name', exportFilter)
              )
              ?.filter((v) => v);

            if (filterKey === filtersMap?.providerType?.id) {
              return [...acc, `${formFilter?.label}: ${filters[filterKey]};`];
            }

            return [
              ...acc,
              `${formFilter?.label}: ${filterValues.join(', ')};`,
            ];
          }

          if (
            filterKey === 'checkInDate' ||
            filterKey === 'checkOutDate' ||
            filterKey === 'checkinDate' ||
            filterKey === 'checkoutDate'
          ) {
            return [
              ...acc,
              `${formFilter?.label}: ${moment(filters[filterKey]).format(
                format
              )};`,
            ];
          }

          if (
            filterKey === 'onlyVipUser' ||
            filterKey === 'onlyWithSpecialRequest' ||
            filterKey === 'hasSpecialRequest' ||
            filterKey === 'noSalePrice'
          ) {
            return [...acc, `${formFilter?.label};`];
          }

          if (filterKey === 'bookingReference' && filters[filterKey] === '') {
            return [...acc, `${filtersMap.bookingReferenceCheckbox.label};`];
          }

          if (filterKey === 'paymentReference' && filters[filterKey] === '') {
            return [...acc, `${filtersMap.paymentReferenceCheckbox.label};`];
          }

          // skip ranges
          if (filterKey === 'isSortingDescending'.toLowerCase()) {
            return [...acc];
          }

          if (!_isUndefined(bookingDateRange[filterKey])) {
            bookingDateRange[filterKey] = exportFilters;

            return [...acc];
          }

          if (!_isUndefined(paymentDateRange[filterKey])) {
            paymentDateRange[filterKey] = exportFilters;

            return [...acc];
          }

          if (!_isUndefined(lastModifiedDateRange[filterKey])) {
            lastModifiedDateRange[filterKey] = exportFilters;

            return [...acc];
          }

          return [
            ...acc,
            `${
              formFilter?.label ? `${formFilter.label}: ${exportFilters}` : ''
            }`,
          ];
        },
        []
      );

      const bookingDateFrom =
        _get(bookingDateRange, 'bookingStartDate') ||
        _get(bookingDateRange, 'createDateFrom');
      const bookingDateTo =
        _get(bookingDateRange, 'bookingEndDate') ||
        _get(bookingDateRange, 'createDateTo');
      const bookingDate = bookingDateFrom
        ? `${filtersMap.bookingDateRange.label}: ${moment(
            bookingDateFrom
          ).format(format)} - ${moment(bookingDateTo).format(format)};`
        : '';

      const paymentDateFrom = _get(paymentDateRange, 'paymentDateFrom');
      const paymentDateTo = _get(paymentDateRange, 'paymentDateTo');
      const paymentDate = paymentDateFrom
        ? `${filtersMap.paymentDateRange.label}: ${moment(
            paymentDateFrom
          ).format(format)} - ${moment(paymentDateTo).format(format)};`
        : '';

      const lastModifiedDateFrom = _get(
        lastModifiedDateRange,
        'lastModifiedDateFrom'
      );
      const lastModifiedDateTo = _get(
        lastModifiedDateRange,
        'lastModifiedDateTo'
      );
      const lastModifiedDate = lastModifiedDateFrom
        ? `${filtersMap.lastModifiedDateRange.label}: ${moment(
            lastModifiedDateFrom
          ).format(format)} - ${moment(lastModifiedDateTo).format(format)};`
        : '';

      const criteria = [
        paymentDate,
        bookingDate,
        lastModifiedDate,
        ...criteriaWithoutDate,
      ];

      return {
        ...item,
        criteria,
        key: index.toString(),
      };
    })
);
