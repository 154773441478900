import React, { FC } from 'react';
import { Button, Input, Layout } from 'antd';
import { StyledPageHeader } from '../../components/StyledPageHeader';
import Stack from '@mui/material/Stack';
import { FormikProvider, useFormik } from 'formik';
import { Spin } from '../../components/Spin';
import { StyledForm } from '../../components/StyledForm';
import Typography from '@mui/material/Typography';
import { FormikField } from '../../components/FormikField';
import * as yup from 'yup';
import { transferFunds, TransferFundsRequest } from './api';

const validationSchema = yup.object().shape({
  amount: yup.number().required('Amount is required'),
  networkId: yup.string().required('Network ID is required'),
  currency: yup
    .string()
    .oneOf(['USDT', 'USDC', 'NATIVE'], 'Invalid currency')
    .required('Currency is required'),
  wallet: yup.string().required('Wallet is required'),
  sourceWalletId: yup.string().optional(),
});

const Form: FC = () => {
  const formik = useFormik<TransferFundsRequest>({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      amount: 0,
      networkId: '',
      currency: '',
      wallet: '',
      sourceWalletId: '',
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      transferFunds(values)
        .then(() => {
          resetForm();
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { handleSubmit, isSubmitting, values, setFieldValue } = formik;

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currency = event.target.value;
    setFieldValue('currency', currency);
    if (currency === 'NATIVE') {
      setFieldValue('sourceWalletId', '');
    }
  };

  if (isSubmitting)
    return (
      <Spin size="large" style={{ display: 'block', textAlign: 'center' }} />
    );

  return (
    <FormikProvider value={formik}>
      <StyledForm layout="horizontal" style={{ width: 700 }}>
        <Typography variant="h4" fontWeight={700}>
          Transfer Details
        </Typography>

        <FormikField
          key="amount"
          label="Amount"
          name="amount"
          placeholder="Enter amount to transfer"
          FormComponent={Input}
        />

        <FormikField
          key="networkId"
          label="Network ID"
          name="networkId"
          placeholder="Enter network ID"
          FormComponent={Input}
        />

        <FormikField
          key="currency"
          label="Currency"
          name="currency"
          placeholder="Enter a currency (e.g. USDT, USDC, NATIVE)"
          FormComponent={Input}
          onChange={handleCurrencyChange}
        />

        <FormikField
          key="wallet"
          label="Wallet"
          name="wallet"
          placeholder="Enter wallet address"
          FormComponent={Input}
        />

        {/*        {values.currency !== 'NATIVE' && (
          <FormikField
            key="sourceWalletId"
            label="Source Wallet ID"
            name="sourceWalletId"
            placeholder="Enter source wallet ID"
            FormComponent={Input}
          />
        )}*/}

        <FormikField
          key="sourceWalletId"
          label="Source Wallet ID"
          name="sourceWalletId"
          placeholder="Enter source wallet ID"
          FormComponent={Input}
        />

        <br />

        <Button type="primary" onClick={() => handleSubmit()}>
          Transfer Funds
        </Button>
      </StyledForm>
    </FormikProvider>
  );
};

export const ManualTransfers: FC = () => (
  <Layout.Content>
    <StyledPageHeader title="Manual Transfers">
      <Stack spacing={5}>
        <Form />
      </Stack>
    </StyledPageHeader>
  </Layout.Content>
);
