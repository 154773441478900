import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, useCallback } from 'react';
import { MultilineCollapse } from 'src/components/MultilineCollapse';
import { useHotelContent } from 'src/hooks/swr/useHotelContent';
import { useSectionsNavigator } from 'src/hooks/useSectionsNavigator';
import styled from 'styled-components';

const Wrapper = styled.div``;

const StyledMultilineCollapse = styled(MultilineCollapse)`
  line-height: 20px;

  h4 {
    font-size: 16px;
    margin: 0;
  }

  p {
    font-size: 14px;
  }

  ul {
    padding-left: 15px;
    font-size: 14px;
  }
`;

const LINES_TO_SHOW = 10;

export const AboutHotel: FC = () => {
  const { data } = useHotelContent();

  const { setSectionRef, scrollToSection } = useSectionsNavigator({
    watchNodes: true,
  });

  const handleExpandButtonClick = useCallback(
    (collapsed: boolean) => {
      if (!collapsed) {
        scrollToSection('about-hotel');
      }
    },
    [scrollToSection]
  );

  const description = data?.hotelContent?.description;

  return (
    <Wrapper ref={setSectionRef} data-id="about-hotel">
      {description && (
        <Stack spacing={2}>
          <Typography variant="h3">About hotel:</Typography>
          <StyledMultilineCollapse
            numberOfLinesToShow={LINES_TO_SHOW}
            text={description}
            onExpandButtonClick={handleExpandButtonClick}
          />
        </Stack>
      )}
    </Wrapper>
  );
};
