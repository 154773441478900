import {
  FileSearchOutlined,
  InfoCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Layout, notification, Spin, Table, Tag, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _groupBy from 'lodash/groupBy';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  CustomerModal,
  ICustomerForm,
} from 'src/containers/Bookings/Booking/compounds/CustomerModal';
import {
  defaultRoomModalState,
  IRoomModalState,
  RoomModal,
} from 'src/containers/Bookings/Booking/compounds/RoomModal';
import {
  ISummaryForm,
  SummaryModal,
} from 'src/containers/Bookings/Booking/compounds/SummaryModal';
import {
  ISupplierForm,
  SupplierModal,
} from 'src/containers/Bookings/Booking/compounds/SupplierModal';
import { Title } from 'src/containers/Bookings/Booking/compounds/Title';
import {
  CancelBooking,
  CancelModal,
  ICancellationPolicy,
  STATUSES_TO_CANCEL,
} from 'src/containers/Bookings/BookingsList/compounds/CancelModal';
import {
  Check,
  CheckStatus,
  IOfferChangeStatus,
} from 'src/containers/Bookings/BookingsList/compounds/CheckStatus';
import { customerConfig } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/Details';
import { Rooms } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/Rooms';
import {
  RebookBooking,
  RebookModal,
  STATUSES_TO_REBOOK,
} from 'src/containers/Bookings/BookingsList/compounds/RebookModal';
import { TextArea } from 'src/containers/Bookings/BookingsList/compounds/TextArea';
import {
  defaultBModalState,
  BModal,
  BModals,
  REFUNDABILITY,
  STATUSES_TO_EDIT,
  STATUSES_TO_SHOW_VOUCHER,
} from 'src/containers/Bookings/helpers';
import { IRoom, ITransaction } from 'src/containers/Bookings/types';
import { apiCall, BackofficeAPI } from 'src/modules/api';
import { DATE_FORMATS, getDate } from 'src/modules/helpers';
import {
  bookingSuppliersMapSelector,
  countryCodesMapSelector,
} from 'src/store/config/selectors';
import styled from 'styled-components';
import {
  cancelBooking,
  changeOfferStatus,
  deleteComment,
  deleteConfirmation,
  handlePreviewVoucher,
  rebookBooking,
  sentVoucher,
  updateComment,
  updateConfirmation,
} from '../api';
import { BookingStatus, Status } from '../BookingsList/compounds/BookingStatus';
import {
  ISentVoucher,
  SendVoucherModal,
  STATUSES_TO_SEND_VOUCHER,
} from '../BookingsList/compounds/SendVoucherModal';
import { AffiliateSystem } from '../BookingsList/compounds/ExpandedItem/Details/index';
import useSWR from 'swr';

export const renderTaxes = (taxes: ITaxesFees[], title: string) => {
  const sum = taxes?.reduce((sum, { value }) => sum + value, 0);
  if (!sum || sum === 0) return null;
  const tooltipTitle = taxes?.map(({ feeTitle, value }) => (
    <h5 key={feeTitle} style={{ color: 'white' }}>
      <b>{feeTitle}:</b>&nbsp;{value}
      <br />
    </h5>
  ));
  return (
    <h4 key={title}>
      <b>{title} </b>
      {sum !== 0 && (
        <>
          {sum}&nbsp;
          <sup
            style={{ cursor: 'help' }}
            children={
              <Tooltip
                placement="top"
                children={<InfoCircleOutlined />}
                title={tooltipTitle}
              />
            }
          />
        </>
      )}
    </h4>
  );
};

const StyledLayout = styled(Layout)`
  margin: 20px;
`;

const StyledHeader = styled.div`
  background: #fafafa;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;

  h4 {
    margin-bottom: 0px;
  }
`;

const StyledFinanceHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled(StyledHeader)`
  background: #fafafa;
  border-radius: 0px;
  margin: 0px;
  h4 {
    margin-bottom: 0px;
    text-decoration: underline;
  }
`;

const StyledSection = styled.section`
  display: block;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  width: fit-content;
  border: 1px dashed black;
`;

const StyledTable = styled(Table)`
  .ant-table-container,
  .ant-table-content {
    ::before,
    ::after {
      box-shadow: none !important;
    }
  }
  .ant-table {
    margin: 0 !important;
  }

  & .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 3px;
  }
  & .ant-table-title {
    display: flex;

    .ant-btn {
      margin-left: auto;
    }
  }
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledFooter = styled.div`
  min-height: 50px;
`;

interface IBookingState {
  loading: boolean;
  dataSource: IBooking;
  error: any;
}

const defaultBookingState = {
  loading: false,
  dataSource: {},
  error: null,
} as IBookingState;

interface ICustomer {
  customerEmail: string;
  customerId: number;
  guestFirstName: string;
  guestLastName: string;
  customerPhone: string;
  customerMarkup: null | number;
}

interface IHotel {
  hotelAddress: string;
  hotelCity: string;
  hotelConfirmation: null | string;
  hotelCountry: string;
  hotelEmail: string;
  hotelName: string;
  hotelPhone: string;
}

interface IFinance {
  profitLoss: number;
  salePrice: number;
  price: number;
  bookedPrice: number;
}

interface ITaxesFees {
  feeTitle: string;
  isIncludedInPrice: boolean;
  value: number;
}

export interface IBooking extends ICustomer, IHotel, IFinance {
  source: string;
  offerId: string;
  bookingId: string;
  newBookingId: string;
  bookingReference: string;
  bookedBy: string;
  checkInDate: string;
  checkOutDate: string;
  commission: number;
  refundability: string;
  supplier: string;
  sourcePrice: number;
  providerType: string;
  checkStatus: string | Check;
  bookingStatus: string | Status;
  rooms: IRoom[];
  cancellationPolicy: ICancellationPolicy;
  cancellationPolicyRaw?: null | string;
  transactions: ITransaction[];
  taxesAndFees: ITaxesFees[];
  bookingRemarks?: string;
  providerSegmentId?: string;
  comment: null | string;
  countryCode: string;
  affiliateSystem: AffiliateSystem;
}

interface IConfigItem {
  title: string;
  key: keyof IBooking;
}

interface IColumn {
  id: string;
  value?:
    | null
    | string
    | number
    | number[]
    | ICancellationPolicy
    | ITransaction[]
    | ITaxesFees[]
    | IRoom[];
}

const summaryLeftColumn = [
  { title: 'Hotel Name', key: 'hotelName' },
  { title: 'Hotel Id', key: 'hotelId' },
  { title: 'Hotel Country', key: 'hotelCountry' },
  { title: 'Hotel City', key: 'hotelCity' },
  { title: 'Hotel Address', key: 'hotelAddress' },
  { title: 'Hotel Phone', key: 'hotelPhone' },
  { title: 'Hotel Email', key: 'hotelEmail' },
  { title: 'Hotel Confirmation', key: 'hotelConfirmation' },
  { title: 'Booking Date', key: 'creationDateTime' },
  { title: 'Check-In Date', key: 'checkInDate' },
  { title: 'Check-Out Date', key: 'checkOutDate' },
  { title: 'Comment', key: 'comment' },
] as IConfigItem[];

const summaryRightColumn = [
  { title: 'Refundability', key: 'refundability' },
  { title: 'Cancellation Policy', key: 'cancellationPolicy' },
  { title: 'Booking Remarks', key: 'bookingRemarks' },
  { title: 'Taxes&Fees', key: 'taxesAndFees' },
] as IConfigItem[];

const supplierConfig = [
  { title: 'Provider', key: 'providerType' },
  { title: 'Supplier', key: 'supplier' },
  { title: 'Booking Reference', key: 'bookingReference' },
  { title: 'Provider Segment ID', key: 'providerSegmentId' },
  { title: 'Provider Booking ID', key: 'providerBookingId' },
  { title: 'Affiliate Source', key: 'affiliateSystem' },
  { title: 'Trivago Reference', key: 'trivagoReference' },
] as IConfigItem[];

const financeConfig = [
  { title: 'Commission', key: 'commission' },
  { title: 'Discount', key: 'customerMarkup' },
  { title: 'Sale Price', key: 'salePrice' },
  { title: 'Transaction Fee', key: 'transactionFee' },
  { title: 'Source Price', key: 'sourcePrice' },
  { title: 'Exchange Rate', key: 'exchangeRate' },
] as IConfigItem[];

const transactionsColumns: ColumnProps<any>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 280,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 110,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 75,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: 70,
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    width: 75,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
  },
];

const SuccessNotification = () =>
  notification.success({
    message: 'Success',
    description: 'Well done! Changes saved.',
  });

export const VIPLabel = ({ value }: { value: null | number }) =>
  value === null ? (
    <>No</>
  ) : (
    <b>
      Yes
      <sup
        style={{ cursor: 'help' }}
        children={
          <Tooltip
            placement="top"
            children={<InfoCircleOutlined />}
            title={`Customer Markup ${Number(value)}%`}
          />
        }
      />
    </b>
  );

export const Booking = memo(() => {
  const { offerId } = useParams<{ offerId: string }>();
  const [{ dataSource, loading }, setBooking] =
    useState<IBookingState>(defaultBookingState);

  const [{ visibility, modalData }, setModalState] =
    useState<BModal>(defaultBModalState);

  const resetModalState = () =>
    setModalState({ modalData, visibility: BModals.NONE });

  const [roomModalState, setRoomModalState] = useState<IRoomModalState>(
    defaultRoomModalState
  );

  const resetRoomModal = () => setRoomModalState(defaultRoomModalState);

  const countryCodesMap = useSelector(countryCodesMapSelector);
  const bookingSuppliersMap = useSelector(bookingSuppliersMapSelector);

  const handleFetchBooking = async (silent?: boolean) => {
    if (!silent) {
      setBooking({
        ...defaultBookingState,
        loading: true,
      });
    }
    try {
      const {
        data: { transactions, bookingStatus, providerType, ...rest },
      }: AxiosResponse<IBooking> = await BackofficeAPI.get<IBooking>(
        `/Booking/${offerId}`
      );

      if (silent) {
        SuccessNotification();
      }
      setBooking({
        ...defaultBookingState,
        dataSource: {
          ...rest,
          providerType,
          bookingStatus,
          transactions: transactions?.map((i) => ({
            ...i,
            currency: i?.currency?.toUpperCase() || '',
          })),
        },
      });
    } catch (e) {
      setBooking({ ...defaultBookingState, error: e });
    }
  };

  const handleUpdateConfirmation = (
    offerId: string,
    hotelConfirmation: string | null
  ) =>
    updateConfirmation(offerId, hotelConfirmation).then(() =>
      handleFetchBooking(true)
    );

  const handleDeleteConfirmation = (offerId: string) =>
    deleteConfirmation(offerId).then(() => handleFetchBooking(true));

  const handleUpdateComment = (offerId: string, comment: string | null) =>
    updateComment(offerId, comment).then(() => handleFetchBooking(true));

  const handleDeleteComment = (id: string) =>
    deleteComment(id).then(() => handleFetchBooking(true));

  const handleChangeOfferStatus = (values: IOfferChangeStatus) =>
    changeOfferStatus(offerId ? { ...values, offerId } : values).then(() =>
      handleFetchBooking(true)
    );

  const handleCancelBooking = (values: CancelBooking) =>
    cancelBooking(offerId ? { ...values, offerId } : values).then(() => {
      resetModalState();
      handleFetchBooking(true);
    });

  const handleRebookBooking = (values: RebookBooking) =>
    rebookBooking(offerId ? { ...values, offerId } : values).then(() => {
      resetModalState();
      handleFetchBooking(true);
    });

  const handleSentVoucher = (values: ISentVoucher) =>
    sentVoucher(offerId ? { ...values, offerId } : values).then(() => {
      resetModalState();
      handleFetchBooking(true);
    });

  const handleUpdateCustomer = (values: ICustomerForm) =>
    BackofficeAPI.put(`/Booking/${offerId}/customer-info`, values).then(() => {
      resetModalState();
      handleFetchBooking(true);
    });

  const handleUpdateSupplier = (values: ISupplierForm) =>
    BackofficeAPI.put(`/Booking/${offerId}/supplier-info`, values).then(() => {
      resetModalState();
      handleFetchBooking(true);
    });

  const handleUpdateSummary = (values: ISummaryForm) =>
    BackofficeAPI.put(`/Booking/${offerId}/summary-info`, values).then(() => {
      resetModalState();
      handleFetchBooking(true);
    });

  const handleUpdateRoom = (values: IRoom) =>
    BackofficeAPI.put(
      `/Booking/${offerId}/room/${roomModalState.roomIndex}`,
      values
    ).then(() => {
      resetRoomModal();
      handleFetchBooking(true);
    });

  const {
    newBookingId,
    bookingStatus,
    source,
    checkStatus,
    rooms,
    supplier,
    bookedBy,
  } = dataSource;

  const openRoomModal = (roomIndex: number) =>
    setRoomModalState({
      visibility: true,
      modalData: {
        ...rooms[roomIndex],
        residency: rooms[roomIndex].residency.toUpperCase(),
      },
      roomIndex,
    });

  const headerColumns = [
    {
      key: 'newBookingId',
      title: 'New Booking ID: ' + (newBookingId || ''),
    },
    {
      key: 'checkStatus',
      title: (
        <>
          Check Status:&nbsp;
          <CheckStatus
            offerId={offerId as string}
            checkStatus={checkStatus}
            changeStatus={handleChangeOfferStatus}
          />
        </>
      ),
    },
    {
      key: 'bookingStatus',
      title: (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          Booking Status:&nbsp;
          <BookingStatus bookingStatus={bookingStatus} />
        </div>
      ),
    },
    {
      key: 'source',
      title:
        'Source: ' + (source === 'Live' ? 'Live' : 'Manual (' + bookedBy + ')'),
    },
  ];

  if (STATUSES_TO_SHOW_VOUCHER.includes(bookingStatus as Status)) {
    headerColumns.push({
      key: 'preview',
      title: (
        <Tag
          key="preview"
          style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
          onClick={() => handlePreviewVoucher(offerId as string)}
        >
          <FileSearchOutlined />
          &nbsp; Preview Voucher
        </Tag>
      ),
    });
  }

  if (STATUSES_TO_SEND_VOUCHER.includes(bookingStatus as Status)) {
    headerColumns.push({
      key: 'send',
      title: (
        <Tag
          key={bookingStatus}
          style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
          onClick={() =>
            setModalState({ modalData, visibility: BModals.SEND_VOUCHER })
          }
        >
          <MailOutlined />
          &nbsp; Send Voucher
        </Tag>
      ),
    });
  }

  if (STATUSES_TO_CANCEL.includes(bookingStatus as Status)) {
    headerColumns.push({
      key: String(BModals.CANCEL),
      title: (
        <Tag
          key={BModals.CANCEL}
          style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
          onClick={() =>
            setModalState({ modalData, visibility: BModals.CANCEL })
          }
        >
          Cancel
        </Tag>
      ),
    });
  }

  if (STATUSES_TO_REBOOK.includes(bookingStatus as Status)) {
    headerColumns.push({
      key: String(BModals.REBOOK),
      title: (
        <Tag
          key={bookingStatus}
          style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
          onClick={() =>
            setModalState({ modalData, visibility: BModals.REBOOK })
          }
        >
          Rebook
        </Tag>
      ),
    });
  }

  const customerColumns: ColumnProps<any>[] = [
    {
      title: Title({
        title: 'Customer Info:',
        onClick: STATUSES_TO_EDIT.includes(bookingStatus as Status)
          ? () =>
              setModalState({
                modalData: dataSource,
                visibility: BModals.CUSTOMER,
              })
          : undefined,
      }),
      align: 'left',
      dataIndex: 'id',
      key: 'id',
      colSpan: 2,
      width: 27,
    },
    {
      key: 'value',
      colSpan: 0,
      width: 70,
      render: ({ id, value }) =>
        id === 'VIP status' ? <VIPLabel value={value} /> : value,
    },
  ];

  const supplierColumns: ColumnProps<any>[] = [
    {
      title: Title({
        title: 'Supplier Info:',
        onClick: STATUSES_TO_EDIT.includes(bookingStatus as Status)
          ? () =>
              setModalState({
                modalData: dataSource,
                visibility: BModals.SUPPLIER,
              })
          : undefined,
      }),
      align: 'left',
      dataIndex: 'id',
      key: 'id',
      colSpan: 2,
      width: 27,
    },
    {
      key: 'value',
      colSpan: 0,
      width: 70,
      render: ({ id, value }) => {
        if (id === 'Supplier') {
          let href = bookingSuppliersMap[supplier]?.backofficeLink;
          if (!href) return value;
          if (!/^https?:\/\//i.test(href)) {
            href = 'http://' + href;
          }
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              children={value}
              href={href}
            />
          );
        }
        return value;
      },
    },
  ];

  const getSummaryColumns = ({ renderTitle = true }): ColumnProps<any>[] => [
    {
      title: renderTitle
        ? 'Summary:'
        : Title({
            title: '\u00A0',
            onClick: STATUSES_TO_EDIT.includes(bookingStatus as Status)
              ? () =>
                  setModalState({
                    modalData: dataSource,
                    visibility: BModals.SUMMARY,
                  })
              : undefined,
          }),
      align: 'left',
      dataIndex: 'id',
      key: 'id',
      colSpan: 2,
      width: 50,
    },
    {
      key: 'value',
      colSpan: 0,
      width: 100,
      render: ({ id, value }) => {
        switch (id) {
          case 'Hotel Confirmation':
            return (
              <TextArea
                deleteItemId={newBookingId}
                deleteItemText="confirmation"
                placeholder="Add a Hotel Confirmation…"
                rows={2}
                text={value}
                offerId={offerId as string}
                handleUpdate={handleUpdateConfirmation}
                handleDelete={handleDeleteConfirmation}
              />
            );
          case 'Comment':
            return (
              <TextArea
                deleteItemId={newBookingId}
                deleteItemText="comment"
                placeholder="Add a comment…"
                rows={2}
                text={value}
                offerId={offerId as string}
                handleUpdate={handleUpdateComment}
                handleDelete={handleDeleteComment}
              />
            );
          case 'Taxes&Fees': {
            const groupedTaxes = _groupBy(
              value,
              (item) => item.isIncludedInPrice
            );
            return (
              <>
                {renderTaxes(groupedTaxes.true, 'Included taxes: ')}
                {renderTaxes(groupedTaxes.false, 'Extra charges: ')}
              </>
            );
          }
          default:
            return value;
        }
      },
    },
  ];

  const { data } = useSWR<any>(`api/bookings/${offerId}`, apiCall);

  const getTableByConfig = (config: IConfigItem[]): IColumn[] =>
    config.map((item) => {
      switch (item.key) {
        case 'trivagoReference' as any:
          return {
            id: item.title,
            value: data?.analytics?.trivagoAtalytics?.reference,
          };
        case 'supplier':
          return {
            id: item.title,
            value: _get(bookingSuppliersMap[dataSource.supplier], 'name', ''),
          };

        case 'hotelCountry':
          return {
            id: item.title,
            value: _get(countryCodesMap[dataSource.hotelCountry], 'name', ''),
          };

        case 'creationDateTime' as any:
          return {
            id: item.title,
            value: getDate(dataSource[item.key] as any, DATE_FORMATS.TIME),
          };

        case 'checkInDate':
        case 'checkOutDate':
          return {
            id: item.title,
            value:
              dataSource[item.key] &&
              getDate(dataSource[item.key] as any, DATE_FORMATS.CHECK_IN),
          };

        case 'refundability':
          return {
            id: item.title,
            value: dataSource.cancellationPolicy?.refundability,
          };

        case 'cancellationPolicy':
          return {
            id: item.title,
            value:
              `${
                dataSource.cancellationPolicy?.refundability ===
                REFUNDABILITY.REFUNDABLE
                  ? 'Refundable until '
                  : 'Non-refundable from '
              }` +
              getDate(
                dataSource.cancellationPolicy?.dateFrom,
                DATE_FORMATS.CANCELLATION
              ),
          };

        case 'bookingRemarks':
          return {
            id: item.title,
            value: dataSource.cancellationPolicy?.bookingRemarks,
          };

        case 'guestFirstName':
          return {
            id: item.title,
            value:
              dataSource?.rooms &&
              dataSource?.rooms[0]?.guestFirstName +
                ' ' +
                dataSource.rooms[0]?.guestLastName,
          };

        default:
          return {
            id: item.title,
            value: dataSource[item.key],
          };
      }
    });

  const financeColumnsConfig = [
    getTableByConfig(financeConfig)?.slice(0, 1),
    getTableByConfig(financeConfig)?.slice(2, 4),
    getTableByConfig(financeConfig)?.slice(4, 6),
  ];

  const financeColumns: ColumnProps<any>[] = [
    {
      title: 'Finance Info:',
      align: 'left',
      key: 'id',
      render: () => (
        <StyledFinanceHeader>
          {financeColumnsConfig?.map((item, index) => (
            <div
              key={index}
              style={{ flexBasis: `${100 / financeColumnsConfig.length}%` }}
            >
              {item?.map(({ id, value }: IColumn) => (
                <h4 key={id + value}>
                  <>
                    <b>{id}: </b>
                    {value}
                  </>
                </h4>
              ))}
            </div>
          ))}
        </StyledFinanceHeader>
      ),
    },
  ];

  useEffect(() => {
    if (offerId) handleFetchBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  if (loading || _isEmpty(dataSource))
    return (
      <Spin
        size="large"
        style={{ display: 'block', textAlign: 'center', marginTop: 400 }}
      />
    );

  return (
    <StyledLayout>
      <StyledHeader>
        {headerColumns.map(({ title, key }) => (
          <h4 key={key}>{title}</h4>
        ))}
      </StyledHeader>

      <TableWrapper>
        <StyledSection>
          <TableWrapper>
            <StyledTable
              tableLayout="fixed"
              key="summary1"
              rowKey="id"
              columns={getSummaryColumns({ renderTitle: true })}
              pagination={false}
              bordered={true}
              loading={loading}
              dataSource={getTableByConfig(summaryLeftColumn)}
            />
            <StyledTable
              tableLayout="fixed"
              key="summary2"
              rowKey="id"
              columns={getSummaryColumns({ renderTitle: false })}
              pagination={false}
              bordered={true}
              loading={loading}
              dataSource={getTableByConfig(summaryRightColumn)}
            />
          </TableWrapper>
        </StyledSection>

        <TableWrapper style={{ flexDirection: 'column' }}>
          <TableWrapper>
            <StyledSection>
              <StyledTable
                tableLayout="fixed"
                key="customer"
                rowKey="id"
                columns={customerColumns}
                pagination={false}
                bordered={true}
                loading={loading}
                dataSource={getTableByConfig(customerConfig)}
              />
            </StyledSection>
            <StyledSection>
              <StyledTable
                tableLayout="fixed"
                key="supplier"
                rowKey="id"
                columns={supplierColumns}
                pagination={false}
                bordered={true}
                loading={loading}
                dataSource={getTableByConfig(supplierConfig)}
              />
            </StyledSection>
          </TableWrapper>
          <StyledSection>
            <StyledTable
              key="finance"
              rowKey="id"
              tableLayout="fixed"
              columns={financeColumns}
              pagination={false}
              bordered={true}
              loading={loading}
              dataSource={[{ id: 'finance', value: 'finance' }]}
            />
            <StyledTitle>
              <h4>Transactions</h4>
            </StyledTitle>
            <StyledTable
              key="transactions"
              rowKey="id"
              tableLayout="fixed"
              columns={transactionsColumns}
              pagination={false}
              bordered={true}
              loading={loading}
              dataSource={dataSource?.transactions}
            />
          </StyledSection>
        </TableWrapper>
      </TableWrapper>
      <StyledSection>
        <Rooms
          rooms={dataSource?.rooms}
          onRoomClick={
            STATUSES_TO_EDIT.includes(bookingStatus as Status)
              ? openRoomModal
              : undefined
          }
        />
      </StyledSection>
      <SummaryModal
        visible={visibility === BModals.SUMMARY}
        initialValues={dataSource}
        onOk={handleUpdateSummary}
        onCancel={resetModalState}
      />
      <CustomerModal
        visible={visibility === BModals.CUSTOMER}
        initialValues={dataSource}
        onOk={handleUpdateCustomer}
        onCancel={resetModalState}
      />
      <SupplierModal
        visible={visibility === BModals.SUPPLIER}
        initialValues={dataSource}
        onOk={handleUpdateSupplier}
        onCancel={resetModalState}
      />
      <RoomModal
        visible={roomModalState.visibility}
        initialValues={roomModalState.modalData}
        roomIndex={roomModalState.roomIndex}
        onOk={handleUpdateRoom}
        onCancel={resetRoomModal}
      />
      <CancelModal
        visible={visibility === BModals.CANCEL}
        initialValues={dataSource}
        onOk={handleCancelBooking}
        onCancel={resetModalState}
      />
      <RebookModal
        visible={visibility === BModals.REBOOK}
        initialValues={dataSource}
        onOk={handleRebookBooking}
        onCancel={resetModalState}
      />
      <RebookModal
        visible={visibility === BModals.REBOOK}
        initialValues={dataSource}
        onOk={handleRebookBooking}
        onCancel={resetModalState}
      />
      <SendVoucherModal
        visible={visibility === BModals.SEND_VOUCHER}
        initialValues={dataSource}
        onOk={handleSentVoucher}
        onCancel={resetModalState}
      />
      <StyledFooter />
    </StyledLayout>
  );
});
