import jwtDecode from 'jwt-decode';
import { handleActions } from 'redux-actions';
import { BEARER_TOKEN_NAME } from 'src/constants';

export type User = {
  unique_name: string;
  // permission: [
  //   'UsersAndRolesTab.ReadOnly',
  //   'UsersAndRolesTab.ActionButtonManagement'
  // ];
};

export enum UserActions {
  SET_USER = 'SET_USER',
}

const getUser = () => {
  const token = localStorage.getItem(BEARER_TOKEN_NAME);

  return (token ? jwtDecode(token) : {}) as User;
};

export const userReducer = handleActions<{ currentUser: User }, User>(
  {
    [UserActions.SET_USER]: (_, { payload }) => ({ currentUser: payload }),
  },
  { currentUser: getUser() }
);
