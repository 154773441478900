import { useState } from 'react';
import _isNil from 'lodash/isNil';
import _isFunction from 'lodash/isFunction';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { IForm } from './PdfForm';

const toolbar = {
  options: ['inline', 'blockType', 'list', 'link'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
  link: {
    inDropdown: false,
    showOpenOptionOnHover: false,
    defaultTargetOption: '_blank',
    options: ['link'],
  },
};

const getEditorStateFromProps = (html: string) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(contentBlocks, entityMap)
  );
};

type TagStyle = {
  tag: string;
  style: string;
};

const tagStyles = [
  { tag: 'strong', style: 'font-weight: 900' },
  { tag: 'h1', style: 'line-height: 1.2' },
  { tag: 'h2', style: 'line-height: 1.2' },
  { tag: 'h3', style: 'line-height: 1.2' },
  { tag: 'h4', style: 'line-height: 1.2' },
  { tag: 'h5', style: 'line-height: 1.2' },
  { tag: 'h6', style: 'line-height: 1.2' },
  { tag: 'a', style: 'color: blue' },
] as TagStyle[];

const applyStyles = (html: string, tagStyles: TagStyle[]): string => {
  let htmlWithStyles = html;
  for (let i = 0; i < tagStyles.length; i++) {
    htmlWithStyles = htmlWithStyles.replaceAll(
      `<${tagStyles[i].tag}`,
      `<${tagStyles[i].tag} style="${tagStyles[i].style}"`
    );
  }
  return htmlWithStyles;
};

export const HtmlEditor = ({ setFieldValue, values }: IForm) => {
  const [editorState, setEditorState] = useState(() =>
    !_isNil(values.bookingRemarks) && _isFunction(getEditorStateFromProps)
      ? getEditorStateFromProps(values.bookingRemarks)
      : EditorState.createEmpty()
  );

  const onChange = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFieldValue('bookingRemarks', applyStyles(html, tagStyles));
  };

  return (
    <Editor
      editorStyle={{ minHeight: 200 }}
      onChange={onChange}
      toolbar={toolbar}
      editorState={editorState}
      onEditorStateChange={setEditorState}
    />
  );
};
