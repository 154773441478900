import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { parse, stringify } from 'query-string';
import en_GB from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import { Provider } from 'react-redux';
import { ConfigProvider, Layout } from 'antd';
import { Loader } from 'google-maps-js-api-react';
import { store } from 'src/store';
import { Header } from 'src/components/Header';
import { Bookings } from 'src/containers/Bookings';
import { BackofficeMain } from 'src/containers/BackofficeMain';
import 'moment/locale/en-gb';
import { BookingEngine } from '../BookingEngine';
import { Temporary } from '../Temporary';
import muiTheme from 'src/configs/muiTheme';
import CssBaseline from '@mui/material/CssBaseline';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import StyledEngineProvider from '@mui/styled-engine/StyledEngineProvider';
import { appConfig } from 'src/modules/app-config';
import { ManualBookingsList } from '../Bookings/ManualList';
import { ExportsList } from '../ExportsList';
import { SWRConfig, SWRConfiguration } from 'swr';
import AuthProvider from 'src/providers/AuthProvider';
import { ManualTransfers } from '../ManualTransfers';

moment.locale('en-gb');

Loader.options = {
  version: '3',
  libraries: ['places'],
  apiKey: appConfig('GOOGLE_API_KEY'),
  defer: true,
};

const AppContent: FC = () => (
  <Layout style={{ height: '100vh' }}>
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/bookings/*" element={<Bookings />} />
            <Route path="/manual-bookings/*" element={<ManualBookingsList />} />
            <Route path="/payments/*" element={<BackofficeMain />} />
            <Route path="/export/*" element={<ExportsList />} />
            <Route path="/temporary/*" element={<Temporary />} />
            <Route path="/booking-engine/*" element={<BookingEngine />} />
            <Route path="/manual-transfers/*" element={<ManualTransfers />} />
            <Route path="/*" element={<Navigate to="/bookings" replace />} />
          </Routes>
        </AuthProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </Layout>
);

const config: SWRConfiguration = {
  revalidateOnFocus: false,
  revalidateIfStale: false,
};

export const App: FC = () => (
  <Provider store={store}>
    <ConfigProvider locale={en_GB}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <SWRConfig value={config}>
            <AppContent />
          </SWRConfig>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ConfigProvider>
  </Provider>
);
