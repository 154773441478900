import { FC, useState } from 'react';
import { Button, notification } from 'antd';
import { apiCall } from 'src/modules/api';

const CompileButton: FC = () => {
  const [disabled, setDisabled] = useState(false);

  return (
    <Button
      type="primary"
      onClick={async () => {
        setDisabled(true);

        notification.info({ message: 'Compilation started' });

        try {
          await apiCall('/api/travelgate/white_lists/_compile', {
            method: 'POST',
          });

          notification.success({ message: 'Whitelists compiled successfully' });
        } catch (err: any) {
          const status = err.response.status;

          notification.error({
            message:
              status === 403
                ? 'Not enough permissions to perform this action'
                : status === 500
                ? 'Compilation failed'
                : status === 504
                ? 'Timeout error. Check if whitelists have compiled in google cloud'
                : 'Unknown error',
          });
        } finally {
          setDisabled(false);
        }
      }}
      disabled={disabled}
      loading={disabled}
    >
      Compile Whitelists
    </Button>
  );
};

export default CompileButton;
