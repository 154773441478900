import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, PropsWithChildren } from 'react';

export const RoomHeader: FC<
  PropsWithChildren<{ title: string; subTitle: string; text: string }>
> = ({ title, subTitle, text }) => (
  <div>
    <Typography variant="h2" color="secondary">
      {title}
    </Typography>
    <Typography variant="body3" color="text.secondary">
      {subTitle}
    </Typography>
    <Typography variant="h3" marginTop="10px">
      {text}
    </Typography>
  </div>
);

export const Room: FC<PropsWithChildren<{ title: string }>> = ({
  children,
  title,
}) => (
  <div>
    <RoomHeader
      title={title}
      subTitle="Please give us the name of one of the people staying in this room"
      text="Guest personal information"
    />
    <Grid container spacing={4}>
      {children}
    </Grid>
  </div>
);
