import addMonths from 'date-fns/addMonths';
import { useRef, useState, FC } from 'react';
import { useTheme } from 'styled-components/macro';
import max from 'date-fns/max';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import startOfMonth from 'date-fns/startOfMonth';
import isEqual from 'date-fns/isEqual';

import { IconButton } from 'src/components/common/WrappedButtons';
import { useAdaptiveEffect } from 'src/hooks/useAdaptiveEffect';

import { Month } from '../Month';
import { CalendarProps } from '..';

import {
  StyledArrowsContainer,
  StyledContainer,
  StyledMonthWrapper,
  StyledWrapper,
} from './styled';

export const HorizontalCalendar: FC<CalendarProps> = ({ value, onChange }) => {
  const start = value[0];
  const [currMonth, setCurrMonth] = useState(() =>
    startOfMonth(start ? max([start, new Date()]) : new Date())
  );

  const months = Array.from({ length: 4 }, (_, index) =>
    addMonths(currMonth, index - 1)
  );

  const ref = useRef<HTMLDivElement>(null);

  useAdaptiveEffect(() => {
    const el = ref.current;

    if (el) {
      const child = el.children[0];

      el.style.width = `${child.clientWidth / 2}px`;
      el.style.height = `${child.clientHeight}px`;
    }
  }, []);

  const isRunRef = useRef(false);

  const { transitions } = useTheme();

  const handleMonthSwitch = (isPrev: boolean) => {
    const [addCount, left] = isPrev ? [-1, '0%'] : [1, '-100%'];

    const transition = transitions.create('left', { duration: 10 });

    return () => {
      if (!isRunRef.current) {
        const el = ref.current?.children[0] as HTMLElement | undefined;

        if (el) {
          isRunRef.current = true;

          el.style.transition = transition;

          el.style.left = left;

          const listener = () => {
            el.removeAttribute('style');

            setCurrMonth((prev) => addMonths(prev, addCount));

            el.removeEventListener('transitionend', listener);

            isRunRef.current = false;
          };

          el.addEventListener('transitionend', listener);
        }
      }
    };
  };

  const isPrevMonthDisabled = isEqual(currMonth, startOfMonth(new Date()));

  return (
    <StyledWrapper>
      <StyledArrowsContainer>
        <IconButton
          onClick={handleMonthSwitch(true)}
          size="small"
          disabled={isPrevMonthDisabled}
        >
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <IconButton onClick={handleMonthSwitch(false)} size="small">
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
      </StyledArrowsContainer>
      <StyledMonthWrapper ref={ref}>
        <StyledContainer>
          {months.map((month) => (
            <Month
              key={+month}
              date={month}
              value={value}
              onChange={onChange}
              sameHeight
            />
          ))}
        </StyledContainer>
      </StyledMonthWrapper>
    </StyledWrapper>
  );
};
