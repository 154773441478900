import { FC } from 'react';
import styled from 'styled-components/macro';
import { useFieldArray } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'src/components/common/WrappedButtons';
import joinBy from 'src/modules/joinBy';
import { ListItem } from '../../../ListItem';
import { Adults } from './Adults';
import { Children } from './Children';
import { Fields, FieldsName } from '../../../SearchPanelForm';

export type FormSectionProps = { index: number; width?: string };

export const MAX_GUESTS = 6;
export const MAX_CHILDREN = 4;
export const MAX_ROOMS = 8;

const StyledButton = styled(Button)`
  position: absolute;
  transform: translate(${({ theme }) => theme.spacing(2)}, -50%);
  border-radius: 50%;
  &&& {
    border-width: 1px;
  }
  line-height: 1;
  font-size: 14px;
  padding: 2px;
  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const labels = {
  adults: {
    id: 'Adults',
  },
  children: {
    id: 'Children',
  },
  childrenAge: {
    id: 'Child Age ',
    values: { number: MAX_CHILDREN },
  },
};

export const RoomChooserForm: FC = () => {
  const { fields, append, remove } = useFieldArray<Fields, FieldsName.ROOMS>({
    name: FieldsName.ROOMS,
  });

  return (
    <Stack spacing={2}>
      {joinBy(
        fields.map((item, index) => (
          <ListItem
            key={item.id}
            title={
              <>
                {'room ' + (index + 1).toString()}
                {fields.length > 1 && (
                  <StyledButton
                    variant="outlined"
                    color="buttonSecondary"
                    onClick={() => remove(index)}
                  >
                    <CloseIcon fontSize="inherit" />
                  </StyledButton>
                )}
              </>
            }
          >
            <Adults index={index} width="100px" labelId={labels.adults.id} />
            <Children
              index={index}
              width="100px"
              labelId={labels.children.id}
              ageLabelId={labels.childrenAge.id}
            />
          </ListItem>
        )),
        <Divider />
      )}
      <Divider />
      <Button
        variant="outlined"
        color="buttonSecondary"
        disableRipple
        onClick={() => {
          const { scrollX, scrollY } = window;

          append({ adults: 2, children: [] });

          /**
           * next line fixing https://clicktrip.atlassian.net/browse/DEV-1095?atlOrigin=eyJpIjoiMjBkYzUwOTcyNmVkNDYxZTk2MzBkMGYxMWU5MDg5MTIiLCJwIjoiaiJ9
           * TODO: Check if this issue still exist after search and hotel details page redesign
           */
          window.requestAnimationFrame(() => window.scroll(scrollX, scrollY));
        }}
        disabled={fields.length >= MAX_ROOMS}
        endIcon={<ArrowForwardIcon />}
      >
        ADD ROOM
      </Button>
    </Stack>
  );
};
