import { URL_QUERY_PARAMS } from 'src/containers/BookingEngine/Main/SearchPanel/SearchForm';
import { useQueryParams } from 'use-query-params';
import { useConfig } from './swr/useConfig';

export const useCurrencyName = () => (currency: string) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency,
    currencyDisplay: 'name',
    minimumFractionDigits: 0,
  })
    .format(0)
    .replace('0 ', '');

export const useFormatPrice = () => {
  const { data } = useConfig();
  const [queryParams] = useQueryParams(URL_QUERY_PARAMS);

  const currency = queryParams?.currency || 'EUR';
  const currentRate = Number(data?.currencies[currency]);

  const { format } = new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
  });

  const { format: formatEuro } = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
  });

  return (price: number, isEuro?: boolean) =>
    isEuro ? formatEuro(price) : format(price * currentRate);
};
