import { Alert, Modal, notification, Spin } from 'antd';
import { useFormik, FormikProvider } from 'formik';
import _get from 'lodash/get';
import * as yup from 'yup';
import { Form } from './Form';
import styled from 'styled-components';
import { IBooking } from 'src/containers/Bookings/Booking';
import moment, { Moment } from 'moment';
import { ICancellationPolicy } from 'src/containers/Bookings/BookingsList/compounds/CancelModal';
import { AxiosError } from 'axios';
import { yupEmail } from 'src/modules/validation';
import { FC } from 'react';

const StyledModal = styled(Modal)`
  top: 50px;
  p {
    margin: 0px;
  }
  h5 {
    display: block;
    margin-left: 25px;
  }
  h4 {
    margin-top: 10px;
  }
`;

export interface ISummaryForm {
  hotelName: string;
  hotelCountry: string;
  hotelCity: string;
  hotelAddress: string;
  hotelPhone: string | null;
  hotelEmail: string | null;
  checkInDate: Moment;
  checkOutDate: Moment;
  cancellationPolicy: ICancellationPolicy;
}

const dataAdapter = ({
  hotelName,
  hotelCountry,
  hotelCity,
  hotelAddress,
  hotelPhone,
  hotelEmail,
  checkInDate,
  checkOutDate,
  cancellationPolicy,
}: IBooking): ISummaryForm => ({
  hotelName,
  hotelCountry,
  hotelCity,
  hotelAddress,
  hotelPhone,
  hotelEmail,
  checkInDate: moment.utc(checkInDate ?? ''),
  checkOutDate: moment.utc(checkOutDate ?? ''),
  cancellationPolicy: {
    ...cancellationPolicy,
    dateFrom: moment.utc(cancellationPolicy?.dateFrom ?? ''),
  },
});

const validationSchema = yup.object().shape({
  hotelName: yup
    .string()
    .nullable()
    .required('Hotel Name is required field')
    .max(255, 'Hotel Name must be below 255 symbols'),
  hotelCountry: yup
    .string()
    .nullable()
    .required('Country is required field')
    .max(255, 'Country must be below 255 symbols'),
  hotelCity: yup
    .string()
    .nullable()
    .required('City is required field')
    .max(255, 'City must be below 255 symbols'),
  hotelAddress: yup
    .string()
    .nullable()
    .required('Address is required field')
    .max(255, 'Address must be below 255 symbols'),
  hotelPhone: yup.string().nullable().notRequired(),
  hotelEmail: yupEmail
    .max(255, 'Value must be below 255 symbols')
    .notRequired(),
  checkInDate: yup.string().nullable().required('Date is required field'),
  checkOutDate: yup.string().nullable().required('Date is required field'),
  cancellationPolicy: yup.object().shape({
    sellingPrice: yup.number().nullable().notRequired(),
    price: yup.number().nullable().notRequired(),
    fee: yup.number().nullable().notRequired(),
    refundability: yup.string().required('Required'),
    dateFrom: yup.string().nullable().required('Required'),
    dateTo: yup.string().nullable().notRequired(),
    bookingRemarks: yup.string().nullable().notRequired(),
  }),
});

interface ISummaryModal {
  visible: boolean;
  initialValues: IBooking;
  onOk: (values: ISummaryForm) => Promise<void>;
  onCancel: () => void;
}

const checkString = (str: string | null) => (str ? str : null);

export const SummaryModal: FC<ISummaryModal> = ({
  visible,
  initialValues,
  onOk,
  onCancel,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: dataAdapter(initialValues),
    onSubmit: (values, { setSubmitting, setFieldError, setStatus }) => {
      setSubmitting(true);
      onOk({
        ...values,
        hotelPhone: checkString(values?.hotelPhone),
        hotelEmail: checkString(values?.hotelEmail),
      }).then(
        () => setSubmitting(false),
        (error: AxiosError<any>) => {
          const { errors } = _get(error, 'response.data');
          if (_get(error, 'response.status') === 400 && errors) {
            for (const key in errors) {
              const msg = errors[key][0];
              if (msg) {
                errors[key].map((message: string) =>
                  notification.error({
                    message: 'Error',
                    description: key.split('.')[0] + ': ' + message,
                  })
                );
                setFieldError(key.split('.')[0], msg);
                setStatus({
                  fieldApiErrors: { error: msg },
                });
              }
            }
          }
          setSubmitting(false);
        }
      );
    },
  });

  const { status, isSubmitting, handleSubmit, ...rest } = formik;

  return (
    <FormikProvider value={formik}>
      <StyledModal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title="Edit Summary"
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Confirm"
        cancelText="Dismiss"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <Form {...rest} />
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </StyledModal>
    </FormikProvider>
  );
};
