export type FiltersMap = keyof typeof filtersMap;

export const filtersMap = {
  bookedBy: {
    label: 'Booked By',
    id: 'bookedBy',
  },
  paymentDateRange: {
    label: 'Payment Date',
    id: 'paymentDateRange',
  },
  bookingId: {
    label: 'New Booking ID',
    id: 'bookingId',
  },
  newBookingId: {
    label: 'New Booking ID',
    id: 'newBookingId',
  },
  NewBookingId: {
    label: 'New Booking ID',
    id: 'NewBookingId',
  },
  extTransactionId: {
    label: 'Transaction External ID',
    id: 'extTransactionId',
  },
  paymentId: {
    label: 'Payment ID',
    id: 'paymentId',
  },
  paymentType: {
    label: 'Payment Type',
    id: 'paymentType',
  },
  paymentMethod: {
    label: 'Payment Method',
    id: 'paymentMethod',
  },
  paymentCurrency: {
    label: 'Payment Currency',
    id: 'paymentCurrency',
  },
  lastModifiedDateRange: {
    label: 'Transaction Date',
    id: 'lastModifiedDateRange',
  },
  transactionStatus: {
    label: 'Transaction Status',
    id: 'transactionStatus',
  },
  paymentProcessor: {
    label: 'Processor',
    id: 'paymentProcessor',
  },
  bookingStatus: {
    label: 'Booking Status',
    id: 'bookingStatus',
  },
  cardLastFour: {
    label: 'Card Number (last 4 digits)',
    id: 'cardLastFour',
  },
  email: {
    label: 'Customer Email',
    id: 'email',
  },
  supplier: {
    label: 'Supplier',
    id: 'supplier',
  },
  hotelCity: {
    label: 'Hotel City',
    id: 'shotelCity',
  },
  hotelCountryIn: {
    label: 'Hotel Country',
    id: 'hotelCountryIn',
  },
  hotelsCountryCodes: {
    label: 'Hotel Country',
    id: 'hotelsCountryCodes',
  },
  paymentStatus: {
    label: 'Payment Status',
    id: 'paymentStatus',
  },
  refundReason: {
    label: 'Refund Reason',
    id: 'refundReason',
  },
  // bookingsList
  bookingDateRange: {
    label: 'Booking Date',
    id: 'BookingDate',
  },
  customerEmail: {
    label: 'Customer Email',
    id: 'CustomerEmail',
  },
  checkInDate: {
    label: 'Check-In Date',
    id: 'CheckInDate',
  },
  checkOutDate: {
    label: 'Check-Out Date',
    id: 'CheckOutDate',
  },
  providerType: {
    label: 'Provider',
    id: 'ProviderType',
  },
  providersTypes: {
    label: 'Provider',
    id: 'providersTypes',
  },
  supplierIn: {
    label: 'Supplier',
    id: 'SupplierIn',
  },
  SupplierIn: {
    label: 'Supplier',
    id: 'supplierIn',
  },
  suppliersNames: {
    label: 'Supplier',
    id: 'suppliersNames',
  },
  HotelCountryIn: {
    id: 'HotelCountryIn',
    label: 'Hotel Country',
  },
  HotelCity: {
    id: 'HotelCity',
    label: 'Hotel City',
  },
  HotelName: {
    id: 'HotelName',
    label: 'Hotel Name',
  },
  countryIn: {
    id: 'countryIn',
    label: 'Hotel Country',
  },
  BookingStatus: {
    id: 'BookingStatus',
    label: 'Booking Status',
  },
  status: {
    id: 'status',
    label: 'Booking Status',
  },
  checkStatuses: {
    id: 'checkStatuses',
    label: 'Check Status',
  },
  CheckStatus: {
    id: 'CheckStatus',
    label: 'Check Status',
  },
  checkStatus: {
    id: 'checkStatus',
    label: 'Check Status',
  },
  customerName: {
    id: 'CustomerName',
    label: 'Customer Name',
  },
  customerId: {
    id: 'CustomerId',
    label: 'Customer ID',
  },
  customerPhone: {
    id: 'CustomerPhone',
    label: 'Customer Phone',
  },
  Source: {
    id: 'Source',
    label: 'Source',
  },
  // todo same camelcase after BE will be changed
  CustomerName: {
    id: 'CustomerName',
    label: 'Customer Name',
  },
  source: {
    id: 'Source',
    label: 'Source',
  },
  creationDate: {
    id: 'CreationDate',
    label: 'Creation Date',
  },
  hotelName: {
    id: 'hotelName',
    label: 'Hotel Name',
  },
  ExternalId: {
    id: 'ExternalId',
    label: 'External Transaction ID',
  },
  externalId: {
    id: 'externalId',
    label: 'External Transaction ID',
  },
  OnlyVipUser: {
    id: 'OnlyVipUser',
    label: 'VIP Users Bookings',
  },
  OnlyWithSpecialRequest: {
    id: 'OnlyWithSpecialRequest',
    label: 'Bookings With Special Request',
  },
  //exports tab
  onlyVipUser: {
    id: 'onlyVipUser',
    label: 'VIP Users Bookings',
  },
  onlyWithSpecialRequest: {
    id: 'onlyWithSpecialRequest',
    label: 'Bookings With Special Request',
  },
  hasSpecialRequest: {
    id: 'hasSpecialRequest',
    label: 'Bookings With Special Request',
  },
  noSalePrice: {
    id: 'noSalePrice',
    label: 'Bookings Without Sale Price',
  },
  //manual booking
  bookingDate: {
    label: 'Booking Date',
    id: 'BookingDate',
  },
  bookingNumber: {
    label: 'Booking Id',
    id: 'BookingNumber',
  },
  SupplierBookingReference: {
    label: 'Supplier Booking Reference',
    id: 'SupplierBookingReference',
  },
  countries: {
    label: 'Hotel Country',
    id: 'HotelsCountryCodes',
  },
  customerLastName: {
    label: 'Customer Last Name',
    id: 'CustomerLastName',
  },
  onlyNoSalePrice: {
    label: 'No Sale Price',
    id: 'NoSalePrice',
  },
  paymentReference: {
    label: 'Payment Reference',
    id: 'PaymentReference',
  },
  paymentReferenceCheckbox: {
    label: 'no Payment Reference',
    id: 'PaymentReferenceCheckbox',
  },
  bookingReference: {
    label: 'Booking Reference',
    id: 'BookingReference',
  },
  bookingReferenceCheckbox: {
    label: 'no Booking Reference',
    id: 'BookingReferenceCheckbox',
  },
  createDateFrom: {
    label: 'Booking Date From',
    id: 'createDateFrom',
  },
  createDateTo: {
    label: 'Booking Date To',
    id: 'createDateTo',
  },
  statuses: {
    label: 'Booking Status',
    id: 'statuses',
  },
  paymenttype: {
    label: 'Payment Type',
    id: 'paymenttype',
  },
  supplierBookingReference: {
    label: 'Booking Reference',
    id: 'supplierBookingReference',
  },
  oldBookingId: {
    label: 'Booking Id',
    id: 'oldBookingId',
  },
  bookingOldId: {
    label: 'Booking Id',
    id: 'bookingOldId',
  },
  supplierId: {
    label: 'Supplier Id',
    id: 'supplierId',
  },
  city: {
    label: 'City',
    id: 'city',
  },
  guestName: {
    label: 'Guest Name',
    id: 'guestName',
  },
  checkinDate: {
    label: 'Check-In Date',
    id: 'checkinDate',
  },
  checkoutDate: {
    label: 'Check-Out Date',
    id: 'checkoutDate',
  },
  blockchain: {
    label: 'Blockchain',
    id: 'blockchain',
  },
  // bookedBy: {
  //   label: 'Booked By',
  //   id: 'bookedBy',
  // },
};
