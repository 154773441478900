import { Alert, Checkbox, Input, Modal, Select, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { apiCall } from 'src/modules/api';
import {
  PAYMENT_STATUSES,
  REFUND_REASONS,
  REFUND_TYPES,
  PARTIAL_REFUND_TYPES,
} from 'src/constants';
import * as yup from 'yup';
import _round from 'lodash/round';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import { AxiosError } from 'axios';
import { ChangeEvent, FC, useEffect, useState } from 'react';

const validationSchema = yup.object().shape({
  refundType: yup.string().required('Required'),
  refundAmount: yup
    .number()
    .required('Required')
    .typeError('Should be a number')
    .positive('Should be higher than 0')
    .when('refundType', {
      is: 'partialRefund',
      then: yup.number().when(['amount', 'refundableAmount'], {
        is: (amount: any, refundableAmount: any) => amount === refundableAmount,
        then: yup
          .number()
          .typeError('Should be a number')
          .lessThan(
            yup.ref('amount'),
            ({ less }: any) => `Should be less than ${less}`
          ),
        otherwise: yup
          .number()
          .typeError('Should be a number')
          .max(
            yup.ref('refundableAmount'),
            ({ max }: any) => `Should be not higher than ${max}`
          ),
      }),
    }),
  refundReason: yup.string().required('Required'),
  comment: yup
    .string()
    .when('refundReason', {
      is: (value: string) => ['custom', 'supplierError'].includes(value),
      then: (s: any) => s.required('Required'),
    })
    .max(255, 'Max length 255 symbols'),
});

interface IRefundModal {
  visible: boolean;
  initialValues?: any;
  onOk: (
    values: yup.InferType<typeof validationSchema>
  ) => Promise<void | AxiosError<any>>;
  onCancel: () => void;
}

const RefundForm = ({
  refundableAmount,
  transactionFee,
  values,
  setFieldValue,
}: any) => (
  <StyledForm layout="horizontal">
    <FormikField
      key="paymentId"
      label="Payment ID"
      name="paymentId"
      FormComponent={Input}
      disabled={true}
    />
    <FormikField
      key="amount"
      label="Payment Amount"
      name="amount"
      FormComponent={Input}
      disabled={true}
    />
    <FormikField
      key="currency"
      label="Payment Currency"
      name="currency"
      FormComponent={Input}
      disabled={true}
    />
    <FormikField
      key="refundType"
      label="Refund Type"
      name="refundType"
      FormComponent={Select}
      onChange={(value: any) => {
        setFieldValue('refundType', value);
        if (value === 'fullRefund') {
          setFieldValue(
            'refundAmount',
            _round(refundableAmount - transactionFee, 2)
          );
          const refundCardFeeValue = transactionFee === 0;
          setFieldValue('refundCardFee', refundCardFeeValue);
        } else {
          setFieldValue('refundAmount', 0);
        }
      }}
      options={
        values.status === PAYMENT_STATUSES.SUCCESSFUL
          ? REFUND_TYPES
          : PARTIAL_REFUND_TYPES
      }
    />
    {values.refundType === 'fullRefund' && (
      <FormikField
        key="refundCardFee"
        label={`Refund Transaction Fee (${transactionFee})`}
        children={`Refund Transaction Fee (${transactionFee})`}
        name="refundCardFee"
        FormComponent={Checkbox}
        disabled={transactionFee === 0}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.checked;
          setFieldValue('refundCardFee', value);
          if (value) {
            setFieldValue('refundAmount', refundableAmount);
          } else {
            setFieldValue(
              'refundAmount',
              _round(refundableAmount - transactionFee, 2)
            );
          }
        }}
      />
    )}
    <FormikField
      key="refundAmount"
      label="Refund Amount"
      extra={
        values.refundType === 'partialRefund'
          ? `Remaining: ${_round(refundableAmount - values.refundAmount, 2)}`
          : null
      }
      name="refundAmount"
      FormComponent={Input}
      disabled={values.refundType === 'fullRefund'}
    />
    <FormikField
      key="refundReason"
      label="Refund Reason"
      name="refundReason"
      FormComponent={Select}
      placeholder="Select"
      options={REFUND_REASONS}
    />
    <FormikField
      key="comment"
      label="Comment"
      name="comment"
      FormComponent={Input.TextArea}
    />
  </StyledForm>
);

export const RefundModal: FC<IRefundModal> = ({
  visible,
  initialValues,
  onOk,
  onCancel,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      ...initialValues,
      refundType:
        initialValues.status === PAYMENT_STATUSES.SUCCESSFUL
          ? 'fullRefund'
          : 'partialRefund',
      refundAmount:
        initialValues.status === PAYMENT_STATUSES.SUCCESSFUL
          ? _round(
              initialValues.refundableAmount?.value -
                initialValues.refundableAmount?.transactionFee,
              2
            )
          : 0,
      refundCardFee: initialValues.transactionFee === 0,
      refundableAmount: initialValues.refundableAmount?.value || 0,
      refundReason: undefined,
      comment: '',
    },
    onSubmit: (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (response: any) => {
          setSubmitting(false);
          if (response.errors) {
            setStatus({
              commonApiErrors: response.errors.filter(
                ({ field }: any) => !field
              ),
              fieldApiErrors: _keyBy(
                response.errors.filter(({ field }: any) => field),
                'field'
              ),
            });
          }
        }
      );
    },
  });

  const { setFieldValue, values, handleSubmit, status, isSubmitting } = formik;

  const [refundableAmount, setRefundableAmount] = useState({
    value: 0,
    transactionFee: 0,
    fetched: false,
  });

  useEffect(() => {
    if (visible && values.paymentId && !refundableAmount.fetched) {
      apiCall<any>(`api/payments/${values.paymentId}/refundable-amount`).then(
        (response) => {
          setRefundableAmount({
            value: response.amount,
            transactionFee: response.cardFee,
            fetched: true,
          });
          setFieldValue('refundCardFee', response.cardFee === 0);
          setFieldValue('refundableAmount', response.amount);
          setFieldValue(
            'refundAmount',
            values.status === PAYMENT_STATUSES.SUCCESSFUL
              ? _round(response.amount - response.cardFee, 2)
              : 0
          );
        }
      );
    } else if (refundableAmount.fetched && !visible) {
      setRefundableAmount({
        value: 0,
        transactionFee: 0,
        fetched: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, values, refundableAmount]);

  return (
    <FormikProvider value={formik}>
      <Modal
        destroyOnClose={true}
        maskClosable={false}
        title="Refund Payment"
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Refund"
        cancelText="Cancel"
      >
        {!refundableAmount.fetched ? (
          <Spin delay={100} />
        ) : (
          <>
            <RefundForm
              {...{ setFieldValue, values }}
              refundableAmount={refundableAmount?.value}
              transactionFee={refundableAmount.transactionFee}
            />
            {_get(status, 'commonApiErrors.length', null)
              ? status.commonApiErrors
                  .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
                  .map(({ msg }: any) => (
                    <Alert key={msg} message={msg} type="error" />
                  ))
              : null}
          </>
        )}
      </Modal>
    </FormikProvider>
  );
};
