import styled from 'styled-components';
import { Table } from 'antd';

export const { Column } = Table;

export const StyledTable = styled(Table)`
  margin: 20px;

  & .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 6px;
  }

  & tr.ant-table-expanded-row,
  & tr.ant-table-expanded-row:hover {
    & > td {
      padding: 18px;
    }

    .ant-tabs {
      background-color: #fff;

      .ant-tabs-tabpane {
        padding: 10px;
      }
    }
  }
`;
