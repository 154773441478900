import { Tooltip } from 'antd';
import { MouseEventHandler } from 'react';
import {
  ConfirmButton,
  StyledEditIcon,
} from 'src/containers/Bookings/BookingsList/compounds/TextArea';
import styled from 'styled-components';
import _isFunction from 'lodash/isFunction';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = ({
  title,
  onClick,
}: {
  title: string;
  onClick?: MouseEventHandler<HTMLElement>;
}) => (
  <Wrapper>
    <span>{title}</span>
    {_isFunction(onClick) && (
      <Tooltip
        title="Edit"
        placement="top"
        children={
          <ConfirmButton
            icon={<StyledEditIcon />}
            type="link"
            size="small"
            onClick={onClick}
            title={title}
          />
        }
      />
    )}
  </Wrapper>
);
