import * as yup from 'yup';

export const yupPhone = yup
  .string()
  .nullable()
  .max(25, 'Must be below 25 symbols')
  .matches(
    // eslint-disable-next-line no-useless-escape
    /^([\(\)\+0-9\s\-]+)$/,
    'Your phone number can include +,(),- and spaces between digits'
  );

export const yupEmail = yup
  .string()
  .nullable()
  .email()
  .max(200, 'Value must be below 200 symbols');
