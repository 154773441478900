import { Input } from 'antd';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';

export const Form = () => (
  <StyledForm layout="horizontal">
    <FormikField
      key="comment"
      label="Comment"
      name="comment"
      FormComponent={Input.TextArea}
    />
  </StyledForm>
);
