import Typography from '@mui/material/Typography';
import differenceInDays from 'date-fns/differenceInDays';
import { FC } from 'react';

import { DateRange } from 'src/components/Calendar';

type Props = { dates: DateRange };

export const NightsCount: FC<Props> = ({ dates: [start, end] }) => (
  <Typography
    variant="h3"
    color="secondary"
    display="flex"
    justifyContent="center"
  >
    {start && end
      ? 'STAY DURATION: ' + differenceInDays(end, start) + ' (nights stay)'
      : 'SELECT DATES'}
  </Typography>
);
