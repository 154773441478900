import Typography from '@mui/material/Typography';
import addDays from 'date-fns/addDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfMonth from 'date-fns/endOfMonth';
import getDay from 'date-fns/getDay';
import startOfMonth from 'date-fns/startOfMonth';
import { CSSProperties, VFC } from 'react';

import { CalendarProps } from '..';
import { useCalendarLocale } from '../CalendarProvider';
import { Day } from '../Day';
import { handleDayDisabled, handleDayStatus } from '../utils';

import {
  StyledMonthContainer,
  StyledHeader,
  StyledWeekdaysContainer,
  StyledDaysContainer,
} from './styled';

export type MonthProps = CalendarProps & {
  date: Date;
  style?: CSSProperties;
  sameHeight?: boolean;
};

export const Month: VFC<MonthProps> = ({
  date,
  value,
  onChange,
  style,
  sameHeight,
}) => {
  const [start, end] = value;
  const locale = useCalendarLocale();

  const firstDay = startOfMonth(date);
  const lastDay = endOfMonth(date);

  const week = Array.from({ length: 7 }, (_, index) => index);

  const weekStartsOn = locale.options?.weekStartsOn;

  if (weekStartsOn) {
    week.push(...week.splice(0, weekStartsOn));
  }

  const getDayState = handleDayStatus(start, end, firstDay, lastDay);

  const shouldDisableDate = handleDayDisabled(firstDay, lastDay);

  const header = `${locale.localize?.month(date.getMonth(), {
    width: 'wide',
  })} ${date.getFullYear()}`;

  return (
    <StyledMonthContainer style={style}>
      <StyledHeader variant="h4">{header}</StyledHeader>
      <div>
        <StyledWeekdaysContainer>
          {week.map((day) => (
            <Typography variant="overline" key={day} color="text.secondary">
              {locale.localize?.day(day, { width: 'short' })}
            </Typography>
          ))}
        </StyledWeekdaysContainer>
        <StyledDaysContainer sameHeight={sameHeight}>
          <Day
            date={firstDay}
            shouldDisableDate={shouldDisableDate}
            getDayStatus={getDayState}
            style={{ gridColumnStart: week.indexOf(getDay(firstDay)) + 1 }}
            onSelect={onChange}
          />
          {eachDayOfInterval({
            start: addDays(firstDay, 1),
            end: lastDay,
          }).map((day, index) => (
            <Day
              onSelect={onChange}
              key={index}
              date={day}
              getDayStatus={getDayState}
              shouldDisableDate={shouldDisableDate}
            />
          ))}
        </StyledDaysContainer>
      </div>
    </StyledMonthContainer>
  );
};
