import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, notification, Spin, Table, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import _get from 'lodash/get';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { StyledTable } from 'src/components/StyledTable';
import { BackofficeAPI } from 'src/modules/api';
import { currencyFormatter } from 'src/modules/helpers';
import { AddBookingModal } from './AddBookingModal';
import { SimpleModal } from './PaymentModal';

enum Modals {
  NONE = 'none',
  ADD_BOOKING = 'add-booking',
  REMOVE_BOOKING = 'remove-booking',
}

const transactionsColumns: ColumnProps<any>[] = [
  {
    title: 'Internal ID',
    dataIndex: 'id',
    key: 'id',
    width: 150,
  },
  {
    title: 'External ID',
    dataIndex: 'externalId',
    key: 'externalId',
    width: 100,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: currencyFormatter,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Processor',
    dataIndex: 'paymentProcessor',
    key: 'paymentProcessor',
  },
  {
    title: 'Method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
  },
  {
    title: 'Last Modified Date',
    dataIndex: 'lastModifiedDate',
    key: 'lastModifiedDate',
    render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'Creation Date',
    dataIndex: 'transactionDate',
    key: 'transactionDate',
    render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'Status',
    dataIndex: 'transactionStatus',
    key: 'transactionStatus',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Processed by',
    dataIndex: 'processedBy',
    key: 'processedBy',
  },
];

const creditCardsColumns: ColumnProps<any>[] = [
  {
    title: 'Internal ID',
    dataIndex: 'id',
    key: 'id',
    width: 310,
  },
  {
    title: 'External ID',
    dataIndex: 'externalId',
    key: 'externalId',
    width: 100,
  },
  {
    title: 'Card Holder Name',
    dataIndex: 'cardHolderName',
    key: 'cardHolderName',
  },
  {
    title: 'Card Number (last 4 digits)',
    dataIndex: 'cardNumber',
    key: 'cardNumber',
  },
  {
    title: 'Card Country',
    dataIndex: 'cardCountry',
    key: 'cardCountry',
    width: 100,
  },
];

const bookingsColumns: ColumnProps<any>[] = [
  {
    title: 'Booking Date',
    dataIndex: 'createdDateTime',
    key: 'createdDateTime',
    width: 150,
    render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'New Booking ID',
    dataIndex: 'bookingNumber',
    key: 'bookingNumber',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
    render: (value) => value,
  },
  {
    title: 'Refundability',
    dataIndex: 'isRefundable',
    key: 'isRefundable',
    render: (value) => (value ? 'Refundable' : 'Non Refundable'),
  },
  {
    title: 'Booking Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
  },
  {
    title: 'Customer Email',
    dataIndex: 'customerEmail',
    key: 'customerEmail',
  },
  {
    title: 'Transaction Fee',
    key: 'transactionFee',
    render: ({ transactionFee, currency }) =>
      `${currency ?? ''} ${currencyFormatter(transactionFee)}`,
  },
];

export const ExpandedPaymentItem = ({ item }: any) => {
  const [itemState, setItemState] = useState<any>({ fetched: false, data: {} });

  const defaultModalState = { visibility: Modals.NONE, modalData: { id: '' } };
  const [{ visibility, modalData }, setModalState] =
    useState(defaultModalState);
  const resetModalState = () => setModalState(defaultModalState);

  const fetchPayment = () => {
    setItemState({ fetched: false, data: {} });
    return BackofficeAPI.get(`/payments/${item.paymentId}/details`).then(
      (response: AxiosResponse<any>) =>
        setItemState({
          data: {
            transactions: response.data.transactions,
            bookings: response.data.bookings.map((i: any) => ({
              ...i,
              currency: item.currency,
            })),
          },
          fetched: true,
        })
    );
  };

  const handleAddBooking = ({ oldBookingId }: any) =>
    BackofficeAPI.put(
      `/payments/${item.paymentId}/booking/${oldBookingId}`
    ).then(
      () => fetchPayment().then(() => resetModalState()),
      (error) => {
        throw _get(error, 'response.data');
      }
    );

  const handleRemoveBooking = () => {
    return BackofficeAPI.delete(
      `/payments/${item.paymentId}/booking/${modalData}`
    ).then(() => fetchPayment());
  };

  const removeBookingClick = ({ id }: { id: string }) => {
    if (itemState.data.bookings.length > 1) {
      setModalState({ visibility: Modals.REMOVE_BOOKING, modalData: { id } });
    } else {
      notification.error({
        message: 'Error',
        description:
          'You can not delete booking. Payment must have at least one booking.',
      });
    }
  };

  const addBookingClick = () => {
    setModalState({ ...defaultModalState, visibility: Modals.ADD_BOOKING });
  };

  useEffect(
    () => {
      if (item.paymentId) fetchPayment();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  if (!itemState.fetched) {
    return (
      <Spin size="large" style={{ display: 'block', textAlign: 'center' }} />
    );
  }

  const tabs = [
    {
      key: 'transactions',
      label: 'Transactions',
      Component: () => (
        <StyledTable
          rowKey="id"
          columns={transactionsColumns}
          dataSource={itemState.data.transactions}
          pagination={false}
          loading={!itemState.fetched}
          bordered={true}
        />
      ),
    },
    {
      key: 'ccInfo',
      label: 'Credit card info',
      Component: () => (
        <StyledTable
          rowKey="id"
          columns={creditCardsColumns}
          dataSource={itemState.data.transactions}
          pagination={false}
          loading={!itemState.fetched}
          bordered={true}
        />
      ),
    },
    {
      key: 'bookingInfo',
      label: 'Booking info',
      Component: () => (
        <StyledTable
          rowKey="id"
          dataSource={itemState.data.bookings}
          pagination={false}
          loading={!itemState.fetched}
          bordered={true}
          title={() => (
            <>
              <Button
                size="small"
                onClick={addBookingClick}
                icon={<PlusOutlined />}
              >
                Add booking
              </Button>
              <AddBookingModal
                visible={visibility === Modals.ADD_BOOKING}
                onOk={handleAddBooking}
                onCancel={resetModalState}
              />
            </>
          )}
        >
          {bookingsColumns.map((column: any) => (
            <Table.Column key={column.key} {...column} />
          ))}
          <Table.Column
            key="removeAction"
            dataIndex="id"
            title=""
            width={40}
            align="center"
            render={(value, record: any) => (
              <Button
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => removeBookingClick(record)}
              />
            )}
          />
        </StyledTable>
      ),
    },
  ];

  return (
    <>
      <Tabs
        defaultActiveKey="transactions"
        type="card"
        items={tabs?.map((t) => ({ ...t, children: t.Component() }))}
      />

      <SimpleModal
        visible={visibility === Modals.REMOVE_BOOKING}
        title="Remove booking"
        initialValues={item}
        onOk={handleRemoveBooking}
        onCancel={resetModalState}
        children={`You are attempting to detach booking ${item.bookingNumber} ${item.oldBookingId} from payment. Continue?`}
      />
    </>
  );
};
