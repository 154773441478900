import { createAsyncAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import _merge from 'lodash/merge';
import { HotelsAPI } from 'src/modules/api';
import { AxiosError, AxiosResponse } from 'axios';
import { _replaceBy } from 'src/modules/helpers';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import {
  hotelImagesSelector,
  hotelRoomsSelector,
} from 'src/store/hotel/selectors';
import { ThunkResult } from 'src/store';
import _get from 'lodash/get';

const fetchHotelActions = createAsyncAction(
  'FETCH_HOTEL_REQUEST',
  'FETCH_HOTEL_SUCCESS',
  'FETCH_HOTEL_FAILURE'
)<undefined, any, any>();

const updateHotelActions = createAsyncAction(
  'UPDATE_HOTEL_REQUEST',
  'UPDATE_HOTEL_SUCCESS',
  'UPDATE_HOTEL_FAILURE'
)<undefined, any, any>();

const uploadHotelImageActions = createAsyncAction(
  'UPLOAD_HOTEL_IMAGE_REQUEST',
  'UPLOAD_HOTEL_IMAGE_SUCCESS',
  'UPLOAD_HOTEL_IMAGE_FAILURE'
)<undefined, any, any>();

const updateHotelImagesActions = createAsyncAction(
  'UPDATE_HOTEL_IMAGES_REQUEST',
  'UPDATE_HOTEL_IMAGES_SUCCESS',
  'UPDATE_HOTEL_IMAGES_FAILURE'
)<undefined, any, any>();

const fetchRoomActions = createAsyncAction(
  'FETCH_ROOM_REQUEST',
  'FETCH_ROOM_SUCCESS',
  'FETCH_ROOM_FAILURE'
)<undefined, any, any>();

const removeRoomActions = createAsyncAction(
  'REMOVE_ROOM_REQUEST',
  'REMOVE_ROOM_SUCCESS',
  'REMOVE_ROOM_FAILURE'
)<undefined, any, any>();

const uploadRoomImageActions = createAsyncAction(
  'UPLOAD_ROOM_IMAGE_REQUEST',
  'UPLOAD_ROOM_IMAGE_SUCCESS',
  'UPLOAD_ROOM_IMAGE_FAILURE'
)<undefined, any, any>();

const updateRoomImagesActions = createAsyncAction(
  'UPDATE_ROOM_IMAGES_REQUEST',
  'UPDATE_ROOM_IMAGES_SUCCESS',
  'UPDATE_ROOM_IMAGES_FAILURE'
)<undefined, any, any>();

interface Hotel {
  address: string;
  advertisable: boolean;
  cityName: string;
  countryCode: string;
  creationDate: string;
  distanceToCentre: number;
  email: string;
  facilities: number[];
  id: string;
  imagesCount: number;
  lastModifiedDate: string;
  latitude: number;
  longitude: number;
  name: string;
  phone: string;
  reviews: any;
  stars: number;
  status: number;
  statusName: string;
  suitableTypes: number[];
  timezone: string;
  tradingState: boolean;
  tradingWorkOnly: boolean;
  url: string;
  visibility: boolean;
  zipCode: string;
}

export interface HotelState {
  fetching?: boolean;
  hotel: Hotel;
  descriptions: any;
  images: any;
  rooms: any;
  providers: any;
}

export const fetchHotel = (id: string) => (dispatch: any) => {
  dispatch(fetchHotelActions.request());
  Promise.all([
    HotelsAPI.get(`/hotels/${id}`),
    HotelsAPI.get(`/hotels/${id}/description`),
    HotelsAPI.get(`/hotels/${id}/images`),
    HotelsAPI.get(`/hotels/${id}/rooms`),
    HotelsAPI.get(`/hotels/${id}/providers`),
  ]).then(
    (responses: AxiosResponse[]) =>
      dispatch(
        fetchHotelActions.success({
          data: responses[0].data,
          descriptions: responses[1].data,
          images: responses[2].data,
          rooms: responses[3].data,
          providers: responses[4].data,
        })
      ),
    (error: AxiosError) => {
      dispatch(fetchHotelActions.failure(error));
      return error;
    }
  );
};

interface ErrorMsg {
  msg: string;
}

type ErrorsMsgs = ErrorMsg[];

interface UpdateHotelErrors {
  errors: { field: string; msgs: ErrorsMsgs }[];
  message: string;
}

export const updateHotel =
  (id: string, { descriptions, ...data }: any): ThunkResult<Promise<any>> =>
  (dispatch) => {
    dispatch(updateHotelActions.request());

    const requests = [
      HotelsAPI.patch(`/hotels/${id}`, data),
      ...(descriptions
        ? [HotelsAPI.patch(`/hotels/${id}/description`, descriptions)]
        : []),
    ];

    return Promise.all(requests).then(
      (responses: AxiosResponse<any>[]) => {
        const hotelData = {
          hotel: responses[0].data,
        };

        const descriptionsData = responses[1]
          ? { descriptions: responses[1].data }
          : {};

        return dispatch(
          updateHotelActions.success({ ...hotelData, ...descriptionsData })
        );
      },

      (error: AxiosError<UpdateHotelErrors>) => {
        const { response } = error;

        dispatch(updateHotelActions.failure(error));

        if (error?.config?.url?.includes('description')) {
          // eslint-disable-next-line no-throw-literal
          throw {
            ...response?.data,
            errors: [
              {
                field: 'descriptions',
                msgs: response?.data.errors.reduce<ErrorsMsgs>(
                  (acc, { msgs }) => [...acc, ...msgs],
                  []
                ),
              },
            ],
          };
        }

        throw response?.data;
      }
    );
  };

export const updateHotelProviders =
  (id: string, values: any) => (dispatch: any) => {
    dispatch(updateHotelActions.request());

    return HotelsAPI.patch(`/hotels/${id}/providers`, values).then(
      (response: AxiosResponse<any>) =>
        dispatch(
          updateHotelActions.success({
            providers: response.data,
          })
        ),
      (error: AxiosError) => {
        dispatch(updateHotelActions.failure(error));
        throw _get(error, 'response.data');
      }
    );
  };

export const fetchRoom = (hotelId: string, roomId: string) => (dispatch: any) =>
  Promise.all([
    HotelsAPI.get(`/hotels/${hotelId}/rooms/${roomId}`),
    HotelsAPI.get(`/hotels/${hotelId}/rooms/${roomId}/description`),
    HotelsAPI.get(`/hotels/${hotelId}/rooms/${roomId}/images`),
  ]).then((response: any) =>
    dispatch(
      fetchRoomActions.success({
        ...response[0].data,
        descriptions: response[1].data,
        images: response[2].data,
      })
    )
  );

export const updateRoom =
  (hotelId: string, roomId: string, { descriptions, ...data }: any) =>
  (dispatch: any) => {
    const requests = [
      HotelsAPI.patch(`/hotels/${hotelId}/rooms/${roomId}`, data),
    ].concat(
      descriptions
        ? [
            HotelsAPI.patch(
              `/hotels/${hotelId}/rooms/${roomId}/description`,
              descriptions
            ),
          ]
        : []
    );

    return Promise.all([...requests]).then((responses: AxiosResponse<any>[]) =>
      dispatch(
        fetchRoomActions.success(
          _merge(
            { ...responses[0].data },
            responses[1] ? { descriptions: responses[1].data } : {}
          )
        )
      )
    );
  };

export const removeRoom =
  (hotelId: string, roomId: string) => (dispatch: any) =>
    HotelsAPI.delete(`/hotels/${hotelId}/rooms/${roomId}`).then(() =>
      dispatch(
        removeRoomActions.success({
          roomId,
        })
      )
    );

export const addRoom =
  (hotelId: string, { descriptions, ...data }: any) =>
  (dispatch: any) =>
    HotelsAPI.post(`/hotels/${hotelId}/rooms`, {
      ...data,
      visibility: true,
    }).then((response: AxiosResponse<any>) => {
      if (descriptions) {
        return HotelsAPI.patch(
          `/hotels/${hotelId}/rooms/${response.data.id}/description`,
          descriptions
        ).then((descrResponse: any) => {
          const fullResponse = _merge(
            { ...response.data },
            { descriptions: descrResponse.data }
          );
          dispatch(fetchRoomActions.success(fullResponse));
          return fullResponse;
        });
      }
      dispatch(fetchRoomActions.success({ ...response.data }));
      return response;
    });

export const uploadHotelImage =
  (hotelId: any, file: any) => (dispatch: any) => {
    dispatch(uploadHotelImageActions.request());

    const bodyFormData = new FormData();

    bodyFormData.append('file', file);

    return HotelsAPI.post(`/hotels/${hotelId}/images`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response: AxiosResponse<any>) => {
      dispatch(uploadHotelImageActions.success({ data: response.data }));
      return response;
    });
  };

// export const updateHotelImages =
//   (hotelId: any, images: any) => (dispatch: any) => {
//     dispatch(updateHotelImagesActions.request());
//     return HotelsAPI.put(`/hotels/${hotelId}/images`, {
//       items: images.map((i: any, index: any) => ({
//         ...i,
//         order: index,
//       })),
//     }).then(
//       (response: AxiosResponse<any>) => {
//         dispatch(
//           updateHotelImagesActions.success({
//             data: response.data,
//           })
//         );
//         return response;
//       },
//       (error: AxiosError) => dispatch(updateHotelImagesActions.failure(error))
//     );
//   };

export const removeHotelImage =
  (hotelId: string, imageId: string) => (dispatch: any, getState: any) =>
    HotelsAPI.delete(`/hotels/${hotelId}/images/${imageId}`).then(() => {
      dispatch(
        updateHotelImagesActions.success({
          data: {
            items: _filter(
              hotelImagesSelector(getState()),
              ({ uuid }: any) => uuid !== imageId
            ),
          },
        })
      );
    });

export const uploadRoomImage =
  (hotelId: any, roomId: any, file: any) => (dispatch: any) => {
    dispatch(uploadRoomImageActions.request());

    const bodyFormData = new FormData();

    bodyFormData.append('file', file);

    return HotelsAPI.post(
      `/hotels/${hotelId}/rooms/${roomId}/images`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    ).then((response: AxiosResponse<any>) => {
      dispatch(uploadRoomImageActions.success({ roomId, data: response.data }));
      return response;
    });
  };

export const updateRoomImages =
  (hotelId: any, roomId: any, images: any) => (dispatch: any) => {
    dispatch(updateRoomImagesActions.request());
    return HotelsAPI.put(`/hotels/${hotelId}/rooms/${roomId}/images`, {
      items: images.map((i: any, index: any) => ({
        ...i,
        order: index,
      })),
    }).then(
      (response: AxiosResponse<any>) => {
        dispatch(
          updateRoomImagesActions.success({
            roomId,
            data: response.data,
          })
        );
        return response;
      },
      (error: AxiosError) => dispatch(updateRoomImagesActions.failure(error))
    );
  };

export const removeRoomImage =
  (hotelId: string, roomId: any, imageId: string) =>
  (dispatch: any, getState: any) =>
    HotelsAPI.delete(
      `/hotels/${hotelId}/rooms/${roomId}/images/${imageId}`
    ).then(() => {
      dispatch(
        updateRoomImagesActions.success({
          roomId,
          data: {
            items: _filter(
              _find(hotelRoomsSelector(getState()), { id: roomId }).images
                .items,
              ({ uuid }: any) => uuid !== imageId
            ),
          },
        })
      );
    });

const hotelDefaultState: HotelState = {
  fetching: false,
  hotel: {} as Hotel,
  descriptions: {},
  images: {},
  rooms: {},
  providers: {},
};

export const hotelReducer: any = handleActions<any, any>(
  {
    [fetchHotelActions.request.toString()]: (state) => ({
      ...state,
      fetching: true,
    }),
    [fetchHotelActions.success.toString()]: (state, { payload }) => ({
      ...state,
      fetching: false,
      hotel: payload.data,
      descriptions: payload.descriptions,
      images: payload.images,
      rooms: payload.rooms,
      providers: payload.providers,
    }),
    [fetchHotelActions.failure.toString()]: (state) => ({
      ...state,
      fetching: false,
      hotel: {},
    }),
    [updateHotelActions.success.toString()]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [updateHotelImagesActions.success.toString()]: (state, { payload }) => ({
      ...state,
      images: payload.data,
    }),
    [uploadHotelImageActions.success.toString()]: (state, { payload }) => ({
      ...state,
      images: {
        items: [...state.images.items, payload.data],
        totalCount: Number(state.images.totalCount) + 1,
      },
    }),
    [fetchRoomActions.success.toString()]: (state, { payload }) => ({
      ...state,
      rooms: {
        ...state.rooms,
        items: _replaceBy(
          state.rooms.items,
          { id: payload.id },
          (oldRoom: any) => ({
            ...oldRoom,
            ...payload,
          })
        ),
      },
    }),
    [removeRoomActions.success.toString()]: (state, { payload }) => ({
      ...state,
      rooms: {
        ...state.rooms,
        items: _filter(
          state.rooms.items,
          ({ id }: any) => id !== payload.roomId
        ),
      },
    }),
    [updateRoomImagesActions.success.toString()]: (state, { payload }) => ({
      ...state,
      rooms: {
        ...state.rooms,
        items: _replaceBy(
          state.rooms.items,
          { id: payload.roomId },
          (oldRoom: any) => ({
            ...oldRoom,
            images: payload.data,
          })
        ),
      },
    }),
    [uploadRoomImageActions.success.toString()]: (state, { payload }) => ({
      ...state,
      rooms: {
        ...state.rooms,
        items: _replaceBy(
          state.rooms.items,
          { id: payload.roomId },
          (oldRoom: any) => ({
            ...oldRoom,
            images: {
              items: [...oldRoom.images.items, payload.data],
              totalCount: Number(state.images.totalCount) + 1,
            },
          })
        ),
      },
    }),
  },
  hotelDefaultState
);
