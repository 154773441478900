import { Row } from 'antd';
import { FormikValues } from 'formik';
import { Moment } from 'moment';
import { useState } from 'react';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import {
  useFormFieldsMap,
  StyledCol,
} from 'src/containers/Bookings/BookingsList/table';
import styled from 'styled-components';

const StyledBigCol = styled(StyledCol)`
  flex-basis: 100%;
  width: 100%;
`;

interface IForm {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: FormikValues;
}

export const Form = ({ values, setFieldValue }: IForm) => {
  const {
    hotelName,
    hotelCountry,
    hotelCity,
    hotelAddress,
    hotelPhone,
    hotelEmail,
    checkInDate,
    checkOutDate,
    dateFrom,
    refundability,
    bookingRemarks,
  } = useFormFieldsMap();
  const [open, setOpen] = useState<Boolean>(false);
  return (
    <StyledForm layout="horizontal">
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...hotelName} />
        </StyledCol>
        <StyledCol>
          <FormikField {...hotelCountry} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...hotelCity} />
        </StyledCol>
        <StyledCol>
          <FormikField {...hotelAddress} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...hotelPhone} />
        </StyledCol>
        <StyledCol>
          <FormikField {...hotelEmail} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...checkInDate} />
        </StyledCol>
        <StyledCol>
          <FormikField {...checkOutDate} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField
            {...dateFrom}
            name="cancellationPolicy.dateFrom"
            onSelect={(time: Moment) => {
              setFieldValue('cancellationPolicy.dateFrom', time);
              const diffInMinutes = time.diff(
                values.cancellationPolicy.dateFrom,
                'minutes'
              );
              if (Math.abs(diffInMinutes) < 60) {
                setOpen(false);
              }
            }}
            open={open}
            onClick={() => setOpen(true)}
            onBlur={() => setOpen(false)}
          />
        </StyledCol>
        <StyledCol>
          <FormikField
            {...refundability}
            name="cancellationPolicy.refundability"
          />
        </StyledCol>
      </Row>
      <Row>
        <StyledBigCol>
          <FormikField
            {...bookingRemarks}
            name="cancellationPolicy.bookingRemarks"
          />
        </StyledBigCol>
      </Row>
    </StyledForm>
  );
};
