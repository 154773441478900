import { FC } from 'react';
import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';
import styled from 'styled-components';

const StyledImgContainer = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 12px;
  mask-image: radial-gradient(white, black);
  overflow: hidden;
`;

const StyledImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const HotelImage: FC = () => {
  const {
    searchValue: { hotelImg },
  } = useSearchContext();

  return (
    <StyledImgContainer>
      <StyledImg src={hotelImg} alt="hotelimg" />
    </StyledImgContainer>
  );
};

export default HotelImage;
