import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Col, Input, Modal, Row } from 'antd';
import _isFunction from 'lodash/isFunction';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IDeleteModalData {
  id: string;
}

interface IDeleteModalState {
  visibility: boolean;
  modalData: IDeleteModalData;
  deleteItemId: string;
}

const defaultDeleteModalState = {
  visibility: false,
  modalData: {},
} as IDeleteModalState;

const ErrorMessage = styled.h5`
  color: red;
`;

export const ConfirmButton = styled(Button)`
  svg {
    fill: grey;
    &:hover {
      fill: #52c41a;
    }
  }
`;

const CancelButton = styled(Button)`
  svg {
    fill: grey;
    &:hover {
      fill: red;
    }
  }
`;

export const StyledEditIcon = styled(EditOutlined)`
  margin-left: 2px;
  width: 16px;
  border: 1px solid;
  border-radius: 6px;
`;

const StyledModal = styled(Modal)`
  h4 {
    margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: left;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 1.6; /* fallback */
  height: 46px;
  margin-left: 12px;
  margin-top: 3px;
  max-height: 46px; /* fallback */
`;

interface ITextArea {
  rows: number;
  text: string | null;
  offerId: string;
  deleteItemId: string;
  deleteItemText: string;
  placeholder: string;
  handleUpdate: (offerId: string, comment: string | null) => Promise<any>;
  handleDelete: (offerId: string) => Promise<any>;
}

export const TextArea = ({
  rows,
  text,
  offerId,
  placeholder,
  handleUpdate,
  handleDelete,
  deleteItemId,
  deleteItemText,
}: ITextArea) => {
  const [value, setValue] = useState<string | null>(text);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [isDirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  function handleInputChange(event: ChangeEvent<HTMLTextAreaElement>) {
    const newValue = event.target.value;
    setDirty(true);
    if (newValue) {
      setValue(newValue);
    } else {
      setValue(null);
    }
  }
  const resetField = useCallback(handleResetField, [handleResetField]);

  function handleResetField() {
    setDirty(false);
    setExpanded(false);
    setValue(text);
  }

  function handleClicked() {
    setDirty(false);
    setExpanded(true);
  }

  function handleCancel() {
    setValue(text);
    resetField();
  }

  const handleSaveClick = () => {
    if (_isFunction(handleUpdate)) {
      resetField();
      if (text !== value) {
        handleUpdate(offerId, value);
      }
    }
  };

  useEffect(() => {
    if (offerId) {
      setValue(text);
      resetField();
      setErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, text]);

  const isTouched = value && value !== text;
  const isBrowserTabChanged = isTouched && !isExpanded;
  const isValueTooBig = value && value?.length > 255;

  useEffect(() => {
    if (isBrowserTabChanged) {
      setExpanded(true);
    } else if (!isTouched) {
      setExpanded(false);
    }

    if (isValueTooBig) {
      setErrorMessage('Must be a string with a maximum length of 255 ');
    } else if (errorMessage) {
      setErrorMessage(null);
    }
  }, [isTouched, isValueTooBig, isBrowserTabChanged, errorMessage]);

  const [deleteModalState, setDeleteModalState] = useState<IDeleteModalState>(
    defaultDeleteModalState
  );

  return (
    <>
      <Row key={`${offerId}${text}`}>
        {isExpanded || isDirty ? (
          <>
            <Col span={22}>
              <Col>
                <Input.TextArea
                  style={{ height: '100%' }}
                  rows={rows}
                  defaultValue={value ?? ''}
                  value={value ?? ''}
                  onChange={handleInputChange}
                  placeholder={placeholder}
                  onClick={handleClicked}
                  onBlur={() => !value && resetField()}
                />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </Col>
            </Col>

            {(value! || !isDirty) && !errorMessage && (
              <Col span={2}>
                <ConfirmButton
                  icon={<CheckCircleOutlined />}
                  type="link"
                  size="small"
                  onClick={handleSaveClick}
                />
                <br />
                <CancelButton
                  icon={<CloseCircleOutlined />}
                  type="link"
                  size="small"
                  onClick={handleCancel}
                />
              </Col>
            )}
          </>
        ) : (
          <>
            {text ? (
              <>
                <Col span={22}>
                  <Wrapper>{text}</Wrapper>
                </Col>

                {!errorMessage && (
                  <Col span={2}>
                    <ConfirmButton
                      icon={<StyledEditIcon />}
                      type="link"
                      size="small"
                      onClick={() => setExpanded(true)}
                    />
                    <br />
                    <CancelButton
                      icon={<DeleteOutlined />}
                      type="link"
                      size="small"
                      onClick={() =>
                        setDeleteModalState({
                          ...defaultDeleteModalState,
                          visibility: true,
                        })
                      }
                    />
                  </Col>
                )}
              </>
            ) : (
              <Col span={22}>
                <Col>
                  <Input.TextArea
                    style={{ height: '100%' }}
                    rows={rows}
                    defaultValue={value ?? ''}
                    value={value ?? ''}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    onClick={() => !text && setExpanded(true)}
                    onBlur={() => !value && setExpanded(false)}
                  />
                </Col>
              </Col>
            )}
          </>
        )}
      </Row>
      <StyledModal
        centered
        destroyOnClose={true}
        maskClosable={false}
        open={deleteModalState.visibility}
        onOk={() =>
          handleDelete(offerId).then(() =>
            setDeleteModalState(defaultDeleteModalState)
          )
        }
        okButtonProps={{
          disabled: false,
        }}
        onCancel={() => setDeleteModalState(defaultDeleteModalState)}
        title={`Delete ${deleteItemText} (${deleteItemId})`}
        okText={`Delete ${deleteItemText}`}
        cancelText="Dismiss"
      >
        <h3>{`Delete this ${deleteItemText}?`}</h3>
        <h4>Once you delete, it`s gone for good.</h4>
      </StyledModal>
    </>
  );
};
