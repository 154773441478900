import { createAsyncAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { BackofficeAPI } from 'src/modules/api';

interface ExportsList {
  totalCount: number;
  items: ExportItem[];
}

interface ExportItem {
  initiationDate: string;
  url: string;
  status: any;
  initiator: string;
  filters: any;
}

const fetchExportsActions = createAsyncAction(
  'FETCH_EXPORTS_LIST_REQUEST',
  'FETCH_EXPORTS_LIST_SUCCESS',
  'FETCH_EXPORTS_LIST_FAILURE'
)<undefined, ExportsList, any>();

export const fetchExports = () => (dispatch: any) => {
  dispatch(fetchExportsActions.request());

  return BackofficeAPI.get<ExportsList>('/export/list', {}).then(
    (response) =>
      dispatch(
        fetchExportsActions.success({
          totalCount: response.data.totalCount,
          items: response.data.items,
        })
      ),
    (error) => dispatch(fetchExportsActions.failure(error))
  );
};

export interface ExportsState {
  fetching?: boolean;
  items: ExportItem[] | null;
  totalCount: number;
}

const exportsDefaultState: ExportsState = {
  fetching: false,
  items: [],
  totalCount: 0,
};

export const exportsReducer: any = handleActions(
  {
    [fetchExportsActions.request.toString()]: (state) => ({
      ...state,
      fetching: true,
    }),
    [fetchExportsActions.success.toString()]: (state, { payload }) => ({
      ...state,
      fetching: false,
      items: payload.items,
      totalCount: payload.totalCount,
    }),
    [fetchExportsActions.failure.toString()]: (state) => ({
      ...state,
      fetching: false,
      items: null,
    }),
  },
  exportsDefaultState
);
