import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import ArrowsUpDown from 'src/icons/ArrowsUpDown';
import { FC, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import { useConfig } from 'src/hooks/swr/useConfig';
import { useCurrencyName } from 'src/hooks/useFormatCurrency';
import { useQueryParam } from 'use-query-params';
import { URL_QUERY_PARAMS } from '../../SearchForm';
import { FieldsName, SearchPanelInputProps } from '../../SearchPanelForm';
import { SearchFormControl } from '../components/FilledInput';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

type Props = SearchPanelInputProps;

const defaultValue = { EUR: '1' };

const useCurrencies = () => {
  const { data } = useConfig();

  const currenciesData = data?.currencies || defaultValue;

  const mapCountryCode = (countryCode: string) => ({
    code: countryCode.toUpperCase(),
    label: currenciesData[countryCode],
  });
  const currenciesCode = Object.keys(currenciesData);
  const mappedCurrencies = _map(currenciesCode, mapCountryCode);
  const sortedCurrencies = _sortBy(mappedCurrencies, ['code']);

  if (!sortedCurrencies) return [{ code: 'EUR', label: 'EUR' }];

  return sortedCurrencies;
};

export const CurencyChooser: FC<PropsWithChildren<Props>> = () => {
  const currencies = useCurrencies();
  const getName = useCurrencyName();
  return (
    <SearchFormControl label={FieldsName.CURRENCY}>
      <Controller
        name={FieldsName.CURRENCY}
        render={({ field }) => (
          <Select
            {...field}
            IconComponent={ArrowsUpDown}
            disableUnderline
            label={FieldsName.CURRENCY}
          >
            {currencies.map(({ code }) => (
              <MenuItem value={code} key={code}>
                <>
                  {code} ({getName(code)})
                </>
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </SearchFormControl>
  );
};

export const CurrencySelector: FC<PropsWithChildren> = () => {
  const currencies = useCurrencies();
  const getName = useCurrencyName();
  const [currency, setCurrency] = useQueryParam(
    FieldsName.CURRENCY,
    URL_QUERY_PARAMS.currency
  );

  return (
    <Stack
      spacing={1}
      alignItems="center"
      direction="row"
      justifyContent="flex-end"
    >
      <Typography variant="body2">Currency: </Typography>
      <Select
        IconComponent={ArrowsUpDown}
        variant="standard"
        disableUnderline
        style={{ width: 70 }}
        label={FieldsName.CURRENCY}
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
        renderValue={(option) => (
          <Typography variant="body2">{option}</Typography>
        )}
      >
        {currencies.map(({ code }) => (
          <MenuItem value={code} key={code}>
            <>
              {code} ({getName(code)})
            </>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
