import { createAsyncAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { BackofficeAPI, apiCall } from 'src/modules/api';
import moment from 'moment';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { REFUND_REASONS_MAP } from 'src/constants';
import { mapPaymentListParams } from 'src/modules/apiMappers';
import { TDispatch, ThunkResult } from 'src/store';
import { AxiosPromise } from 'axios';

const fetchPaymentsActions = createAsyncAction(
  'FETCH_PAYMENTS_LIST_REQUEST',
  'FETCH_PAYMENTS_LIST_SUCCESS',
  'FETCH_PAYMENTS_LIST_FAILURE'
)<undefined, any, any>();

const cancelPaymentActions = createAsyncAction(
  'CANCEL_PAYMENT_REQUEST',
  'CANCEL_PAYMENT_SUCCESS',
  'CANCEL_PAYMENT_FAILURE'
)<undefined, any, any>();

const capturePaymentActions = createAsyncAction(
  'CAPTURE_PAYMENT_REQUEST',
  'CAPTURE_PAYMENT_SUCCESS',
  'CAPTURE_PAYMENT_FAILURE'
)<undefined, any, any>();

const refundPaymentActions = createAsyncAction(
  'REFUND_PAYMENT_REQUEST',
  'REFUND_PAYMENT_SUCCESS',
  'REFUND_PAYMENT_FAILURE'
)<undefined, any, any>();

const fetchPaymentItemActions = createAsyncAction(
  'FETCH_PAYMENT_ITEM_REQUEST',
  'FETCH_PAYMENT_ITEM_SUCCESS',
  'FETCH_PAYMENT_ITEM_FAILURE'
)<undefined, any, any>();

const addPaymentActions = createAsyncAction(
  'ADD_PAYMENT_REQUEST',
  'ADD_PAYMENT_SUCCESS',
  'ADD_PAYMENT_FAILURE'
)<undefined, any, any>();

const processPaymentActions = createAsyncAction(
  'PROCESS_PAYMENT_REQUEST',
  'PROCESS_PAYMENT_SUCCESS',
  'PROCESS_PAYMENT_FAILURE'
)<undefined, any, any>();

export interface PaymentsState {
  fetching?: boolean;
  items: any;
  totalCount: number;
  searchParams: {
    [propName: string]: any;
  };
}

export const fetchPayments =
  (params: any = {}) =>
  (dispatch: TDispatch) => {
    const mappedParams = mapPaymentListParams(params);

    dispatch(fetchPaymentsActions.request());
    return BackofficeAPI.get('/payments', {
      params: mappedParams,
    }).then(
      (response) =>
        dispatch(
          fetchPaymentsActions.success({
            data: response.data,
            params,
          })
        ),
      (error) => dispatch(fetchPaymentsActions.failure(error))
    );
  };

const fetchPaymentItem =
  (id: string): ThunkResult<void> =>
  (dispatch) => {
    dispatch(fetchPaymentItemActions.request());
    BackofficeAPI.get(`/payments/${id}`).then(
      (response) =>
        dispatch(
          fetchPaymentItemActions.success({
            data: response.data,
          })
        ),
      (error) => dispatch(fetchPaymentItemActions.failure(error))
    );
  };

export const cancelPayment =
  (id: string): ThunkResult<any> =>
  (dispatch) => {
    dispatch(cancelPaymentActions.request());
    return apiCall(`api/payments/${id}/cancel`, { method: 'POST' }).then(
      (response) => {
        dispatch(fetchPaymentItem(id));
        return response;
      },
      (error) => {
        dispatch(cancelPaymentActions.failure(error));
        throw error;
      }
    );
  };

export const capturePayment =
  (id: string): ThunkResult<any> =>
  (dispatch) => {
    dispatch(capturePaymentActions.request());
    return apiCall(`api/payments/${id}/capture`, { method: 'POST' }).then(
      (response) => {
        dispatch(fetchPaymentItem(id));
        return response;
      },
      (error) => {
        dispatch(capturePaymentActions.failure(error));
        throw error;
      }
    );
  };

export const refundPayment =
  (id: string, values: any): ThunkResult<any> =>
  (dispatch) => {
    const refundData = {
      amount: values.refundAmount,
      comment: `${REFUND_REASONS_MAP[values.refundReason].name}. ${
        values.comment || ''
      }`,
    };
    dispatch(refundPaymentActions.request());
    return apiCall(`api/payments/${id}/refund`, {
      method: 'POST',
      data: refundData,
    }).then(
      (response) => {
        dispatch(fetchPaymentItem(id));
        return response;
      },
      (error) => {
        dispatch(refundPaymentActions.failure(error));
        throw _get(error, 'response.data');
      }
    );
  };

export const processInvoice =
  (id: string, values: any): ThunkResult<AxiosPromise<any>> =>
  (dispatch) =>
    BackofficeAPI.post(`/payments/${id}/process`, values).then(
      (response) => {
        dispatch(fetchPaymentItem(id));
        return response.data;
      },
      (error) => {
        dispatch(processPaymentActions.failure(error));
        throw _get(error, 'response.data');
      }
    );

const ADD_PAYMENT_URLS: {
  [key: string]: string;
} = {
  deposit: 'deposit',
  invoice: 'invoice',
  internalCost: 'internal-cost',
  payAtHotel: 'pay-at-hotel',
};

export const addPayment =
  ({ type, ...values }: any): ThunkResult<AxiosPromise<any>> =>
  (dispatch) => {
    dispatch(addPaymentActions.request());
    return BackofficeAPI.post(
      `/payments/${ADD_PAYMENT_URLS[type]}`,
      values
    ).then(
      (response) => {
        dispatch(fetchPayments({}));
        return response.data;
      },
      (error) => {
        dispatch(addPaymentActions.failure(error));
        throw _get(error, 'response.data');
      }
    );
  };

const paymentsDefaultState: PaymentsState = {
  fetching: false,
  items: [],
  totalCount: 0,
  searchParams: {
    page: 1,
    pageSize: 10,
    paymentDateRange: [moment().subtract(7, 'day'), moment()],
    paymentType: '',
  },
};

export const paymentsReducer = handleActions(
  {
    [fetchPaymentsActions.request.toString()]: (state) => ({
      ...state,
      fetching: true,
    }),
    [fetchPaymentsActions.success.toString()]: (state, { payload }) => ({
      ...state,
      fetching: false,
      // @ts-expect-error
      items: payload.data.items,
      // @ts-expect-error
      searchParams: payload.params,
      // @ts-expect-error
      totalCount: payload.data.totalCount,
    }),
    [fetchPaymentsActions.failure.toString()]: (state) => ({
      ...state,
      fetching: false,
      items: null,
    }),
    [fetchPaymentItemActions.success.toString()]: (state, { payload }: any) => {
      const cancelledItemIndex = _findIndex(state.items, [
        'paymentId',
        payload.data.paymentId,
      ]);
      return {
        ...state,
        items: [
          ...state.items.slice(0, cancelledItemIndex),
          {
            ...payload.data,
          },
          ...state.items.slice(cancelledItemIndex + 1),
        ],
      };
    },
  },
  paymentsDefaultState
);
