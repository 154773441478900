import { Tabs } from 'antd';
import { IBooking } from 'src/containers/Bookings/Booking';
import { Details } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/Details';
import { Transactions } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/Transactions';
import { CryptoTransactions } from 'src/containers/Bookings/BookingsList/compounds/ExpandedItem/CryptoTransactions';

enum TABS {
  DETAILS = 'Booking Details',
  TRANSACTIONS = 'Transactions Summary',
  CRYPTO_TRANSACTIONS = 'Crypto Transactions Summary',
}

const tabs = [
  {
    key: TABS.DETAILS,
    label: TABS.DETAILS,
    Component: (props: IBooking) => <Details {...props} />,
  },
  {
    key: TABS.TRANSACTIONS,
    label: TABS.TRANSACTIONS,
    Component: (props: IBooking) => <Transactions {...props} />,
  },
  {
    key: TABS.CRYPTO_TRANSACTIONS,
    label: TABS.CRYPTO_TRANSACTIONS,
    Component: (props: IBooking) => <CryptoTransactions {...props} />,
  },
];

export const ExpandedItem = (props: IBooking) => (
  <Tabs
    defaultActiveKey={TABS.DETAILS}
    type="card"
    items={tabs.map((t) => ({
      ...t,
      children: t.Component(props),
    }))}
  />
);
