import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const StyledCode = styled.code`
  background: rgba(110, 118, 129, 0.4);
  padding: 0.1em 0.4em;
  font-size: 0.9em;
  border-radius: 5px;
  margin-left: 4px;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

export const Label: FC<{ text: string; value: ReactNode }> = ({
  text,
  value,
}) => (
  <StyledContainer>
    <span>{text}</span>
    <StyledCode>{value}</StyledCode>
  </StyledContainer>
);
