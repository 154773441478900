import { Tag } from 'antd';
import styled from 'styled-components';

export enum Status {
  WAITING_FOR_PAYMENT = 'WaitingForPayment',
  IN_PROGRESS = 'InProgress',
  FAILED = 'Failed',
  REBOOKED = 'Rebooked',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  CREATED = 'Created',
}

const STATUS_LABEL = {
  [Status.WAITING_FOR_PAYMENT]: 'Waiting For Payment',
  [Status.IN_PROGRESS]: 'In Progress',
  [Status.FAILED]: 'Failed',
  [Status.REBOOKED]: 'Rebooked',
  [Status.CANCELLED]: 'Cancelled',
  [Status.COMPLETED]: 'Completed',
  [Status.CREATED]: 'Created',
};

const COLORS_MAP = new Map([
  [Status.WAITING_FOR_PAYMENT, ''],
  [Status.IN_PROGRESS, ''],
  [Status.FAILED, 'red'],
  [Status.REBOOKED, 'green'],
  [Status.CANCELLED, 'orange'],
  [Status.COMPLETED, 'green'],
  [Status.CREATED, ''],
]);

const StyledTag = styled(Tag)`
  display: inline-block;
  white-space: normal;
  text-align: center;
`;

interface IBookingStatus {
  bookingStatus: string | Status;
}

export const BookingStatus = ({ bookingStatus }: IBookingStatus) => (
  <StyledTag
    color={COLORS_MAP.get(bookingStatus as Status)}
    children={STATUS_LABEL[bookingStatus as Status] ?? bookingStatus}
  />
);
