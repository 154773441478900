import { Alert, Modal, Spin } from 'antd';
import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { Moment } from 'moment';
import { FC } from 'react';
import { CancellationPolicyContent } from 'src/containers/BookingEngine/Search/components/Rooms/common/Table/CancellationPolicy/CancellationPolicyPopOver';
import { IBooking } from 'src/containers/Bookings/Booking';
import styled from 'styled-components';
import * as yup from 'yup';
import { Status } from '../BookingStatus';
import { Form } from './Form';

export const STATUSES_TO_CANCEL = [Status.COMPLETED, Status.REBOOKED];

export interface ICancellationPolicy {
  dateFrom: Moment;
  bookingRemarks: string;
  refundability: number;
  description?: string | null;
}

const StyledModal = styled(Modal)`
  p {
    margin: 0px;
  }
  h5 {
    display: block;
    margin-left: 25px;
  }
  h4 {
    margin-top: 10px;
  }
`;

const validationSchema = yup.object().shape({
  comment: yup
    .string()
    .nullable()
    .required('Comment is required field')
    .max(255, 'Comment must be below 255 symbols'),
});

interface ICancelModal {
  visible: boolean;
  initialValues: CancelBooking;
  onOk: (values: CancelBooking) => Promise<void | AxiosError<any>>;
  onCancel: () => void;
}

export type CancelBooking = Pick<
  IBooking,
  | 'comment'
  | 'offerId'
  | 'newBookingId'
  | 'cancellationPolicy'
  | 'cancellationPolicyRaw'
>;

export const CancelModal: FC<ICancelModal> = ({
  visible,
  initialValues,
  onOk,
  onCancel,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (response: any) => {
          setSubmitting(false);
          if (response.errors) {
            setStatus({
              commonApiErrors: response.errors.filter(
                ({ field }: any) => !field
              ),
              fieldApiErrors: _keyBy(
                response.errors.filter(({ field }: any) => field),
                'field'
              ),
            });
          }
        }
      );
    },
  });

  const { status, isSubmitting, handleSubmit } = formik;

  const { newBookingId, cancellationPolicy, cancellationPolicyRaw } =
    initialValues;
  const dateFrom = cancellationPolicy?.dateFrom;
  const refundability = cancellationPolicy?.refundability;
  return (
    <FormikProvider value={formik}>
      <StyledModal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title={`Cancel Booking (${newBookingId})`}
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Cancel booking"
        cancelText="Dismiss"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <>
            <h4>You are attempting to Cancel Booking</h4>
            <h4>
              <b>Cancellation Policy</b>
            </h4>
            <h5>
              <CancellationPolicyContent
                dateFrom={dateFrom as unknown as string}
                refundability={refundability}
              />
            </h5>
            {cancellationPolicyRaw && (
              <>
                <h4>
                  <b>Supplier Cancellation Policy</b>
                </h4>
                <h5>
                  <pre>
                    {JSON.stringify(JSON.parse(cancellationPolicyRaw), null, 2)}
                  </pre>
                </h5>
              </>
            )}
            <h4>
              Please make sure the booking is canceled on the supplier’s side
              and check whether related payments need to be refunded.
            </h4>
            <Form />
          </>
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </StyledModal>
    </FormikProvider>
  );
};
