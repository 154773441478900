import Stack from '@mui/material/Stack';
import { isInteger } from 'lodash';
import { ChangeEventHandler, forwardRef } from 'react';
import styled from 'styled-components/macro';

import MinusIcon from 'src/icons/Minus';
import PlusIcon from 'src/icons/Plus';

import { Button } from './WrappedButtons';

type NumberStepperProps = {
  value: number;
  onChange: (v: number) => void;
  onFocus?: () => void;
  decimal?: boolean;
  step?: number;
  min?: number;
  max?: number;
  size?: number;
};

const StyledInput = styled.input`
  text-align: center;
  border: none;
  outline: none !important;
  background: transparent;
  ${({ theme }) => theme.typography.label1 as {}}
`;

const StyledButton = styled(Button)`
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing(2)};
  line-height: 1;
  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const NumberStepper = forwardRef<HTMLInputElement, NumberStepperProps>(
  ({ value, onChange, onFocus, decimal, step = 1, min, max, size }, ref) => {
    const handleMinus = () => onChange(value - step);

    const handlePlus = () => onChange(value + step);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const value = Number(e.target.value);

      if (
        !isNaN(value) &&
        (decimal || isInteger(value)) &&
        (typeof min === 'undefined' || value >= min) &&
        (typeof max === 'undefined' || value <= max)
      ) {
        onChange(value);
      }
    };

    return (
      <Stack direction="row" alignItems="center" spacing={4}>
        <StyledButton
          onClick={handleMinus}
          variant="contained"
          color="buttonSecondary"
          disabled={typeof min !== 'undefined' && value <= min}
        >
          <MinusIcon />
        </StyledButton>
        <StyledInput
          ref={ref}
          value={value}
          readOnly
          onChange={handleChange}
          onFocus={onFocus}
          size={size}
        />
        <StyledButton
          onClick={handlePlus}
          variant="contained"
          color="buttonSecondary"
          disabled={typeof max !== 'undefined' && value >= max}
        >
          <PlusIcon />
        </StyledButton>
      </Stack>
    );
  }
);
