import {
  FC,
  useMemo,
  useState,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';

type SearchValue = {
  hotelId?: number;
  hotelImg?: string;
};

type SearchContextType = {
  searchValue: SearchValue;
  setSearchValue: (props: SearchValue) => void;
  resetSearchValue: () => void;
};

const initial_state = {} as SearchValue;

const SearchContext = createContext<SearchContextType>({
  searchValue: initial_state,
  setSearchValue: () => {},
  resetSearchValue: () => {},
});

export const useSearchContext = () => {
  const { searchValue, setSearchValue, resetSearchValue } =
    useContext<SearchContextType>(SearchContext);
  return { searchValue, setSearchValue, resetSearchValue };
};

export const SearchContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchValue, setSearchValue] = useState(initial_state);
  const resetSearchValue = () => setSearchValue(initial_state);
  const value = useMemo(
    () => ({ searchValue, setSearchValue, resetSearchValue }),
    [searchValue]
  );
  return <SearchContext.Provider value={value} children={children} />;
};
