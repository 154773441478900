import _keyBy from 'lodash/keyBy';

export const BEARER_TOKEN_NAME = 'bearer-token';

export const BEARER_TOKEN_EXPIRATION_NAME = 'bearer-token-expiration';

export const REFRESH_TOKEN_NAME = 'refresh-token';

export const REFRESH_TOKEN_EXPIRATION_NAME = 'refresh-token-expiration';

export const TWO_FA_ENABLED_NAME = 'two-fa-enabled';

export const TRADING_STATES: {}[] = [
  { id: true, name: 'Tradable' },
  { id: false, name: 'Suspended' },
];

export const VISIBILITY_STATES: {}[] = [
  { id: true, name: 'Visible' },
  { id: false, name: 'Invisible' },
];

export const HOTEL_STARS = [0, 1, 2, 3, 4, 5];

export const REFUND_TYPES: {}[] = [
  { id: 'fullRefund', name: 'Full refund' },
  { id: 'partialRefund', name: 'Partial refund' },
];

export const PARTIAL_REFUND_TYPES: {}[] = REFUND_TYPES.filter(
  (refundType: any) => refundType.id !== 'fullRefund'
);

export const REFUND_REASONS: {}[] = [
  { id: 'bookingCancelled', name: 'Booking Cancelled' },
  { id: 'bookingFailed', name: 'Booking Failed' },
  { id: 'custom', name: 'Custom' },
  { id: 'supplierError', name: 'Supplier Error' },
];

export const REFUND_REASONS_MAP: any = _keyBy(REFUND_REASONS, 'id');

export const PAYMENT_TYPES = {
  DEPOSIT: 'Deposit',
  INVOICE: 'Invoice',
  INTERNAL_COST: 'InternalCost',
  PAY_AT_HOTEL: 'PayAtHotel',
  PAY_AT_HOTEL_HOLD: 'PayAtHotelHold',
};

export const PAYMENT_STATUSES = {
  PROCESSING: 'Processing',
  UNDER_REVIEW: 'UnderReview',
  UNCAPTURED: 'Uncaptured',
  SUCCESSFUL: 'Successful',
  REFUNDED: 'Refunded',
  PARTIALLY_REFUNDED: 'PartiallyRefunded',
  CHARGEBACK: 'Chargeback',
  CANCELLED: 'Cancelled',
  FAILED: 'Failed',
};

export const PAYMENT_PROCESSORS = {
  QUICK_PAY: 'QuickPay',
  STRIPE: 'Stripe',
};

export const EXTERNAL_HOTEL_FACILITIES_STATUS: {}[] = [
  { id: true, name: 'Enabled' },
  { id: false, name: 'Disabled' },
];
