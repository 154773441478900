import Skeleton from '@mui/material/Skeleton';
import { FC } from 'react';

import HotelCardContainer from './HotelCardContainer';

const HotelCardSkeleton: FC = () => (
  <HotelCardContainer
    selected={false}
    carousel={<Skeleton variant="rectangular" height="100%" />}
    title={<Skeleton width={150} />}
    subTitle={<Skeleton width={175} />}
    stars={<Skeleton width={40} />}
    score={<Skeleton width={50} />}
    rate={<Skeleton width={120} />}
  />
);

export default HotelCardSkeleton;
