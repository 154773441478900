import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ManualBooking } from 'src/hooks/swr/useManualBookings';
import { useManualBookingsDetails } from 'src/hooks/swr/useManualBookingsDetails';
import { BackofficeAPI } from 'src/modules/api';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';
import { manualBookingPath } from '../../api';
import { Modal } from './Components';
import { Fields, FieldsName, Form } from './Form';

const SuccessNotification = () =>
  notification.success({
    message: 'Success',
    description: 'Well done! Changes saved.',
  });

const ErrorNotification = () =>
  notification.error({
    message: 'Error',
    description: 'Changes not appllied',
  });

const getRoundValue = (value: number) => Math.round(value * 100) / 100;

yup.addMethod(yup.number, 'noWhiteSpace', (value, originalValue) =>
  /\s/.test(originalValue) ? NaN : value
);

const validationSchema = yup.object().shape({
  [FieldsName.PRICE]: yup.number().nullable().notRequired(),
  [FieldsName.BOOKING_REFERENCE]: yup
    .string()
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .matches(/^[0-9]+$/, 'Must be a number')
    .nullable()
    .test('len', 'Max length is 10 symbols', (val) =>
      val ? val.length <= 10 : true
    )
    .notRequired(),
  [FieldsName.PAYMENT_REFERENCE]: yup
    .string()
    .nullable()
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .max(100, 'Max length is 100 symbols')
    .notRequired(),
});

type TEditModal = {
  visible: boolean;
  mData: ManualBooking;
  onCancel: () => void;
  mutateList: KeyedMutator<any>;
};

const EditModal: FC<TEditModal> = ({
  visible,
  mData,
  onCancel,
  mutateList,
}) => {
  const {
    data: detailsData,
    mutate,
    isValidating,
  } = useManualBookingsDetails(mData.id);

  const methods = useForm<Fields>({
    resolver: yupResolver(validationSchema),
  });

  const resetForm = () => {
    if (detailsData && mData) {
      methods.reset({
        [FieldsName.PRICE]:
          mData.salePrice === null
            ? null
            : getRoundValue(mData.salePrice * Number(mData.exchangeRate)),
        [FieldsName.EXCHANGE_RATE]: mData.exchangeRate,
        [FieldsName.CUSTOMER_CURRENCY]: mData.customerCurrency,
        [FieldsName.BOOKING_REFERENCE]: detailsData?.bookingReference,
        [FieldsName.PAYMENT_REFERENCE]: detailsData?.paymentReference,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetForm, [detailsData, mData]);

  const onClose = () => {
    resetForm();
    onCancel();
  };

  const [loading, setLoading] = useState(false);

  const onSubmitHandler = ({
    bookingReference,
    paymentReference,
    salePrice,
  }: Fields) => {
    setLoading(true);
    return BackofficeAPI.patch(`${manualBookingPath}/${mData.id}`, {
      salePrice,
      bookingReference,
      paymentReference,
    })
      .then(() =>
        mutateList().then(() =>
          mutate().then(() => {
            setLoading(false);
            SuccessNotification();
            onCancel();
          })
        )
      )
      .catch(() => {
        setLoading(false);
        ErrorNotification();
      });
  };

  return (
    <Modal open={visible} onClose={onClose} withCloseButton>
      <Stack spacing={4}>
        <Typography variant="h2">
          Edit booking {mData.bookingNumber} information:
        </Typography>
        <Divider />
        {isValidating || loading ? (
          <Stack spacing={4} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <FormProvider {...methods}>
            <Form onSubmit={onSubmitHandler} onCancel={onClose} />
          </FormProvider>
        )}
      </Stack>
    </Modal>
  );
};

export default EditModal;
