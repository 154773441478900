import { buttonClasses } from '@mui/material/Button';
import createPalette, {
  PaletteColor,
  PaletteColorOptions,
} from '@mui/material/styles/createPalette';
import createSpacing from '@mui/system/createTheme/createSpacing';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import createTheme from '@mui/material/styles/createTheme';
import createTypography from '@mui/material/styles/createTypography';
import { CSSObject } from '@mui/system';
import { omit } from 'lodash';
import { TypographyProps } from '@mui/material/Typography';
import createTransitions from '@mui/material/styles/createTransitions';

import ArrowDown from 'src/icons/ArrowDown';

import { mainTheme, CustomTheme } from './mainTheme';

export const POINTER_FINE = '@media (pointer: fine)';

type CustomShadows = {
  widget: string;
  text: string;
};

declare module '@mui/material/styles' {
  interface Theme {
    custom: CustomTheme;
    customShadows: CustomShadows;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom: CustomTheme;
    customShadows: CustomShadows;
  }

  interface Palette {
    buttonSecondary: PaletteColor;
    focusSecondary: PaletteColor;
  }
  interface PaletteOptions {
    buttonSecondary: PaletteColorOptions;
    focusSecondary: PaletteColorOptions;
  }

  interface TypographyVariants {
    buttonLarge: CSSObject;
    label1: CSSObject;
    label2: CSSObject;
    body3: CSSObject;
    body4: CSSObject;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    buttonLarge: CSSObject;
    label1: CSSObject;
    label2: CSSObject;
    body3: CSSObject;
    body4: CSSObject;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h6: false;
    subtitle1: false;
    subtitle2: false;
    caption: false;

    buttonLarge: true;
    label1: true;
    label2: true;
    body3: true;
    body4: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    buttonSecondary: true;
    focusSecondary: true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    buttonSecondary: true;
    focusSecondary: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    inline: true;
  }
  interface ButtonPropsColorOverrides {
    buttonSecondary: true;
    focusSecondary: true;
  }
}

const muiTheme = (() => {
  const colorWhite = '#FFFFFF';
  const colorBlack = '#000000';
  const colorBlueMain = '#1948F0';

  const outlineSecondaryButtonBorder = '2px solid';

  // const tabPadding = 15;

  const getButtonPadding = (
    value: number
  ): Pick<CSSObject, 'padding' | 'margin' | 'width'> => ({
    padding: value,
    margin: -value,
    width: `calc(100% + ${2 * value}px)`,
  });

  const palette = createPalette({
    mode: 'light',
    background: { default: colorWhite, paper: colorWhite },
    primary: {
      main: '#FB440A',
      light: '#FF5C28',
    },
    buttonSecondary: {
      light: 'rgba(82, 82, 82, 1)',
      main: colorBlueMain,
      contrastText: colorWhite,
    },
    secondary: {
      main: '#1948F0',
    },
    focusSecondary: { main: '#577CFF' },
    common: {
      black: colorBlack,
      white: colorWhite,
    },
    text: {
      primary: colorBlack,
      secondary: 'rgba(82, 82, 82, 1)',
      disabled: 'rgba(196, 196, 196, 1)',
    },
    divider: '#E4E4E7',
  });

  const spacing = createSpacing((v: number) => v * 4);

  const breakpoints = createBreakpoints({});

  const downMd = breakpoints.down('md');

  const typography = createTypography(palette, {
    fontFamily: '"Noto Sans Display", sans-serif',

    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    caption: undefined,

    h1: {
      fontSize: '24px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '24px' },
    },
    h2: {
      fontSize: '18px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '18px' },
    },
    h3: {
      fontSize: '16px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '16px' },
    },
    h4: {
      fontSize: '16px',
      lineHeight: 1.4,
      fontWeight: 400,
      [downMd]: { fontSize: '16px' },
    },
    h5: {
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '14px' },
      textTransform: 'initial',
    },
    overline: {
      fontSize: '12px',
      lineHeight: 1.4,
      fontWeight: 500,
      [downMd]: { fontSize: '12px' },
    },
    button: {
      fontSize: '15px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '15px' },
    },
    buttonLarge: {
      fontSize: '19px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '19px' },
    },
    label1: {
      fontSize: '17px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '17px' },
    },
    label2: {
      fontSize: '10px',
      lineHeight: 1.4,
      fontWeight: 600,
      [downMd]: { fontSize: '10px' },
    },
    body1: {
      fontSize: '22px',
      lineHeight: 1.4,
      fontWeight: 'normal',
      [downMd]: { fontSize: '18px' },
    },
    body2: {
      fontSize: '16px',
      lineHeight: 1.4,
      fontWeight: 400,
      [downMd]: { fontSize: '16px' },
    },
    body3: {
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 500,
      [downMd]: { fontSize: '14px' },
    },
    body4: {
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 400,
      [downMd]: { fontSize: '14px' },
    },
  });

  //@ts-expect-error
  const { create: createTransition } = createTransitions({});

  const theme = createTheme({
    spacing,

    palette,

    breakpoints,

    typography,

    customShadows: {
      widget: '0px -3px 57px rgba(0, 0, 0, 0.1)',
      text: '0px 4px 17px rgba(0, 0, 0, 0.27)',
    },

    components: {
      MuiTableCell: {
        styleOverrides: {
          sizeSmall: {
            padding: '4px 8px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: { wrapper: { paddingLeft: 10, paddingRight: 10 } },
      },
      MuiTextField: {
        defaultProps: { InputLabelProps: { shrink: true } },
        styleOverrides: { root: { input: typography.body3 } },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: omit(getButtonPadding(9), 'width'),
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: { margin: 0 },
          label: { marginLeft: spacing(3) },
        },
      },
      MuiSelect: {
        defaultProps: { IconComponent: ArrowDown },
        styleOverrides: {
          icon: { top: 'auto', right: 12 },
          select: { borderRadius: 6 },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          icon: { top: 'auto', right: 12 },
          select: { borderRadius: 6 },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { borderRadius: 6 },
          input: {
            padding: 12,
            borderRadius: 'inherit',
          },
          notchedOutline: {
            legend: {
              width: 0,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            background: 'rgba(0,0,0,0.35)',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: { ...(typography.overline as any), transform: 'none' },
          outlined: { position: 'static', marginBottom: 6 },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            'label + &': {
              marginTop: 14,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: typography.body3,
          input: { height: 'auto' },
        },
        defaultProps: { color: 'buttonSecondary' },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiGrid: {
        defaultProps: {
          columns: { xs: 6, sm: 16, md: 16, lg: 16, xl: 16 },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 0,
            padding: 15,
            // textTransform: 'inherit',
          },
          colorInherit: {
            background: palette.common.white,
          },
          outlined: {
            borderRadius: 16,
            '@media (pointer: fine)': {
              '&:hover': {
                background: 'none',
                border: outlineSecondaryButtonBorder,
              },
            },
            border: outlineSecondaryButtonBorder,
          },
          contained: {
            borderRadius: 6,
          },
          text: {
            ...getButtonPadding(7),
            borderRadius: 10,
            fontWeight: 500,
            [`.${buttonClasses.startIcon}`]: { marginRight: 5 },
          },
          endIcon: {
            marginLeft: 6,
            '& > *:nth-of-type(1)': { fontSize: 'inherit' },
          },
          sizeSmall: typography.body3,
          sizeLarge: typography.buttonLarge,
        },
        defaultProps: {
          disableElevation: true,
        },
        variants: [
          {
            props: { variant: 'text' },
            style: {
              [`& .${buttonClasses.startIcon}`]: { marginRight: spacing(3) },
            },
          },
          {
            props: {
              variant: 'contained',
              color: 'primary',
            },
            style: {
              '@media (pointer: fine)': {
                '&:hover': { background: palette.primary.light },
              },
            },
          },
          {
            props: {
              variant: 'contained',
              color: 'buttonSecondary',
            },
            style: {
              '@media (pointer: fine)': {
                '&:hover': { background: palette.buttonSecondary.light },
              },
            },
          },
          {
            props: {
              variant: 'outlined',
              color: 'buttonSecondary',
            },
            style: {
              '@media (pointer: fine)': {
                '&:hover': {
                  borderColor: palette.buttonSecondary.light,
                  color: palette.buttonSecondary.light,
                },
              },
              borderColor: palette.buttonSecondary.main,
              padding: '10px 15px',
            },
          },
          {
            props: { variant: 'inline' },
            style: {
              justifyContent: 'left',
              fontWeight: 'normal',
              ...getButtonPadding(12),
              borderRadius: 12,
              '@media (pointer: fine)': {
                '&:hover': { background: palette.divider },
              },
              [`.${buttonClasses.startIcon}`]: {
                marginRight: 15,
                marginLeft: 0,
                padding: 5,
                background: 'rgba(117, 117, 136, .1)',
                borderRadius: 4,
              },
            },
          },
          {
            props: { selected: true, variant: 'inline' } as any,
            style: {
              background: palette.divider,
              pointerEvents: 'none',
              [`.${buttonClasses.endIcon}`]: { marginLeft: 'auto' },
            },
          },
        ],
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
          variant: 'body3',
          color: 'text.primary',
        },
        styleOverrides: {
          root: { transition: createTransition('color') },
        },
        variants: [
          {
            props: { color: 'text.primary' },
            style: {
              '@media (pointer: fine)': {
                '&:hover': { color: palette.primary.light },
              },
            },
          },
          {
            props: { color: 'secondary' },
            style: {
              '@media (pointer: fine)': {
                '&:hover': { color: palette.secondary.light },
              },
            },
          },
        ],
      },
    },

    custom: mainTheme,
  });

  return theme;
})();

export default muiTheme;

export type MuiTheme = typeof muiTheme;

export type TypographyVariant = Exclude<
  TypographyProps['variant'],
  'inherit' | undefined
>;
