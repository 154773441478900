import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _isEmpty from 'lodash/isEmpty';
import { FC, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { MultilineCollapse } from 'src/components/MultilineCollapse';
import {
  SearchFormControl,
  SearchPanelFilledInput,
} from 'src/containers/BookingEngine/Main/SearchPanel/choosers/components/FilledInput';
import { useBookingSummary } from 'src/hooks/swr/useBookingSummary';
import { useConfiguration } from 'src/hooks/swr/useConfig';
import ArrowsUpDown from 'src/icons/ArrowsUpDown';
import { BedType } from 'src/modules/getRoomInfo';
import joinBy from 'src/modules/joinBy';
import { yupEmail } from 'src/modules/validation';
import { filterBeds } from 'src/store/config/selectors';
import * as yup from 'yup';
import { CancellationPolicyContent } from '../Table/CancellationPolicy/CancellationPolicyPopOver';
import { BookModal } from './BookModal';
import { Loader } from './Loader';
import { IPhone, PhoneInput } from './PhoneInput';
import { Room, RoomHeader } from './Room';
import Sidebar from './Sidebar';
import { languages } from './utils';

enum CheckoutField {
  EMAIL = 'email',
  PHONE = 'phone',
  ROOMS = 'roomSummaries',
  LANGUAGE = 'language',
}

enum RoomField {
  ROOM_NAME = 'name',
  NAME = 'guestFirstName',
  SURNAME = 'guestLastName',
  BED = 'bedType',
  SPECIAL_REQUEST = 'specialRequest',
}

const defaultValues = { [CheckoutField.LANGUAGE]: 'en' };

type TRoom = {
  [RoomField.ROOM_NAME]: string; //only for rendering
  [RoomField.NAME]: string;
  [RoomField.SURNAME]: string;
  [RoomField.BED]?: BedType;
  [RoomField.SPECIAL_REQUEST]?: string;
};

const onlyLatinValidation = yup
  .string()
  .matches(/^[A-Za-z]+([\s-]*[A-Za-z]+)*$/, {
    message: 'Only latin letters are allowed',
    excludeEmptyString: true,
  });

const yupLatinValidation = onlyLatinValidation
  .required('Required')
  .max(255, 'Field must be below 255 symbols');

const phoneValidation = yup
  .object()
  .test(
    'format',
    'Incorrect phone',
    ({ isValid, phoneNumber }) => isValid || !phoneNumber
  )
  .test('notEmpty', 'Phone is required field', ({ phoneNumber }) =>
    Boolean(phoneNumber)
  );

const yupRoom = yup.object().shape({
  [RoomField.NAME]: yupLatinValidation,
  [RoomField.SURNAME]: yupLatinValidation,
  [RoomField.BED]: yup.number().nullable().default(0),
  [RoomField.SPECIAL_REQUEST]: yup
    .string()
    .nullable()
    .default(undefined)
    .notRequired()
    .max(1000, 'Special request must be below 1000 symbols'),
});

export type CheckoutFields = {
  [CheckoutField.EMAIL]: string;
  [CheckoutField.LANGUAGE]: string;
  [CheckoutField.PHONE]: IPhone;
  [CheckoutField.ROOMS]: TRoom[];
};

const validationSchema = yup.object().shape({
  [CheckoutField.EMAIL]: yupEmail.required('Email is required field'),
  [CheckoutField.LANGUAGE]: yup.string(),
  [CheckoutField.PHONE]: phoneValidation,
  [CheckoutField.ROOMS]: yup.array().of(yupRoom),
});

export type CheckoutProps = {
  roomRateId: string;
  cancellationPolicyRaw: string;
};

const Checkout: FC<CheckoutProps & { onClose: () => void }> = ({
  cancellationPolicyRaw,
  roomRateId,
  onClose,
}) => {
  const { data: oldConfig } = useConfiguration();
  const { data, isValidating } = useBookingSummary(roomRateId);

  const [bookFields, setBookFields] = useState<CheckoutFields>();

  const methods = useForm<CheckoutFields>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: CheckoutField.ROOMS,
  });

  const handleSubmit = (checkoutFields: CheckoutFields) =>
    _isEmpty(methods.formState.errors) ? setBookFields(checkoutFields) : null;

  useEffect(() => {
    if (data?.rooms) {
      methods.setValue(
        CheckoutField.ROOMS,
        data.rooms.map(({ name }, index) => ({
          ...fields[index],
          [RoomField.ROOM_NAME]: name ?? ' ',
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.rooms]);

  if (isValidating || fields?.length === 0 || !data) return <Loader />;

  return (
    <>
      <FormProvider {...methods}>
        <Grid container spacing={4} columns={9}>
          <Grid xs={5} item>
            <Stack spacing={4}>
              <RoomHeader
                title="Booking owner information"
                subTitle="Please give us information of the booking owner"
                text="Owner information"
              />

              <Grid item xs={16}>
                <SearchFormControl label="Email*">
                  <Controller
                    name={CheckoutField.EMAIL}
                    render={({ field, fieldState }) => (
                      <SearchPanelFilledInput
                        {...field}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        variant="filled"
                        fullWidth
                        placeholder="e.x. customer@example.com"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                </SearchFormControl>
              </Grid>

              <Grid item xs={16}>
                <SearchFormControl label="Phone number*">
                  <Controller
                    name={CheckoutField.PHONE}
                    render={({ field: { ref, ...field } }) => (
                      <PhoneInput {...field} />
                    )}
                  />
                </SearchFormControl>
              </Grid>

              <Grid item xs={16}>
                <SearchFormControl label="Emails language">
                  <Controller
                    name={CheckoutField.LANGUAGE}
                    render={({ field }) => (
                      <Select
                        {...field}
                        disableUnderline
                        IconComponent={ArrowsUpDown}
                      >
                        {languages.map((t) => (
                          <MenuItem value={t.code} key={t.code}>
                            {`${t.name} (${t.code})`}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </SearchFormControl>
              </Grid>

              {data?.rooms &&
                joinBy(
                  fields?.map((item, index) => (
                    <Room
                      title={`Room ${
                        fields.length === 1 ? '' : index + 1
                      } Name: ${item[RoomField.ROOM_NAME]}`}
                      key={item.id}
                    >
                      <Grid item xs={8}>
                        <SearchFormControl label="First Name*">
                          <Controller
                            name={`${CheckoutField.ROOMS}.${index}.${RoomField.NAME}`}
                            render={({ field, fieldState }) => (
                              <SearchPanelFilledInput
                                {...field}
                                error={Boolean(fieldState.error?.message)}
                                helperText={fieldState.error?.message}
                                variant="filled"
                                fullWidth
                                placeholder="e.x. John"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            )}
                          />
                        </SearchFormControl>
                      </Grid>

                      <Grid item xs={8}>
                        <SearchFormControl label="Last Name*">
                          <Controller
                            name={`${CheckoutField.ROOMS}.${index}.${RoomField.SURNAME}`}
                            render={({ field, fieldState }) => (
                              <SearchPanelFilledInput
                                {...field}
                                error={Boolean(fieldState.error?.message)}
                                helperText={fieldState.error?.message}
                                variant="filled"
                                fullWidth
                                placeholder="e.x. Doe"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            )}
                          />
                        </SearchFormControl>
                      </Grid>

                      <Grid item xs={16}>
                        <SearchFormControl label="Special Request (Optional)">
                          <Controller
                            name={`${CheckoutField.ROOMS}.${index}.${RoomField.SPECIAL_REQUEST}`}
                            render={({ field, fieldState }) => (
                              <SearchPanelFilledInput
                                {...field}
                                error={Boolean(fieldState.error?.message)}
                                helperText={fieldState.error?.message}
                                variant="filled"
                                fullWidth
                                multiline
                                placeholder="e.x. Room for non-smoking visitors"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            )}
                          />
                        </SearchFormControl>
                      </Grid>

                      <Grid item xs={16}>
                        <SearchFormControl label="Bed Preference">
                          <Controller
                            name={`${CheckoutField.ROOMS}.${index}.${RoomField.BED}`}
                            render={({ field }) => (
                              <Select
                                {...field}
                                displayEmpty
                                disableUnderline
                                IconComponent={ArrowsUpDown}
                              >
                                <MenuItem value={undefined} key="unset">
                                  Not selected
                                </MenuItem>
                                {filterBeds(
                                  oldConfig?.bedType?.filter(
                                    ({ name }) => name !== 'None'
                                  ) || []
                                ).map((t) => (
                                  <MenuItem value={t.id} key={t.name}>
                                    {t.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </SearchFormControl>
                      </Grid>
                    </Room>
                  )),
                  <Divider />
                )}
              {data.cancellationPolicy.dateFrom &&
                data.cancellationPolicy.refundability && (
                  <>
                    <Typography variant="h3">Cancellation Policy</Typography>
                    <Typography variant="body3">
                      <CancellationPolicyContent
                        refundability={data.cancellationPolicy.refundability}
                        dateFrom={data.cancellationPolicy.dateFrom}
                      />
                    </Typography>
                  </>
                )}
              {cancellationPolicyRaw && (
                <>
                  <Typography variant="h3">
                    Supplier Cancellation Policy
                  </Typography>
                  <Typography variant="body3">
                    <pre>
                      <MultilineCollapse
                        numberOfLinesToShow={6}
                        withMaxVisiblePartHeight={false}
                        text={
                          JSON.stringify(
                            JSON.parse(cancellationPolicyRaw),
                            null,
                            2
                          ) ?? ''
                        }
                      />
                    </pre>
                  </Typography>
                </>
              )}
              {data.cancellationPolicy.description && (
                <>
                  <Typography variant="h3">Booking Remarks</Typography>
                  <Typography variant="body3">
                    <MultilineCollapse
                      numberOfLinesToShow={6}
                      withMaxVisiblePartHeight={false}
                      text={data.cancellationPolicy.description ?? ''}
                    />
                  </Typography>
                </>
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!_isEmpty(bookFields)}
                onClick={methods.handleSubmit((v) => handleSubmit(v))}
              >
                {_isEmpty(bookFields) ? (
                  'Confirm Booking'
                ) : (
                  <CircularProgress size="1em" />
                )}
              </Button>
            </Stack>
          </Grid>

          <Grid xs={4} item>
            <Sidebar data={data} />
          </Grid>
        </Grid>
      </FormProvider>

      <BookModal
        onClose={onClose}
        roomRateId={roomRateId}
        checkoutFields={bookFields}
      />
    </>
  );
};

export default Checkout;
