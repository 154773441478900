import { Alert, Modal, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import { FC, ReactNode } from 'react';

interface ISimpleModal {
  title: string;
  children?: ReactNode;
  visible: boolean;
  onOk: (values: any) => Promise<any>;
  okText?: string;
  onCancel: () => void;
  cancelText?: string;
  initialValues?: any;
}

export const SimpleModal: FC<ISimpleModal> = ({
  title,
  children,
  visible,
  okText,
  onOk,
  cancelText,
  onCancel,
  initialValues = {},
}) => {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (response: any) => {
          setSubmitting(false);
          if (response.errors) {
            setStatus({
              commonApiErrors: response.errors.filter(
                ({ field }: any) => !field
              ),
              fieldApiErrors: _keyBy(
                response.errors.filter(({ field }: any) => field),
                'field'
              ),
            });
          }
        }
      );
    },
  });

  const { handleSubmit, isSubmitting, status } = formik;

  return (
    <FormikProvider value={formik}>
      <Modal
        destroyOnClose={true}
        maskClosable={false}
        title={title}
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText={okText || 'Ok'}
        cancelText={cancelText || 'Cancel'}
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <>{children}</>
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </Modal>
    </FormikProvider>
  );
};
