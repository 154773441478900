import { Route, Routes } from 'react-router-dom';
import { fetchBackofficeConfig } from 'src/store/config/reducer';
import { backofficeConfigFetchedSelector } from 'src/store/config/selectors';
import { BookingsList } from 'src/containers/Bookings/BookingsList';
import { Booking } from 'src/containers/Bookings/Booking';
import { Spin } from 'src/components/Spin';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, FC } from 'react';
import { ThunkDispatcher } from 'src/store';

export const Bookings: FC = () => {
  const dispatch = useDispatch<ThunkDispatcher>();
  const isConfigFetched = useSelector(backofficeConfigFetchedSelector);

  useEffect(() => {
    if (!isConfigFetched) dispatch(fetchBackofficeConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isConfigFetched) return <Spin size="large" />;

  return (
    <Routes>
      <Route path="/" element={<BookingsList />} />
      <Route path=":offerId" element={<Booking />} />
    </Routes>
  );
};
