import { Alert, Modal, notification } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import * as yup from 'yup';
import { PdfForm } from './PdfForm';
import styled from 'styled-components';
import { convertDateToIso } from 'src/modules/helpers';
import { AxiosError } from 'axios';
import { yupEmail, yupPhone } from 'src/modules/validation';
import { FC } from 'react';
import {
  MAX_CHILDREN,
  MAX_GUESTS,
} from 'src/containers/BookingEngine/Main/SearchPanel/choosers/RoomChooser/RoomChooserForm';

const StyledModal = styled(Modal)`
  display: block;
  width: 100% !important;
  top: 0px;
  text {
    display: block;
    margin-left: 25px;
  }
  h4 {
    margin-top: 10px;
  }
`;

const hotelInitialValue = {
  name: '',
  address: '',
  phone: null,
  email: null,
};

export const roomsPackageInitialValue = {
  title: '',
  amount: 0,
  isIncludedInPrice: false,
};

const extraChargesInitialValue = {
  currency: undefined,
  roomsPackageTaxAndFeeDto: [],
};

export const roomInitialValue = {
  key: '',
  residency: '',
  type: '',
  numberOfAdults: 2,
  numberOfChildren: 0,
  ageOfChildren: [],
  mainGuestName: '',
  specialRequests: '',
  included: {
    foodType: '',
    customValue: '',
  },
  bedType: {
    bedType: '',
    customValue: '',
  },
};

const pdfInitialValues = {
  bookingRemarks: '',
  bookingId: null,
  bookingReference: null,
  emailCulture: 'en',
  paymentConfirmationId: null,
  cancellationPolicy: '',
  arrivalDate: '',
  departureDate: '',
  hotelDetails: hotelInitialValue,
  extraCharges: extraChargesInitialValue,
  rooms: [roomInitialValue],
};

const validationSchema = yup.object().shape({
  hotelDetails: yup.object().shape({
    name: yup
      .string()
      .required('Required')
      .max(255, 'Value must be below 255 symbols'),
    address: yup
      .string()
      .required('Required')
      .max(255, 'Value must be below 255 symbols'),
    phone: yupPhone.notRequired(),
    email: yupEmail.notRequired(),
  }),
  bookingRemarks: yup.string().notRequired(),
  cancellationPolicy: yup.string().required('Required').nullable(),
  arrivalDate: yup.string().required('Required').nullable(),
  departureDate: yup.string().required('Required').nullable(),
  bookingId: yup.string().notRequired().nullable(),
  bookingReference: yup.string().notRequired().nullable(),
  rooms: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Required'),
      specialRequests: yup.string().notRequired(),
      residency: yup.string().required('Required'),
      numberOfAdults: yup
        .number()
        .typeError('you must specify a number')
        .min(1, 'Allow at least 1')
        .max(MAX_GUESTS, `Allow maximum ${MAX_GUESTS}`)
        .required('Required'),
      numberOfChildren: yup
        .number()
        .typeError('you must specify a number')
        .min(0, 'Allow at least 0')
        .max(MAX_CHILDREN, `Allow maximum ${MAX_CHILDREN}`)
        .required('Required'),
      ageOfChildren: yup.array().of(yup.number()),
      mainGuestName: yup.string().required('Required'),
      included: yup.object().shape({
        foodType: yup.number().required('Required'),
        customValue: yup.string().when('foodType', {
          is: (foodType: number) => foodType === 100,
          then: yup.string().required('Must enter custom value'),
        }),
      }),
      bedType: yup.object().shape({
        bedType: yup.number().notRequired(),
        customValue: yup.string().when('bedType', {
          is: (bedType: number) => bedType !== 100,
          then: yup.string().notRequired(),
          otherwise: yup.string().required('Must enter custom value'),
        }),
      }),
    })
  ),
  extraCharges: yup.object().shape({
    currency: yup.string().notRequired(),
    roomsPackageTaxAndFeeDto: yup.array().of(
      yup.object().shape({
        title: yup.string().notRequired(),
        amount: yup.number().notRequired(),
        isIncludedInPrice: yup.boolean().notRequired(),
      })
    ),
  }),
  emailCulture: yup.string().required('Required'),
  paymentConfirmationId: yup.string().notRequired().nullable(),
});

export type TVoucher = yup.InferType<typeof validationSchema>;

interface IPdfModal {
  visible: boolean;
  onOk: (values: TVoucher) => Promise<void | AxiosError<any>>;
  onCancel: () => void;
}

const formatPdfValues = (values: TVoucher) => {
  const arrivalDate = convertDateToIso(values.arrivalDate, true);
  const departureDate = convertDateToIso(values.departureDate, true);
  const cancellationPolicy = convertDateToIso(values.cancellationPolicy, false);

  const rooms = values.rooms?.map((room) =>
    room?.bedType?.bedType ? room : _omit(room, ['bedType'])
  );

  const extraCharges = values.extraCharges.currency
    ? values.extraCharges
    : null;

  const checkValue = (value?: string | null) => (value ? value : null);

  const phone = checkValue(values.hotelDetails.phone);
  const email = checkValue(values.hotelDetails.email);
  const hotelDetails = { ...values?.hotelDetails, phone, email };

  return {
    ...values,
    rooms,
    extraCharges,
    hotelDetails,
    cancellationPolicy,
    departureDate,
    arrivalDate,
  };
};

export const PdfModal: FC<IPdfModal> = ({ visible, onCancel, onOk }) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: pdfInitialValues,
    onSubmit: (values, { setSubmitting, setStatus, setFieldError }) => {
      setSubmitting(true);
      onOk(formatPdfValues(values as any) as any).then(
        () => setSubmitting(false),
        (error: AxiosError<any>) => {
          const { errors } = _get(error, 'response.data');
          if (_get(error, 'response.status') === 400 && errors) {
            for (const key in errors) {
              const msg = errors[key][0];
              if (msg) {
                notification.error({
                  message: 'Error',
                  description: key.split('.')[0] + ': ' + msg,
                });
                setFieldError(key.split('.')[0], msg);
                setStatus({
                  fieldApiErrors: { error: msg },
                });
              }
            }
          }
          setSubmitting(false);
        }
      );
    },
  });

  const { handleSubmit, status, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <StyledModal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title="Create Voucher"
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Create Voucher"
        cancelText="Dismiss"
      >
        <PdfForm {...formik} />
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </StyledModal>
    </FormikProvider>
  );
};
