import { Input, Select } from 'antd';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';

export const AddPaymentPayAtHotelForm = ({ currencies }: any) => (
  <StyledForm layout="horizontal">
    <FormikField
      key="type"
      label="Payment Type"
      name="type"
      FormComponent={Input}
      disabled={true}
    />
    <FormikField
      key="currency"
      label="Payment Currency"
      name="currency"
      FormComponent={Select}
      placeholder="Select"
      options={currencies}
    />
    <FormikField
      key="amount"
      label="Payment Amount"
      name="amount"
      FormComponent={Input}
    />
    <FormikField
      key="oldBookingId"
      label="Old Booking ID"
      name="oldBookingId"
      FormComponent={Input}
    />
  </StyledForm>
);
