import { useSelect } from 'downshift';
import { FC, PropsWithChildren, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import { CPopover } from 'src/components/common/CPopover';
import { POINTER_FINE } from 'src/configs/muiTheme';

import { SortingItem, SortingType } from './SortringSection';
import HeaderButton, { HeaderButtonProps } from './HeaderButton';

const selectMixin = css`
  background: ${({ theme }) => theme.palette.divider};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const StyledItem = styled.div<{ isSelected: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(5)};
  transition: ${({ theme }) =>
    theme.transitions.create(['background', 'color'])};
  background: ${({ theme }) => theme.palette.background.paper};
  cursor: pointer;
  ${({ theme }) => theme.typography.body4 as any}
  ${POINTER_FINE} {
    &:hover {
      ${selectMixin}
    }
  }
  ${({ isSelected }) => isSelected && selectMixin}
`;

const StyledPopper = styled(CPopover)`
  border-radius: 12px;
  padding: ${({ theme }) => theme.spacing(3)} 0;
`;

type Props = Omit<HeaderButtonProps, 'onChange'> & {
  options: SortingItem<false>[];
  value: SortingType;
  onChange: (value: SortingType) => void;
};

const ButtonSelect: FC<PropsWithChildren<Props>> = ({
  options,
  value,
  children,
  onChange,
  $selected: selected,
  ...rest
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } =
    useSelect({
      items: options.map((v) => v.value),
      selectedItem: value,
      onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem!),
    });

  const theme = useTheme();

  return (
    <>
      <HeaderButton
        $selected={selected}
        fullWidth
        {...rest}
        {...getToggleButtonProps({ ref: buttonRef })}
      >
        {children}
      </HeaderButton>
      <StyledPopper
        open={isOpen}
        anchorEl={buttonRef.current}
        {...getMenuProps({}, { suppressRefError: true })}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, parseInt(theme.spacing(1))],
            },
          },
        ]}
      >
        {options.map((item, index) => (
          <StyledItem
            key={index}
            {...getItemProps({
              index,
              item: item.value,
              isSelected: item.value === value,
            })}
          >
            {item.label}
          </StyledItem>
        ))}
      </StyledPopper>
    </>
  );
};

export default ButtonSelect;
