import InputLabel from '@mui/material/InputLabel';
import { FC } from 'react';
import IntlTelInput, { IntlTelInputProps } from 'react-intl-tel-input';
import styled, { css } from 'styled-components/macro';

const InputWrapper = styled.div<{ invalid?: boolean }>`
  .intl-tel-input {
    width: 100%;
  }

  .intl-tel-input input {
    width: 100%;
    height: 55px;
    border-radius: 12px;
    /* border: 1px solid ${({ theme }) => theme.custom.greyAlternativeColor};
    border-radius: ${({ theme }) => theme.custom.borderRadiusBase}; */
    font-size: ${({ theme }) => theme.custom.fontSizeBase};
    background: #f7f8fb;
    outline: none;
    border: none;
    /* &::placeholder {
      color: ${({ theme }) => theme.custom.grayTextColor};
    }

    &:hover {
      border-color: ${({ theme }) => theme.custom.grayTextColor};
    }

    &:focus {
      border-color: ${({ theme }) => theme.custom.primaryColor};
    } */
  }

  && {
    .intl-tel-input input {
      padding-left: 20px;

      ${({ theme, invalid }) =>
        invalid &&
        css`
          border-color: ${theme.custom.errorMessageColor};
        `};
    }

    .selected-flag {
      width: 70px;
      outline: none;
      border-radius: 12px;
    }

    .intl-tel-input input,
    .flag-container {
      padding-top: 20px;
      margin-left: 5px;
    }

    .flag-container:hover {
      .selected-flag {
        background-color: transparent;
      }
    }
  }

  .country-list {
    border: 1px solid ${({ theme }) => theme.custom.greyAlternativeColor};
    box-shadow: 5px 15px 30px rgba(29, 28, 75, 0.2);
    border-radius: 5px;
    max-height: 240px;
    background: ${({ theme }) => theme.custom.whiteMainColor};

    .country {
      padding: 12px 12px 12px 14px;
      font-size: ${({ theme }) => theme.custom.fontSizeBase};

      &:hover,
      &.highlight {
        background-color: ${({ theme }) => theme.custom.greySecondaryColor};
        outline: none;
      }
    }
  }
`;

export interface CustomPhoneInputProps extends IntlTelInputProps {
  className?: string;
  invalid?: boolean;
  formFieldLabel?: {
    id: string;
    description: string;
  };
}

export const CustomPhoneInput: FC<CustomPhoneInputProps> = ({
  className,
  formFieldLabel,
  invalid,
  ...rest
}) => (
  <InputWrapper className={className} invalid={invalid}>
    {formFieldLabel && (
      <InputLabel htmlFor={formFieldLabel.id}>
        {formFieldLabel.description}
      </InputLabel>
    )}
    <IntlTelInput {...rest} />
  </InputWrapper>
);
