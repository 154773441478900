import { useSearchContext } from 'src/containers/BookingEngine/Search/SearchContext';
import { apiCall } from 'src/modules/api';
import useSWR from 'swr';
import { Price } from './useHotels';
import { useSearchId } from './useSearchId';

export interface CancellationPolicy {
  dateFrom: string;
  description: string;
  refundability: number | string;
}

export interface TaxesAndFees {
  title: string | null;
  value: number;
  isIncludedInPrice: boolean;
}
export interface RoomRate {
  id: string;
  providerType: number;
  feedType: string;
  foodType: number;
  supplier: string;
  isGross: boolean;
  cancellationPolicy: CancellationPolicy;
  cancellationPolicyRaw?: string;
  taxesAndFees: TaxesAndFees[];
  sourcePrice?: Price;
  supplierRecommendedSellingPrice?: Price;
  rooms: { name: string; totalPrice: number }[];
}

interface RoomRatesResponse {
  rates: RoomRate[];
}

export const useRooms = () => {
  const { data } = useSearchId();

  const {
    searchValue: { hotelId },
  } = useSearchContext();

  return useSWR(
    data?.searchId && hotelId
      ? `api/booking-engine/search/${data.searchId}/hotels/${hotelId}/room-rates`
      : null,
    async (url) => {
      const res = await apiCall<RoomRatesResponse>(url);

      if (!Object.keys(res).length) {
        throw new Error();
      }

      return res;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );
};
