import { FC } from 'react';
import useSWR from 'swr';

import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Label } from './utils';
import { apiCall } from 'src/modules/api';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Modal, notification } from 'antd';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DEFAULT_ERROR_MSG,
  TECHNICAL_ERROR_MESSAGE,
} from 'src/modules/helpers';

type Props = {
  paymentId: string;
  open: boolean;
  onClose(): void;
};

const BlockModal: FC<Props> = ({ paymentId, open, onClose }) => {
  const { data } = useSWR<{
    customerEmail: string;
    customerIp: string;
    cardFingerprint: string;
    customerPhoneNumber: string;
  }>(open ? `api/fraud-guard/${paymentId}/info` : null, apiCall, {
    shouldRetryOnError: false,
    onError(err) {
      notification.error({
        message:
          err.response?.status !== 500
            ? err.response.data?.Message
            : TECHNICAL_ERROR_MESSAGE,
      });
    },
  });

  const methods = useForm({
    defaultValues: {
      addFingerprint: true,
      addEmail: true,
      addIp: true,
      addPhoneNumber: true,
    },
  });

  const values = methods.watch();

  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      title="Add to block list"
      open={open}
      onOk={methods.handleSubmit(async (data) => {
        try {
          notification.success({
            message: await apiCall(`/api/fraud-guard/${paymentId}/block`, {
              method: 'POST',
              data,
            }),
            duration: 6,
          });

          onClose();
        } catch (err: any) {
          notification.error({
            message:
              err.response.status === 422
                ? err.response.data
                : DEFAULT_ERROR_MSG,
            duration: 6,
          });
        }
      })}
      afterClose={() => {
        methods.reset();
      }}
      okButtonProps={{
        disabled:
          methods.formState.isSubmitting ||
          !data ||
          (!values.addEmail &&
            !values.addFingerprint &&
            !values.addPhoneNumber &&
            !values.addIp),
      }}
      onCancel={onClose}
      okText="Add"
      cancelText="Cancel"
    >
      <Typography variant="body2" marginBottom={4}>
        Adding a payment to a block list blocks all future charges that have the
        same characteristics. You should only add them to the block list if you
        believe this is not a legitimate customer.
      </Typography>
      <FormControl component="fieldset" variant="standard">
        <Typography variant="body2" component="legend" marginBottom={1}>
          Select characteristics you wish to block:
        </Typography>
        <FormProvider {...methods}>
          <Stack marginLeft={0.5} spacing={1}>
            <Controller
              name="addFingerprint"
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  name={field.name}
                  control={
                    <Checkbox ref={field.ref} color="secondary" disableRipple />
                  }
                  checked={field.value}
                  onChange={field.onChange}
                  label={
                    <Label
                      text="Card fingerprint"
                      value={
                        data ? (
                          data.cardFingerprint
                        ) : (
                          <CircularProgress size="1em" />
                        )
                      }
                    />
                  }
                />
              )}
            />
            <Controller
              name="addEmail"
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  control={
                    <Checkbox ref={field.ref} color="secondary" disableRipple />
                  }
                  name={field.name}
                  checked={field.value}
                  onChange={field.onChange}
                  label={
                    <Label
                      text="Email"
                      value={
                        data ? (
                          data.customerEmail
                        ) : (
                          <CircularProgress size="1em" />
                        )
                      }
                    />
                  }
                />
              )}
            />
            <Controller
              name="addIp"
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  control={
                    <Checkbox ref={field.ref} color="secondary" disableRipple />
                  }
                  name={field.name}
                  checked={field.value}
                  onChange={field.onChange}
                  label={
                    <Label
                      text="IP address"
                      value={
                        data ? data.customerIp : <CircularProgress size="1em" />
                      }
                    />
                  }
                />
              )}
            />
            <Controller
              name="addPhoneNumber"
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  control={
                    <Checkbox ref={field.ref} color="secondary" disableRipple />
                  }
                  name={field.name}
                  checked={field.value}
                  onChange={field.onChange}
                  label={
                    <Label
                      text="Phone number"
                      value={
                        data ? (
                          data.customerPhoneNumber
                        ) : (
                          <CircularProgress size="1em" />
                        )
                      }
                    />
                  }
                />
              )}
            />
          </Stack>
        </FormProvider>
      </FormControl>
    </Modal>
  );
};

export default BlockModal;
