import styled from 'styled-components';
import { Form } from 'antd';

export const StyledForm = styled(Form)`
  & .ant-select-selection--multiple {
    .ant-select-selection__choice {
      max-width: 40%;
    }
  }

  & .ant-form-item-with-help {
    margin-bottom: 0;
  }
`;
