import { alpha } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import CModal from 'src/components/common/CModal';
import { POINTER_FINE } from 'src/configs/muiTheme';
import AddressLink from 'src/containers/BookingEngine/common/AddressLink';
import { useBook } from 'src/hooks/swr/useBook';
import { useBookingSummary } from 'src/hooks/swr/useBookingSummary';
import { useConfigMap } from 'src/hooks/swr/useConfig';
import { useHotelContent } from 'src/hooks/swr/useHotelContent';
import { useFormatPrice } from 'src/hooks/useFormatCurrency';
import styled from 'styled-components';
import { CheckoutFields } from '..';
import HotelImage from '../../HotelImage';
import { PricePopOver } from '../../Table/TotalPrice/PricePopOver';
import { Row } from '../Row';
import { FailReason } from './FailReason';
import { VoucherButton } from './VoucherButton';

const Modal = styled(CModal)`
  max-width: 680px;
  max-height: 100vh;
  height: fit-content;
`;

const StyledPaper = styled(Paper).attrs({ elevation: 0 })`
  padding: 20px;
  background: #f7f8fb;
  border-radius: 12px;
`;

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.palette.divider};
  ${POINTER_FINE} {
    &:hover {
      background: ${({ theme }) => alpha(theme.palette.divider, 0.5)};
    }
  }
  @media (hover: none) {
    &:hover {
      background: ${({ theme }) => theme.palette.divider};
    }
  }
  color: ${({ theme }) => theme.palette.text.primary};
  > svg {
    font-size: 1em;
  }
`;

type TBookModal = {
  roomRateId: string;
  onClose: () => void;
  checkoutFields?: CheckoutFields;
};

export const BookModal: FC<TBookModal> = ({
  onClose,
  roomRateId,
  checkoutFields,
}) => {
  const formatPrice = useFormatPrice();
  const { data } = useBookingSummary(roomRateId);
  const { supplierMap, providerTypeMap } = useConfigMap();
  const { data: hotelData } = useHotelContent();
  const { data: bookData, mutate } = useBook({
    roomRateId,
    checkoutFields,
  });

  const resetBookingModal = () => {
    mutate(undefined, false);
    onClose();
  };

  if (!data || !bookData) return null;

  return (
    <Modal open={!_isEmpty(bookData)}>
      <Stack spacing={6}>
        <Typography variant="h1" align="center">
          {bookData?.isSuccess
            ? 'Booking completed successfully!'
            : 'Booking Failed'}
        </Typography>
        <StyledPaper>
          <Stack spacing={3}>
            <HotelImage />
            <AddressLink
              address={hotelData?.hotelContent?.address}
              name={hotelData?.hotelContent?.name}
            />
            {bookData.bookingNumber && (
              <Row title="Booking ID" value={bookData.bookingNumber} />
            )}

            <Row
              title="Provider"
              value={
                _get(
                  providerTypeMap[data.providerType],
                  'name',
                  data.providerType
                ) ?? bookData?.provider
              }
            />

            {data.supplier && (
              <Row
                title="Supplier"
                value={
                  _get(supplierMap[data.supplier], 'name', '') ??
                  bookData.supplier ??
                  data.supplier
                }
              />
            )}

            {bookData.supplierBookingReference && (
              <Row
                brakeValue
                title="Supplier Booking Reference"
                value={bookData.supplierBookingReference}
              />
            )}

            {bookData.searchId && (
              <Row title="SearchID" value={bookData.searchId} />
            )}

            {data?.sourcePrice && (
              <Row
                brakeValue
                title="Source Price in Euro"
                value={
                  <Typography variant="h2" color="secondary">
                    {formatPrice(data.sourcePrice.total, true)}
                    <PricePopOver
                      label="Price per night:"
                      price={formatPrice(data.sourcePrice.perNight, true)}
                    />
                  </Typography>
                }
              />
            )}

            {data?.supplierRecommendedSellingPrice && (
              <Row
                title="SRSP in Euro"
                value={
                  <Typography variant="h2" color="secondary">
                    {formatPrice(
                      data.supplierRecommendedSellingPrice.total,
                      true
                    )}
                    <PricePopOver
                      label="Price per night:"
                      price={formatPrice(
                        data.supplierRecommendedSellingPrice.perNight,
                        true
                      )}
                    />
                  </Typography>
                }
              />
            )}

            {bookData.failReason !== undefined &&
              bookData.isSuccess === false && (
                <FailReason
                  failReason={bookData.failReason}
                  errorMessage={bookData.errorMessage}
                  bookErrors={bookData.bookErrors}
                />
              )}
          </Stack>
        </StyledPaper>
        <Stack marginTop={4}>
          {bookData?.bookingId && bookData?.isSuccess ? (
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={16}>
                <VoucherButton
                  bookingId={bookData.bookingId}
                  fileName={`Booking voucher - ${
                    checkoutFields?.roomSummaries![0].guestFirstName
                  } ${checkoutFields?.roomSummaries![0].guestLastName}- ${
                    hotelData?.hotelContent.name
                  }.pdf`}
                />
              </Grid>

              <Grid item xs={8}>
                <Link to="/manual-bookings" target="_blank" color="black">
                  <StyledButton variant="contained" color="primary" fullWidth>
                    View in orders
                  </StyledButton>
                </Link>
              </Grid>
              <Grid item xs={8}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={resetBookingModal}
                >
                  Do Another Booking
                </StyledButton>
              </Grid>
            </Grid>
          ) : (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={resetBookingModal}
            >
              Back To Rooms
            </Button>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
