import { FC, useState } from 'react';
import {
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Dropdown,
  Button,
  Menu,
  notification,
  TablePaginationConfig,
} from 'antd';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPayments,
  cancelPayment,
  capturePayment,
  refundPayment,
  addPayment,
  processInvoice,
} from 'src/store/payments/reducer';
import { FiltersPanel } from 'src/components/FiltersPanel';
import { paymentsSelector } from 'src/store/payments/selectors';
import {
  currencyFormatter,
  capitalFirstLetter,
  isDescending,
} from 'src/modules/helpers';
import styled from 'styled-components';
import moment from 'moment';

import { ExpandedPaymentItem } from './compounds/ExpandedPaymentItem';
import {
  PAYMENT_STATUSES,
  PAYMENT_TYPES,
  PAYMENT_PROCESSORS,
} from 'src/constants';
import { RefundModal } from './compounds/RefundModal';
import _omit from 'lodash/omit';
import { BackofficeAPI } from 'src/modules/api';
import { AddPaymentModal } from './compounds/AddPaymentModal';
import { ProcessInvoiceModal } from './compounds/ProcessInvoiceModal';
import { mapPaymentListParams } from 'src/modules/apiMappers';
import { AxiosError, AxiosResponse } from 'axios';
import { StyledPageHeader } from 'src/components/StyledPageHeader';
import { StyledTable, Column } from 'src/components/StyledTable';
import { columns, useFilters, defaultPaginationConfig } from './helpers';
import { SimpleModal } from './compounds/ExpandedPaymentItem/PaymentModal';
import { IParams } from 'src/store/bookings/reducer';
import { ThunkDispatcher } from 'src/store';
import AllowModal from './FraudGuardModal/AllowModal';
import BlockModal from './FraudGuardModal/BlockModal';

enum Modals {
  NONE = 'none',
  ADD_PAYMENT = 'add',
  CANCEL_PAYMENT = 'cancel',
  REFUND_PAYMENT = 'refund',
  CAPTURE_PAYMENT = 'capture',
  PROCESS_PAYMENT = 'process',
  ADD_TO_BLOCK_LIST = 'add_to_block_list',
  ADD_TO_ALLOW_LIST = 'add_to_allow_list',
}

const StyledButton = styled(Button)`
  padding: 0 8px;
`;

export const InfoDescription = (
  <div>
    Export has been started. You can check the status of your file here:
    <StyledButton href="/export" type="link" size="small">
      go to exports
    </StyledButton>
  </div>
);

const showNotificationError = (error: AxiosError<any>) => {
  const errors = _get(error, 'response.data.errors', null);
  if (errors) {
    _forEach(errors, ({ msgs }: any) => {
      _forEach(msgs, ({ msg }: any) => {
        notification.error({
          message: 'Error',
          description: msg,
        });
      });
    });
  }
  return error;
};

const expandedRowRender = (item: any) => <ExpandedPaymentItem item={item} />;

const canCancel = (record: any) =>
  ((record.processor === PAYMENT_PROCESSORS.STRIPE ||
    record.processor === PAYMENT_PROCESSORS.QUICK_PAY) &&
    (record.type === PAYMENT_TYPES.PAY_AT_HOTEL_HOLD ||
      record.type === PAYMENT_TYPES.DEPOSIT) &&
    record.status === PAYMENT_STATUSES.UNCAPTURED) ||
  (record.type === PAYMENT_TYPES.INVOICE &&
    record.status === PAYMENT_STATUSES.PROCESSING) ||
  (record.type === PAYMENT_TYPES.INTERNAL_COST &&
    record.status === PAYMENT_STATUSES.SUCCESSFUL);

const canCapture = (record: any) =>
  (record.processor === PAYMENT_PROCESSORS.STRIPE ||
    record.processor === PAYMENT_PROCESSORS.QUICK_PAY) &&
  (record.type === PAYMENT_TYPES.DEPOSIT ||
    record.type === PAYMENT_TYPES.PAY_AT_HOTEL_HOLD) &&
  record.status === PAYMENT_STATUSES.UNCAPTURED;

const canRefund = (record: any) =>
  (record.processor === PAYMENT_PROCESSORS.STRIPE ||
    record.processor === PAYMENT_PROCESSORS.QUICK_PAY) &&
  (record.type === PAYMENT_TYPES.DEPOSIT ||
    record.type === PAYMENT_TYPES.PAY_AT_HOTEL_HOLD) &&
  (record.status === PAYMENT_STATUSES.SUCCESSFUL ||
    record.status === PAYMENT_STATUSES.PARTIALLY_REFUNDED);

const canProcess = (record: any) =>
  record.type === PAYMENT_TYPES.INVOICE &&
  record.status === PAYMENT_STATUSES.PROCESSING;

export const PaymentsList: FC = () => {
  const filters = useFilters();
  const dispatch = useDispatch<ThunkDispatcher>();
  const payments = useSelector(paymentsSelector);
  const { fetching, totalCount, searchParams } = useSelector(paymentsSelector);

  const defaultModalState = {
    visibility: Modals.NONE,
    type: Modals.NONE,
    modalData: {} as {
      paymentId: string;
      amount: string;
      currency: string;
      refundType: string;
      refundAmount: string;
      refundReason: string;
      comment: string;
      type: string;
    },
  };
  const [{ modalData, visibility }, setModalState] =
    useState(defaultModalState);
  const resetModalState = () => setModalState(defaultModalState);

  const pagination = {
    ...defaultPaginationConfig,
    current: searchParams.page,
    pageSize: searchParams.pageSize || defaultPaginationConfig.pageSize,
    total: totalCount,
    showTotal: (total: number) => `Total ${total} items`,
  } as TablePaginationConfig;

  const [exportState, setExportState] = useState({ loading: false });

  const handleCancelPayment = ({ paymentId }: any) =>
    dispatch(cancelPayment(paymentId))
      .then(resetModalState)
      .catch(showNotificationError);

  const handleCapturePayment = ({ paymentId }: any) =>
    dispatch(capturePayment(paymentId))
      .then(resetModalState)
      .catch(showNotificationError);

  const handleRefundPayment = (values: any) =>
    dispatch(refundPayment(modalData.paymentId, values))
      .then(resetModalState)
      .catch(showNotificationError);

  const handleAddPayment = (values: any) =>
    dispatch(addPayment(values))
      .then(resetModalState)
      .catch(showNotificationError);

  const handleProcessInvoice = (values: any) =>
    dispatch(processInvoice(modalData.paymentId, values))
      .then(resetModalState)
      .catch(showNotificationError);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: IParams,
    sorter: IParams,
    extra: IParams
  ) =>
    dispatch(
      fetchPayments(
        extra.action === 'sort'
          ? {
              ...searchParams,
              SortField: sorter.order && capitalFirstLetter(sorter.columnKey),
              IsSortingDescending: isDescending(sorter.order),
            }
          : {
              ...searchParams,
              page:
                pagination.pageSize !== searchParams?.pageSize
                  ? 1
                  : pagination.current,
              pageSize: pagination.pageSize,
            }
      )
    );

  const onFiltersChanged = (filters: any) =>
    dispatch(
      fetchPayments({
        ...filters,
        pageSize: searchParams.pageSize,
        page: 1,
      })
    );

  const handleActionsMenuClick = (record: any) => (e: any) =>
    setModalState({
      visibility: e.key,
      modalData: record,
      type: e.key,
    });

  const handleAddPaymentClick = (e: any) => {
    const modalData: any = { type: e.key };
    if (modalData.type === 'invoice') {
      modalData.date = moment();
    }

    setModalState({
      visibility: Modals.ADD_PAYMENT,
      modalData,
      type: Modals.ADD_PAYMENT,
    });
  };

  const handleExport = () => {
    setExportState({ loading: true });

    const params = mapPaymentListParams(
      _omit(searchParams, ['page', 'pageSize'])
    );

    let shouldOpenInNewTab = true;

    const wait = window.setTimeout(() => {
      notification.info({
        message: 'Info',
        description: InfoDescription,
      });

      shouldOpenInNewTab = false;
    }, 10000);

    BackofficeAPI.get('/Export/payments', {
      params,
    }).then(
      (response: AxiosResponse<any>) => {
        window.clearTimeout(wait);
        setExportState({ loading: false });

        if (shouldOpenInNewTab) {
          window.open(response.data.exportUrl, '_blank');
        }
      },
      () => {
        window.clearTimeout(wait);
        setExportState({ loading: false });
      }
    );
  };

  return (
    <Layout.Content>
      <StyledPageHeader
        title="Payments List"
        extra={
          <>
            <Button
              icon={<DownloadOutlined />}
              size="small"
              onClick={handleExport}
              loading={exportState.loading}
            />
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu
                  onClick={handleAddPaymentClick}
                  items={[
                    {
                      key: 'deposit',
                      label: 'Deposit',
                    },
                    {
                      key: 'invoice',
                      label: 'Invoice',
                    },
                    {
                      key: 'internalCost',
                      label: 'Internal Cost',
                    },
                    {
                      key: 'payAtHotel',
                      label: 'Pay At Hotel',
                    },
                  ]}
                />
              }
            >
              <Button icon={<PlusOutlined />} size="small" />
            </Dropdown>
          </>
        }
      >
        <FiltersPanel
          searchParams={searchParams}
          config={filters}
          onChange={onFiltersChanged}
        />
      </StyledPageHeader>
      <StyledTable
        pagination={pagination}
        rowKey="paymentId"
        loading={fetching}
        dataSource={payments.items}
        onChange={handleTableChange}
        expandedRowRender={expandedRowRender}
        bordered={true}
        tableLayout="fixed"
      >
        {columns.map((column: any) => (
          <Column key={column.key} {...column} />
        ))}
        <Column
          title="Action"
          width={110}
          align="center"
          render={(value, record: any) => {
            const items = [];
            if (canProcess(record)) {
              items.push({
                key: Modals.PROCESS_PAYMENT,
                label: 'Process',
              });
            }

            if (canCapture(record)) {
              items.push({
                key: Modals.CAPTURE_PAYMENT,
                label: 'Capture',
              });
            }

            if (canCancel(record)) {
              items.push({ key: Modals.CANCEL_PAYMENT, label: 'Cancel' });
            }

            if (canRefund(record)) {
              items.push({ key: Modals.REFUND_PAYMENT, label: 'Refund' });
            }

            return items.length ? (
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu
                    onClick={handleActionsMenuClick(record)}
                    items={items}
                  />
                }
              >
                <Button size="small">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            ) : null;
          }}
        />
      </StyledTable>
      <AddPaymentModal
        visible={visibility === Modals.ADD_PAYMENT}
        onOk={handleAddPayment}
        initialValues={modalData}
        onCancel={resetModalState}
      />
      <RefundModal
        visible={visibility === Modals.REFUND_PAYMENT}
        onOk={handleRefundPayment}
        initialValues={modalData}
        onCancel={resetModalState}
      />
      {visibility === Modals.PROCESS_PAYMENT && (
        <ProcessInvoiceModal
          visible={visibility === Modals.PROCESS_PAYMENT}
          onOk={handleProcessInvoice}
          initialValues={modalData}
          onCancel={resetModalState}
        />
      )}
      <SimpleModal
        visible={visibility === Modals.CAPTURE_PAYMENT}
        onOk={() => handleCapturePayment(modalData)}
        onCancel={resetModalState}
        title="Capture Payment"
        children={`You are attempting to capture the ${modalData.type} payment
      of ${currencyFormatter(modalData.amount)} ${
          modalData.currency
        }. Continue capturing?`}
      />
      <SimpleModal
        visible={visibility === Modals.CANCEL_PAYMENT}
        onOk={() => handleCancelPayment(modalData)}
        onCancel={resetModalState}
        title="Cancel Payment"
        children={`You are attempting to cancel the ${modalData.type} payment
      of ${currencyFormatter(modalData.amount)} ${
          modalData.currency
        }. Continue cancelling?`}
      />
      <AllowModal
        open={visibility === Modals.ADD_TO_ALLOW_LIST}
        onClose={resetModalState}
        {...modalData}
      />
      <BlockModal
        open={visibility === Modals.ADD_TO_BLOCK_LIST}
        onClose={resetModalState}
        {...modalData}
      />
    </Layout.Content>
  );
};
