import Skeleton from '@mui/material/Skeleton';
import { forwardRef } from 'react';
import styled from 'styled-components/macro';

import { EllipsisTypography } from 'src/components/common/EllipsisTypography';

const AutocompleteVariant = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

type VariantProps<T> = {
  item?: T;
};

export const GooglePredictionVariant = forwardRef<
  HTMLDivElement,
  VariantProps<google.maps.places.AutocompletePrediction>
>(({ item, ...rest }, ref) => (
  <AutocompleteVariant {...rest} ref={ref}>
    <EllipsisTypography variant="label1">
      {item ? item.description : <Skeleton variant="text" />}
    </EllipsisTypography>
  </AutocompleteVariant>
));
