import { useCallback, useState } from 'react';

export const useToggle = (
  defaultState: boolean
): [boolean, () => void, () => void, () => void] => {
  const [toggledOn, setToggledOn] = useState(defaultState);
  const toggle = useCallback(() => setToggledOn((current) => !current), []);
  const hide = useCallback(() => setToggledOn(false), []);
  const show = useCallback(() => setToggledOn(true), []);

  return [toggledOn, toggle, hide, show];
};
