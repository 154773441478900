import { stringifyUrl } from 'query-string';
import { manualBookingPath } from 'src/containers/Bookings/api';
import { Status } from 'src/containers/Bookings/BookingsList/compounds/BookingStatus';
import { withFilters } from 'src/containers/Bookings/ManualList/table';
import { apiCall } from 'src/modules/api';
import { IParams } from 'src/store/bookings/reducer';
import useSWRInfinite from 'swr/infinite';

interface CancellationPolicy {
  price: number;
  fee: number;
  dateFrom: string;
  dateTo: string;
  description: string;
  refundability: string | number;
  sellingPrice: number;
}

export interface ManualBooking {
  status: string | Status;
  createDate: string;
  id: string;
  bookingNumber: number;
  customerFirstName: string;
  customerLastName: string;
  checkinDate: string;
  checkoutDate: string;
  providerType: number;
  supplierName: string;
  supplierBookingId: string;
  supplierBookingReference: string;
  sourcePrice: number;
  salePrice: number | null;
  checkStatus: number | string;
  commentField: string | null;
  rawCancellationPolicy: string | null;
  cancellationPolicy: CancellationPolicy;
  bookingCurrency: string;
  customerCurrency: string;
  exchangeRate: number;
}

export const useManualBookings = (params: IParams) =>
  useSWRInfinite(
    () => (params?.page ? withParams(params) : null),
    async (url: string) => {
      let res = await apiCall<{ items: ManualBooking[] }>(url);

      if (!Object.keys(res).length) {
        throw new Error();
      }

      return res;
    },
    {
      revalidateAll: false,
      revalidateFirstPage: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

const withParams = ({
  page,
  pageSize,
  IsSortingDescending,
  SortField,
  ...rest
}: IParams) => {
  const filters = withFilters(rest);
  return stringifyUrl({
    url: `api/backoffice${manualBookingPath}/list`,
    query: {
      'Pagination.Page': page,
      'Pagination.PageSize': pageSize,
      ...(SortField
        ? {
            'Sorting.Field': SortField,
            'Sorting.IsSortingDescending': IsSortingDescending,
          }
        : {
            'Sorting.Field': 'CreationDate',
            'Sorting.IsSortingDescending': true,
          }),
      ...filters,
    },
  });
};
