import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, notification } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { PaginationConfig } from 'antd/lib/pagination';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import _omit from 'lodash/omit';
import { stringifyUrl } from 'query-string';
import { useState } from 'react';
import { FiltersPanel } from 'src/components/FiltersPanel';
import { StyledPageHeader } from 'src/components/StyledPageHeader';
import { Column, StyledTable } from 'src/components/StyledTable';
import { InfoDescription } from 'src/containers/PaymentsList';
import {
  ManualBooking,
  useManualBookings,
} from 'src/hooks/swr/useManualBookings';
import { BackofficeAPI } from 'src/modules/api';
import { mapManualListParams } from 'src/modules/apiMappers';
import { isDescending } from 'src/modules/helpers';
import { IParams } from 'src/store/bookings/reducer';
import {
  cancelManualBooking,
  changeManualCheckStatus,
  deleteManualComment,
  handleDownloadManualVoucher,
  updateManualComment,
} from '../api';
import { showNotificationError } from '../BookingsList';
import { Status } from '../BookingsList/compounds/BookingStatus';
import {
  CancelBooking,
  CancelModal,
  STATUSES_TO_CANCEL,
} from '../BookingsList/compounds/CancelModal';
import {
  CheckStatus,
  IOfferChangeStatus,
} from '../BookingsList/compounds/CheckStatus';
import { TextArea } from '../BookingsList/compounds/TextArea';
import {
  BModal,
  BModals,
  defaultBModalState,
  MModal,
  MModals,
  defaultMModalState,
  STATUSES_TO_SHOW_VOUCHER,
  STATUSES_TO_EDIT,
} from '../helpers';
import EditModal from './EditModal';
import { ExpandedItem } from './ExpandedItem';
import { useColumns, useFilters } from './table';

type MutateList = {
  id: string;
  payload: Partial<ManualBooking>;
};

const defaultPaginationConfig: PaginationConfig = {
  pageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '25', '50'],
};

const trimAll = <T extends object>(obj: T) =>
  (Object.keys(obj) as Array<keyof T>).reduce(
    (acc, key) =>
      typeof obj[key] == 'string'
        ? {
            ...acc,
            [key]: (obj[key] as string).trim(),
          }
        : acc,
    obj
  );

export const ManualBookingsList = () => {
  const columns = useColumns();
  const filters = useFilters();

  const [exportState, setExportState] = useState({ loading: false });
  const [{ visibility, modalData }, setModalState] =
    useState<BModal>(defaultBModalState);

  const resetModalState = () =>
    setModalState({ modalData, visibility: BModals.NONE });

  const [{ visible, mData }, setMData] = useState<MModal>(defaultMModalState);

  const resetMData = () => setMData({ mData, visible: MModals.NONE });

  const [searchParams, setSearchParams] = useState<IParams>(
    defaultPaginationConfig
  );

  const {
    mutate,
    isValidating,
    data: bookingsData,
  } = useManualBookings(searchParams);

  const data = bookingsData?.map((i) => i.items);
  //@ts-expect-error
  const bookings = data ? [].concat(...data) : [];

  const mutateList = ({ id, payload }: MutateList) =>
    mutate(
      (prevData) =>
        prevData?.map((i) => ({
          ...i,
          items: i.items?.map((item) =>
            item.id === id ? { ...item, ...payload } : item
          ),
        })),
      false
    );

  const handleChangeCheckStatus = ({
    offerId: id,
    checkStatus,
  }: IOfferChangeStatus) =>
    changeManualCheckStatus({ id, checkStatus })
      .then(() =>
        mutateList({
          id,
          payload: { checkStatus },
        })
      )
      .catch(showNotificationError);

  const handleUpdateComment = (id: string, commentField: string | null) =>
    updateManualComment({ id, commentField })
      .then(() =>
        mutateList({
          id,
          payload: { commentField },
        })
      )
      .catch(showNotificationError);

  const handleDeleteComment = (id: string) =>
    deleteManualComment({ id })
      .then(() =>
        mutateList({
          id,
          payload: { commentField: null },
        })
      )
      .catch(showNotificationError);

  const handleCancelBooking = ({
    offerId: id,
    comment: commentField,
  }: CancelBooking) =>
    cancelManualBooking({ id, commentField })
      .then(() => {
        resetModalState();
        mutateList({
          id,
          payload: {
            commentField,
            status: Status.CANCELLED,
          },
        });
      })
      .catch(showNotificationError);

  const handleExport = () => {
    setExportState({ loading: true });

    let shouldOpenInNewTab = true;

    const wait = window.setTimeout(() => {
      notification.info({
        message: 'Info',
        description: InfoDescription,
      });
      shouldOpenInNewTab = false;
    }, 10000);

    BackofficeAPI.get(
      stringifyUrl({
        url: '/Export/manualBookings',
        query: mapManualListParams(_omit(searchParams, ['page', 'pageSize'])),
      })
    ).then(
      (response: AxiosResponse<any, { exportUrl: string }>) => {
        window.clearTimeout(wait);
        setExportState({ loading: false });

        if (shouldOpenInNewTab) {
          window.open(response.data.exportUrl, '_blank');
        }
      },
      () => {
        window.clearTimeout(wait);
        setExportState({ loading: false });
      }
    );
  };

  const pagination = {
    ...defaultPaginationConfig,
    current: searchParams.page,
    pageSize: searchParams.pageSize,
    total:
      bookings.length < searchParams.pageSize
        ? searchParams.page * searchParams.pageSize
        : (searchParams.page + 1) * searchParams.pageSize,
  } as TablePaginationConfig;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: IParams,
    sorter: IParams,
    extra: IParams
  ) =>
    setSearchParams(
      extra.action === 'sort'
        ? {
            ...searchParams,
            SortField: sorter.order ? sorter.columnKey : 'CreationDate',
            IsSortingDescending: isDescending(sorter.order),
            page: 1,
          }
        : {
            ...searchParams,
            page: pagination.current,
            current: pagination.current,
            pageSize: pagination.pageSize,
          }
    );

  //for compability between filters and api
  const onFiltersChanged = async ({
    HasSpecialRequest,
    NoSalePrice,
    ...params
  }: IParams) =>
    setSearchParams({
      ...trimAll(params),
      ...(NoSalePrice && { NoSalePrice }),
      ...(HasSpecialRequest && { HasSpecialRequest }),
      pageSize: searchParams.pageSize,
      page: 1,
    });

  return (
    <Layout.Content>
      <StyledPageHeader
        title="Manual Bookings List"
        extra={
          <Button
            icon={<DownloadOutlined />}
            size="small"
            onClick={handleExport}
            loading={exportState.loading}
          />
        }
      >
        <FiltersPanel
          config={filters}
          searchParams={searchParams}
          onChange={onFiltersChanged}
        />
      </StyledPageHeader>
      <StyledTable
        bordered
        rowKey="id"
        tableLayout="fixed"
        loading={isValidating}
        dataSource={bookings}
        pagination={pagination}
        onChange={handleTableChange}
        sortDirections={['ascend', 'descend']}
        expandedRowRender={ExpandedItem as any}
      >
        {columns?.map((column: ColumnProps<ManualBooking>) => (
          <Column key={column.key} {...column} />
        ))}
        <Column
          key="checkStatus"
          title="Check Status"
          width={85}
          align="center"
          render={({ id, checkStatus }: ManualBooking) => (
            <CheckStatus
              offerId={id}
              isManualBookings
              checkStatus={checkStatus as string}
              changeStatus={handleChangeCheckStatus}
            />
          )}
        />
        <Column
          title="Comment"
          width={280}
          align="center"
          key="comment"
          render={(props) => (
            <TextArea
              deleteItemId={props.bookingNumber}
              deleteItemText="comment"
              placeholder="Add a comment..."
              rows={2}
              text={props.commentField}
              offerId={props.id}
              handleUpdate={handleUpdateComment}
              handleDelete={handleDeleteComment}
            />
          )}
        />
        <Column
          title="Action"
          width={70}
          align="center"
          key="action"
          render={(value, record: ManualBooking) => {
            const items = [] as ItemType[];
            const addItem = (
              key: BModals | MModals,
              label: string,
              onClick: () => void
            ) =>
              items.push({
                key,
                label: <div children={label} onClick={onClick} />,
              });

            if (STATUSES_TO_CANCEL.includes(record.status as Status)) {
              addItem(BModals.CANCEL, 'Cancel', () =>
                setModalState({
                  visibility: BModals.CANCEL,
                  modalData: {
                    ...record,
                    comment: record.commentField,
                    offerId: record.id,
                    newBookingId: String(record.bookingNumber),
                    cancellationPolicyRaw: record.rawCancellationPolicy,
                  },
                } as any)
              );
            }

            if (STATUSES_TO_EDIT.includes(record.status as Status)) {
              addItem(MModals.EDIT, 'Edit', () =>
                setMData({
                  visible: MModals.EDIT,
                  mData: record,
                })
              );
            }

            if (STATUSES_TO_SHOW_VOUCHER.includes(record.status as Status)) {
              addItem(BModals.SEND_VOUCHER, 'Download Voucher', () =>
                handleDownloadManualVoucher({
                  id: record?.id,
                  fileName: `Booking voucher - ${record?.customerFirstName} ${record?.customerLastName}.pdf`,
                })
              );
            }

            return items.length === 0 ? null : (
              <Dropdown trigger={['click']} overlay={<Menu items={items} />}>
                <Button size="small">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            );
          }}
        />
      </StyledTable>
      <CancelModal
        visible={visibility === BModals.CANCEL}
        onOk={handleCancelBooking}
        initialValues={modalData}
        onCancel={resetModalState}
      />
      <EditModal
        visible={visible === MModals.EDIT}
        mData={mData}
        onCancel={resetMData}
        mutateList={mutate}
      />
    </Layout.Content>
  );
};
