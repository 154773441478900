import { PageHeader } from 'antd';
import styled from 'styled-components';

export const StyledPageHeader = styled(PageHeader)`
  &.ant-page-header {
    background-color: #fafafa;
    padding: 0px 20px;
    .ant-page-header-heading {
      display: flex;
      justify-content: space-between;
      .ant-page-header-heading-extra {
        position: relative;
        top: 0;
      }
    }
    .ant-page-header-content {
      padding-top: unset;
    }
  }
`;
