import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import { FC, PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import { SearchFormControl } from '../components/FilledInput';
import { useConfig } from 'src/hooks/swr/useConfig';
import ArrowsUpDown from 'src/icons/ArrowsUpDown';
import {
  Fields,
  FieldsName,
  SearchPanelInputProps,
} from '../../SearchPanelForm';

type Props = SearchPanelInputProps;

export const ProviderChooser: FC<PropsWithChildren<Props>> = (props) => {
  const { data: config } = useConfig();

  const options = config?.providers || [];

  return (
    <SearchFormControl label={FieldsName.PROVIDER} {...props}>
      <Controller<Fields, FieldsName.PROVIDER>
        name={FieldsName.PROVIDER}
        render={({ field, fieldState }) => (
          <>
            <Select
              {...field}
              onChange={(e) => {
                const { value } = e.target;

                field.onChange(
                  value[value.length - 1] === 'all'
                    ? value.length - 1 === options.length
                      ? []
                      : options.map((item) => item.id)
                    : e
                );
              }}
              multiple
              fullWidth
              disableUnderline
              IconComponent={ArrowsUpDown}
              error={Boolean(fieldState.error?.message)}
              renderValue={(selected) =>
                options.length > 0 && selected.length === options.length
                  ? 'All'
                  : selected
                      .map((id) => options.find((t) => t.id === id)?.name)
                      .join(', ') || 'Select some options'
              }
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      options.length > 0 &&
                      field.value.length === options.length
                    }
                    indeterminate={
                      field.value.length > 0 &&
                      field.value.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <ListItemIcon>
                    <Checkbox checked={field.value.includes(option.id)} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
            {fieldState.error ? (
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            ) : null}
          </>
        )}
      />
    </SearchFormControl>
  );
};
