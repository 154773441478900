import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useHotelContent } from 'src/hooks/swr/useHotelContent';
import { AboutHotel } from './common/AboutHotel';
import { HotelFacilities } from './common/HotelFacilities';
import { HotelLink } from './common/HotelLink';
import { RoomsTable } from './common/Table';
import { StyledScrollableContainer } from '../..';
import Box from '@mui/material/Box';

export const RoomsSection: FC = () => {
  const { data } = useHotelContent();

  if (!data?.hotelContent) return null;

  return (
    <>
      <Box padding={'8px 16px'} position="sticky" top={0}>
        <HotelLink />
      </Box>
      <StyledScrollableContainer>
        <Grid container padding={2} spacing={4}>
          <Grid item xs={16}>
            <RoomsTable />
          </Grid>
          <Grid item xs={11}>
            <AboutHotel />
          </Grid>
          <Grid item xs={5}>
            <HotelFacilities />
          </Grid>
        </Grid>
      </StyledScrollableContainer>
    </>
  );
};
