import { createSelector } from 'reselect';
import _keyBy from 'lodash/keyBy';
import _isNumber from 'lodash/isNumber';
import { ReduxState } from 'src/store';
import { IOption } from './reducer';

//hardcoded renaming in FE, Rename it ASAP after BE will be ready
export const filterBeds = (options: IOption[]) =>
  options.map(({ name, ...rest }: IOption) => ({
    ...rest,
    name: _isNumber(name)
      ? name
      : name
          .replace('None', 'Unknown')
          .replace('Full', 'A Double Bed')
          .replace('Twins', 'Two Twin Beds'),
  }));
//hardcoded renaming in FE, Rename it ASAP after BE will be ready
const PROVIDERS_TO_SHOW = [1, 2, 4, 9, 32];
const filterProviders = (options: IOption[]) =>
  options
    .filter(({ id }) => PROVIDERS_TO_SHOW.includes(Number(id)))
    ?.map(({ name, ...rest }: IOption) => ({
      ...rest,
      name: _isNumber(name)
        ? name
        : name
            .replace('Hotel Beds', 'Direct HotelBeds')
            .replace('Ostrovok', 'Direct RateHawk'),
    }));

const inventoryConfigSelector = (state: ReduxState) => state.config.inventory;

const backofficeConfigSelector = (state: ReduxState) => state.config.backoffice;

export const inventoryConfigFetchedSelector = createSelector(
  inventoryConfigSelector,
  ({ fetched }) => fetched
);

export const backofficeConfigFetchedSelector = createSelector(
  backofficeConfigSelector,
  ({ fetched }) => fetched
);

// Inventory API

export const countriesSelector = createSelector(
  inventoryConfigSelector,
  ({ countries }) => countries
);

export const hotelFacilitiesSelector = createSelector(
  inventoryConfigSelector,
  ({ hotelFacilities }) => hotelFacilities
);

export const roomFacilitiesSelector = createSelector(
  inventoryConfigSelector,
  ({ roomFacilities }) => roomFacilities
);

export const hotelStatusesSelector = createSelector(
  inventoryConfigSelector,
  ({ statuses }) => statuses
);

export const hotelsProvidersSelector = createSelector(
  inventoryConfigSelector,
  ({ hotelProviders }) => hotelProviders
);

export const roomsProvidersSelector = createSelector(
  inventoryConfigSelector,
  ({ roomProviders }) => roomProviders
);

export const suitableTypesSelector = createSelector(
  inventoryConfigSelector,
  ({ suitableTypes }) => suitableTypes
);

export const countriesMapSelector = createSelector(
  countriesSelector,
  (countries) => _keyBy(countries, 'code')
);

export const statusesMapSelector = createSelector(
  hotelStatusesSelector,
  (statuses) => _keyBy(statuses, 'id')
);

export const descriptionLanguagesSelector = createSelector(
  inventoryConfigSelector,
  ({ descriptionLanguages }) => descriptionLanguages
);

export const sourcesSelector = createSelector(
  inventoryConfigSelector,
  ({ sources }) => sources
);

export const sourcesMapSelector = createSelector(sourcesSelector, (sources) =>
  _keyBy(sources, 'id')
);

export const externalFacilityTypesSelector = createSelector(
  inventoryConfigSelector,
  ({ facilityTypes }) => facilityTypes
);

export const externalFacilityTypesMapSelector = createSelector(
  externalFacilityTypesSelector,
  (externalFacilityTypes) => _keyBy(externalFacilityTypes, 'id')
);

// BackOffice API

export const transactionStatusesSelector = createSelector(
  backofficeConfigSelector,
  ({ transactionStatus }) => transactionStatus
);

export const paymentStatusesSelector = createSelector(
  backofficeConfigSelector,
  ({ paymentStatus }) => paymentStatus
);

export const paymentProcessorsSelector = createSelector(
  backofficeConfigSelector,
  ({ paymentProcessor }) => paymentProcessor
);

export const paymentMethodsSelector = createSelector(
  backofficeConfigSelector,
  ({ paymentMethod = [] }) => paymentMethod
);

export const blockchainsSelector = createSelector(
  backofficeConfigSelector,
  ({ blockchain = [] }) => blockchain
);

export const offerStatusesSelector = createSelector(
  backofficeConfigSelector,
  ({ offerStatus }) => offerStatus
);

export const paymentTypesSelector = createSelector(
  backofficeConfigSelector,
  ({ paymentType }) => paymentType
);

export const currenciesSelector = createSelector(
  backofficeConfigSelector,
  ({ currencies }) => currencies
);

export const currenciesMapSelector = createSelector(
  currenciesSelector,
  (currencies) => _keyBy(currencies, 'id')
);

export const offerCheckStatusesSelector = createSelector(
  backofficeConfigSelector,
  ({ offerCheckStatus }) => offerCheckStatus
);

export const offerCheckStatusesMapSelector = createSelector(
  offerCheckStatusesSelector,
  (offerCheckStatus) => _keyBy(offerCheckStatus, 'id')
);

export const offerManualCheckStatusesMapSelector = createSelector(
  offerCheckStatusesSelector,
  (offerCheckStatus) =>
    _keyBy(
      offerCheckStatus.map((i) => ({
        ...i,
        name: i.name === 'Fraud' ? 'Fraud/Test' : i.name,
      })),
      'id'
    )
);

export const bookingSuppliersSelector = createSelector(
  backofficeConfigSelector,
  ({ suppliers }) => suppliers
);

export const bookingSourcesSelector = createSelector(
  backofficeConfigSelector,
  ({ bookingSource }) => bookingSource
);

export const providerTypesSelector = createSelector(
  backofficeConfigSelector,
  ({ providerType }) => filterProviders(providerType)
);

export const providerTypesMapSelector = createSelector(
  providerTypesSelector,
  (providerTypes) => _keyBy(providerTypes, 'id')
);

export const countryCodesSelector = createSelector(
  backofficeConfigSelector,
  ({ countries }) =>
    countries?.map(({ code, name }) => ({
      id: code,
      name,
    }))
);

export const countryCodesMapSelector = createSelector(
  countryCodesSelector,
  (countries) => _keyBy(countries, 'id')
);

export const pdfBedTypesSelector = createSelector(
  backofficeConfigSelector,
  ({ pdfBedType }) => filterBeds(pdfBedType)
);

export const bedTypesSelector = createSelector(
  backofficeConfigSelector,
  ({ bedType }) => filterBeds(bedType)
);

export const pdfFoodTypesSelector = createSelector(
  backofficeConfigSelector,
  ({ pdfFoodType }) => pdfFoodType
);

export const foodTypesSelector = createSelector(
  backofficeConfigSelector,
  ({ foodType }) => foodType
);

export const bookingSuppliersMapSelector = createSelector(
  bookingSuppliersSelector,
  (suppliers) => _keyBy(suppliers, 'id')
);

export const refundReasonsSelector = createSelector(
  backofficeConfigSelector,
  ({ refundReason }) => refundReason
);

export const languagesSelector = createSelector(
  backofficeConfigSelector,
  ({ languages }) => languages
);

export const refundReasonsMapSelector = createSelector(
  refundReasonsSelector,
  (refundReasons) => _keyBy(refundReasons, 'id')
);

export const paymentTypesMapSelector = createSelector(
  paymentTypesSelector,
  (paymentTypes) => _keyBy(paymentTypes, 'id')
);

export const paymentStatusesMapSelector = createSelector(
  paymentStatusesSelector,
  (paymentStatuses) => _keyBy(paymentStatuses, 'id')
);

export const transactionStatusesMapSelector = createSelector(
  transactionStatusesSelector,
  (transactionStatuses) => _keyBy(transactionStatuses, 'id')
);

export const paymentProcessorsMapSelector = createSelector(
  paymentProcessorsSelector,
  (paymentProcessors) => _keyBy(paymentProcessors, 'id')
);

export const paymentMethodsMapSelector = createSelector(
  paymentMethodsSelector,
  (paymentMethod) => _keyBy(paymentMethod, 'id')
);

export const blockchainsMapSelector = createSelector(
  blockchainsSelector,
  (blockchain) => _keyBy(blockchain, 'id')
);

export const offerStatusesMapSelector = createSelector(
  offerStatusesSelector,
  (offerStatuses) => _keyBy(offerStatuses, 'id')
);
