import { Alert, Modal, notification, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import _get from 'lodash/get';
import * as yup from 'yup';
import { Form } from './Form';
import styled from 'styled-components';
import { IBooking } from 'src/containers/Bookings/Booking';
import { AxiosError } from 'axios';
import { yupEmail, yupPhone } from 'src/modules/validation';
import { FC } from 'react';

const StyledModal = styled(Modal)`
  p {
    margin: 0px;
  }
  h5 {
    display: block;
    margin-left: 25px;
  }
  h4 {
    margin-top: 10px;
  }
`;

export interface ICustomerForm {
  customerEmail: string;
  customerPhone: string;
}

const validationSchema: yup.SchemaOf<ICustomerForm> = yup.object().shape({
  customerEmail: yupEmail.required('Email is required field'),
  customerPhone: yupPhone.required('Phone is required field'),
});

interface ICustomerModal {
  visible: boolean;
  initialValues: IBooking;
  onOk: (values: ICustomerForm) => Promise<void>;
  onCancel: () => void;
}

export const CustomerModal: FC<ICustomerModal> = ({
  visible,
  initialValues: { customerEmail, customerPhone },
  onOk,
  onCancel,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: { customerEmail, customerPhone },
    onSubmit: (values, { setSubmitting, setFieldError, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (error: AxiosError<any>) => {
          const { errors } = _get(error, 'response.data');
          if (_get(error, 'response.status') === 400 && errors) {
            for (const key in errors) {
              const msg = errors[key][0];
              if (msg) {
                errors[key].map((message: string) =>
                  notification.error({
                    message: 'Error',
                    description: key + ': ' + message,
                  })
                );
                setFieldError(key, msg);
                setStatus({
                  fieldApiErrors: { error: msg },
                });
              }
            }
          }
          setSubmitting(false);
        }
      );
    },
  });

  const { status, isSubmitting, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <StyledModal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title="Edit Customer Info"
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Confirm"
        cancelText="Dismiss"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <Form />
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </StyledModal>
    </FormikProvider>
  );
};
