import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Alert, DatePicker, Input, Modal, Select, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { paymentProcessorsSelector } from 'src/store/config/selectors';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import moment from 'moment';
import * as yup from 'yup';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import { AxiosError } from 'axios';

const validationSchema = yup.object().shape({
  date: yup.lazy(() =>
    yup
      .date()
      .typeError('Invalid date')
      .required('Required')
      .max(new Date(), 'Date can not be in future')
      .min(
        yup.ref('createdDateTime'),
        () => 'Date should be the same as Payment Date or later'
      )
  ),
  paymentProcessor: yup.string().required('Required'),
  externalId: yup.string().required('Required'),
  comment: yup.string().required('Required'),
});

interface IProcessInvoiceModal {
  visible: boolean;
  initialValues: any;
  onOk: (
    values: yup.InferType<typeof validationSchema>
  ) => Promise<void | AxiosError<any>>;
  onCancel: () => void;
}

const ProcessInvoiceForm = () => {
  const paymentProcessors = useSelector(paymentProcessorsSelector);
  return (
    <StyledForm layout="horizontal">
      <FormikField
        key="date"
        label="Date"
        name="date"
        FormComponent={DatePicker}
        showTime={true}
        placeholder="Select Time"
      />
      <FormikField
        key="currency"
        label="Payment Currency"
        name="currency"
        FormComponent={Input}
        disabled={true}
      />
      <FormikField
        key="amount"
        label="Payment Amount"
        name="amount"
        FormComponent={Input}
        disabled={true}
      />
      <FormikField
        key="paymentProcessor"
        label="Processor"
        name="paymentProcessor"
        FormComponent={Select}
        placeholder="Select"
        options={paymentProcessors}
      />
      <FormikField
        key="externalId"
        label="Transaction External ID"
        name="externalId"
        FormComponent={Input}
      />
      <FormikField
        key="comment"
        label="Comment"
        name="comment"
        FormComponent={Input.TextArea}
      />
    </StyledForm>
  );
};

export const ProcessInvoiceModal: FC<IProcessInvoiceModal> = ({
  visible,
  initialValues,
  onCancel,
  onOk,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      date: moment(),
      currency: '',
      amount: '',
      paymentProcessor: undefined,
      externalId: '',
      ...initialValues,
      createdDateTime: new Date(initialValues.createdDateTime),
      comment: '',
    },
    onSubmit: (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (response: any) => {
          setSubmitting(false);
          if (response.errors) {
            setStatus({
              commonApiErrors: response.errors.filter(
                ({ field }: any) => !field
              ),
              fieldApiErrors: _keyBy(
                response.errors.filter(({ field }: any) => field),
                'field'
              ),
            });
          }
        }
      );
    },
  });

  const { handleSubmit, status, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Modal
        destroyOnClose={true}
        maskClosable={false}
        title="Process Invoice "
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Process"
        cancelText="Cancel"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <ProcessInvoiceForm />
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </Modal>
    </FormikProvider>
  );
};
