import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { differenceInDays, format, parseISO } from 'date-fns';
import debounce from 'lodash/debounce';
import { FC, ReactNode, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { getCountryLabel } from 'src/containers/BookingEngine/Main/SearchPanel/choosers/ResidencyChooser';
import { URL_QUERY_PARAMS } from 'src/containers/BookingEngine/Main/SearchPanel/SearchForm';
import {
  Child,
  Room,
} from 'src/containers/BookingEngine/Main/SearchPanel/SearchPanelForm';
import { useConfig } from 'src/hooks/swr/useConfig';
import { RatesFilters, RATES_QUERY_PARAMS } from 'src/hooks/swr/useHotels';
import styled from 'styled-components';
import { useQueryParams } from 'use-query-params';

const getRoomsValues = (rooms: Room[]) =>
  rooms.reduce(
    (res, room) => ({
      ...res,
      adults: res.adults + room.adults,
      children: res.children + room?.children?.length,
      childrenAges: res.childrenAges.concat(room?.children),
    }),
    { rooms: rooms.length, adults: 0, children: 0, childrenAges: [] as Child[] }
  );

export const getDate = (date: string) => format(parseISO(date), 'dd MMM yyyy');

const SoldOutCheckBox: FC = () => {
  const [params, setParams] = useQueryParams(RATES_QUERY_PARAMS);

  const methods = useForm({
    defaultValues: params,
  });
  const { watch, handleSubmit } = methods;

  useEffect(() => {
    const fn = handleSubmit((data) => setParams(data));

    const debouncedFn = debounce(fn, 400);

    const t = watch((_, { name }) => {
      if (name === RatesFilters.HOTEL_NAME) {
        void debouncedFn();
      } else {
        void fn();
      }
    });

    return () => t.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <FormProvider {...methods}>
      <Controller
        name={RatesFilters.SHOW_SOLD_OUT}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={field.value}
                onChange={field.onChange}
              />
            }
            label={
              <Typography variant="body2" color="textSecondary">
                Show sold out
              </Typography>
            }
          />
        )}
      />
    </FormProvider>
  );
};

const StyledFieldContainer = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background: #f7f8fb;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Field: FC<{ label: ReactNode; value: string }> = ({ label, value }) => (
  <StyledFieldContainer>
    <Typography variant="body3">{label}</Typography>
    <StyledTypography variant="body4" overflow="hidden" title={value}>
      {value}
    </StyledTypography>
  </StyledFieldContainer>
);

const StyledContainer = styled.div`
  margin: 8px;
`;

export const SideBar: FC = () => {
  const { data: config } = useConfig();

  const [searchParams] = useQueryParams(URL_QUERY_PARAMS);

  const dataArray = [];

  if (searchParams.dates && searchParams?.dates[0] && searchParams?.dates[1]) {
    dataArray.push(
      {
        label: 'Dates',
        value:
          getDate(searchParams?.dates[0]) +
          ' - ' +
          getDate(searchParams?.dates[1]),
      },
      {
        label: 'Nights',
        value: differenceInDays(
          parseISO(searchParams?.dates[1]),
          parseISO(searchParams?.dates[0])
        ),
      }
    );
  }

  if (searchParams?.rooms) {
    const { rooms, adults, children, childrenAges } = getRoomsValues(
      searchParams?.rooms
    );
    dataArray.push(
      {
        label: 'Rooms',
        value: rooms,
      },
      {
        label: 'Guests',
        value: `${adults} adults ${
          children ? '+ ' + children + ' children' : ''
        }`,
      }
    );
    if (children > 0) {
      childrenAges.map(({ age }, index) =>
        dataArray.push({
          label: `Child age ${index + 1}`,
          value:
            age === 0
              ? 'Less than 1 year'
              : `${age} ${age === 1 ? 'year' : 'years'}`,
        })
      );
    }
  }

  if (searchParams?.destination) {
    dataArray.push({
      label: 'Destination',
      value: searchParams?.destination?.description || '',
    });
  }

  if (searchParams?.radius) {
    dataArray.push({
      label: 'Radius (km)',
      value: searchParams?.radius || '',
    });
  }

  if (searchParams?.residency && config?.countries) {
    dataArray.push({
      label: 'Residency',
      value: getCountryLabel(
        config?.countries?.find(({ code }) => code === searchParams?.residency)
          ?.name ?? '',
        searchParams?.residency
      ),
    });
  }

  if (searchParams?.propertyTypes && config?.propertyTypes) {
    dataArray.push({
      label: 'Property Type',
      value:
        config?.propertyTypes?.length === searchParams?.propertyTypes?.length
          ? 'All'
          : config?.propertyTypes
              ?.filter((x) =>
                searchParams?.propertyTypes?.map((i) => i).includes(x.id)
              )
              .map((i) => i.name)
              .join(', '),
    });
  }

  if (searchParams?.provider && config?.providers) {
    const value =
      searchParams?.provider?.length === config?.providers?.length
        ? 'All'
        : config?.providers
            ?.filter((x) =>
              searchParams?.provider?.map((i) => i).includes(x.id)
            )
            .map((i) => i.name)
            .join(', ');

    dataArray.push({
      label: 'Provider',
      value,
    });
  }

  if (searchParams?.feedType && config?.feedTypes) {
    dataArray.push({
      label: 'Feed Type',
      value: config?.feedTypes?.find(
        (type) => type.id === searchParams?.feedType
      )?.name,
    });
  }

  return (
    <StyledContainer>
      <Grid spacing={2} container alignItems="center">
        {dataArray.map((i) => (
          <Grid key={i.label} item>
            <Field {...i} />
          </Grid>
        ))}
        <Grid item>
          <SoldOutCheckBox />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
