import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SearchForm } from 'src/containers/BookingEngine/Main/SearchPanel/SearchForm';

export const Main: FC = () => (
  <Stack spacing={1} alignItems="center" marginTop={4}>
    <Typography variant="h4">Fill-up the form</Typography>
    <Typography variant="h5" color="#525252">
      To make a search provide according Trip information
    </Typography>
    <SearchForm />
  </Stack>
);
