import { handleActions } from 'redux-actions';
import { createAsyncAction } from 'typesafe-actions';

interface ConfigSuccess {
  fetching: boolean;
  fetched: boolean;
  items: [];
  searchParams: {
    [propName: string]: any;
  };
}

export interface InternalFacilitiesState {
  fetching: boolean;
  fetched: boolean;
  items: [];
  searchParams: {
    [propName: string]: any;
  };
}

const fetchInternalFacilitiesActions = createAsyncAction(
  'FETCH_INTERNAL_FACILITIES_REQUEST',
  'FETCH_INTERNAL_FACILITIES_SUCCESS',
  'FETCH_INTERNAL_FACILITIES_FAILURE'
)<undefined, ConfigSuccess, any>();

const defaultState: InternalFacilitiesState = {
  fetching: false,
  fetched: false,
  items: [],
  searchParams: {},
};

export const internalFacilitiesReducer: any =
  handleActions<InternalFacilitiesState>(
    {
      [fetchInternalFacilitiesActions.request.toString()]: (state) => ({
        ...state,
        fetching: true,
        fetched: false,
        items: [],
      }),
      [fetchInternalFacilitiesActions.success.toString()]: (
        state,
        { payload }
      ) => ({
        ...state,
        ...payload,
      }),
      [fetchInternalFacilitiesActions.failure.toString()]: () => ({
        ...defaultState,
      }),
    },
    defaultState
  );
