import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { notification } from 'antd';
import { getTokenRef } from '../providers/AuthProvider';

const serialize = (params: any) =>
  qs.stringify(params, {
    filter: (prefix, value) => {
      if (_isArray(value) && !_isEmpty(value)) {
        return value.join(',');
      }
      if (value === '') {
        return;
      }
      return value;
    },
  });

const commonConfig: AxiosRequestConfig = {
  responseType: 'json',
  paramsSerializer: { serialize },
  baseURL: '/',
};

const authRequestInterceptor = async (options: AxiosRequestConfig) => ({
  ...options,
  headers: {
    ...options.headers,
    Authorization: `Bearer ${await getTokenRef[0](new Date())}`,
  },
});

const errorResponseInterceptor = (error: AxiosError<any>) => {
  if (_get(error, 'response.status') === 500) {
    notification.error({
      message: 'Error',
      description: _get(error, 'response.data.message', ''),
    });
  }

  if (_get(error, 'response.status') === 400) {
    notification.error({
      message: 'Error',
      description: _get(error, 'response.data.title', ''),
    });
  }

  return Promise.reject(error);
};

export const AdminAPI = axios.create({
  ...commonConfig,
  baseURL: '/api',
});

AdminAPI.interceptors.request.use(authRequestInterceptor, (error: AxiosError) =>
  Promise.reject(error)
);

AdminAPI.interceptors.response.use(
  (response: AxiosResponse<any>) => response,
  errorResponseInterceptor
);

export const HotelsAPI = axios.create({
  ...commonConfig,
  baseURL: '/api/inventory/v1',
});

HotelsAPI.interceptors.request.use(
  authRequestInterceptor,
  (error: AxiosError) => Promise.reject(error)
);

HotelsAPI.interceptors.response.use(
  (response: AxiosResponse<any>) => response,
  errorResponseInterceptor
);

export const BackofficeAPI = axios.create({
  ...commonConfig,
  baseURL: '/api/backoffice',
});

BackofficeAPI.interceptors.request.use(
  authRequestInterceptor,
  (error: AxiosError) => Promise.reject(error)
);

BackofficeAPI.interceptors.response.use(
  (response: AxiosResponse<any>) => response,
  errorResponseInterceptor
);

export const NewAPI = axios.create(commonConfig);

NewAPI.interceptors.response.use(
  (response: AxiosResponse<any>) => response,
  errorResponseInterceptor
);

export const apiCall = async <T = unknown>(
  url: string,
  config: AxiosRequestConfig = {},
  skipAuth?: boolean
) =>
  (
    await NewAPI.request<T>({
      url,
      ...config,
      headers: {
        contentType: 'application/json',
        ...(skipAuth
          ? {}
          : { Authorization: `Bearer ${await getTokenRef[0](new Date())}` }),
        ...config.headers,
      },
    })
  ).data;
