import startOfDay from 'date-fns/startOfDay';
import { FC } from 'react';

import { HorizontalCalendar } from './HorizontalCalendar';
import addDays from 'date-fns/addDays';

export enum EDateSelection {
  START,
  END,
}

export type DateRange = [start: Date | null, end: Date | null];

export type DateProps = {
  value: DateRange;
  onChange: (value: DateRange) => void;
};

export type CalendarProps = {
  value: DateRange;
  onChange: (value: Date) => void;
};

type Props = DateProps & {
  dateSelection: EDateSelection;
  setDateSelection: (v: EDateSelection) => void;
};

export const Calendar: FC<Props> = ({
  value,
  onChange,
  dateSelection,
  setDateSelection,
}) => {
  let handleChange: (value: Date) => void;

  const [start, end] = value;

  switch (dateSelection) {
    case EDateSelection.START:
      handleChange = (value) => {
        onChange([value, end && value < end ? end : null]);

        setDateSelection(EDateSelection.END);
      };

      break;

    case EDateSelection.END:
      handleChange = (value) => {
        if (
          !start ||
          value <= start ||
          (end && +value === +end) ||
          start < addDays(startOfDay(new Date()), -1)
        ) {
          onChange([value, null]);
        } else {
          onChange([start, value]);

          setDateSelection(EDateSelection.START);
        }
      };

      break;
  }

  return <HorizontalCalendar onChange={handleChange} value={value} />;
};
