import { createAsyncAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';
import { BackofficeAPI, HotelsAPI } from 'src/modules/api';

enum ExportStatus {
  Pending = 0,
  Success = 1,
  Fail = 2,
}

export interface IOption {
  id: string | number;
  name: string;
}

interface ISupplier extends IOption {
  backofficeLink: string;
}
interface ConfigSuccess {
  inventory?: {
    fetching: boolean;
    fetched: boolean;
    countries: [];
    hotelFacilities: [];
    roomFacilities: [];
    statuses: [];
    suppliers: [];
    descriptionLanguages: [];
    externalFacilityTypes: [];
  };
  backoffice?: {
    fetching: boolean;
    fetched: boolean;
  };
}

interface ICountryCode {
  code: string;
  name: string;
}

export interface ConfigState {
  [x: string]: any;
  inventory: {
    fetching: boolean;
    fetched: boolean;
    countries: IOption[];
    hotelFacilities: IOption[];
    roomFacilities: IOption[];
    statuses: IOption[];
    descriptionLanguages: [];
    facilityTypes: IOption[];
    sources: IOption[];
    hotelProviders: IOption[];
    roomProviders: IOption[];
    suitableTypes: IOption[];
  };
  backoffice: {
    fetching: boolean;
    fetched: boolean;
    currencies: IOption[];
    offerStatus: IOption[];
    offerCheckStatus: IOption[];
    paymentMethod: IOption[];
    paymentProcessor: IOption[];
    paymentStatus: IOption[];
    paymentType: IOption[];
    transactionStatus: IOption[];
    transactionType: IOption[];
    suppliers: ISupplier[];
    refundReason: IOption[];
    bedType: IOption[];
    bookingSource: IOption[];
    providerType: IOption[];
    countries: ICountryCode[];
    pdfFoodType: IOption[];
    pdfBedType: IOption[];
    foodType: IOption[];
    languages: IOption[];
    exportStatus: { id: number; name: ExportStatus }[];
  };
}

const fetchConfigActions = createAsyncAction(
  'FETCH_CONFIG_REQUEST',
  'FETCH_CONFIG_SUCCESS',
  'FETCH_CONFIG_FAILURE'
)<undefined, ConfigSuccess, any>();

export const fetchBackofficeConfig = () => (dispatch: any) => {
  dispatch(fetchConfigActions.request());
  BackofficeAPI.get('/configurations').then(
    (configurations: AxiosResponse<any>) =>
      dispatch(
        fetchConfigActions.success({
          backoffice: {
            fetching: false,
            fetched: true,
            ...configurations.data,
          },
        })
      ),
    (error: AxiosError) => dispatch(fetchConfigActions.failure(error))
  );
};

export const fetchInventoryConfig = () => (dispatch: any) => {
  dispatch(fetchConfigActions.request());
  HotelsAPI.get('/common/configuration').then(
    (configurations: AxiosResponse<any>) =>
      dispatch(
        fetchConfigActions.success({
          inventory: {
            fetching: false,
            fetched: true,
            ...configurations.data,
          },
        })
      ),
    (error: AxiosError) => dispatch(fetchConfigActions.failure(error))
  );
};

const defaultState: ConfigState = {
  inventory: {
    fetching: false,
    fetched: false,
    countries: [],
    hotelFacilities: [],
    roomFacilities: [],
    statuses: [],
    descriptionLanguages: [],
    facilityTypes: [],
    sources: [],
    hotelProviders: [],
    roomProviders: [],
    suitableTypes: [],
  },
  backoffice: {
    fetching: false,
    fetched: false,
    currencies: [],
    bedType: [],
    bookingSource: [],
    offerStatus: [],
    offerCheckStatus: [],
    paymentMethod: [],
    paymentProcessor: [],
    paymentStatus: [],
    paymentType: [],
    transactionStatus: [],
    transactionType: [],
    suppliers: [],
    refundReason: [],
    exportStatus: [],
    providerType: [],
    countries: [],
    pdfFoodType: [],
    pdfBedType: [],
    foodType: [],
    languages: [],
  },
};

export const configReducer: any = handleActions<ConfigState>(
  {
    [fetchConfigActions.success.toString()]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchConfigActions.failure.toString()]: () => ({
      ...defaultState,
    }),
  },
  defaultState
);
