import { Alert, Input, Modal, Spin } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import * as yup from 'yup';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { AxiosError } from 'axios';
import { FC } from 'react';

interface AddBookingValues {
  oldBookingId: string;
}

const validationSchema = yup.object().shape({
  oldBookingId: yup.string().required('Required'),
});

interface IAddBookingModal {
  visible: boolean;
  onOk: (values: AddBookingValues) => Promise<void | AxiosError<any>>;
  onCancel: () => void;
}

const AddBookingForm = () => (
  <StyledForm layout="horizontal">
    <FormikField
      key="oldBookingId"
      label="Old Booking ID"
      name="oldBookingId"
      FormComponent={Input}
    />
  </StyledForm>
);

export const AddBookingModal: FC<IAddBookingModal> = ({
  visible,
  onCancel,
  onOk,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      oldBookingId: '',
    },
    onSubmit: (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onOk(values).then(
        () => setSubmitting(false),
        (response: any) => {
          if (response.errors) {
            setStatus({
              commonApiErrors: response.errors.filter(
                ({ field }: any) => !field
              ),
              fieldApiErrors: _keyBy(
                response.errors.filter(({ field }: any) => field),
                'field'
              ),
            });
          }
          setSubmitting(false);
        }
      );
    },
  });

  const { handleSubmit, status, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Modal
        destroyOnClose={true}
        maskClosable={false}
        title="Add Booking"
        open={visible}
        onOk={handleSubmit as any}
        okButtonProps={{
          disabled: isSubmitting,
        }}
        onCancel={onCancel}
        okText="Add"
        cancelText="Cancel"
      >
        {isSubmitting ? (
          <Spin
            size="large"
            style={{ display: 'block', textAlign: 'center' }}
          />
        ) : (
          <AddBookingForm />
        )}
        {_get(status, 'commonApiErrors.length', null)
          ? status.commonApiErrors
              .reduce((acc: any, error: any) => [...acc, ...error.msgs], [])
              .map(({ msg }: any) => (
                <Alert key={msg} message={msg} type="error" />
              ))
          : null}
      </Modal>
    </FormikProvider>
  );
};
