import styled from 'styled-components/macro';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Label = styled.span`
  margin-right: 8px;
`;

export const Value = styled.span``;

export const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.custom.blackMainColor};
`;

export const StyledRow = styled(Row)`
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  color: ${({ theme }) => theme.custom.blackMainColor};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Icon = styled(InfoOutlinedIcon)`
  width: 13px;
  height: 13px;
  margin-left: 1px;
  color: #1948f0;
  cursor: pointer;
`;

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    padding: 14,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #EEEFF2',
    boxShadow: '0px -3px 57px rgba(0, 0, 0, 0.05)',
    borderRadius: 12,
  },
}));
