import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AxiosResponse } from 'axios';
import { memo, useEffect, useState } from 'react';
import { IBooking } from 'src/containers/Bookings/Booking';
import { ITransaction } from 'src/containers/Bookings/types';
import { BackofficeAPI } from 'src/modules/api';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  .ant-table {
    margin: 0 !important;
  }

  & .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 3px;
  }
  & .ant-table-title {
    display: flex;

    .ant-btn {
      margin-left: auto;
    }
  }
`;

const transactionsColumns: ColumnProps<any>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 350,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 120,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
  },
  {
    title: 'Currency',
    key: 'currency',
    render: ({ currency }) => currency?.toUpperCase(),
    width: 70,
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
  },
];

const defaultTransactionsState = {
  loading: false,
  dataSource: [] as ITransaction[],
  error: null,
};

export const Transactions = memo(({ offerId }: IBooking) => {
  const [itemState, setItemState] = useState(defaultTransactionsState);

  const handleFetchTransactions = () => {
    setItemState({
      ...defaultTransactionsState,
      loading: true,
    });
    return BackofficeAPI.get(`/Booking/${offerId}/transactions`).then(
      ({ data }: AxiosResponse<any, ITransaction[]>) =>
        setItemState({
          ...defaultTransactionsState,
          dataSource: data.map((i: ITransaction) => ({
            ...i,
          })),
        }),
      (error) => setItemState({ ...defaultTransactionsState, error })
    );
  };

  useEffect(() => {
    if (offerId) handleFetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  return (
    <StyledTable
      rowKey="id"
      columns={transactionsColumns}
      pagination={false}
      bordered={true}
      {...itemState}
    />
  );
});
