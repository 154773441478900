import _merge from 'lodash/merge';
import _get from 'lodash/get';

declare const window: Window & {
  APP: {
    config: any;
  };
};

/**
 * All default configuration variables should be placed here
 */
// const defaultConfig = {
//   target: 'https://admin-qa.clicktrip.dev/',
//   ENV: 'local',
//   AUTH_TENANT: 'a93a031a-c2a6-41b5-b9f6-29727088dbf0',
//   AUTH_CLIENT_ID: 'afa1123e-5ecf-4bda-be7b-ae94bf31a12f',
//   AUTH_LOGIN_RESOURCE: 'afa1123e-5ecf-4bda-be7b-ae94bf31a12f',
//   GOOGLE_API_KEY: 'AIzaSyAZxz5P9mM4ARZQY_B8NGJkssUr-Eb93Q0',
//   AUTH_REDIRECT_URI: window.location.origin,
// };

const defaultConfig = {
  target: 'https://admin-dbe.clicktrip.dev/',
  ENV: 'local',
  AUTH_TENANT: 'a93a031a-c2a6-41b5-b9f6-29727088dbf0',
  AUTH_CLIENT_ID: 'd6f384d8-5117-4006-adeb-0b0101688558',
  AUTH_LOGIN_RESOURCE: 'd6f384d8-5117-4006-adeb-0b0101688558',
  GOOGLE_API_KEY: 'AIzaSyBFY8CS3ZCUZZhT7fmTfCEzXlg7GkgnCps',
  AUTH_REDIRECT_URI: window.location.origin,
};

let effectiveConfig: any = null;

/**
 * Resolves configuration variables
 */
export const appConfig = (name: string) => {
  if (!effectiveConfig) {
    effectiveConfig = _merge({}, defaultConfig, window.APP.config);
  }
  return _get(effectiveConfig, name);
};
