import { Input } from 'antd';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';

export const AddPaymentInternalCostForm = () => (
  <StyledForm layout="horizontal">
    <FormikField
      key="type"
      label="Payment Type"
      name="type"
      FormComponent={Input}
      disabled={true}
    />
    <FormikField
      key="oldBookingId"
      label="Old Booking ID"
      name="oldBookingId"
      FormComponent={Input}
    />
  </StyledForm>
);
