import { Button, Input, Layout, notification } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'src/components/Spin';
import { StyledPageHeader } from 'src/components/StyledPageHeader';
import { fetchBackofficeConfig } from 'src/store/config/reducer';
import { backofficeConfigFetchedSelector } from 'src/store/config/selectors';
import * as yup from 'yup';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import { ThunkDispatcher } from 'src/store';
import { updateHotelSettings } from './api';
import { Controller, useForm } from 'react-hook-form';
import { apiCall } from 'src/modules/api';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { stringifyUrl } from 'query-string';
import { AxiosError } from 'axios';
import { ArgsProps } from 'antd/lib/notification';
import Divider from '@mui/material/Divider';
import { DEFAULT_ERROR_MSG } from 'src/modules/helpers';
import CompileButton from './CompileButton';

export type TValidationSchema = yup.InferType<typeof validationSchema>;

const getError = (error: AxiosError): string => {
  const resp = error.response;

  if (resp) {
    const data: any = resp.data;

    if (data) {
      if (typeof data === 'string') {
        return data;
      }

      return `${data.title}${
        data.errors ? `\n${Object.values(data.errors).join(', ')}` : ''
      }`;
    }

    return resp.statusText;
  }

  return error.message;
};

const handleError = (error: AxiosError): ArgsProps => {
  return { message: getError(error) || DEFAULT_ERROR_MSG, duration: 6 };
};

export const Temporary: FC = () => {
  const dispatch = useDispatch<ThunkDispatcher>();
  const isConfigFetched = useSelector(backofficeConfigFetchedSelector);

  useEffect(() => {
    if (!isConfigFetched) dispatch(fetchBackofficeConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isConfigFetched) return <Spin size="large" />;

  return (
    <Layout.Content>
      <StyledPageHeader title="Temporary">
        <Stack spacing={5}>
          <Form />
          <Divider />
          <VipForm />
          <Divider />
          <BookingLinkForm />
          <Divider />
          <div>
            <CompileButton />
          </div>
        </Stack>
      </StyledPageHeader>
    </Layout.Content>
  );
};

type VipFields = {
  email: string;
  markup: number;
};

const vipValidation = yup.object().shape({
  markup: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Markup is required')
    .min(1, 'The minimum value is 1')
    .max(50, 'The maximum value is 50')
    .integer('Markup is required'),
  email: yup
    .string()
    .min(5)
    .max(318)
    .email('Wrong format')
    .required('Email is required'),
});

const VipForm: FC = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm<VipFields>({
    defaultValues: { email: '', markup: 5 },
    resolver: yupResolver(vipValidation) as any,
  });

  return (
    <div>
      <Typography variant="h4" fontWeight={700} marginBottom={3}>
        Vip control:
      </Typography>
      <Stack direction="row" spacing={2} marginBottom={2} width="50vw">
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              error={!!error}
              color="secondary"
              placeholder="Email"
              type="email"
              fullWidth
              label="Email"
              inputRef={ref}
              helperText={error && error.message}
              {...field}
              disabled={isSubmitting}
              required
            />
          )}
        />
        <Controller
          name="markup"
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              required
              error={!!error}
              helperText={error && error.message}
              color="secondary"
              placeholder="Markup"
              type="number"
              inputMode="numeric"
              variant="outlined"
              fullWidth
              {...field}
              inputRef={ref}
              label="Markup"
              disabled={isSubmitting}
              inputProps={{ min: 1, max: 50, step: 1 }}
            />
          )}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          type="primary"
          onClick={handleSubmit(async (data) => {
            try {
              notification.success({
                message: await apiCall(
                  stringifyUrl({ url: 'api/customers/vip', query: data }),
                  {
                    method: 'PUT',
                  }
                ),
              });

              reset();
            } catch (error: any) {
              notification.error(handleError(error));
            }
          })}
          disabled={isSubmitting}
        >
          Make VIP
        </Button>
        <Button
          type="default"
          onClick={handleSubmit(async (data) => {
            try {
              notification.success({
                message: await apiCall(
                  stringifyUrl({
                    url: 'api/customers/vip',
                    query: { email: data.email },
                  }),
                  {
                    method: 'DELETE',
                  }
                ),
              });

              reset();
            } catch (error: any) {
              notification.error(handleError(error));
            }
          })}
          disabled={isSubmitting}
        >
          Remove VIP
        </Button>
      </Stack>
    </div>
  );
};

type BookingLinkFields = {
  email: string;
  bookingNumber: string;
};

const bookingLinkValidation = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .min(5)
    .max(318)
    .email('Wrong format')
    .required('Email is required'),
  bookingNumber: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .integer()
    .required('Booking ID is required'),
});

const BookingLinkForm: FC = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm<BookingLinkFields>({
    defaultValues: { email: '', bookingNumber: '' },
    resolver: yupResolver(bookingLinkValidation) as any,
  });

  return (
    <div>
      <Typography variant="h4" fontWeight={700} marginBottom={3}>
        Booking linking:
      </Typography>
      <Stack direction="row" spacing={2} marginBottom={2} width="50vw">
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              error={!!error}
              helperText={error && error.message}
              color="secondary"
              placeholder="Email"
              type="email"
              fullWidth
              label="Email"
              {...field}
              inputRef={ref}
              disabled={isSubmitting}
              required
            />
          )}
        />
        <Controller
          name="bookingNumber"
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              error={!!error}
              helperText={error && error.message}
              color="secondary"
              placeholder="Booking ID"
              variant="outlined"
              type="number"
              inputMode="numeric"
              fullWidth
              {...field}
              inputRef={ref}
              label="Booking ID"
              required
              disabled={isSubmitting}
            />
          )}
        />
      </Stack>
      <Button
        type="primary"
        onClick={handleSubmit(async (data) => {
          try {
            notification.success({
              message: await apiCall(
                stringifyUrl({
                  url: 'api/customers/link',
                  query: data,
                }),
                {
                  method: 'POST',
                  data,
                }
              ),
            });

            reset();
          } catch (error: any) {
            notification.error(handleError(error));
          }
        })}
        disabled={isSubmitting}
      >
        Link booking
      </Button>
    </div>
  );
};

const validationSchema = yup.object().shape({
  hotelId: yup
    .number()
    .nullable()
    .min(1, 'The minimum value is one')
    .required('Hotel Id is required field and must be number'),
  isVisible: yup.boolean().notRequired(),
});

const Form: FC = () => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      hotelId: null,
      isVisible: true,
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      updateHotelSettings(values as any).then(() => {
        setSubmitting(false);
        resetForm();
      });
    },
  });

  const { handleSubmit, isSubmitting, setFieldValue } = formik;

  if (isSubmitting)
    return (
      <Spin size="large" style={{ display: 'block', textAlign: 'center' }} />
    );

  return (
    <FormikProvider value={formik}>
      <StyledForm layout="horizontal" style={{ width: 700 }}>
        <Typography variant="h4" fontWeight={700}>
          Hotel visibility:
        </Typography>
        <FormikField
          key="hotelId"
          label="Hotel ID"
          name="hotelId"
          FormComponent={Input}
        />
        <Button
          type="primary"
          onClick={() => {
            setFieldValue('isVisible', true);
            handleSubmit();
          }}
        >
          Make visible <EyeOutlined />
        </Button>
        <Button
          type="default"
          onClick={() => {
            setFieldValue('isVisible', false);
            handleSubmit();
          }}
        >
          Make invisible <EyeInvisibleOutlined />
        </Button>
      </StyledForm>
    </FormikProvider>
  );
};
