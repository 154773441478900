import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { Spin, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTable, Column } from 'src/components/StyledTable';
import { fetchExports } from 'src/store/exports/reducer';
import {
  exportsSelector,
  exportsTableListSelector,
} from 'src/store/exports/selectors';
import { BackofficeAPI } from 'src/modules/api';
import { AxiosResponse } from 'axios';
import { ThunkDispatcher } from 'src/store';
import { backofficeConfigFetchedSelector } from 'src/store/config/selectors';
import { fetchBackofficeConfig } from 'src/store/config/reducer';

const StyledTag = styled(Tag)`
  display: inline-block;
  margin: 0 0 0 10px !important;
  cursor: pointer !important;
  user-select: none;
`;

enum EXPORT_TYPE {
  Payment = 'Payment',
  Booking = 'Booking',
  ManualBooking = 'ManualBooking',
}

const EXPORT_TYPE_LABEL = {
  [EXPORT_TYPE.Payment]: 'Payment',
  [EXPORT_TYPE.Booking]: 'Live Booking',
  [EXPORT_TYPE.ManualBooking]: 'Manual Booking',
};

const statusIcon: Record<string, ReactNode> = {
  pending: <ClockCircleOutlined style={{ color: '#ffcc00' }} />,
  success: <CheckOutlined style={{ color: '#52c41a' }} />,
  fail: <ExclamationCircleOutlined style={{ color: '#ff0000' }} />,
};

const columns: ColumnProps<any>[] = [
  {
    title: 'Initiation date',
    dataIndex: 'initiationDate',
    key: 'initiationDate',
    render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'Source',
    key: 'type',
    render: ({ type }) => EXPORT_TYPE_LABEL[type as EXPORT_TYPE] ?? type,
  },
  {
    title: 'Initiator',
    dataIndex: 'initiator',
    key: 'initiator',
  },
  {
    title: 'Criteria',
    dataIndex: 'criteria',
    key: 'criteria',
    render: (values: any[]) =>
      values.map((value) => (
        <p key={value} style={{ margin: '3px' }}>
          {value}
        </p>
      )),
  },
  {
    title: 'Status',
    key: 'status',
    width: 260,
    render: ({ status, id, initiationDate, type }) => {
      const getFile = () =>
        BackofficeAPI.get(`/Export/download`, {
          params: { id },
          responseType: 'blob',
        }).then((response: AxiosResponse<any>) => {
          const blob: Blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const fileName = `${moment(initiationDate).format(
            'YYYY_MM_DD__HH_mm_ss'
          )}-${EXPORT_TYPE_LABEL[type as EXPORT_TYPE] ?? type}.xlsx`;
          const objectUrl: string = URL.createObjectURL(blob);
          const a: HTMLAnchorElement = document.createElement('a');

          a.href = objectUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(objectUrl);
        });

      return (
        <div>
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            {statusIcon[status.toLowerCase()]}
          </span>
          <span>{status}</span>
          <StyledTag>
            <span onClick={getFile}>Download file</span>
          </StyledTag>
        </div>
      );
    },
  },
];

export const ExportsList = () => {
  const dispatch = useDispatch<ThunkDispatcher>();
  const exports = useSelector(exportsTableListSelector);
  const isConfigFetched = useSelector(backofficeConfigFetchedSelector);
  const { fetching } = useSelector(exportsSelector);

  useEffect(() => {
    dispatch(fetchExports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isConfigFetched) dispatch(fetchBackofficeConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isConfigFetched) return <Spin size="large" />;

  return (
    <StyledTable
      loading={fetching}
      dataSource={exports}
      bordered={true}
      rowKey="id"
    >
      {columns.map((column: any) => (
        <Column key={column.key} {...column} />
      ))}
    </StyledTable>
  );
};
