import Divider from '@mui/material/Divider';
import moment from 'moment';
import { FC } from 'react';
import styled from 'styled-components/macro';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CancellationPolicy } from 'src/hooks/swr/useRooms';
import { DATE_FORMATS } from 'src/modules/helpers';
import CModal from 'src/components/common/CModal';
import useToggler from 'src/hooks/useToggler';

const Modal = styled(CModal)`
  width: 800px;
`;

const Text = styled.div`
  position: relative;
  text-align: justify;

  & > :last-child {
    margin-bottom: 0;
  }

  * {
    line-height: inherit;
  }
`;

export const NrfIcon = styled.div`
  color: #cf1322;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  padding: 0px 5px;
  border-radius: 5px;
  width: fit-content;
`;

export const RefIcon = styled.div`
  color: #389e0d;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  padding: 0px 5px;
  border-radius: 5px;
  width: fit-content;
`;

interface Props {
  cancellationPolicy: CancellationPolicy;
  cancellationPolicyRaw: string;
}

enum REFUNDABILITY {
  REFUNDABLE = 1,
  NON_REFUNDABLE = 2,
}

enum REFUNDABILITY_STRING {
  REFUNDABLE = 'Refundable',
  NON_REFUNDABLE = 'Nonrefundable',
}

export const CancellationPolicyContent: FC<{
  refundability: number | string;
  dateFrom: string;
}> = ({ refundability, dateFrom }) => {
  const isRefundable =
    refundability === REFUNDABILITY.REFUNDABLE ||
    refundability === REFUNDABILITY_STRING.REFUNDABLE;
  const date1 = moment.utc(dateFrom).format(DATE_FORMATS.CANCELLATION);
  const date2 = moment
    .utc(isRefundable ? moment(dateFrom).add(1, 'minute') : dateFrom)
    .format(DATE_FORMATS.CANCELLATION);

  return (
    <>
      {isRefundable && (
        <>
          Free cancellation untill {date1} GMT
          <br />
          Otherwise the following cancellation policy applies:
          <br />
        </>
      )}
      If the reservation canceled on or after {date2} GMT,
      <br />
      cancellation penalty 100% will be applied
    </>
  );
};

export const CancellationPolicyPopOver: FC<Props> = ({
  cancellationPolicy,
  cancellationPolicyRaw,
}) => {
  const { refundability, dateFrom, description } = cancellationPolicy;

  const { toggler, handleOpen, handleClose } = useToggler();

  const content = (
    <Stack spacing={2} style={{ wordWrap: 'break-word' }}>
      <Typography variant="h2">Cancellation Policy </Typography>
      <Typography variant="h3">
        <CancellationPolicyContent
          refundability={refundability}
          dateFrom={dateFrom}
        />
      </Typography>

      {cancellationPolicyRaw && (
        <>
          <Divider variant="fullWidth" />
          <Typography variant="h2">Supplier Cancellation Policy </Typography>
          <Typography variant="h3">
            <pre>
              {JSON.stringify(JSON.parse(cancellationPolicyRaw), null, 2)}
            </pre>
          </Typography>
        </>
      )}

      {description && (
        <>
          <Divider variant="fullWidth" />
          <Typography variant="h2">Booking Remarks </Typography>
          <Typography variant="h3">
            <Text dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
        </>
      )}
    </Stack>
  );

  const isRefundable =
    refundability === REFUNDABILITY.REFUNDABLE ||
    refundability === REFUNDABILITY_STRING.REFUNDABLE;
  const Icon = isRefundable ? RefIcon : NrfIcon;

  return (
    <>
      <Modal open={toggler} onClose={handleClose} withCloseButton>
        {content}
      </Modal>
      <Icon
        onClick={toggler ? handleClose : handleOpen}
        style={{ cursor: 'pointer' }}
      >
        {isRefundable ? 'REF' : 'NRF'}
      </Icon>
    </>
  );
};
