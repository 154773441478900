import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import _groupBy from 'lodash/groupBy';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useCheckPrice } from 'src/hooks/swr/useCheckPrice';
import { useConfig } from 'src/hooks/swr/useConfig';
import { RoomRate, useRooms } from 'src/hooks/swr/useRooms';
import { useFormatPrice } from 'src/hooks/useFormatCurrency';
import joinBy from 'src/modules/joinBy';
import styled from 'styled-components';
import { BookButton } from './BookButton';
import { PricePopOver } from './TotalPrice/PricePopOver';
import {
  CancellationPolicyPopOver,
  NrfIcon,
  RefIcon,
} from './CancellationPolicy/CancellationPolicyPopOver';
import {
  getSumTaxes,
  TaxesAndFeesPopOver,
} from './TaxAndFee/TaxesAndFeesPopOver';
import { StyledButton } from 'src/containers/BookingEngine/common/Button';
import { REFUNDABILITY } from 'src/containers/Bookings/helpers';
import { Column } from '.';

const StyledTableRow = styled(TableRow)`
  transition: 0.3s;
  background: transparent;
  &:hover {
    background: #d6d7db;
  }
`;

const StyledContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContainerWithTitle: FC<{ children: string | number }> = ({
  children,
}) => <StyledContainer title={String(children)}>{children}</StyledContainer>;

const getLabel = ({
  arr,
  id,
}: {
  arr?: { id: number | string; name: string }[];
  id: number | string;
}) => arr?.find((item) => item?.id === id)?.name ?? id;

const SoldOutCell: FC = () => (
  <Stack display="inline-block" color="red">
    Sold Out
  </Stack>
);

const RefComponent = () => <RefIcon>REF</RefIcon>;

const NrfComponent = () => <NrfIcon>NRF</NrfIcon>;

interface RoomRowProps {
  rate: RoomRate;
  columns: Column[];
}

export const RoomRow: FC<RoomRowProps> = ({ rate, columns }) => {
  const formatPrice = useFormatPrice();
  const { data: config } = useConfig();
  const { data: roomsData, mutate } = useRooms();

  const [rateId, setRateId] = useState<string | undefined>();

  const {
    data: checkedRate,
    isValidating: isRateLoading,
    error: err,
  } = useCheckPrice(rateId);

  const error = err?.response;

  const isSoldOut = error?.status === 410;

  const isPriceChanged =
    rate?.sourcePrice?.total &&
    checkedRate?.sourcePrice?.total &&
    checkedRate?.sourcePrice?.total !== rate?.sourcePrice?.total;

  useEffect(() => {
    if (rateId && roomsData?.rates && checkedRate?.cancellationPolicyRaw) {
      mutate(
        {
          rates: roomsData.rates.map((roomRate) =>
            roomRate.id === rateId
              ? {
                  ...roomRate,
                  ...(checkedRate.cancellationPolicyRaw
                    ? {
                        cancellationPolicyRaw:
                          checkedRate.cancellationPolicyRaw,
                      }
                    : {}),
                }
              : roomRate
          ),
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateId, checkedRate]);

  const renderMap: Record<string, (field: any) => ReactNode> = {
    providerType: ({ providerType }: RoomRate) => (
      <ContainerWithTitle>
        {getLabel({ arr: config?.providerType, id: providerType })}
      </ContainerWithTitle>
    ),
    supplier: ({ supplier }: RoomRate) => (
      <ContainerWithTitle>
        {getLabel({ arr: config?.suppliers, id: supplier })}
      </ContainerWithTitle>
    ),
    feedType: ({ feedType }: RoomRate) => (
      <ContainerWithTitle>
        {getLabel({ arr: config?.feedTypes, id: feedType })}
      </ContainerWithTitle>
    ),
    isGross: ({ isGross }: RoomRate) => (
      <ContainerWithTitle>{isGross ? 'Gross' : 'Net'}</ContainerWithTitle>
    ),
    rooms: ({ rooms }: RoomRate) => (
      <ContainerWithTitle>
        {Object.entries(_groupBy(rooms, (room) => room.name))
          .map(([roomName, rooms]) =>
            rooms.length > 1 ? `${rooms.length}x ${roomName}` : roomName
          )
          .join('; ')}
      </ContainerWithTitle>
    ),
    foodType: ({ foodType }: RoomRate) => (
      <ContainerWithTitle>
        {getLabel({ arr: config?.foodType, id: foodType })}
      </ContainerWithTitle>
    ),
    'cancellationPolicy.refundability': ({
      cancellationPolicy,
      cancellationPolicyRaw,
    }: RoomRate) => (
      <Stack direction="row" spacing={1}>
        {cancellationPolicyRaw ? (
          <CancellationPolicyPopOver
            cancellationPolicy={cancellationPolicy}
            cancellationPolicyRaw={cancellationPolicyRaw}
          />
        ) : (
          <div>
            {cancellationPolicy.refundability === REFUNDABILITY.REFUNDABLE ? (
              <RefComponent />
            ) : (
              <NrfComponent />
            )}
          </div>
        )}
      </Stack>
    ),
    sourcePrice: ({ sourcePrice }: RoomRate) => (
      <>
        {sourcePrice ? (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            color={isPriceChanged ? 'red' : '#1948F0'}
          >
            <div>{formatPrice(sourcePrice.total)}</div>
            <PricePopOver
              label="Price per night"
              price={formatPrice(sourcePrice.perNight)}
            />
          </Stack>
        ) : (
          '-'
        )}
      </>
    ),
    srsplPrice: ({ supplierRecommendedSellingPrice }: RoomRate) => (
      <>
        {supplierRecommendedSellingPrice?.total ? (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            color={isPriceChanged ? 'red' : '#1948F0'}
          >
            <div>{formatPrice(supplierRecommendedSellingPrice?.total)}</div>
            <PricePopOver
              label="Price per night"
              price={formatPrice(supplierRecommendedSellingPrice.perNight)}
            />
          </Stack>
        ) : (
          '-'
        )}
      </>
    ),
    taxesAndFees: ({ taxesAndFees }: RoomRate) => {
      const groupedTaxes = _groupBy(
        taxesAndFees,
        (item) => item.isIncludedInPrice
      );
      const inclSum = getSumTaxes(groupedTaxes.true);
      const exclSum = getSumTaxes(groupedTaxes.false);

      const newArr = [];

      if (inclSum) {
        newArr.push(formatPrice(inclSum) + ' incl.');
      }

      if (exclSum) {
        newArr.push(formatPrice(exclSum) + ' excl.');
      }

      if (!inclSum && !exclSum) return <br />;

      return (
        <Stack direction="row" color="#1948F0" alignItems="center">
          <div>{joinBy(newArr, <br />)}</div>
          <TaxesAndFeesPopOver taxesAndFees={taxesAndFees} />
        </Stack>
      );
    },
    actionButton: () => (
      <>
        {isPriceChanged && (
          <Typography fontSize={12} color="red">
            {Number(checkedRate?.sourcePrice?.total) >
            Number(rate?.sourcePrice?.total)
              ? 'Price increased'
              : 'Price decreased'}
          </Typography>
        )}
        {checkedRate?.cancellationPolicyRaw ? (
          <BookButton
            roomRateId={rate.id}
            cancellationPolicyRaw={checkedRate.cancellationPolicyRaw}
          />
        ) : (
          <>
            {error?.data ? (
              <>
                <SoldOutCell />
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  children={
                    <Alert severity="error">
                      {isSoldOut
                        ? `${error?.statusText}: TraceId ${error?.data?.traceId}`
                        : Object.values(error).join(', ')}
                    </Alert>
                  }
                />
              </>
            ) : (
              <StyledButton
                disabled={isRateLoading}
                onClick={() => setRateId(rate.id)}
                variant="contained"
                size="small"
                fullWidth
              >
                {isRateLoading ? (
                  <CircularProgress size="1em" color="primary" />
                ) : (
                  'Check'
                )}
              </StyledButton>
            )}
          </>
        )}
      </>
    ),
  };

  return (
    <StyledTableRow>
      {columns.map((column) => (
        <TableCell key={column.key} style={{ width: column.width }}>
          <Typography variant="body4">
            {renderMap[column.key]({ ...rate, ...checkedRate })}
          </Typography>
        </TableCell>
      ))}
    </StyledTableRow>
  );
};
