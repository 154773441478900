import { createAsyncAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { BackofficeAPI } from 'src/modules/api';
import { mapBookingsListParams } from 'src/modules/apiMappers';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { TDispatch } from '..';

interface BookingsList {
  totalCount: number;
  result: IBookingItem[];
}

interface IBookingItem {
  newBookingId: string;
  checkInDate: string;
  checkOutDate: string;
  checkStatus: number;
  creationDateTime: string;
  customerId: number;
  guestFirstName: string;
  guestLastName: string;
  customerPhone: string;
  destination: string;
  hotelName: string;
  offerId: string;
  oldBookingId: string;
  providerType: number;
  bookingStatus: number;
  supplier: string;
  supplierId: string;
  filters: any;
  config: any;
  countryCode: string;
}

const fetchBookingsActions = createAsyncAction(
  'FETCH_BOOKINGS_REQUEST',
  'FETCH_BOOKINGS_SUCCESS',
  'FETCH_BOOKINGS_FAILURE'
)<undefined, any, any>();

export interface IParams {
  [propName: string]: any;
}

export interface BookingsState {
  fetching?: boolean;
  items: IBookingItem[] | null;
  totalCount: number;
  searchParams: IParams;
}

export const defaultSearchParams = {
  page: 1,
  pageSize: 10,
  BookingDate: [moment().subtract(7, 'day'), moment()],
  BookingStatus: [2, 3, 4, 5],
};

const BookingsDefaultState: BookingsState = {
  fetching: false,
  items: [],
  totalCount: 0,
  searchParams: defaultSearchParams,
};

export const fetchBookings =
  (params: any = {}) =>
  (dispatch: TDispatch) => {
    const mappedParams = mapBookingsListParams(params);
    dispatch(fetchBookingsActions.request());

    return BackofficeAPI.get<BookingsList>('/Booking/list', {
      params: mappedParams,
    }).then(
      (response: AxiosResponse<any>) =>
        dispatch(
          fetchBookingsActions.success({
            totalCount: response.data.totalCount,
            items: response.data.result,
            params,
          })
        ),
      (error: AxiosError<any>) => dispatch(fetchBookingsActions.failure(error))
    );
  };

export const bookingsReducer = handleActions<BookingsState>(
  {
    [fetchBookingsActions.request.toString()]: (state) => ({
      ...state,
      fetching: true,
    }),
    [fetchBookingsActions.success.toString()]: (state, { payload }) => ({
      ...state,
      fetching: false,
      items: payload.items,
      // @ts-expect-error
      searchParams: payload.params,
      totalCount: payload.totalCount,
    }),
    [fetchBookingsActions.failure.toString()]: (state) => ({
      ...state,
      fetching: false,
      items: null,
    }),
  },
  BookingsDefaultState
);
