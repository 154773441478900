import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { FieldArray, FieldArrayRenderProps, FormikValues } from 'formik';
import { FormikField } from 'src/components/FormikField';
import { StyledForm } from 'src/components/StyledForm';
import { MAX_CHILDREN, MAX_PEOPLE } from 'src/containers/Bookings/helpers';
import {
  useFormFieldsMap,
  StyledCol,
} from 'src/containers/Bookings/BookingsList/table';
import styled from 'styled-components';

const StyledLabel = styled.label`
  color: #7b7b9b;
  font-size: 12px;
  font-weight: 500;
`;

const StyledBigCol = styled(StyledCol)`
  flex-basis: 100%;
  width: 100%;
`;

interface IForm {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: FormikValues;
}

export const Form = ({ values, setFieldValue }: IForm) => {
  const {
    roomName,
    guestFirstName,
    guestLastName,
    foodType,
    preferredBed,
    specialRequest,
    childrenAges,
    residency,
  } = useFormFieldsMap();

  return (
    <StyledForm layout="horizontal">
      <Row gutter={24}>
        <StyledBigCol>
          <FormikField {...roomName} />
        </StyledBigCol>
      </Row>
      <Row gutter={24}>
        <StyledBigCol>
          <FormikField {...residency} />
        </StyledBigCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...guestFirstName} />
        </StyledCol>
        <StyledCol>
          <FormikField {...guestLastName} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledCol>
          <FormikField {...preferredBed} />
        </StyledCol>
        <StyledCol>
          <FormikField {...foodType} />
        </StyledCol>
      </Row>
      <Row gutter={24}>
        <StyledBigCol>
          <FormikField {...specialRequest} />
        </StyledBigCol>
      </Row>
      <FieldArray
        name="childrenAges"
        render={({ form, ...arrayHelpers }: FieldArrayRenderProps) => (
          <>
            <Row gutter={24}>
              <StyledCol>
                <Row style={{ alignItems: 'center' }}>
                  <StyledLabel>Adults:</StyledLabel>
                </Row>
                <Row style={{ alignItems: 'center' }}>
                  <Button
                    shape="circle"
                    icon={<MinusOutlined />}
                    disabled={values?.adults === 1}
                    onClick={() => setFieldValue('adults', values?.adults - 1)}
                  />
                  <Col style={{ width: 40, textAlign: 'center' }}>
                    <h2 style={{ margin: 0 }}>{values?.adults}</h2>
                  </Col>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    disabled={
                      MAX_PEOPLE -
                        values?.childrenAges?.length -
                        values?.adults ===
                      0
                    }
                    onClick={() => setFieldValue('adults', values?.adults + 1)}
                  />
                </Row>
              </StyledCol>
              <StyledCol>
                <Row style={{ alignItems: 'center' }}>
                  <StyledLabel>Children:</StyledLabel>
                </Row>
                <Row style={{ alignItems: 'center' }}>
                  <Button
                    shape="circle"
                    icon={<MinusOutlined />}
                    disabled={values?.childrenAges?.length === 0}
                    onClick={() =>
                      arrayHelpers.remove(values?.childrenAges?.length - 1)
                    }
                  />
                  <Col style={{ width: 40, textAlign: 'center' }}>
                    <h2 style={{ margin: 0 }}>
                      {values?.childrenAges?.length}
                    </h2>
                  </Col>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    disabled={
                      MAX_PEOPLE -
                        values?.adults -
                        values?.childrenAges?.length ===
                        0 || values?.childrenAges?.length === MAX_CHILDREN
                    }
                    onClick={() => arrayHelpers.push(0)}
                  />
                </Row>
              </StyledCol>
            </Row>

            <Row gutter={24}>
              {values?.childrenAges?.map((age: number, childIndex: number) => (
                <StyledCol key={childIndex}>
                  <FormikField
                    {...childrenAges}
                    name={`childrenAges.${childIndex}`}
                    label={`Child Age ${childIndex + 1}`}
                  />
                </StyledCol>
              ))}
            </Row>
          </>
        )}
      />
    </StyledForm>
  );
};
